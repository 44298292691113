import ApiService from './ApiService'

import AuthService from './AuthService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    usuarioInfo,
} from './UsuarioService'



class VwArquivoAcessoService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwArquivoAcesso)
    }

    async buscarArquivoList(variaveisGlobais, buscaArquivo) {

        let params = `idUsuarioLogado=${AuthService.obterUsuarioLogado(variaveisGlobais)}`
                            
        params = `${params}&idUsuario=${buscaArquivo.idUsuario}`

        if (buscaArquivo.grupoArquivo) {
            params = `${params}&grupoArquivo=${buscaArquivo.grupoArquivo}`
        }

        if (buscaArquivo.tipoArquivo) {
            params = `${params}&tipoArquivo=${buscaArquivo.tipoArquivo}`
        }

        if (buscaArquivo.instituicaoFinanceira) {
            params = `${params}&instituicaoFinanceira=${buscaArquivo.instituicaoFinanceira}`
        }

        if (buscaArquivo.extensaoArquivo) {
            params = `${params}&extensaoArquivo=${buscaArquivo.extensaoArquivo}`
        }

        if (buscaArquivo.statusProcessamento) {
            params = `${params}&statusProcessamento=${buscaArquivo.statusProcessamento}`
        }

        if (buscaArquivo.idConfiguracaoAcesso) {
            params = `${params}&idConfiguracaoAcesso=${buscaArquivo.idConfiguracaoAcesso}`
        }

        if (buscaArquivo.idUsuarioAcesso) {
            params = `${params}&idUsuarioAcesso=${buscaArquivo.idUsuarioAcesso}`
        }

        if (buscaArquivo.idClienteAcesso) {
            params = `${params}&idClienteAcesso=${buscaArquivo.idClienteAcesso}`
        }

        if (buscaArquivo.numeroCotacaoAcesso) {
            params = `${params}&numeroCotacaoAcesso=${buscaArquivo.numeroCotacaoAcesso}`
        }

        if (buscaArquivo.numeroPropostaAcesso) {
            params = `${params}&numeroPropostaAcesso=${buscaArquivo.numeroPropostaAcesso}`
        }
        
        if (buscaArquivo.numeroApoliceAcesso) {
            params = `${params}&numeroApoliceAcesso=${buscaArquivo.numeroApoliceAcesso}`
        }

        if (buscaArquivo.numeroConsorcioAcesso) {
            params = `${params}&numeroConsorcioAcesso=${buscaArquivo.numeroConsorcioAcesso}`
        }

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwArquivoAcessoBuscarArquivoList}/?${params}`, paramsAux, false)
    }
}

export default VwArquivoAcessoService