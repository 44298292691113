import ApiService from './ApiService'

import AuthService from './AuthService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    usuarioInfo,
} from './UsuarioService'



class ArquivoService extends ApiService {

    constructor() {
        super(HARDCODE.apiArquivos)
    }

    async salvarArquivoArquivoAcesso(variaveisGlobais, arquivoArquivoAcesso) {

        const body = {
            arquivoArquivoAcessoDTO: arquivoArquivoAcesso,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.post(variaveisGlobais, HARDCODE.apiArquivosSalvarArquivoArquivoAcesso, body, false)
    }

    async atualizarArquivo(variaveisGlobais, arquivoArquivoAcesso) {

        const body = {
            arquivoArquivoAcessoDTO: arquivoArquivoAcesso,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.put(variaveisGlobais, HARDCODE.apiArquivosAtualizarArquivo, body, false)
    }
    
    async atualizarArquivoStatusProcessamento(variaveisGlobais, arquivoArquivoAcesso) {

        const body = {
            arquivoArquivoAcessoDTO: arquivoArquivoAcesso,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.put(variaveisGlobais, HARDCODE.apiArquivosAtualizarArquivoStatusProcessamento, body, false)
    }

    async salvarAtualizarListaTipoArquivo(variaveisGlobais, arquivoListaTipoArquivo) {

        const body = {
            arquivoListaTipoArquivoDTO: arquivoListaTipoArquivo,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.put(variaveisGlobais, HARDCODE.apiArquivosSalvarAtualizarListaTipoArquivo, body, false)
    }

    deletarArquivoArquivoAcesso(variaveisGlobais, idUsuario, idArquivo) {

        let params = `${idUsuario}`

        params = `${params}/${idArquivo}`

        params = `${params}/${HARDCODE.conexao.bucket}`

        return this.delete(variaveisGlobais, `${HARDCODE.apiArquivosDeletarArquivoArquivoAcesso}/${params}`, false)
    }

    async buscarArquivoByIdMandatorioConteudo(variaveisGlobais, idUsuario, idArquivo) {
        
        let params = `idUsuario=${idUsuario}`

        params = `${params}&bucket=${HARDCODE.conexao.bucket}`

        params = `${params}&idArquivo=${idArquivo}`

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiArquivosBuscarArquivoByIdMandatorioConteudo}/?${params}`,
            paramsAux, false)
    }

    async buscarArquivoByGrupoArquivoTipoArquivoConteudo(variaveisGlobais, idUsuario, grupoArquivo, tipoArquivo,
        idConfiguracaoAcessoNULL, idUsuarioAcessoNULL, idClienteAcessoNULL) {
        
        let params = `idUsuarioLogado=${AuthService.obterUsuarioLogado(variaveisGlobais)}`
                    
        params = `${params}&idUsuario=${idUsuario}`
        
        params = `${params}&bucket=${HARDCODE.conexao.bucket}`

        params = `${params}&grupoArquivo=${grupoArquivo}`

        params = `${params}&tipoArquivo=${tipoArquivo}`

        if (idConfiguracaoAcessoNULL) {

            params = `${params}&idConfiguracaoAcesso=${idConfiguracaoAcessoNULL}`
        }

        if (idUsuarioAcessoNULL) {

            params = `${params}&idUsuarioAcesso=${idUsuarioAcessoNULL}`
        }

        if (idClienteAcessoNULL) {

            params = `${params}&idClienteAcesso=${idClienteAcessoNULL}`
        }

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiArquivosBuscarArquivoByGrupoArquivoTipoArquivoConteudo}/?${params}`,
            paramsAux, false)
    }
}

export default ArquivoService