import {
    COLOR
} from '../../business-const/Color/Color'

import { 
    IsMdDownScreen,
} from '../../business-components/Service/GlobalService'



export const FONTSIZE = () => {

    return {
        TextField: IsMdDownScreen() ? "80%" : "90%",
        TextFieldDestaque: IsMdDownScreen() ? "85%" : "95%",
        LabelTextField: IsMdDownScreen() ? "65%" : "85%",
        LabelTextFieldWithValue: IsMdDownScreen() ? "75%" : "95%",
        TextFieldSelectRenderValue: "100%",
        TextFieldSelectRenderValueDestaque: "110%",
        TextFieldSelectRenderValueCorDestaque: "100%",//"110%",
        CheckBox: IsMdDownScreen() ? "80%" : "90%",
        HelperText: "0.5125rem", // se for alterado, revisar _typography.js text-helperText
        ChartPadraoDataLabels: "12px",
        ChartPadraoDataLabelsReport: "10px",
        ChartPadraoDataLabelsEspecial: "12px",
        ChartPadraoDataLabelsEspecialReport: "10px",
        ChartPadraoDataLabelsIdade: "9px",
        ChartPadraoDataLabelsIdadeReport: "8px",
        ChartPadraoDataLabelsIdadeMaior: "11px",
        ChartPadraoDataLabelsIdadeMaiorReport: "9px",
        ChartPadraoDataLabelsLarge: "16px",
        ChartPadraoDataLabelsLargeReport: "12px",
        ChartPadraoDataLabelsMedium: "14px",
        ChartPadraoDataLabelsMediumReport: "10px",
        ChartPadraoXaxis: "11px",
        ChartPadraoXaxisReport: "10px",
        ChartPadraoXaxisLarge: "16px",
        ChartPadraoXaxisLargeReport: "14px",
        ChartPadraoXaxisMedium: "14px",
        ChartPadraoXaxisMediumReport: "12px",
        ChartPadraoYaxis: "12px",
        ChartPadraoYaxisReport: "10px",
        LinkPadraoSubmitDashboard: "20px",
    }
}



export const FONTWEIGHT = () => {

    return {
        TextField: 500,
        TextFieldDestaque: 500,//200,
        TextFieldSelectRenderValue: 500,//200,
        TextFieldSelectRenderValueDestaque: 400,//200,
        TextFieldSelectRenderValueCorDestaque: 400,//500,
        LabelTextField: 500,
        LabelTextFieldWithValue:600,
        CheckBox: 400,
        ChartPadraoDataLabels: 300,
        ChartPadraoDataLabelsReport: 300,
        ChartPadraoDataLabelsEspecial: 600,
        ChartPadraoDataLabelsEspecialReport: 600,
        ChartPadraoDataLabelsIdade: 600,
        ChartPadraoDataLabelsIdadeReport: 600,
        ChartPadraoDataLabelsIdadeMaior: 600,
        ChartPadraoDataLabelsIdadeMaiorReport: 600,
        ChartPadraoDataLabelsLarge: 600,
        ChartPadraoDataLabelsLargeReport: 600,
        ChartPadraoDataLabelsMedium: 600,
        ChartPadraoDataLabelsMediumReport: 600,
        ChartPadraoXaxis: 300,
        ChartPadraoXaxisReport: 300,
        ChartPadraoXaxisLarge: 400,
        ChartPadraoXaxisLargeReport: 400,
        ChartPadraoXaxisMedium: 400,
        ChartPadraoXaxisMeidumReport: 400,
        ChartPadraoYaxis: 300,
        ChartPadraoYaxisReport: 300,
        LinkPadraoSubmitDashboard: 500,
    }
}



export const STYLE = ( destaque, disabled ) => {

    return {
        styleTextField: {
            backgroundColor: disabled ? COLOR().bgTextFieldDisabled : COLOR().bgTextField,
            color: disabled ? COLOR().textFieldDisabled : COLOR().textField,
            fontSize: destaque ? FONTSIZE().TextFieldDestaque : FONTSIZE().TextField,
            fontWeight: destaque ? FONTWEIGHT().TextFieldDestaque : FONTWEIGHT().TextField,
            borderRadius: '5px',
        },

        styleLabelTextField: {
            fontSize: FONTSIZE().LabelTextField,
            fontWeight: FONTWEIGHT().LabelTextField,
        },

        styleLabelTextFieldWithValue: {
            fontSize: FONTSIZE().LabelTextFieldWithValue,
            fontWeight: FONTWEIGHT().LabelTextFieldWithValue,
        },

        styleHelperText: {
            color: COLOR().helperText,
            fontSize: FONTSIZE().HelperText,
        },

        styleHelperTextDesejavel: {
            color: COLOR().helperTextDesejavel,
            fontSize: FONTSIZE().HelperText,
        },

        styleChartPadraoDataLabels: {
            fontSize: FONTSIZE().ChartPadraoDataLabels,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabels,
        },

        styleChartPadraoDataLabelsReport: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsReport,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsReport,
        },

        styleChartPadraoDataLabelsEspecial: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsEspecial,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsEspecial,
        },
        
        styleChartPadraoDataLabelsEspecialReport: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsEspecialReport,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsEspecialReport,
        },

        styleChartPadraoDataLabelsIdade: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsIdade,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsIdade,
        },

        styleChartPadraoDataLabelsIdadeReport: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsIdadeReport,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsIdadeReport,
        },

        styleChartPadraoDataLabelsIdadeMaior: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsIdadeMaior,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsIdadeMaior,
        },

        styleChartPadraoDataLabelsIdadeMaiorReport: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsIdadeMaiorReport,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsIdadeMaiorReport,
        },

        styleChartPadraoDataLabelsLarge: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsLarge,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsLarge,
        },

        styleChartPadraoDataLabelsLargeReport: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsLargeReport,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsLargeReport,
        },

        styleChartPadraoDataLabelsMedium: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsMedium,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsMedium,
        },

        styleChartPadraoDataLabelsMediumReport: {
            fontSize: FONTSIZE().ChartPadraoDataLabelsMediumReport,
            fontWeight: FONTWEIGHT().ChartPadraoDataLabelsMediumReport,
        },

        styleChartPadraoXaxis: {
            fontSize: FONTSIZE().ChartPadraoXaxis,
            fontWeight: FONTWEIGHT().ChartPadraoXaxis,
        },

        styleChartPadraoXaxisReport: {
            fontSize: FONTSIZE().ChartPadraoXaxisReport,
            fontWeight: FONTWEIGHT().ChartPadraoXaxisReport,
        },

        styleChartPadraoXaxisLarge: {
            fontSize: FONTSIZE().ChartPadraoXaxisLarge,
            fontWeight: FONTWEIGHT().ChartPadraoXaxisLarge,
        },
        
        styleChartPadraoXaxisLargeReport: {
            fontSize: FONTSIZE().ChartPadraoXaxisLargeReport,
            fontWeight: FONTWEIGHT().ChartPadraoXaxisLargeReport,
        },
        
        styleChartPadraoXaxisMedium: {
            fontSize: FONTSIZE().ChartPadraoXaxisMedium,
            fontWeight: FONTWEIGHT().ChartPadraoXaxisMedium,
        },

        styleChartPadraoXaxisMediumReport: {
            fontSize: FONTSIZE().ChartPadraoXaxisMediumReport,
            fontWeight: FONTWEIGHT().ChartPadraoXaxisMediumReport,
        },

        styleChartPadraoYaxis: {
            fontSize: FONTSIZE().ChartPadraoYaxis,
            fontWeight: FONTWEIGHT().ChartPadraoYaxis,
        },

        styleChartPadraoYaxisReport: {
            fontSize: FONTSIZE().ChartPadraoYaxisReport,
            fontWeight: FONTWEIGHT().ChartPadraoYaxisReport,
        },
    }
}



export const STYLEHTML = (report) => {

    return {
        styleHtmlCapitacaoLeadsPreencher: 'style="margin-left: ' + (report ? '20px' : '25px') + '; margin-top: 15px"',
        styleHtmlCapitacaoLeadsAjudarAnalisar: 'style="margin-left: ' + (report ? '20px' : '25px') + '; margin-top: 7px"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero: 'style="font-size: ' + (report ? '8px' : '15px') +'; font-weight: bold; color: black"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraIdade: 'style="font-size: ' + (report ? '7px' : '12px') +'; font-weight: normal; color: black"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargem1oItem: 'style="margin-left: 25px; margin-top: 5px"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargem2oItem: 'style="margin-left: 25px; margin-top: 8px"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargemImportante: 'style="margin-top: 7px"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorDepositoMensalViverRentabilidade: 'style="color: ' + COLOR().corChartPadraoManterReservaManterPadraoVida + '"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorDepositoMensalConsumirRecursos: 'style="color: ' + COLOR().corChartPadraoConsumirReservaManterPadraoVida + '"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorViverRentabilidadeSemAporte: 'style="color: ' + COLOR().corChartPadraoManterReservaSemNovosAportes + '"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorConsumirReservaSemAporte: 'style="color: ' + COLOR().corChartPadraoConsumirReservaSemNovosAportes + '"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorSituacaoAtualSuperavit: 'style="color: ' + COLOR().corChartPadraoVerde + '"',
        styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorSituacaoAtualDeficit: 'style="color: ' + COLOR().corChartPadraoVermelho + '"',
        styleHtmlGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico: 'style="color: ' + COLOR().resumoPlanejamentoEspecializacaoPeriodoSabatico + '; font-weight: 500"',
        styleHtmlPrevidenciaPrivadaValorPrincipal: 'style="font-size: ' + (report ? '16px' : '20px') + '; font-weight: 600; color: #3232d7"',
        styleHtmlPrevidenciaPrivadaValorSecundario: 'style="font-size: ' + (report ? '14px' : '17px') + '; font-weight: 600; color: #1e7d1e"',
        styleHtmlPrevidenciaPrivadaMaiorMenor: 'style="font-size: ' + (report ? '14px' : '17px') + '; font-weight: 600"',
        styleHtmlPrevidenciaPrivadaIdentacao: 'style="margin-left: ' + (report ? '20px' : '25px') + '; margin-top: 5px"',
    }
}



export const CLASSNAME = ( report, aumentoLetra, aumentoLetraReport, values ) => {

    aumentoLetra = aumentoLetra ? aumentoLetra : 0
    aumentoLetraReport = aumentoLetraReport && !values.modoApresentacao ? aumentoLetraReport : 0

    const classNameValorAcumulado = "mt-1 text-14 font-light text-muted"
    const classNameValorReceitaDespesaCliente = "mt-1 text-12 font-light text-primary"
    const classNameValorReceitaDespesaConjuge = "mt-1 text-12 font-light text-green"
    const classNameAnosProtegerProtegido = "mt-1 text-14 font-light text-error"

    return {
        dialogContentClassNameFormExpandir: 'bg-default plr-sm-80',

        helpTextInLine: 'ml-1 text-muted text-9',

        dragDropTwoListTitle: 'text-18 font-medium text-center',

        valorAcumulado: classNameValorAcumulado + " font-light",
        valorReceitaDespesaCliente: classNameValorReceitaDespesaCliente + " font-light",
        valorReceitaDespesaConjuge: classNameValorReceitaDespesaConjuge + " font-light",
        anosProtegerProtegido: classNameAnosProtegerProtegido + " font-light",

        valorAcumuladoTitulo: classNameValorAcumulado + " font-medium",
        valorReceitaDespesaClienteTitulo: classNameValorReceitaDespesaCliente + " font-medium",
        valorReceitaDespesaConjugeTitulo: classNameValorReceitaDespesaConjuge + " font-medium",
        anosProtegerProtegidoTitulo: classNameAnosProtegerProtegido + " font-medium",

        formataTableCellHeadExibeDados:
            report
                ?
                    "mt-2 font-bold text-" + (8 + aumentoLetraReport) + " whitespace-pre-wrap align-bottom" // mudança da fonte: font-medium text-9
                :
                    "mt-2 font-bold text-" + (9 + aumentoLetra) + " whitespace-pre-wrap align-bottom", // mudança da fonte: font-light-medium text-12
        formataTableCellHeadExibeDadosDestaque: 
            report 
                ? 
                    "mt-2 font-bold text-" + (9 + aumentoLetraReport) + " whitespace-pre-wrap text-tableCellHeadExibeDadosDestaque" // mudança da fonte: font-light-medium
                : 
                    "mt-2 font-bold text-" + (10 + aumentoLetra) + " whitespace-pre-wrap text-tableCellHeadExibeDadosDestaque", // mudança da fonte: font-light-medium text-12

        formataTableCellBodyExibeDados:
            report
                ?
                    "mt-1 font-light-medium text-" + (9 + aumentoLetraReport) + " whitespace-pre-wrap"
                : 
                    "mt-1 font-light-medium text-" + (11 + aumentoLetra) + " whitespace-pre-wrap",
        formataTableCellBodyExibeDadosObservacao:
            report
                ?
                    "mt-1 font-light-medium text-" + (6 + aumentoLetraReport) + " whitespace-pre-wrap"
                :
                    "mt-1 font-light-medium text-" + (9 + aumentoLetra) + " whitespace-pre-wrap",
        formataTableCellBodyExibeDadosValorNegativo:
            report ? 
                "mt-1 font-light-medium text-" + (9 + aumentoLetraReport) + " whitespace-pre-wrap text-valorNegativo"
            : 
                "mt-1 font-light-medium text-" + (11 + aumentoLetra) + " whitespace-pre-wrap text-valorNegativo",
        formataTableCellBodyExibeDadosDestaque:
            report
                ?
                    "mt-1 font-light-medium text-" + (9 + aumentoLetraReport) + " whitespace-pre-wrap text-tableCellBodyExibeDadosDestaque"
                :
                    "mt-1 font-light-medium text-" + (11 + aumentoLetra) + " whitespace-pre-wrap text-tableCellBodyExibeDadosDestaque",
        formataTableCellBodyExibeDadosSubTotal:
            //report ? "mt-7 text-muted-strong font-medium font-underline font-italic text-9 whitespace-pre-wrap" : "mt-7 text-muted-strong font-medium font-underline font-italic text-13 whitespace-pre-wrap", // mudança da fonte
            report
                ?
                    "mt-6 font-bold text-" + (8 + aumentoLetraReport) + " whitespace-pre-wrap"
                :
                    "mt-6 font-bold text-" + (12 + aumentoLetra) + " whitespace-pre-wrap",
        formataTableCellBodyExibeDadosSubTotal0:
            //report ? "mt-6 mb-6 text-muted-strong font-bold text-10 whitespace-pre-wrap" : "mt-6 mb-6 text-muted-strong font-bold text-15 whitespace-pre-wrap", // mudança da fonte
            report
                ?
                    "mt-6 mb-6 font-bold font-underline text-" + (10 + aumentoLetraReport) + " whitespace-pre-wrap"
                :
                    "mt-6 mb-6 font-bold font-underline text-" + (14 + aumentoLetra) + " whitespace-pre-wrap",
        formataTableCellBodyExibeDadosSubTotal0MargemBottomMenor:
            //report ? "mt-6 mb-0 text-muted-strong font-bold text-10 whitespace-pre-wrap" : "mt-6 mb-0 text-muted-strong font-bold text-15 whitespace-pre-wrap", // mudança da fonte
            report
                ?
                    "mt-6 mb-0 font-bold font-underline text-" + (10 + aumentoLetraReport) + " whitespace-pre-wrap"
                :
                    "mt-6 mb-0 font-bold font-underline text-" + (14 + aumentoLetra) + " whitespace-pre-wrap",
        formataTableCellBodyExibeDadosTotal:
            //report ? "mt-5 font-bold text-10 whitespace-pre-wrap" : "mt-5 font-bold text-15 whitespace-pre-wrap", // mudança da fonte
            report
                ?
                    "mt-5 font-bold text-" + (10 + aumentoLetraReport) + " whitespace-pre-wrap"
                :
                    "mt-5 font-bold text-" + (15 + aumentoLetra) + " whitespace-pre-wrap",

        formataListCabecalho: "font-bold text-20",
        formataListIdentificacaoUsuario: "font-bold text-muted",
        formataListTitulo: "font-bold text-8 text-muted",
        formataListValor: "text-12",

        formataPrimeiraReuniaoLinhaColuna: 'text-13 font-medium',
        formataPrimeiraReuniaoLabelValorLabel: 'text-14 text-muted',
        formataPrimeiraReuniaoLabelValorValor: 'text-16 font-medium',
        formataPrimeiraReuniaoLabel: 'text-10 text-muted',
        formataPrimeiraReuniaoTextoValorNumeroData: 'text-14 font-medium',
        formataPrimeiraReuniaoTextoDestaque: 'text-20 font-medium',
        formataPrimeriaReuniaoLinkManterReservaManterPadraoVida: 'text-16 font-bold text-corChartPadraoManterReservaManterPadraoVida',
        formataPrimeiraReuniaoLinkManterReservaSemNovosAportes: 'text-16 font-bold text-corChartPadraoManterReservaSemNovosAportes',
        formataPrimeiraReuniaoLinkConsumirReservaManterPadraoVida: 'text-16 font-bold text-corChartPadraoConsumirReservaManterPadraoVida',
        formataPrimeiraReuniaoLinkConsumirReservaSemNovosAportes: 'text-16 font-bold text-corChartPadraoConsumirReservaSemNovosAportes',

        formataEstudoDiagnosticoAposentadoriaTitulo: 'text-14 text-muted-strong',
        formataEstudoDiagnosticoAposentadoriaValor: 'text-16 font-bold',
        formataEstudoDiagnosticoAposentadoriaResumo: 'text-13 text-muted-strong',
        formataEstudoDiagnosticoAposentadoriaResumoReport: 'text-8 text-muted-strong',

        formataNotaConsorcio: 'text-12',

        formataAIFT: 'text-14 text-muted-strong',

        formataEstudoHipotesesRetorno: 'font-light-medium',

        formataEstudoPrevidenciaPrivadaResumo: 'text-12',
        formataEstudoPrevidenciaPrivadaResumoReport: 'text-9',

        formataReportCapaNomeCurto: 'text-30 font-light', // mudança da fonte: text-40
        formataReportCapaTitulo2: 'text-26 font-medium', // mudança da fonte: text-32

        formataReportOQueEsperarDesseTrabalhoRetrato: 'text-muted text-11', // mudança da fonte: text-13
        formataReportOQueEsperarDesseTrabalhoPaisagem: 'text-muted text-10', // mudança da fonte: text-13
        formataReportConferenciaDadosCabecalho: 'text-22 font-light-medium',
        formataReportConferenciaDadosLabel: 'text-muted text-10',
        formataReportConferenciaDadosConteudo: 'text-13 font-normal',
        formataReportConferenciaDadosObjetivos: 'text-15 font-light-medium',
        formataReportDetalhamento: 'text-muted text-15',

        formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaCabecalho: 'text-15 font-medium font-bold', // mudança da fonte: font-bold
        formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaLabel:
            report
                ?
                    'text-muted-strong text-9 font-light'
                :
                    'text-muted-strong text-11 font-light',
        formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValor:
            report // mudança da fonte
                ?
                    'text-10 font-medium'
                :
                    'text-13 font-medium',
        formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorLigth:
            report // mudança da fonte
                ?
                    'text-8 font-ligth'
                :
                    'text-12 font-ligth',
        formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorNegativo:
            report // mudança da fonte
                ?
                    'text-valorNegativo text-10 font-medium'
                :
                    'text-valorNegativo text-13 font-medium',
        
        formataReportTitulo: 'text-15 text-muted-strong text-light',
        formataReportTituloDestaque: 'text-21 font-bold', // mudança da fonte: text-22 acrescentou font-bold
        formataReportSubTitulo: 'text-15 font-medium text-muted',
        formataReportSubTituloBoldPreto: 'text-15 font-bold', // mudança da fonte
        formataReportTituloColuna: 'text-15 font-bold',
        formataReportTituloLinha: 'text-17 font-medium text-muted',
        formataReportTituloLinhaTotal: 'text-21 font-bold', // mudança da fonte: font-medium
        formataReportTextoValorNumeroPercData: 'text-14 font-ligth',
        formataReportTextoValorNegativo: 'text-14 font-ligth text-error',
        formataReportTextoTotal: 'text-14 font-bold',
        formataReportValorTotal: 'text-14 font-bold',
        formataReportValorSubTotal: 'text-14 font-medium',

        formataReportDisclaimerRetrato: 'text-muted text-12', // mudança da fonte: text-14
        formataReportDisclaimerPaisagem: 'text-muted text-11', // mudança da fonte: text-14

        formataAssinaturaTitulo: 'text-16 font-medium',
        formataAssinaturaTexto: 'text-20 text-muted font-bold',

        alertaCamposObrigatorios: "text-16 font-light",

        formataPanelRadioGroupHelpText: "text-15 text-panelRadioGroupHelpText font-light",

        formataEstudoHelpText: "text-15 text-estudoHelpText font-light",
    }
}