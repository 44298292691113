import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    totalArray
} from '../../../business-components/Array/Array'

import {
    ano,
    mes,
    mesMesAno,
    anoMesAno,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosValorDestaque2Valores,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import tipoFinanciamentoList from '../../../business-rules/List/tipoFinanciamentoList'

import familiaresList from '../../../business-rules/List/familiaresList'

import {
    calculaValorPerc,
    calculaValorMensal,
    calculaIntervaloTempoAnos,
} from '../cliente-form/ClienteService'

import {
    calculaValorAmortizacaoTabelaSAC,
    dadosCompletosLinhaFinanciamento,
} from '../despesas-form/DespesasServiceFinanciamento'

import {
    inicializaSerieGraficoDespesa,
    inicializaSerieGrafico,
    incluiDespesaMensalDetalhadaTemp,
    calculaIndexGraficoAnosIdades,
    calculaGraficoValorPresente,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'



export const obtemLinhaTipoFinanciamentoDescricaoTipoFinanciamento = (linhaFinanciamento) => {

    const linhaTipoFinanciamento = pesquisaList(tipoFinanciamentoList, linhaFinanciamento.tipoFinanciamento)

    const descricaoTipoFinanciamento =
        (linhaFinanciamento.descricaoOutros
            ?
                linhaFinanciamento.descricaoOutros
            : 
                linhaTipoFinanciamento.descricao
        ) +
            (linhaFinanciamento.tabelaSAC ? ' (' + LABEL.tabelaSACAbreviada + ')' : '') +
                (
                    linhaFinanciamento.consorcio
                        ?
                            ' (' + LABEL.consorcio +
                                (
                                    linhaFinanciamento.instituicao
                                        ?
                                            ' ' + linhaFinanciamento.instituicao
                                        :
                                            ''
                                ) + ')'
                        :
                            ''
                ) 
                
    return { linhaTipoFinanciamento, descricaoTipoFinanciamento }
}

export const calculaValoresMesesFinanciamento = (values, linhaFinanciamento, considerarFinanciamentoSeraQuitadoAntesUltimaParcela) => {

    let valorMensal
    let valorAmortizacao
    let mesInicial
    let anoInicial
    let mesFinal
    let anoFinal

    valorMensal = calculaValorMensal (linhaFinanciamento.valorParcela, null)

    if (linhaFinanciamento.tabelaSAC) {

        valorAmortizacao = calculaValorAmortizacaoTabelaSAC(
            linhaFinanciamento.valorSaldoDevedor, linhaFinanciamento.qtdeParcelas)
    }

    if (linhaFinanciamento.mesAnoInicial) {

        mesInicial = mesMesAno(linhaFinanciamento.mesAnoInicial)
        anoInicial = anoMesAno(linhaFinanciamento.mesAnoInicial)

    } else {

        mesInicial = mes(values.dataSimulacaoEstudo)
        anoInicial = ano(values.dataSimulacaoEstudo)
    }

    if (
            linhaFinanciamento.tabelaSAC &&
            linhaFinanciamento.financiamentoSeraQuitadoAntesUltimaParcela &&
            considerarFinanciamentoSeraQuitadoAntesUltimaParcela
    ) {

        mesFinal =
            mesFinalParcelas(linhaFinanciamento.ultimaParcelaFinanciamento, mesInicial)
        anoFinal =
            anoFinalParcelas(linhaFinanciamento.ultimaParcelaFinanciamento, mesInicial, anoInicial)

    } else {

        mesFinal =
            mesFinalParcelas(linhaFinanciamento.qtdeParcelas, mesInicial)
        anoFinal =
            anoFinalParcelas(linhaFinanciamento.qtdeParcelas, mesInicial, anoInicial)
    }

    return { valorMensal, valorAmortizacao, mesInicial, anoInicial, mesFinal, anoFinal }
}

export const mesFinalParcelas = (qtdeParcelas, mesInicial) => {

    const mesesAnoInicial = 12 - mesInicial + 1

    let mesFinal

    if (mesesAnoInicial >= qtdeParcelas) {

        mesFinal = mesInicial + qtdeParcelas - 1

    } else {

        mesFinal = (qtdeParcelas - mesesAnoInicial) % 12

        if (mesFinal === 0) {

            mesFinal = 12
        }
    }

    return mesFinal
}

export const anoFinalParcelas = (qtdeParcelas, mesInicial, anoInicial) => {

    const mesesAnoInicial = 12 - mesInicial + 1

    let anoFinal

    if (mesesAnoInicial >= qtdeParcelas) {

        anoFinal = anoInicial

    } else {

        anoFinal = anoInicial + Math.floor((qtdeParcelas - mesesAnoInicial) / 12)

        if ((qtdeParcelas - mesesAnoInicial) % 12 !== 0) {

            anoFinal++
        }
    }

    return anoFinal
}



export const calculaDespesasFinanciamento = (values, estudo, report) => {
    let financiamentoTemp = []
    let financiamento = []
    let panelDespesasTotaisFinanciamento

    let valorFinanciamentoAnalisePatrimonial = 0

    if (values.financiamento) {
        //let linhaTipoFinanciamento
        //let descricaoTipoFinanciamento
        let nomeCurtoFamiliar

        let indexGraficoConsumoReservaAposentadoria
        let indexGraficoDespesaAnual
        let indexGraficoDespesaAnualMedia /**/

        //let valorMensal
        //let valorAmortizacao
        //let mesInicial
        //let anoInicial
        //let mesFinal
        //let anoFinal
        let maiorAnoFinal = ''
        let valorTotalParcelas
        let valorSaldoDevedor
        let valorTotalParcelasSaldoDevedor
        let valorResponsabilidadeCliente
        let valorGarantiaCliente
        let valorResponsabilidadeConjuge
        let valorGarantiaConjuge

        let valorMensalTotal = 0
        let valorTotalParcelasTotal = 0
        let valorTotalParcelasTotalAtual = 0
        let valorResponsabilidadeClienteTotal = 0
        let valorGarantiaClienteTotal = 0
        let valorResponsabilidadeConjugeTotal = 0
        let valorGarantiaConjugeTotal = 0

        let saldo

        values.financiamento.forEach( (linhaFinanciamento, index) => {

            if (
                    dadosCompletosLinhaFinanciamento(linhaFinanciamento) &&
                    !pesquisaList(values.exclusaoFamiliares, linhaFinanciamento.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias,
                        LABEL.financiamento + '/' + linhaFinanciamento.id).id &&
                    (linhaFinanciamento.id !== values.objComparacaoFinanciamentos.idFinanciamentoAlternativo ||
                        !values.objComparacaoFinanciamentos.idFinanciamentoSugerido)
            ) {
                
                const { linhaTipoFinanciamento, descricaoTipoFinanciamento } =
                    obtemLinhaTipoFinanciamentoDescricaoTipoFinanciamento(linhaFinanciamento)

                nomeCurtoFamiliar = pesquisaDescricaoList(familiaresList(values), linhaFinanciamento.familiar)

                const { valorMensal, valorAmortizacao, mesInicial, anoInicial, mesFinal, anoFinal } =
                    calculaValoresMesesFinanciamento(values, linhaFinanciamento, true)

                if (
                    linhaFinanciamento.familiar === HARDCODE.idFamiliarCliente ||
                    linhaFinanciamento.familiar === HARDCODE.idFamiliarConjuge
                ) {

                    valorTotalParcelas = estudo.current.graficoFinanciamento[0]

                    if (linhaFinanciamento.tabelaSAC) {

                        calculaGraficoValorPresente(mesInicial, anoInicial, mesFinal, anoFinal, valorAmortizacao, null,
                            estudo.current.graficoAnos, estudo.current.graficoFinanciamento,
                                estudo.current.graficoDespesasAcumuladas, values, estudo,
                                    values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                        values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                            values.taxaRetornoAnualRealAposAposentadoria,
                                                linhaFinanciamento.qtdeParcelas, linhaFinanciamento.taxaAnual, null, true
                        )
                        
                    } else {

                        calculaGraficoValorPresente(mesInicial, anoInicial, mesFinal, anoFinal, valorMensal, null,
                            estudo.current.graficoAnos, estudo.current.graficoFinanciamento,
                                estudo.current.graficoDespesasAcumuladas, values, estudo,
                                    values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                        values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                            values.taxaRetornoAnualRealAposAposentadoria, null, null/*linhaFinanciamento.taxaAnual*/,
                                                null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/, true
                        )
                    }

                    if (linhaFinanciamento.possuiParcelasAdicionais && linhaFinanciamento.parcelasAdicionais) {
                            
                        linhaFinanciamento.parcelasAdicionais?.forEach( (item, ind) => {

                            calculaGraficoValorPresente(mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                                mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal), round(item.valorParcela, 2),
                                    item.tipoPeriodicidadePagto, estudo.current.graficoAnos, estudo.current.graficoFinanciamento,
                                        estudo.current.graficoDespesasAcumuladas, values, estudo,
                                            values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                                values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                                    values.taxaRetornoAnualRealAposAposentadoria, null,
                                                        null/*linhaFinanciamento.taxaAnual*/,
                                                            null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/, true
                            )
                        })
                    }
                               
                    if (linhaFinanciamento.consumirRecursosFinanceirosEmVezDaReceitaMensal) {
                        
                        // se essa chamada for alterada, revisar a chamada correspondente em calculaGraficoEconomia

                        indexGraficoConsumoReservaAposentadoria =
                            inicializaSerieGrafico(values, estudo.current.graficoConsumoReservaAposentadoria, linhaTipoFinanciamento,
                                LABEL.financiamento + LABEL.traco + linhaTipoFinanciamento.id,
                                    LABEL.financiamento + LABEL.traco + descricaoTipoFinanciamento)

                        if (linhaFinanciamento.tabelaSAC) {
                        
                            saldo = calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorAmortizacao * -1, null, estudo.current.graficoAnos,
                                    estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                        estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                            values, estudo, values.taxaRetornoAnualReal,
                                                values.taxaRetornoAnualRealAposAposentadoria, true,
                                                    linhaFinanciamento.qtdeParcelas, linhaFinanciamento.taxaAnual,
                                                        null, estudo.current.evolucaoReservaFinanceiraEstudo, null)

                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorAmortizacao, null, estudo.current.graficoAnos,
                                    estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                                        null, values, estudo, 0, 0, false, linhaFinanciamento.qtdeParcelas,
                                            linhaFinanciamento.taxaAnual, null, null, null)

                        } else {

                            saldo = calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorMensal * -1, null, estudo.current.graficoAnos,
                                    estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                        estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                            values, estudo, values.taxaRetornoAnualReal,
                                                values.taxaRetornoAnualRealAposAposentadoria, true, null,
                                                    null/*linhaFinanciamento.taxaAnual*/,
                                                        null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/,
                                                            estudo.current.evolucaoReservaFinanceiraEstudo, null)
                            
                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorMensal, null, estudo.current.graficoAnos,
                                    estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                                        null, values, estudo, 0, 0, false, null, null/*linhaFinanciamento.taxaAnual*/,
                                            null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/, null, null)
                        }

                        if (
                                anoFinal < values.anoFinalVitalicioClienteConjuge ||
                                (
                                    anoFinal === values.anoFinalVitalicioClienteConjuge &&
                                    mesFinal < 12
                                )
                        ) {

                            if (mesFinal < 12) {

                                const indexGraficoAnos =
                                    calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, anoFinal)
    
                                estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[indexGraficoAnos] -= saldo
    
                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal[indexGraficoAnos] -= saldo
                            }

                            calculaGraficoAcumulacaoMensal(mesFinal === 12 ? 1 : mesFinal + 1,
                                mesFinal === 12 ? anoFinal + 1 : anoFinal, null,
                                    values.anoFinalVitalicioClienteConjuge, saldo, 0, null, estudo.current.graficoAnos,
                                        estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                values, estudo, values.taxaRetornoAnualReal,
                                                    values.taxaRetornoAnualRealAposAposentadoria, true, null, null, null, null, null)
                        }

                        if (linhaFinanciamento.possuiParcelasAdicionais && linhaFinanciamento.parcelasAdicionais) {
                            
                            linhaFinanciamento.parcelasAdicionais?.forEach( (item, ind) => {

                                saldo = calculaGraficoAcumulacaoMensal(mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                                    mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal), 0,
                                        round(item.valorParcela, 2) * -1, item.tipoPeriodicidadePagto, estudo.current.graficoAnos,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                    values, estudo, values.taxaRetornoAnualReal,
                                                        values.taxaRetornoAnualRealAposAposentadoria, true, null,
                                                            null/*linhaFinanciamento.taxaAnual*/,
                                                                null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/,
                                                                    estudo.current.evolucaoReservaFinanceiraEstudo, null)
                                
                                calculaGraficoAcumulacaoMensal(mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                                    mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal), 0, round(item.valorParcela, 2),
                                        item.tipoPeriodicidadePagto, estudo.current.graficoAnos,
                                            estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                                                null, values, estudo, 0, 0, false, null, null/*linhaFinanciamento.taxaAnual*/,
                                                    null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/, null, null)

                                if (
                                        anoMesAno(item.mesAnoFinal) < values.anoFinalVitalicioClienteConjuge ||
                                        (
                                            anoMesAno(item.mesAnoFinal) === values.anoFinalVitalicioClienteConjuge &&
                                            mesMesAno(item.mesAnoFinal) < 12
                                        )
                                ) {
        
                                    if (mesMesAno(item.mesAnoFinal) < 12) {
        
                                        const indexGraficoAnos =
                                            calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, anoMesAno(item.mesAnoFinal))
            
                                        estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[indexGraficoAnos] -= saldo
            
                                        estudo.current.graficoRecursosFinanceirosAcumuladosTotal[indexGraficoAnos] -= saldo
                                    }
        
                                    calculaGraficoAcumulacaoMensal(mesMesAno(item.mesAnoFinal === 12) ? 1 : mesMesAno(item.mesAnoFinal) + 1,
                                        mesMesAno(item.mesAnoFinal) === 12 ? anoMesAno(item.mesAnoFinal) + 1 : anoMesAno(item.mesAnoFinal), null,
                                            values.anoFinalVitalicioClienteConjuge, saldo, 0, null, estudo.current.graficoAnos,
                                                estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                                    estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                        values, estudo, values.taxaRetornoAnualReal,
                                                            values.taxaRetornoAnualRealAposAposentadoria,
                                                                true, null, null, null, null, null)
                                }
                            })
                        }

                    } else {

                        indexGraficoDespesaAnual =
                            inicializaSerieGrafico(values, estudo.current.graficoDespesaAnual,
                                inicializaSerieGraficoDespesa(linhaFinanciamento, linhaFinanciamento.id, descricaoTipoFinanciamento), null, null)

                        /**/
                        indexGraficoDespesaAnualMedia =
                            inicializaSerieGrafico(values, estudo.current.graficoDespesaAnualMedia,
                                inicializaSerieGraficoDespesa(linhaFinanciamento, linhaFinanciamento.id, descricaoTipoFinanciamento), null, null)
                        /**/

                        saldo = totalArray(estudo.current.graficoDespesaAnualTotal)

                        if (linhaFinanciamento.tabelaSAC) {

                            //calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                            //    valorAmortizacao, null, estudo.current.graficoAnos,
                            //        estudo.current.graficoDespesaAnualTotal, null, values, estudo, 0, 0, false, 
                            //            linhaFinanciamento.qtdeParcelas, linhaFinanciamento.taxaAnual, null, null, null)
                                   
                            // se essa chamada for alterada, revisar a chamada correspondente em calculaGraficoFinanciamento
                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorAmortizacao, null, estudo.current.graficoAnos,
                                    estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie,
                                        estudo.current.graficoDespesaAnualTotal/*null*/, values, estudo, 0, 0, false,
                                            linhaFinanciamento.qtdeParcelas, linhaFinanciamento.taxaAnual, null, null, null)

                            /**/
                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorAmortizacao, null, estudo.current.graficoAnos,
                                    estudo.current.graficoDespesaAnualMedia[indexGraficoDespesaAnualMedia].serie,
                                        estudo.current.graficoDespesaAnualTotalMedia,
                                            values, estudo, 0, 0, false, linhaFinanciamento.qtdeParcelas,
                                                linhaFinanciamento.taxaAnual, null, null, null)
                            /**/

                        } else {

                            //calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                            //    valorMensal, null, estudo.current.graficoAnos,
                            //        estudo.current.graficoDespesaAnualTotal, null, values, estudo, 0, 0, false, null, null, null, null, null)

                            // se essa chamada for alterada, revisar a chamada correspondente em calculaGraficoFinanciamento
                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorMensal, null, estudo.current.graficoAnos,
                                    estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie,
                                        estudo.current.graficoDespesaAnualTotal/*null*/, values, estudo, 0, 0, false,
                                            null, null/*linhaFinanciamento.taxaAnual*/,
                                                null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/, null, null)
            
                            /**/
                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorMensal, null, estudo.current.graficoAnos,
                                    estudo.current.graficoDespesaAnualMedia[indexGraficoDespesaAnualMedia].serie,
                                        estudo.current.graficoDespesaAnualTotalMedia,
                                            values, estudo, 0, 0, false, null, null/*linhaFinanciamento.taxaAnual*/,
                                                null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/, null, null)
                            /**/
                        }

                        saldo = totalArray(estudo.current.graficoDespesaAnualTotal) - saldo

                        incluiDespesaMensalDetalhadaTemp(values, estudo, linhaFinanciamento, descricaoTipoFinanciamento,
                            valorMensal, HARDCODE.idTipoPeriodicidadeMensal, valorMensal, saldo/*linhaFinanciamento.valorTotalParcelas*/,
                                mesInicial, anoInicial, mesFinal, anoFinal)

                        if (linhaFinanciamento.possuiParcelasAdicionais && linhaFinanciamento.parcelasAdicionais) {
                            
                            linhaFinanciamento.parcelasAdicionais?.forEach( (item, ind) => {

                                saldo = totalArray(estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie)

                                // se essa chamada for alterada, revisar a chamada correspondente em calculaGraficoFinanciamento
                                calculaGraficoAcumulacaoMensal(mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                                    mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal), 0,
                                        round(item.valorParcela, 2), item.tipoPeriodicidadePagto, estudo.current.graficoAnos,
                                            estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie,
                                                estudo.current.graficoDespesaAnualTotal/*null*/, values, estudo, 0, 0, false,
                                                    null, null/*linhaFinanciamento.taxaAnual*/,
                                                        null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/, null, null)
                                
                                saldo = totalArray(estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie) - saldo
                
                                calculaGraficoAcumulacaoMensal(mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                                    mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal), 0,
                                        round(item.valorParcela, 2), item.tipoPeriodicidadePagto, estudo.current.graficoAnos,
                                            estudo.current.graficoDespesaAnualMedia[indexGraficoDespesaAnualMedia].serie,
                                                estudo.current.graficoDespesaAnualTotalMedia,
                                                    values, estudo, 0, 0, false, null, null/*linhaFinanciamento.taxaAnual*/,
                                                        null/*linhaFinanciamento.mesAnoPrimeiroReajuste*/, null, null)

                                incluiDespesaMensalDetalhadaTemp(values, estudo, linhaFinanciamento,
                                    descricaoTipoFinanciamento + (item.observacao ? ' (' + item.observacao + ')' : ''),
                                        round(item.valorParcela, 2), item.tipoPeriodicidadePagto, 0,
                                            saldo, mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                                                mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal))
                            })
                        }
                    }

                    if (!maiorAnoFinal || anoFinal > maiorAnoFinal) {
                        maiorAnoFinal = anoFinal
                    }

                    valorTotalParcelas = round(estudo.current.graficoFinanciamento[0] - valorTotalParcelas, 2)
                    valorSaldoDevedor = round(linhaFinanciamento.valorSaldoDevedor, 2)

                    if (linhaFinanciamento.valorSaldoDevedor/* && valorTotalParcelas > valorSaldoDevedor*/) {

                        valorTotalParcelasSaldoDevedor = valorSaldoDevedor

                    } else {
                        
                        valorTotalParcelasSaldoDevedor = valorTotalParcelas
                    }

                    if (!linhaFinanciamento.financiamentoFuturoAindaNaoContratado) {

                        valorFinanciamentoAnalisePatrimonial += valorTotalParcelasSaldoDevedor
                    }

                    valorResponsabilidadeCliente = valorTotalParcelasSaldoDevedor
                    valorResponsabilidadeConjuge = valorTotalParcelasSaldoDevedor

                } else {

                    valorTotalParcelas = 0
                    valorSaldoDevedor = 0
                    valorTotalParcelasSaldoDevedor = 0
                    valorResponsabilidadeCliente = 0
                    valorResponsabilidadeConjuge = 0
                }

                if (
                        linhaFinanciamento.temSeguro === "S" &&
                        (
                            linhaFinanciamento.familiar === HARDCODE.idFamiliarCliente ||
                            linhaFinanciamento.familiar === HARDCODE.idFamiliarConjuge
                        )
                ) {

                    valorGarantiaCliente = calculaValorPerc(valorTotalParcelasSaldoDevedor, linhaFinanciamento.percSeguroCliente)

                    if (linhaFinanciamento.alterarPercSeguroConjuge) {

                        valorGarantiaConjuge = calculaValorPerc(valorTotalParcelasSaldoDevedor, linhaFinanciamento.percSeguroConjuge)

                    } else {

                        valorGarantiaConjuge = valorTotalParcelasSaldoDevedor - valorGarantiaCliente
                    }
                    
                } else {

                    valorGarantiaCliente = 0
                    valorGarantiaConjuge = 0
                }

                financiamentoTemp.push({
                    ordem: ("0000" + linhaFinanciamento.familiar).slice(-4) + 
                        ("000000000000000000000" + valorTotalParcelasSaldoDevedor).slice(-21),
                    familiar: linhaFinanciamento.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar, 
                    descricaoTipoFinanciamento: descricaoTipoFinanciamento,
                    valorMensal: valorMensal,
                    qtdeParcelas: linhaFinanciamento.qtdeParcelas,
                    anoFinal: anoFinal,
                    valorTotalParcelas: valorTotalParcelas,
                    valorSaldoDevedor: valorSaldoDevedor,
                    valorTotalParcelasSaldoDevedor: valorTotalParcelasSaldoDevedor,
                    valorResponsabilidadeCliente: valorResponsabilidadeCliente,
                    valorGarantiaCliente: valorGarantiaCliente,
                    valorResponsabilidadeConjuge: valorResponsabilidadeConjuge,
                    valorGarantiaConjuge: valorGarantiaConjuge,
                })

                valorMensalTotal += valorMensal
                valorTotalParcelasTotal += valorTotalParcelas
                if (!linhaFinanciamento.financiamentoFuturoAindaNaoContratado/*financiamentoFuturo*/) {
                    
                    valorTotalParcelasTotalAtual += valorTotalParcelas
                }
                valorResponsabilidadeClienteTotal += valorResponsabilidadeCliente
                valorGarantiaClienteTotal += valorGarantiaCliente
                valorResponsabilidadeConjugeTotal += valorResponsabilidadeConjuge
                valorGarantiaConjugeTotal += valorGarantiaConjuge
            }
        })

        financiamentoTemp = sortAsc(financiamentoTemp)

        let familiarAnt = null

        financiamentoTemp.forEach( (linhaFinanciamento, index) => {
            if (linhaFinanciamento.familiar !== familiarAnt) {
                familiarAnt = linhaFinanciamento.familiar

                let valorMensal = 0
                valorTotalParcelas = 0
                valorResponsabilidadeCliente = 0
                valorGarantiaCliente = 0
                valorResponsabilidadeConjuge = 0
                valorGarantiaConjuge = 0

                financiamentoTemp.forEach( (linhaFinanciamentoTotal, index) => {
                    if (linhaFinanciamentoTotal.familiar === linhaFinanciamento.familiar) {
                        valorMensal += linhaFinanciamentoTotal.valorMensal
                        valorTotalParcelas += linhaFinanciamentoTotal.valorTotalParcelas
                        valorResponsabilidadeCliente += linhaFinanciamentoTotal.valorResponsabilidadeCliente
                        valorGarantiaCliente += linhaFinanciamentoTotal.valorGarantiaCliente
                        valorResponsabilidadeConjuge += linhaFinanciamentoTotal.valorResponsabilidadeConjuge
                        valorGarantiaConjuge += linhaFinanciamentoTotal.valorGarantiaConjuge
                    }
                })

                financiamento.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaFinanciamento.nomeCurtoFamiliar, report),
                    valorMensal: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorMensal, report),
                    valorTotalParcelasSaldoDevedor: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorTotalParcelas, report),
                    valorResponsabilidadeCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeCliente, report),
                    valorGarantiaCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaCliente, report),
                    valorResponsabilidadeConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeConjuge, report),
                    valorGarantiaConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaConjuge, report),
                })
            }

            financiamento.push({
                descricao: 
                    formataTableCellBodyExibeDadosTitulo("          " + linhaFinanciamento.descricaoTipoFinanciamento, report),
                valorMensal: 
                    formataTableCellBodyExibeDadosValor(linhaFinanciamento.valorMensal, report),
                qtdeParcelas: 
                    formataTableCellBodyExibeDadosTexto(linhaFinanciamento.qtdeParcelas, report),
                anoFinal: 
                    formataTableCellBodyExibeDadosTexto(linhaFinanciamento.anoFinal, report),
                valorTotalParcelasSaldoDevedor:
                    linhaFinanciamento.valorSaldoDevedor
                        ?
                            formataTableCellBodyExibeDadosValorDestaque2Valores(linhaFinanciamento.valorTotalParcelas,
                                linhaFinanciamento.valorSaldoDevedor, report)
                        :
                            formataTableCellBodyExibeDadosValor(linhaFinanciamento.valorTotalParcelas, report),
                valorResponsabilidadeCliente: 
                    formataTableCellBodyExibeDadosValor(linhaFinanciamento.valorResponsabilidadeCliente, report),
                valorGarantiaCliente: 
                    formataTableCellBodyExibeDadosValor(linhaFinanciamento.valorGarantiaCliente, report),
                valorResponsabilidadeConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaFinanciamento.valorResponsabilidadeConjuge, report),
                valorGarantiaConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaFinanciamento.valorGarantiaConjuge, report),
            })
        })

        financiamento.push({
            descricao:
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valorMensal:
                formataTableCellBodyExibeDadosTotalValor(valorMensalTotal, report),
            valorTotalParcelasSaldoDevedor: 
                formataTableCellBodyExibeDadosTotalValor(valorTotalParcelasTotal, report),
            valorResponsabilidadeCliente: 
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeClienteTotal, report),
            valorGarantiaCliente: 
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaClienteTotal, report),
            valorResponsabilidadeConjuge: 
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeConjugeTotal, report),
            valorGarantiaConjuge: 
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaConjugeTotal, report),    
        })
    
        panelDespesasTotaisFinanciamento = {
            descricao: LABEL.financiamento,
            valorAcumuladoPainel: valorTotalParcelasTotal,
            valorDespesaClientePainel: valorResponsabilidadeClienteTotal - valorGarantiaClienteTotal,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: valorResponsabilidadeConjugeTotal - valorGarantiaConjugeTotal,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: calculaIntervaloTempoAnos(values, maiorAnoFinal),
            valorTotalParcelasTotalAtual: valorTotalParcelasTotalAtual,
        }

    } else {
 
        panelDespesasTotaisFinanciamento = {
            descricao: LABEL.financiamento,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
            valorTotalParcelasTotalAtual: 0,
        }
    }

    estudo.current.financiamento = financiamento

    estudo.current.valorFinanciamentoAnalisePatrimonial = valorFinanciamentoAnalisePatrimonial

    estudo.current.panelDespesasTotais[HARDCODE.indexEstudoFinanciamento] = panelDespesasTotaisFinanciamento
}