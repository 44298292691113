import ApiService from './ApiService'

import AuthService from './AuthService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    usuarioInfo,
} from './UsuarioService'



class ConfiguracaoService extends ApiService {

    constructor() {
        super(HARDCODE.apiConfiguracoes)
    }

    atualizarConfiguracaoUsuario(variaveisGlobais, configuracaoUsuario) {

        return this.put(variaveisGlobais, HARDCODE.apiConfiguracoesAtualizarConfiguracaoUsuario, configuracaoUsuario, false)
    }
    
    atualizarCamposCalculadosConfiguracaoNovaVersao(variaveisGlobais, idUsuario) {

        let params = `idUsuario=${idUsuario}`

        return this.get(variaveisGlobais,
            `${HARDCODE.apiConfiguracoesAtualizarCamposCalculadosConfiguracaoNovaVersao}/?${params}`, false)
    }

    async buscarConfiguracaoUsuario(variaveisGlobais, idUsuario, obterImagem) {

        let params = `idUsuarioLogado=${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        params = `${params}&idUsuario=${idUsuario}`

        params = `${params}&obterImagem=${obterImagem ? 'S' : 'N'}`

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiConfiguracoesBuscarConfiguracaoUsuario}/?${params}`, paramsAux, false)
    }
    
    buscarConfiguracaoURL(variaveisGlobais, idConfiguracao) {
        let params = `idConfiguracao=${idConfiguracao}`

        return this.get(variaveisGlobais, `${HARDCODE.apiConfiguracoesBuscarConfiguracaoURL}/?${params}`, true)
    }
    
    gerarTokenIntegracao(variaveisGlobais, idUsuario, idConfiguracao) {
        let params = `idUsuario=${idUsuario}`

        params = `${params}&idConfiguracao=${idConfiguracao}`

        return this.get(variaveisGlobais, `${HARDCODE.apiConfiguracoesGerarTokenIntegracao}/?${params}`, false)
    }



    static setarConfiguracaoUsuario(variaveisGlobais, configuracaoUsuario) {

        variaveisGlobais.configuracaoUsuario = configuracaoUsuario
    }

    static obterConfiguracaoUsuario(variaveisGlobais) {

        return variaveisGlobais.configuracaoUsuario
    }
}

export default ConfiguracaoService