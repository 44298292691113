import ApiService from './ApiService'

import AuthService from './AuthService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    usuarioInfo,
} from './UsuarioService'



class InfoclientService extends ApiService {

    constructor() {
        super(HARDCODE.apiInfoclients)
    }

    async buscarByIdClienteMandatorio(variaveisGlobais, idUsuarioClienteSelecionado, idCliente, versao) {

        let params = `idUsuarioLogado=${AuthService.obterUsuarioLogado(variaveisGlobais)}`
        
        params = `${params}&idUsuario=${idUsuarioClienteSelecionado}`
        
        params = `${params}&idCliente=${idCliente}`
        
        params = `${params}&versao=${versao}`

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiInfoclientsBuscarByIdClienteMandatorio}/?${params}`, paramsAux, false)
    }

    
    buscarVersoesInfoclientList(variaveisGlobais, idUsuario, idCliente, ultimaVersao) {

        let params = `idUsuarioLogado=${AuthService.obterUsuarioLogado(variaveisGlobais)}`
        
        params = `${params}&idUsuario=${idUsuario}`

        params = `${params}&idCliente=${idCliente}`

        params = `${params}&ultimaVersao=${ultimaVersao}`

        return this.get(variaveisGlobais, `${HARDCODE.apiInfoclientsBuscarVersoesInfoclientList}/?${params}`, false)
    }
}

export default InfoclientService