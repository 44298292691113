const NUMMAX = {
    numeroSUSEP: 999999999,

    ordem: 100,

    larguraLogoTopbar: 300,
    larguraLogoReport: 280,
    alturaLogoReport: 68,
    alturaLogoTopbar: 60,

    estudoReportCapaTitulo1MargemTopo: 120,
    estudoReportCapaClienteConjugeTitulo2MargemTopo: 190,//150,
    estudoReportCapaClienteConjugeTitulo2MargemEsquerda: 135,

    margem: 10,

    diasAntecedenciaEnvioLembreteAporte: 5, // HARDCODEBACKEND.diasAntecedenciaEnvioLembreteAporte

    idade: 150,

    tempoMinimoEntreAportesResgatesCalculoIRAnos: 100,

    qtdeDependentes: 20,

    peso: 500,
    imc: 999,
    qtdeFumoSemanal: 9999,

    perc: 100,

    anosReajusteAumento: 50,

    dia: 31,
    mes: 12,
    ano: 2200,
    meses: 2400,
    anos: 100,

    anoMes: 220012,
    
    anosExibicaoRelatorioEvolucaoReservaFinanceira: 10,

    cotaConsorcio: 999,
    numeroAssembleiaParticipacao: 999,
    prazoTotalGrupoConsorcio: 999,
    numeroTotalParticipantesGrupoConsorcio: 999,

    percRentabilidadeAnualRecursoFinanceiro: 9999.99,

    taxa: 999.99,
    percIR: 35,

    qtdeParcelas: 9999,
    numeroCobertura: 999999999999999,

    duracaoPremioExtra: 50,
}

export default NUMMAX