import HARDCODE from '../../business-const/HardCode/HardCode'

import AuthService from '../../business-api/AuthService'

import {
    pesquisaList
} from '../../business-rules/List/ListService'

/*
const THEMEOriginal = {
    palleteType: 'light',
    palletePrimaryMain: '#1976d2',
    palletePrimaryContrastText: '#ffffff',
    palleteSecondaryMain: '#FFAF38',
    palleteSecondaryContrastText: 'rgba(52, 49, 76, 1)',

    textMutedStrong: lightGray,

    bgLogin: corPadrao1,
    bgLoginCard: corPadrao2,

    bgSidenav: '#222A45',
    sidenav: corPadrao4,
    sidenavSwitch: '#ff9e43',
    
    bgDefault: whiteSmoky,

    bgFooter: preto,

    bgTransparente: transparente,
    
    bgAgendaEvent: '#f1465e',
    bgAgendaHeader: '#4c9fff',
    agendaOffRange: gray,
    bgAgendaOffRange: '#f7cd65',
    bgAgendaToday: '#e7745f',
    bgAgendaMonthRow: '#b0a69c',
    bgAgendaTimeView: '#d06c84',
    bgAgendaDaySlot: '#f2b1b1',
    bgAgendaAgendaTable: '#f26277',

    bgCardGridDivHeaderEntradaDados: 'rgba(0, 0, 0, 0.15)',
    bgCardGridEntradaDados: '',
    bgTextField: whiteSmoky,
    bgTextFieldDisabled: whiteSmoky,
    textField: preto,
    textFieldDisabled: preto,

    checkBox: gray,

    bgCardGridDivHeaderDestaque1: '#90EE90',

    bgCardGridDivHeaderDestaqueAlerta: vermelho,

    bgEstudoReport: lightGray,

    bgEstudoReportPageCabecalho: lightGray,

    bgCardGridDivHeaderResumoCabecalho: 'rgba(141, 180 , 226, 1)',
    bgCardGridDivBodyResumo: lightGray,
    bgCardGridDivBodyResumoTotal: 'rgba(216, 228, 188, 1)',
    bgCardGridDivBodyResumoTotalGeral: 'rgba(204, 192, 218, 1)',

    bgCardGridEntradaDadosHeaderBody: '',

    bgDragDropTwoListItem: verde,
    bgDragDropTwoListItemObrigatorio: verde,
    bgDragDropTwoListItemEspecial: verde,
    bgDragDropTwoListItemIsDraggingOver: vermelho,
    bgDragDropTwoListList: lightGray20,
    bgDragDropTwoListListIsDraggingOver: lightGray,

    bgTableRowBodyExibeDados: vermelho,
    
    bgTableRowHeadExibeTabela: lightGray20,
    bgTableRowBodyExibeTabela: lightGray20,

    tabsPadraoColor: '#ffffff',
    bgTabsPadraoIndicator: 'rgba(173, 139, 25, 1)',

    bgStepperPadrao: '',
    bgStepperCardValor: '#efefef',

    bgPanelRadioGroup: '#ADD8E6',

    bgGroupPanel: '#ADD8E6',
    bgGroupPanelPanelTotal: 'rgba(0, 0, 0, 0.15)',

    bgTableHeadExibeDados: 'rgba(0, 0, 0, 0.15)',
    bgTableHeadExibeDadosReportPopup: whiteSmoky,//osloGray,//silverChalice,

    bgComparacaoCenariosEstudosGrupo: '#063970',
    bgComparacaoCenariosEstudosItem: '#2596BE',
    bgComparacaoCenariosEstudosItem2: '#76B5C5',

    bgUpload: '#00009C',

    bgBold: '#00009C',

    breadcrumb: '#ffffff',
    breadcrumbIconHome: 'rgba(173, 139, 25, 1)',

    buttonPadrao1: '#ffffff',
    bgButtonPadrao1: corPadrao3,
    buttonPadrao2: '#ffffff',
    bgButtonPadrao2: primary,
    buttonPadrao3: '#FFFFFF',
    bgButtonPadrao3: vermelho,
    
    linkPadrao: primary,
    APadrao: primary,

    iconButtonEditar: corPadrao3,
    iconButtonRevisaoDados: corPadrao3,
    iconButtonVerDetalhes: corPadrao3,
    iconButtonDuplicar: corPadrao3,
    iconButtonTransferir: corPadrao3,
    iconButtonClearEntradaDados: vermelho,
    iconButtonClearDialog: vermelho,
    iconButtonClear: vermelho,
    iconButtonVersao: corPadrao3,
    iconButtonFiltrar: corPadrao3,
    iconButtonFiltrarSetado: vermelho,
    iconButtonImprimir: corPadrao3,
    iconButtonEnderecos: primary,
    iconButtonExpandMore: corPadrao3,
    iconButtonExpandLess: corPadrao3,
    iconButtonWhatsapp: corPadrao3,
    iconButtonPDF: corPadrao3,
    iconButtonExcel: corPadrao3,
    iconButtonDocument: corPadrao3,
    iconButtonMaisInformacoes: corPadrao3,
    iconButtonConfiguracao: primary,
    iconButtonTrilhaConhecimento: corPadrao3,
    iconButtonTreinamento: corPadrao3,
    iconButtonAgenda: corPadrao3,
    iconButtonVisualizarTabela: corPadrao3,
    iconButtonModoApresentacao: corPadrao3,
    iconModoApresentacaoPaginaAnteriorProximaPagina: vermelho,
    iconButtonSelecionarCenariosTipoMorteInvalidez: corPadrao3,

    iconButtonHelp: corPadrao3,
    iconButtonHelpTooltip: '',
    bgIconButtonHelpTooltip: '',
    iconButtonHelpTitle: martinique,
    bgIconButtonHelpTitle: '',
    iconButtonHelpText: smoky,
    bgIconButtonHelpText: '',
    
    iconButtonConfirmacaoText: smoky,

    valorNegativo: vermelho,

    tableCellHeadExibeDadosDestaque: vermelho,
    tableCellBodyExibeDadosDestaque: vermelho,

    panelRadioGroupHelpText: branco,
    estudoHelpText: branco,

    iconCompleted: '#ffffff',
    bgIconCompleted: '#1B7B34',
    textCompleted: '#1B7B34',
    iconSelected: '#ffffff',
    bgIconSelected: '#1976d2',
    textSelected: '#1976d2',
    
    cardGridEntradaDadosHeaderBody: verdeWhatsapp,

    corChartPadraoManterReservaManterPadraoVida:'#4c9fff',//primeiraReuniaoLinkAporteMensal: '#43B047',
    corChartPadraoConsumirReservaManterPadraoVida: '#f7cd65', //primeiraReuniaoLinkRetirada: '#b93c28',
    corChartPadraoManterReservaSemNovosAportes: '#e7745f',//: '#f5ad3f',
    corChartPadraoConsumirReservaSemNovosAportes: '#b0a69c', //primeiraReuniaoLinkRetirada: '#b93c28',
    resumoPlanejamentoEspecializacaoPeriodoSabatico: '#b0a69c', //primeiraReuniaoLinkRetirada: '#b93c28',

    disabled: '#C6C6C6',

    helperText: vermelho,
    helperTextDesejavel: amareloVivido,

    //toolBarRichTextEditor: branco,
    //bgToolBarRichTextEditor: '#EEE8AA',
    //bgContainerRichTextEditor: '#FFFFFF',
    
    corEstruturaFamiliar: gray,
    corMeacao1: silverChalice,
    corMeacao2: lightGray,
    corHeranca1: martinique,
    corHeranca2: smoky,

    capaRelatorio: corPadrao7,
    cabecalhoRelatorio: corPadrao5,
    rodapeRelatorio: corPadrao5,

    corDestaquePreto: preto,
    corDestaqueVerde: '#1B7B34',
    corDestaqueAmarelo: '#FFD700',
    corDestaqueLaranja: '#FFA500',
    corDestaqueVermelho: vermelho,

    //corChartPadrao0: preto,
    //corChartPadrao1: '#008FFB',
    //corChartPadrao2: '#fbaf0f',
    //corChartPadrao3: vermelho,
    //corChartPadrao4: '#00E396',
    //corChartPadrao5: '#5d78ff',
    //corChartPadrao6: '#1B7B34',
    //corChartPadrao7: '#CED4DC',
    //corChartPadrao8: '#FFD700',

    corChartPadraoPreto: preto,
    corChartPadraoVerde: '#1B7B34',
    corChartPadraoAmarelo: amareloVivido,
    corChartPadraoVermelho: vermelho,
    corChartPadraoCinza: '#71848f',
    //corChartPadraoCinzaEscuro: '#71848f',
    corChartPadraoProjetoVidaSonho: 'rgba(51, 102, 255, 1)',
    corChartPadraoAporteAposentadoria: 'rgba(0, 0, 255, 1)',
    corChartPadraoAporteAposentadoriaBalancoPositivo: 'rgba(0, 0, 153, 1)',
    corChartPadraoAporteAtingirMetaInicioAposentadoria: 'rgba(0, 0, 153, 1)',
    corChartPadraoTabelaEvolucaoValorResgate: amareloVivido,
    corChartPadraoCapitalSegurado: '#8c7c62',
    //corChartPadraoEconomiaInventarioSeguroVida: '#0db2ac',
    corChartPadraoValorPremioAnualizadoAcumulado: preto,

    corChartPadraoPrevidenciaPrivada: '#6d2205',
    corChartPadraoPrevidenciaPrivadaAporte: '#8e412f',
    corChartPadraoPrevidenciaPrivadaRentabilidade: '#b35637',
    corChartPadraoPrevidenciaPrivadaConsumirReserva: '#539162',
    corChartPadraoPrevidenciaPrivadaPreservarReserva: '#cc0a00',

    corChartPadraoComparacaoFinanciamentoSugerido: '#55e393',
    corChartPadraoComparacaoFinanciamentoAlternativo: '#f5c200',
    corChartPadraoComparacaoFinanciamentoEconomiaFinanciamentoSugerido: '#3e8aff',
    corChartPadraoComparacaoFinanciamentoPerdaFinanciamentoSugerido: vermelho,
    corChartPadraoSaldoPagtoFinanciamentoSugeridoAVistaPositivo: '#55e393',
    corChartPadraoSaldoPagtoFinanciamentoSugeridoAVistaNegativo: '#55e393',
    corChartPadraoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo: '#55e393',
    corChartPadraoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo: '#55e393',

    //corChartPadraoDespesasAcumuladasSucessaoVitalicia: '#4c9fff',
    corChartPadraoDespesasAcumuladasSucessaoVitaliciaEmergencial: '#4c9fff',
    corChartPadraoDespesasAcumuladasDespesasVitalicias: vermelho,
    corChartPadraoDespesasAcumuladasProjetoVidaSonho: '#f7cd65',
    corChartPadraoDespesasAcumuladasEducacao: '#e7745f',
    corChartPadraoDespesasAcumuladasFinanciamento: '#b0a69c',
    corChartPadraoDespesasAcumuladasDespesasTemporarias: '#d06c84',

    corChartPadrao1: '#008FFB',
    corChartPadrao2: '#f5b100',
    corChartPadrao3: '#e7745f',
    corChartPadrao4: '#ce2eee',
    corChartPadrao5: '#1B7B34',
    corChartPadrao6: '#5d78ff',
    corChartPadrao7: '#b0a69c',
    corChartPadrao8: '#69bde6',
    corChartPadrao9: '#f26277',
    corChartPadrao10: '#FFD700',
    corChartPadrao11: '#5ca1a0',
    corChartPadrao12: '#4c9fff',
    corChartPadrao13: '#55e393',
    corChartPadrao14: '#a695e1',
    corChartPadrao15: '#f1465e',
    corChartPadrao16: '#fbaf0f',
    corChartPadrao17: '#3e8efe',
    corChartPadrao18: '#775cd3',
    corChartPadrao19: '#00E396',
    corChartPadrao20: '#f48898',
    corChartPadrao21: '#5fdfed',
    corChartPadrao22: '#5ee7a4',
    corChartPadrao23: '#d06c84',
    corChartPadrao24: '#f7cd65',
    corChartPadrao25: '#f2b1b1',

    corChartHipotesesRetornoReal: '#44b575',
}
*/

const amareloVivido = 'rgba(230, 198, 25, 1)'
//const bitOfSugar = 'rgb(244, 242, 236, 1)'
const black50 = 'rgba(0, 0, 0, 0.5)'
const branco = 'rgba(255, 255, 255, 1)'
const darkGray = '#A9A9A9'//'rgba(169, 169, 169, 1)' ////'rgba(255, 165, 0, 0.8)'
//const dourado = 'rgba(173, 139, 25, 1)' ////'rgba(255, 165, 0, 1)'
const geyser = '#d8e1e6'
const gray = '#808080'//'rgba(128, 128, 128, 1)'
//const gray98 = 'rgba(250, 250, 250, 1)'
const lightGray = '#D3D3D3'//'rgba(211, 211, 211, 1)'
const lightGray20 = 'rgba(211, 211, 211, 0.2)'
const lightGray30 = 'rgba(211, 211, 211, 0.3)'
//const marrom90 = 'rgba(76, 73, 68, 0.9)' ////'rgba(0, 0, 0, 0.5)'
const martinique = '#35324E'//'rgba(53, 50, 78, 1)'
const mediumAquamarine = 'rgba(117, 192, 154, 1)'
//const narvik = 'rgba(235, 231, 220, 1)'
const osloGray = '#7d8a92'
const preto = '#000000'//'rgba(0, 0, 0, 1)'
const primalGreen = 'rgba(16, 134, 92, 1)'
const primary = '#1976d2'
const silverChalice = '#9E9E9E'//'rgba(158, 158, 158, 1)'
const smoky = '#5E5B72'//'rgba(94, 91, 114, 1)'
//const whisper = 'rgba(245, 246, 250, 1)'
const whiteSmoky = '#f2f2f2'
const transparente = 'rgba(255, 255, 255, 0)'
const vermelho = 'rgba(255, 0, 18, 1)'
const verde = 'rgba(135, 135, 97, 1)'
const verdeWhatsapp = 'rgba(52, 175, 35, 1)'
const vividYellow = 'rgba(221, 187, 4, 1)'


const paletaCores = AuthService.obterPaletaCores()







const corPadrao = (hardcodeCor, corDefault) => {

    const linhaPaletaCores = pesquisaList(paletaCores, hardcodeCor)

    return linhaPaletaCores && linhaPaletaCores.cor
            ?
                linhaPaletaCores.cor.hex
            :
                corDefault
}

const corPadrao1 = corPadrao(HARDCODE.corPadrao1, primalGreen)
const corPadrao2 = corPadrao(HARDCODE.corPadrao2, mediumAquamarine)
const corPadrao3 = corPadrao(HARDCODE.corPadrao3, vividYellow)
const corPadrao4 = corPadrao(HARDCODE.corPadrao4, branco)
const corPadrao5 = corPadrao(HARDCODE.corPadrao5, preto)
const corPadrao6 = corPadrao(HARDCODE.corPadrao6, preto)
const corPadrao7 = corPadrao(HARDCODE.corPadrao7, preto)

const corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizado =
    corPadrao(HARDCODE.corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizado,
        '#551694' //'#6d2205' //'#df8b1a' //'#6d2205'
    )
const corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoBensMoveisImoveis =
    corPadrao(HARDCODE.corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoBensMoveisImoveis,
        '#662D9E' //'#8e412f' //'#fd9319' //'#8e412f'
    )
const corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoNegocios =
    corPadrao(HARDCODE.corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoNegocios,
        '#885BB4' //'#b35637' //'#fecd5a' //'#b35637'
    )
const corChartPadraoAnalisePatrimonialAtivosRecursosFinanceiros =
    corPadrao(HARDCODE.corChartPadraoAnalisePatrimonialAtivosRecursosFinanceiros,
        '#2d92d1',//'#55e393' //'#539162' //'#212786' //'#539162'
    )
    /*
    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    */
const corChartPadraoAnalisePatrimonialPassivos =
    corPadrao(HARDCODE.corChartPadraoAnalisePatrimonialPassivos,
        '#cc0a00' //'#db4f4b' //'#cc0a00'
    )
const corChartPadraoAnalisePatrimonialSaldoPatrimonial =
    corPadrao(HARDCODE.corChartPadraoAnalisePatrimonialSaldoPatrimonial,
        '#f5c200' //'#d8b600' //'#51db73' //'#d8b600' //'#ebc600' //'#d8b600'
    )
const corChartPadraoAnalisePatrimonialLegendaGraficoPizza =
    corPadrao(HARDCODE.corChartPadraoAnalisePatrimonialLegendaGraficoPizza,
        branco,
    )

const corChartPadraoManterReservaManterPadraoVida =
    corPadrao(HARDCODE.corChartPadraoManterReservaManterPadraoVida,
        '#3e8aff',//'#4c9fff',//primeiraReuniaoLinkAporteMensal: '#43B047'
    )
const corChartPadraoConsumirReservaManterPadraoVida =
    corPadrao(HARDCODE.corChartPadraoConsumirReservaManterPadraoVida,
        '#f5c200',//'#f5c200',//'#f7cd65', //primeiraReuniaoLinkRetirada: '#b93c28'
    )
const corChartPadraoManterReservaSemNovosAportes =
    corPadrao(HARDCODE.corChartPadraoManterReservaSemNovosAportes,
        '#551694',//'#8c847c',//'#e7745f',//: '#f5ad3f'
    )
const corChartPadraoConsumirReservaSemNovosAportes =
    corPadrao(HARDCODE.corChartPadraoConsumirReservaSemNovosAportes,
        '#ff6050',//'#7b746d',//'#b0a69c', //primeiraReuniaoLinkRetirada: '#b93c28'
    )
    

            

const gradienteRightPadrao12 = corPadrao1//'linear-gradient(to right, ' + corPadrao1 + ', ' + corPadrao2 + ')'



let THEMEFinTracks = {
    palleteType: 'light',
    palletePrimaryMain: primary,
    palletePrimaryContrastText: '#ffffff',
    palleteSecondaryMain: corPadrao3,
    palleteSecondaryContrastText: 'rgba(52, 49, 76, 1)',

    textMutedStrong: gray,

    bgLogin: gradienteRightPadrao12,//corPadrao1,
    bgLoginCard: corPadrao2,

    bgSidenav: corPadrao2,////preto,////'rgba(29, 29, 27, 1)',
    sidenav: corPadrao4,
    sidenavSwitch: '#ff9e43',

    bgDefault: gradienteRightPadrao12,//aqui whisper,//corPadrao1,////'#FFFFFF',

    bgFooter: preto,

    bgTransparente: transparente,

    bgAgendaEvent: '#f1465e',
    bgAgendaHeader: '#67aeca',//'#88bbd6',//'#4c9fff',
    agendaOffRange: gray,
    bgAgendaOffRange: '#c0b283',//'#dcd0c0',//'#f7cd65',
    bgAgendaToday: '#e7745f',
    bgAgendaMonthRow: '#b0a69c',
    bgAgendaTimeView: '#8eadbd',//'#8fd8d2',//'#9099a2',//'#6d7993',//'#d06c84',
    bgAgendaDaySlot: '#d7cec7',//'#d5d5d5',//'#9099a2',//'#f2b1b1',
    bgAgendaAgendaTable: '#f26277',

    bgCardGridDivHeaderEntradaDados: whiteSmoky,//narvik,//aqui corPadrao1,//'rgba(0, 0, 0, 0.15)',////corPadrao2,////'rgba(255, 165, 0, 1)',
    bgCardGridEntradaDados: branco,//'#fcfcfc',////'#CCDFCB',
    bgTextField: branco,//whiteSmoky,////'rgba(242, 242, 242, 0.1)',
    bgTextFieldDisabled: whiteSmoky,////'rgba(242, 242, 242, 0.1)',
    textField: preto,//'#000080',//'#00008B',//.'#228B22',//primary,//'#166abd',//preto,
    textFieldDisabled: '#666666',

    checkBox: preto,

    bgCardGridDivHeaderDestaque1: whiteSmoky,//narvik,//darkGray,

    bgCardGridDivHeaderDestaqueAlerta: vermelho,

    bgEstudoReport: 'rgba(245, 245, 245, 1)',

    bgEstudoReportPageCabecalho: gradienteRightPadrao12,//lightGray,

    bgCardGridDivHeaderResumoCabecalho: lightGray,//'rgba(118, 147, 60, 0.7)',
    bgCardGridDivBodyResumo: '',//lightGray30,//lightGray20,
    bgCardGridDivBodyResumoTotal: whiteSmoky,//'rgba(216, 228, 188, 1)',
    bgCardGridDivBodyResumoTotalGeral: lightGray,//'rgba(204, 192, 218, 1)',

    bgCardGridEntradaDadosHeaderBody: whiteSmoky,

    bgDragDropTwoListItem: silverChalice,
    bgDragDropTwoListItemObrigatorio: vermelho,
    bgDragDropTwoListItemEspecial: '#76B5C5',//mediumAquamarine,
    bgDragDropTwoListItemIsDraggingOver: verde,
    bgDragDropTwoListList: lightGray20,
    bgDragDropTwoListListIsDraggingOver: lightGray,

    bgTableRowBodyExibeDados: lightGray30,//lightGray20

    bgTableRowHeadExibeTabela: darkGray,
    bgTableRowBodyExibeTabela: lightGray20,

    tabsPadraoColor: branco,//'#ffffff',//aqui preto,
    bgTabsPadraoIndicator: corPadrao3,

    bgStepperPadrao: '',////'#CCDFCB',
    bgStepperCardValor: '#efefef',

    bgPanelRadioGroup: silverChalice,//corPadrao2,////'rgba(50, 205, 50, 0.8)',

    bgGroupPanel: silverChalice,
    bgGroupPanelPanelTotal: lightGray,

    bgTableHeadExibeDados: '#f7f7f7',//whiteSmoky,//osloGray,//silverChalice,
    bgTableHeadExibeDadosReportPopup: whiteSmoky,//osloGray,//silverChalice,

    bgComparacaoCenariosEstudosGrupo: osloGray,//'#27314a',//'#063970',
    bgComparacaoCenariosEstudosItem: geyser,//'#165fce',//'#2596BE',
    bgComparacaoCenariosEstudosItem2: '#bdcdd5',//'#02a1ca',//'#76B5C5',

    bgUpload: '#23238E',

    bgBold: '#7c7e8f',

    breadcrumb: branco,//'#ffffff',//aqui preto,
    breadcrumbIconHome: corPadrao3,

    buttonPadrao1: branco,//'#ffffff',
    bgButtonPadrao1: corPadrao3,
    buttonPadrao2: branco,//'#ffffff',
    bgButtonPadrao2: primary,
    buttonPadrao3: branco,//'#FFFFFF',
    bgButtonPadrao3: vermelho,

    linkPadrao: primary,
    APadrao: primary,

    iconButtonEditar: corPadrao3,
    iconButtonRevisaoDados: primary,
    iconButtonVerDetalhes: corPadrao3,
    iconButtonDuplicar: preto,
    iconButtonTransferir: primary,
    iconButtonClearEntradaDados: vermelho,
    iconButtonClearDialog: corPadrao3,
    iconButtonClear: gray,
    iconButtonVersao: primary,
    iconButtonFiltrar: primary,
    iconButtonFiltrarSetado: vermelho,
    iconButtonImprimir: preto,//corPadrao3,
    iconButtonEnderecos: primary,
    iconButtonExpandMore: corPadrao3,
    iconButtonExpandLess: corPadrao3,
    iconButtonWhatsapp: verdeWhatsapp,
    iconButtonPDF: corPadrao3,
    iconButtonExcel: corPadrao3,
    iconButtonDocument: corPadrao3,
    iconButtonMaisInformacoes: corPadrao3,
    iconButtonConfiguracao: primary,
    iconButtonTrilhaConhecimento: vermelho,//corPadrao3,
    iconButtonTreinamento: primary,//corPadrao3,
    iconButtonAgenda: primary,
    iconButtonVisualizarTabela: primary,
    iconButtonModoApresentacao: primary,
    iconModoApresentacaoPaginaAnteriorProximaPagina: vermelho,
    iconButtonSelecionarCenariosTipoMorteInvalidez: primary,

    iconButtonHelp: corPadrao3,
    iconButtonHelpTooltip: '',
    bgIconButtonHelpTooltip: '',
    iconButtonHelpTitle: martinique,
    bgIconButtonHelpTitle: '',
    iconButtonHelpText: smoky,
    bgIconButtonHelpText: '',

    iconButtonConfirmacaoText: smoky,

    valorNegativo: vermelho,
    
    tableCellHeadExibeDadosDestaque: vermelho,
    tableCellBodyExibeDadosDestaque: vermelho,
    
    panelRadioGroupHelpText: branco,
    estudoHelpText: '',

    iconCompleted: branco,//'#ffffff',
    bgIconCompleted: '#1B7B34',
    textCompleted: '#1B7B34',
    iconSelected: branco,//'#ffffff',
    bgIconSelected: corPadrao3,
    textSelected: corPadrao3,


    cardGridEntradaDadosHeaderBody: preto,//'#75757e',//'#53535e',
    

    corChartPadraoManterReservaManterPadraoVida: corChartPadraoManterReservaManterPadraoVida,
    corChartPadraoConsumirReservaManterPadraoVida: corChartPadraoConsumirReservaManterPadraoVida,
    corChartPadraoManterReservaSemNovosAportes: corChartPadraoManterReservaSemNovosAportes,
    corChartPadraoConsumirReservaSemNovosAportes: corChartPadraoConsumirReservaSemNovosAportes,
    resumoPlanejamentoEspecializacaoPeriodoSabatico: '#9192a5',

    disabled: black50,

    helperText: vermelho,
    helperTextDesejavel: amareloVivido,

    //toolBarRichTextEditor: corPadrao1,
    //bgToolBarRichTextEditor: corPadrao3,
    //bgContainerRichTextEditor: branco,//'#FFFFFF',

    corEstruturaFamiliar: '#2d92d1',//'#174e86',//silverChalice,
    corMeacao1: '#6ddbff',//'#33CCFF',//'#2f7ba8',
    corMeacao2: '#8ae2ff',//'#6ddbff',//'#9EEDFC',
    corHeranca1: '#97e4f4',//'#74c9e8',//'#3d9794',
    corHeranca2: '#abe9f6',//'#97e4f4',//'#8ebebf',

    capaRelatorio: corPadrao7,
    cabecalhoRelatorio: corPadrao5,
    rodapeRelatorio: corPadrao6,

    corDestaquePreto: preto,
    corDestaqueVerde: '#1B7B34',
    corDestaqueAmarelo: amareloVivido,
    corDestaqueLaranja: '#FFA500',
    corDestaqueVermelho: vermelho,

    corChartPadraoPreto: preto,
    corChartPadraoVerde: '#55e393',
    corChartPadraoAmarelo: '#ffff4e',
    corChartPadraoVermelho: '#f1465e',
    corChartPadraoCinza: '#CED4DC',
    //corChartPadraoCinzaEscuro: gray,

    corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizado: corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizado,
    corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoBensMoveisImoveis:
        corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoBensMoveisImoveis,
    corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoNegocios:
        corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoNegocios,
    corChartPadraoAnalisePatrimonialAtivosRecursosFinanceiros:
        corChartPadraoAnalisePatrimonialAtivosRecursosFinanceiros,
    corChartPadraoAnalisePatrimonialPassivos: corChartPadraoAnalisePatrimonialPassivos,
    corChartPadraoAnalisePatrimonialSaldoPatrimonial: corChartPadraoAnalisePatrimonialSaldoPatrimonial,
    corChartPadraoAnalisePatrimonialLegendaGraficoPizza: corChartPadraoAnalisePatrimonialLegendaGraficoPizza,

    corChartPadraoProjetoVidaSonho: '#ff7fbf',//'#00BFFF',//'rgba(0, 153, 255, 1)',//'rgba(153, 204, 255, 1)',//'#FFA500',
    corChartPadraoAporteAposentadoria: '#00BFFF',//'#FFFF00',//'#fbaf0f',//'#FFFF00',//'rgba(0, 0, 255, 1)',
    corChartPadraoAporteAposentadoriaBalancoPositivo: '#a232a2',//#8B008B',//,'#A020F0',//'#FF8C00',//'rgba(0, 51, 102, 1)',
    corChartPadraoAporteAtingirMetaInicioAposentadoria: 'rgba(226, 107, 10, 1)',
    corChartPadraoTabelaEvolucaoValorResgate: '#f5b349',//'#e7a83e',//'#0db2ac',
    corChartPadraoCapitalSegurado: '#11925b',//'#73b06f',//'#036564',//'#8c7c62',
    //corChartPadraoEconomiaInventarioSeguroVida: '#ce3ba0',//amareloVivido,
    corChartPadraoValorPremioAnualizadoAcumulado: '#755196',//'#0c285e',

    corChartPadraoPrevidenciaPrivada: '#55e393',
    corChartPadraoPrevidenciaPrivadaAporte: '#f5c200',
    corChartPadraoPrevidenciaPrivadaRentabilidade: '#3e8aff',
    corChartPadraoPrevidenciaPrivadaConsumirReserva: '#ff6050',
    corChartPadraoPrevidenciaPrivadaPreservarReserva: '#551694',

    corChartPadraoComparacaoFinanciamentoSugerido: '#3e8aff',//'#55e393',
    corChartPadraoComparacaoFinanciamentoAlternativo: '#f5c200',
    corChartPadraoComparacaoFinanciamentoEconomiaFinanciamentoSugerido: '#76e8a8',//'#3e8aff',
    corChartPadraoComparacaoFinanciamentoPerdaFinanciamentoSugerido: '#ff6670',
    corChartPadraoSaldoPagtoFinanciamentoSugeridoAVistaPositivo: '#468B64',
    corChartPadraoSaldoPagtoFinanciamentoSugeridoAVistaNegativo: '#cc5159',
    corChartPadraoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo: '#7dad92',
    corChartPadraoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo: '#db858a',

    //corChartPadraoDespesasAcumuladasSucessaoVitalicia: '#4c9fff',
    corChartPadraoDespesasAcumuladasSucessaoVitaliciaEmergencial: '#519edb',
    corChartPadraoDespesasAcumuladasDespesasVitalicias: '#f1465e',
    corChartPadraoDespesasAcumuladasProjetoVidaSonho: '#f7cd65',
    corChartPadraoDespesasAcumuladasEducacao: '#e7745f',
    corChartPadraoDespesasAcumuladasFinanciamento: '#b0a69c',
    corChartPadraoDespesasAcumuladasDespesasTemporarias: '#d06c84',

    corChartPadrao1: '#4c9fff',
    corChartPadrao2: '#f7cd65',
    corChartPadrao3: '#e7745f',
    corChartPadrao4: '#b0a69c',
    corChartPadrao5: '#d06c84',
    corChartPadrao6: '#5d78ff',
    corChartPadrao7: '#f2b1b1',
    corChartPadrao8: '#69bde6',
    corChartPadrao9: '#f26277',
    corChartPadrao10: '#FFD700',
    corChartPadrao11: '#5ca1a0',
    corChartPadrao12: '#008FFB',
    corChartPadrao13: '#55e393',
    corChartPadrao14: '#a695e1',
    corChartPadrao15: '#f1465e',
    corChartPadrao16: '#fbaf0f',
    corChartPadrao17: '#3e8efe',
    corChartPadrao18: '#775cd3',
    corChartPadrao19: '#00E396',
    corChartPadrao20: '#f48898',
    corChartPadrao21: '#5fdfed',
    corChartPadrao22: '#1B7B34',
    corChartPadrao23: '#ce2eee',
    corChartPadrao24: '#f5b100',
    corChartPadrao25: '#5ee7a4',

    corChartHipotesesRetornoReal: '#44b575',
}

export const COLOR = () => {
    const THEME = THEMEFinTracks;

    return {
        palleteType: THEME.palleteType,
        palletePrimaryMain: THEME.palletePrimaryMain,
        palletePrimaryContrastText: THEME.palletePrimaryContrastText,
        palleteSecondaryMain: THEME.palleteSecondaryMain,
        palleteSecondaryContrastText: THEME.palleteSecondaryContrastText,

        textMutedStrong: THEME.textMutedStrong,

        bgLogin: THEME.bgLogin,
        bgLoginCard: THEME.bgLoginCard,

        bgSidenav: THEME.bgSidenav,
        sidenav: THEME.sidenav,
        sidenavSwitch: THEME.sidenavSwitch,
        
        bgDefault: THEME.bgDefault,

        bgFooter: THEME.bgFooter,

        bgTransparente: THEME.bgTransparente,

        bgAgendaEvent: THEME.bgAgendaEvent,
        bgAgendaHeader: THEME.bgAgendaHeader,
        agendaOffRange: THEME.agendaOffRange,
        bgAgendaOffRange: THEME.bgAgendaOffRange,
        bgAgendaToday: THEME.bgAgendaToday,
        bgAgendaMonthRow: THEME.bgAgendaMonthRow,
        bgAgendaTimeView: THEME.bgAgendaTimeView,
        bgAgendaDaySlot: THEME.bgAgendaDaySlot,
        bgAgendaAgendaTable: THEME.bgAgendaAgendaTable,

        bgCardGridDivHeaderEntradaDados: THEME.bgCardGridDivHeaderEntradaDados,
        bgCardGridEntradaDados: THEME.bgCardGridEntradaDados,
        bgTextField: THEME.bgTextField,
        bgTextFieldDisabled: THEME.bgTextFieldDisabled,
        textField: THEME.textField,
        textFieldDisabled: THEME.textFieldDisabled,

        checkBox: THEME.checkBox,

        bgCardGridDivHeaderDestaque1: THEME.bgCardGridDivHeaderDestaque1,

        bgCardGridDivHeaderDestaqueAlerta: THEME.bgCardGridDivHeaderDestaqueAlerta,

        bgEstudoReport: THEME.bgEstudoReport,

        bgEstudoReportPageCabecalho: THEME.bgEstudoReportPageCabecalho,

        bgCardGridDivHeaderResumoCabecalho: THEME.bgCardGridDivHeaderResumoCabecalho,
        bgCardGridDivBodyResumo: THEME.bgCardGridDivBodyResumo,
        bgCardGridDivBodyResumoTotal: THEME.bgCardGridDivBodyResumoTotal,
        bgCardGridDivBodyResumoTotalGeral: THEME.bgCardGridDivBodyResumoTotalGeral,

        bgCardGridEntradaDadosHeaderBody: THEME.bgCardGridEntradaDadosHeaderBody,

        bgDragDropTwoListItem: THEME.bgDragDropTwoListItem,
        bgDragDropTwoListItemObrigatorio: THEME.bgDragDropTwoListItemObrigatorio,
        bgDragDropTwoListItemEspecial: THEME.bgDragDropTwoListItemEspecial,
        bgDragDropTwoListItemIsDraggingOver: THEME.bgDragDropTwoListItemIsDraggingOver,
        bgDragDropTwoListList: THEME.bgDragDropTwoListList,
        bgDragDropTwoListListIsDraggingOver: THEME.bgDragDropTwoListListIsDraggingOver,

        bgTableRowBodyExibeDados: THEME.bgTableRowBodyExibeDados,

        bgTableRowHeadExibeTabela: THEME.bgTableRowHeadExibeTabela,
        bgTableRowBodyExibeTabela: THEME.bgTableRowBodyExibeTabela,

        tabsPadraoColor: THEME.tabsPadraoColor,
        bgTabsPadraoIndicator: THEME.bgTabsPadraoIndicator,

        bgStepperPadrao: THEME.bgStepperPadrao,
        bgStepperCardValor: THEME.bgStepperCardValor,

        bgPanelRadioGroup: THEME.bgPanelRadioGroup,

        bgGroupPanel: THEME.bgGroupPanel,
        bgGroupPanelPanelTotal: THEME.bgGroupPanelPanelTotal,

        bgTableHeadExibeDados: THEME.bgTableHeadExibeDados,
        bgTableHeadExibeDadosReportPopup: THEME.bgTableHeadExibeDadosReportPopup,

        bgComparacaoCenariosEstudosGrupo: THEME.bgComparacaoCenariosEstudosGrupo,
        bgComparacaoCenariosEstudosItem: THEME.bgComparacaoCenariosEstudosItem,
        bgComparacaoCenariosEstudosItem2: THEME.bgComparacaoCenariosEstudosItem2,

        bgUpload: THEME.bgUpload,

        bgBold: THEME.bgBold,

        breadcrumb: THEME.breadcrumb,
        breadcrumbIconHome: THEME.breadcrumbIconHome,

        buttonPadrao1: THEME.buttonPadrao1,
        bgButtonPadrao1: THEME.bgButtonPadrao1,
        buttonPadrao2: THEME.buttonPadrao2,
        bgButtonPadrao2: THEME.bgButtonPadrao2,
        buttonPadrao3: THEME.buttonPadrao3,
        bgButtonPadrao3: THEME.bgButtonPadrao3,

        linkPadrao: THEME.linkPadrao,
        APadrao: THEME.APadrao,

        iconButtonEditar: THEME.iconButtonEditar,
        iconButtonRevisaoDados: THEME.iconButtonRevisaoDados,
        iconButtonVerDetalhes: THEME.iconButtonVerDetalhes,
        iconButtonDuplicar: THEME.iconButtonDuplicar,
        iconButtonTransferir: THEME.iconButtonTransferir,
        iconButtonClearEntradaDados: THEME.iconButtonClearEntradaDados,
        iconButtonClearDialog: THEME.iconButtonClearDialog,
        iconButtonClear: THEME.iconButtonClear,
        iconButtonVersao: THEME.iconButtonVersao,
        iconButtonFiltrar: THEME.iconButtonFiltrar,
        iconButtonFiltrarSetado: THEME.iconButtonFiltrarSetado,
        iconButtonImprimir: THEME.iconButtonImprimir,
        iconButtonEnderecos: THEME.iconButtonEnderecos,
        iconButtonExpandMore: THEME.iconButtonExpandMore,
        iconButtonExpandLess: THEME.iconButtonExpandLess,
        iconButtonWhatsapp: THEME.iconButtonWhatsapp,
        iconButtonPDF: THEME.iconButtonPDF,
        iconButtonExcel: THEME.iconButtonExcel,
        iconButtonDocument: THEME.iconButtonDocument,
        iconButtonMaisInformacoes: THEME.iconButtonMaisInformacoes,
        iconButtonConfiguracao: THEME.iconButtonConfiguracao,
        iconButtonTrilhaConhecimento: THEME.iconButtonTrilhaConhecimento,
        iconButtonTreinamento: THEME.iconButtonTreinamento,
        iconButtonAgenda: THEME.iconButtonAgenda,
        iconButtonVisualizarTabela: THEME.iconButtonVisualizarTabela,
        iconButtonModoApresentacao: THEME.iconButtonModoApresentacao,
        iconModoApresentacaoPaginaAnteriorProximaPagina: THEME.iconModoApresentacaoPaginaAnteriorProximaPagina,
        iconButtonSelecionarCenariosTipoMorteInvalidez: THEME.iconButtonSelecionarCenariosTipoMorteInvalidez,


        iconButtonHelp: THEME.iconButtonHelp,
        iconButtonHelpTooltip: THEME.iconButtonHelpTooltip,
        bgIconButtonHelpTooltip: THEME.bgIconButtonHelpTooltip,
        iconButtonHelpTitle: THEME.iconButtonHelpTitle,
        bgIconButtonHelpTitle: THEME.bgIconButtonHelpTitle,
        iconButtonHelpText: THEME.iconButtonHelpText,
        bgIconButtonHelpText: THEME.bgIconButtonHelpText,

        iconButtonConfirmacaoText: THEME.iconButtonConfirmacaoText,

        valorNegativo: THEME.valorNegativo,

        tableCellHeadExibeDadosDestaque: THEME.tableCellHeadExibeDadosDestaque,
        tableCellBodyExibeDadosDestaque: THEME.tableCellBodyExibeDadosDestaque,

        panelRadioGroupHelpText: THEME.panelRadioGroupHelpText,
        estudoHelpText: THEME.estudoHelpText,

        iconCompleted: THEME.iconCompleted,
        bgIconCompleted: THEME.bgIconCompleted,
        textCompleted: THEME.textCompleted,
        iconSelected: THEME.iconSelected,
        bgIconSelected: THEME.bgIconSelected,
        textSelected: THEME.textSelected,

        cardGridEntradaDadosHeaderBody: THEME.cardGridEntradaDadosHeaderBody,

        corChartPadraoManterReservaManterPadraoVida: THEME.corChartPadraoManterReservaManterPadraoVida,
        corChartPadraoConsumirReservaManterPadraoVida: THEME.corChartPadraoConsumirReservaManterPadraoVida,
        corChartPadraoManterReservaSemNovosAportes: THEME.corChartPadraoManterReservaSemNovosAportes,
        corChartPadraoConsumirReservaSemNovosAportes: THEME.corChartPadraoConsumirReservaSemNovosAportes,
        resumoPlanejamentoEspecializacaoPeriodoSabatico: THEME.resumoPlanejamentoEspecializacaoPeriodoSabatico,

        disabled: THEME.disabled,

        helperText: THEME.helperText,
        helperTextDesejavel: THEME.helperTextDesejavel,

        //toolBarRichTextEditor: THEME.toolBarRichTextEditor,
        //bgToolBarRichTextEditor: THEME.bgToolBarRichTextEditor,
        //: THEME.bgContainerRichTextEditor,

        corEstruturaFamiliar: THEME.corEstruturaFamiliar,
        corMeacao1: THEME.corMeacao1,
        corMeacao2: THEME.corMeacao2,
        corHeranca1: THEME.corHeranca1,
        corHeranca2: THEME.corHeranca2,

        capaRelatorio: THEME.capaRelatorio,
        cabecalhoRelatorio: THEME.cabecalhoRelatorio,
        rodapeRelatorio: THEME.rodapeRelatorio,

        corDestaquePreto: THEME.corDestaquePreto,
        corDestaqueVerde: THEME.corDestaqueVerde,
        corDestaqueAmarelo: THEME.corDestaqueAmarelo,
        corDestaqueLaranja: THEME.corDestaqueLaranja,
        corDestaqueVermelho: THEME.corDestaqueVermelho,
        
        corChartPadraoPreto: THEME.corChartPadraoPreto,
        corChartPadraoVerde: THEME.corChartPadraoVerde,
        corChartPadraoAmarelo: THEME.corChartPadraoAmarelo,
        corChartPadraoVermelho: THEME.corChartPadraoVermelho,
        corChartPadraoCinza: THEME.corChartPadraoCinza,
        //corChartPadraoCinzaEscuro: THEME.corChartPadraoCinzaEscuro,

        corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizado: THEME.corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizado,
        corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoBensMoveisImoveis:
            THEME.corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoBensMoveisImoveis,
        corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoNegocios:
            THEME.corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoNegocios,
        corChartPadraoAnalisePatrimonialAtivosRecursosFinanceiros: THEME.corChartPadraoAnalisePatrimonialAtivosRecursosFinanceiros,
        corChartPadraoAnalisePatrimonialPassivos: THEME.corChartPadraoAnalisePatrimonialPassivos,
        corChartPadraoAnalisePatrimonialSaldoPatrimonial: THEME.corChartPadraoAnalisePatrimonialSaldoPatrimonial,
        corChartPadraoAnalisePatrimonialLegendaGraficoPizza: THEME.corChartPadraoAnalisePatrimonialLegendaGraficoPizza,

        corChartPadraoProjetoVidaSonho: THEME.corChartPadraoProjetoVidaSonho,
        corChartPadraoAporteAposentadoria: THEME.corChartPadraoAporteAposentadoria,
        corChartPadraoAporteAposentadoriaBalancoPositivo: THEME.corChartPadraoAporteAposentadoriaBalancoPositivo,
        corChartPadraoAporteAtingirMetaInicioAposentadoria: THEME.corChartPadraoAporteAtingirMetaInicioAposentadoria,
        corChartPadraoTabelaEvolucaoValorResgate: THEME.corChartPadraoTabelaEvolucaoValorResgate,
        corChartPadraoCapitalSegurado: THEME.corChartPadraoCapitalSegurado,
        //corChartPadraoEconomiaInventarioSeguroVida: THEME.corChartPadraoEconomiaInventarioSeguroVida,
        corChartPadraoValorPremioAnualizadoAcumulado: THEME.corChartPadraoValorPremioAnualizadoAcumulado,

        corChartPadraoPrevidenciaPrivada: THEME.corChartPadraoPrevidenciaPrivada,
        corChartPadraoPrevidenciaPrivadaAporte: THEME.corChartPadraoPrevidenciaPrivadaAporte,
        corChartPadraoPrevidenciaPrivadaRentabilidade: THEME.corChartPadraoPrevidenciaPrivadaRentabilidade,
        corChartPadraoPrevidenciaPrivadaConsumirReserva: THEME.corChartPadraoPrevidenciaPrivadaConsumirReserva,
        corChartPadraoPrevidenciaPrivadaPreservarReserva: THEME.corChartPadraoPrevidenciaPrivadaPreservarReserva,

        corChartPadraoComparacaoFinanciamentoSugerido: THEME.corChartPadraoComparacaoFinanciamentoSugerido,
        corChartPadraoComparacaoFinanciamentoAlternativo: THEME.corChartPadraoComparacaoFinanciamentoAlternativo,
        corChartPadraoComparacaoFinanciamentoEconomiaFinanciamentoSugerido:
            THEME.corChartPadraoComparacaoFinanciamentoEconomiaFinanciamentoSugerido,
        corChartPadraoComparacaoFinanciamentoPerdaFinanciamentoSugerido:
            THEME.corChartPadraoComparacaoFinanciamentoPerdaFinanciamentoSugerido,
        corChartPadraoSaldoPagtoFinanciamentoSugeridoAVistaPositivo:
            THEME.corChartPadraoSaldoPagtoFinanciamentoSugeridoAVistaPositivo,
        corChartPadraoSaldoPagtoFinanciamentoSugeridoAVistaNegativo:
            THEME.corChartPadraoSaldoPagtoFinanciamentoSugeridoAVistaNegativo,
        corChartPadraoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo:
            THEME.corChartPadraoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo,
        corChartPadraoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo:
            THEME.corChartPadraoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo,

        //corChartPadraoDespesasAcumuladasSucessaoVitalicia: THEME.corChartPadraoDespesasAcumuladasSucessaoVitalicia,
        corChartPadraoDespesasAcumuladasSucessaoVitaliciaEmergencial: THEME.corChartPadraoDespesasAcumuladasSucessaoVitaliciaEmergencial,
        corChartPadraoDespesasAcumuladasDespesasVitalicias: THEME.corChartPadraoDespesasAcumuladasDespesasVitalicias,
        corChartPadraoDespesasAcumuladasProjetoVidaSonho: THEME.corChartPadraoDespesasAcumuladasProjetoVidaSonho,
        corChartPadraoDespesasAcumuladasEducacao: THEME.corChartPadraoDespesasAcumuladasEducacao,
        corChartPadraoDespesasAcumuladasFinanciamento: THEME.corChartPadraoDespesasAcumuladasFinanciamento,
        corChartPadraoDespesasAcumuladasDespesasTemporarias: THEME.corChartPadraoDespesasAcumuladasDespesasTemporarias,

        corChartPadrao1: THEME.corChartPadrao1,
        corChartPadrao2: THEME.corChartPadrao2,
        corChartPadrao3: THEME.corChartPadrao3,
        corChartPadrao4: THEME.corChartPadrao4,
        corChartPadrao5: THEME.corChartPadrao5,
        corChartPadrao6: THEME.corChartPadrao6,
        corChartPadrao7: THEME.corChartPadrao7,
        corChartPadrao8: THEME.corChartPadrao8,
        corChartPadrao9: THEME.corChartPadrao9,
        corChartPadrao10: THEME.corChartPadrao10,
        corChartPadrao11: THEME.corChartPadrao11,
        corChartPadrao12: THEME.corChartPadrao12,
        corChartPadrao13: THEME.corChartPadrao13,
        corChartPadrao14: THEME.corChartPadrao14,
        corChartPadrao15: THEME.corChartPadrao15,
        corChartPadrao16: THEME.corChartPadrao16,
        corChartPadrao17: THEME.corChartPadrao17,
        corChartPadrao18: THEME.corChartPadrao18,
        corChartPadrao19: THEME.corChartPadrao19,
        corChartPadrao20: THEME.corChartPadrao20,
        corChartPadrao21: THEME.corChartPadrao21,
        corChartPadrao22: THEME.corChartPadrao22,
        corChartPadrao23: THEME.corChartPadrao23,
        corChartPadrao24: THEME.corChartPadrao24,
        corChartPadrao25: THEME.corChartPadrao25,

        corChartHipotesesRetornoReal: THEME.corChartHipotesesRetornoReal,
    }
}

export const paletaCores1 = [
    COLOR().corChartPadrao1,
    COLOR().corChartPadrao2,
    COLOR().corChartPadrao3,
    COLOR().corChartPadrao4,
    COLOR().corChartPadrao5,
    COLOR().corChartPadrao6,
    COLOR().corChartPadrao7,
    COLOR().corChartPadrao8,
    COLOR().corChartPadrao9,
    COLOR().corChartPadrao10,
    COLOR().corChartPadrao11,
    COLOR().corChartPadrao12,
    COLOR().corChartPadrao13,
    COLOR().corChartPadrao14,
    COLOR().corChartPadrao15,
    COLOR().corChartPadrao16,
    COLOR().corChartPadrao17,
    COLOR().corChartPadrao18,
    COLOR().corChartPadrao19,
    COLOR().corChartPadrao20,
    COLOR().corChartPadrao21,
    COLOR().corChartPadrao22,
    COLOR().corChartPadrao23,
    COLOR().corChartPadrao24,
    COLOR().corChartPadrao25,
    COLOR().corChartPadraoPreto,
    COLOR().corChartPadraoVerde,
    COLOR().corChartPadraoVermelho,
    COLOR().corChartPadraoCinza,
]

/*
export const paletaCores2 = [
    COLOR().corChartPadrao1,
    COLOR().corChartPadrao2,
    COLOR().corChartPadrao3,
    COLOR().corChartPadrao4,
    COLOR().corChartPadrao5,
    COLOR().corChartPadrao6,
    COLOR().corChartPadrao7,
    COLOR().corChartPadrao8,
    COLOR().corChartPadrao9,
    COLOR().corChartPadrao10,
    COLOR().corChartPadrao11,
    COLOR().corChartPadrao12,
    COLOR().corChartPadrao13,
    COLOR().corChartPadrao14,
    COLOR().corChartPadrao15,
    COLOR().corChartPadrao16,
    COLOR().corChartPadrao17,
    COLOR().corChartPadrao18,
    COLOR().corChartPadrao19,
    COLOR().corChartPadrao20,
    COLOR().corChartPadrao21,
    COLOR().corChartPadrao22,
    COLOR().corChartPadrao23,
    COLOR().corChartPadrao24,
    COLOR().corChartPadrao25,
    COLOR().corChartPadraoPreto,
    COLOR().corChartPadraoVerde,
    COLOR().corChartPadraoVermelho,
    COLOR().corChartPadraoCinza,
]
*/

/*
export const paletaCores3 = [
    COLOR().corChartPadrao1,
    COLOR().corChartPadraoVermelho,
    COLOR().corChartPadrao2,
    COLOR().corChartPadrao3,
    COLOR().corChartPadrao4,
    COLOR().corChartPadrao5,
    COLOR().corChartPadrao6,
    COLOR().corChartPadrao7,
    COLOR().corChartPadrao8,
    COLOR().corChartPadrao9,
    COLOR().corChartPadrao10,
    COLOR().corChartPadrao11,
    COLOR().corChartPadrao12,
    COLOR().corChartPadrao13,
    COLOR().corChartPadrao14,
    COLOR().corChartPadrao15,
    COLOR().corChartPadrao16,
    COLOR().corChartPadrao17,
    COLOR().corChartPadrao18,
    COLOR().corChartPadrao19,
    COLOR().corChartPadrao20,
    COLOR().corChartPadrao21,
    COLOR().corChartPadrao22,
    COLOR().corChartPadrao23,
    COLOR().corChartPadrao24,
    COLOR().corChartPadrao25,
    COLOR().corChartPadraoVerde,
    COLOR().corChartPadraoCinza,
    COLOR().corChartPadraoPreto,
]
*/

export const paletaCores4 = [
    '#304D63',
    '#B2E7E8',
    '#8FB9AA',
    '#F2D096',
    '#ED8975',
    '#6B256F',
    '#C8B7E7',
    COLOR().corChartPadraoVerde,
    COLOR().corChartPadraoVermelho,
    COLOR().corChartPadraoPreto,
    COLOR().corChartPadrao1,
    COLOR().corChartPadrao2,
    COLOR().corChartPadrao3,
    COLOR().corChartPadrao4,
    COLOR().corChartPadrao5,
    COLOR().corChartPadrao6,
    COLOR().corChartPadrao7,
    COLOR().corChartPadrao8,
    COLOR().corChartPadrao9,
    COLOR().corChartPadrao10,
    COLOR().corChartPadrao11,
    COLOR().corChartPadrao12,
    COLOR().corChartPadrao13,
    COLOR().corChartPadrao14,
    COLOR().corChartPadrao15,
    COLOR().corChartPadrao16,
    COLOR().corChartPadrao17,
    COLOR().corChartPadrao18,
    COLOR().corChartPadrao19,
    COLOR().corChartPadrao20,
    COLOR().corChartPadrao21,
    COLOR().corChartPadrao22,
    COLOR().corChartPadrao23,
    COLOR().corChartPadrao24,
    COLOR().corChartPadrao25,
    COLOR().corChartPadraoCinza,
]

export const paletaCores5 = [
    COLOR().corChartPadraoPreto,
    COLOR().corChartPadraoVermelho,
    COLOR().corChartPadraoVerde,
    COLOR().corChartPadrao1,
    COLOR().corChartPadrao2,
    COLOR().corChartPadrao3,
    COLOR().corChartPadrao4,
    COLOR().corChartPadrao5,
    COLOR().corChartPadrao6,
    COLOR().corChartPadrao7,
    COLOR().corChartPadrao8,
    COLOR().corChartPadrao9,
    COLOR().corChartPadrao10,
    COLOR().corChartPadrao11,
    COLOR().corChartPadrao12,
    COLOR().corChartPadrao13,
    COLOR().corChartPadrao14,
    COLOR().corChartPadrao15,
    COLOR().corChartPadrao16,
    COLOR().corChartPadrao17,
    COLOR().corChartPadrao18,
    COLOR().corChartPadrao19,
    COLOR().corChartPadrao20,
    COLOR().corChartPadrao21,
    COLOR().corChartPadrao22,
    COLOR().corChartPadrao23,
    COLOR().corChartPadrao24,
    COLOR().corChartPadrao25,
    COLOR().corChartPadraoCinza,
]

export const paletaCores6 = [
    COLOR().corChartPadraoCinza,
    COLOR().corChartPadrao1,
    COLOR().corChartPadrao2,
    COLOR().corChartPadrao3,
    COLOR().corChartPadrao4,
    COLOR().corChartPadrao5,
    COLOR().corChartPadrao6,
    COLOR().corChartPadrao7,
    COLOR().corChartPadrao8,
    COLOR().corChartPadrao9,
    COLOR().corChartPadrao10,
    COLOR().corChartPadrao11,
    COLOR().corChartPadrao12,
    COLOR().corChartPadrao13,
    COLOR().corChartPadrao14,
    COLOR().corChartPadrao15,
    COLOR().corChartPadrao16,
    COLOR().corChartPadrao17,
    COLOR().corChartPadrao18,
    COLOR().corChartPadrao19,
    COLOR().corChartPadrao20,
    COLOR().corChartPadrao21,
    COLOR().corChartPadrao22,
    COLOR().corChartPadrao23,
    COLOR().corChartPadrao24,
    COLOR().corChartPadrao25,
    COLOR().corChartPadraoPreto,
    COLOR().corChartPadraoVerde,
    COLOR().corChartPadraoVermelho,
]

export const paletaCores7 = [
    COLOR().corChartPadraoVerde,
    COLOR().corChartPadraoVermelho,
    COLOR().corChartPadrao1,
    COLOR().corChartPadrao2,
    COLOR().corChartPadrao3,
    COLOR().corChartPadrao4,
    COLOR().corChartPadrao5,
    COLOR().corChartPadrao6,
    COLOR().corChartPadrao7,
    COLOR().corChartPadrao8,
    COLOR().corChartPadrao9,
    COLOR().corChartPadrao10,
    COLOR().corChartPadrao11,
    COLOR().corChartPadrao12,
    COLOR().corChartPadrao13,
    COLOR().corChartPadrao14,
    COLOR().corChartPadrao15,
    COLOR().corChartPadrao16,
    COLOR().corChartPadrao17,
    COLOR().corChartPadrao18,
    COLOR().corChartPadrao19,
    COLOR().corChartPadrao20,
    COLOR().corChartPadrao21,
    COLOR().corChartPadrao22,
    COLOR().corChartPadrao23,
    COLOR().corChartPadrao24,
    COLOR().corChartPadrao25,
    COLOR().corChartPadraoPreto,
    COLOR().corChartPadraoCinza,
]

export const paletaTonsVerde = [
    '#B2CC36',
    '#50C878',
    '#1B651E',
    '#228822',
    '#104716',
    '#00FF40',
    '#00A693',
    '#40E0E0',
    '#B2CC36',
    '#50C878',
    '#1B651E',
    '#228822',
    '#104716',
    '#00FF40',
    '#00A693',
    '#40E0E0',

    '#B2CC36',
    '#50C878',
    '#1B651E',
    '#228822',
    '#104716',
    '#00FF40',
    '#00A693',
    '#40E0E0',
    '#B2CC36',
    '#50C878',
    '#1B651E',
    '#228822',
    '#104716',
    '#00FF40',
    '#00A693',
    '#40E0E0',
]

export const paletaTonsVermelho = [
    '#FA8072',
    '#CD5C5C',
    '#E0115F',
    '#FF4CC6',
    '#ff0000',
    '#FF8AAF',
    '#B22222',
    '#FF4568',
    '#E886CC',
    '#BF5B8F',
    '#FA8072',
    '#CD5C5C',
    '#E0115F',
    '#FF4CC6',
    '#ff0000',
    '#FF8AAF',
    '#B22222',
    '#FF4568',
    '#E886CC',
    '#BF5B8F',

    '#FA8072',
    '#CD5C5C',
    '#E0115F',
    '#FF4CC6',
    '#ff0000',
    '#FF8AAF',
    '#B22222',
    '#FF4568',
    '#E886CC',
    '#BF5B8F',
    '#FA8072',
    '#CD5C5C',
    '#E0115F',
    '#FF4CC6',
    '#ff0000',
    '#FF8AAF',
    '#B22222',
    '#FF4568',
    '#E886CC',
    '#BF5B8F',
]

export const paletaTonsAmarelo = [
    '#eb9302',
    '#bb5008',
    '#f5bd1f',
    '#df6c02',
    '#eaaa34',
    '#cb8b15',
    '#ffee33',
    '#e6cf00',
    '#ffa600',
    '#df6c02',
    '#eb9302',
    '#ffee32',
    '#bb5008',
    '#f5bd1f',
    '#eaaa34',
    '#cb8b15',
    '#ffee33',
    '#e6cf00',
    '#ffa600',
    '#ffee32',

    '#eb9302',
    '#bb5008',
    '#f5bd1f',
    '#df6c02',
    '#eaaa34',
    '#cb8b15',
    '#ffee33',
    '#e6cf00',
    '#ffa600',
    '#df6c02',
    '#eb9302',
    '#ffee32',
    '#bb5008',
    '#f5bd1f',
    '#eaaa34',
    '#cb8b15',
    '#ffee33',
    '#e6cf00',
    '#ffa600',
    '#ffee32',
]

export const paletaTonsAzul = [
    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    '#0c285e',
    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    '#0c285e',

    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    '#0c285e',
    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    '#0c285e',

    //mais cores
    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    '#0c285e',
    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    '#0c285e',

    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    '#0c285e',
    '#174e86',
    '#2d92d1',
    '#74c9e8',
    '#0974f1',
    '#97e4f4',
    '#336699',
    '#70b1d4',
    '#3185fc',
    '#ade8f4',
    '#0c285e',
]