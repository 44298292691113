import formPrimeiraReuniaoPadraoList from './formPrimeiraReuniaoPadraoList'



const formPrimeiraReuniaoInvestimento60List = () => {

    const formList = [...formPrimeiraReuniaoPadraoList]
    
    formList.push(
        {
            id:  140,
            descricao: 'Quais são os seus objetivos?',
            default: false,
            grupoPergunta: '',
            type: 'MultiList',
            list: [
                {id: '10', descricao: 'Proteger a mim e minha renda, caso não consiga trabalhar', ordem: 10},
                {id: '20', descricao: 'Proteger a minha família de uma falta precoce, apoiando no pagamento de dívidas e acesso ao patrimônio (inventário)', ordem: 20},
                {id: '30', descricao: 'Garantir a educação dos meus filhos/dependentes financeiros na minha falta', ordem: 30},
                {id: '40', descricao: 'Quitação de Dívidas', ordem: 40},
                {id: '50', descricao: 'Gerar uma renda vitalícia adicional aos meus dependentes financeiros na minha falta', ordem: 50},
                {id: '99', descricao: 'Outro', ordem: 60},
            ],
            labelButtonIncluirLinha: 'Incluir Objetivo',
            idOutros: '99',
            tamMaxOutros: 100,
            ordem: 140,
        },
    )

    return formList
}
 
export default formPrimeiraReuniaoInvestimento60List