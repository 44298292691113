import React, { useState, useContext } from 'react'

import Cryptr from 'cryptr'

import useSingleton from '../../business-components/useSingleton/useSingleton'

//import {
//  Checkbox,
//  FormControlLabel,
//} from '@material-ui/core'

import { ValidatorForm } from 'react-material-ui-form-validator'

import history from 'history.js'

import { useLocation } from "react-router-dom"

import AppContext from '../../contexts/AppContext'

import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import LABELESPECIAL from '../../business-const/Label/LabelEspecial'

import MESSAGE from '../../business-const/Message/Message'

import PATH from '../../business-const/Path/Path'

import TAMMAX from '../../business-rules/TamMax/TamMax'

import AuthService from '../../business-api/AuthService'

import EmailService from '../../business-api/EmailService'

import IntegracaoService from '../../business-api/IntegracaoService'

import UsuariosService from '../../business-api/UsuarioService'

import {
    mensagemAlerta,
    mensagemErroErroTexto,
} from '../../business-components/Toastr/Toastr'

import {
    CardGridEntradaDados,
} from '../../business-components/Card/Card'

import {
    GridContainer,
    GridContainerLogin,
    GridItem1sm3xs3,
    GridItem4sm12xs12,
    GridItem6sm12xs12,
    GridItem7sm10xs10,
    GridItem7sm12xs12,
} from '../../business-components/Grid/Grid'

import {
    TextValidatorText,
    TextValidatorEmail,
    TextValidatorSenha,
} from '../../business-components/TextValidator/TextValidator'

import {
    ButtonEntrar,
    ButtonCadastreSe,
    ButtonEsqueceuSenha,
} from '../../business-components/Button/Button'

import {
    CircularProgressPadrao,
} from '../../business-components/CircularProgress/CircularProgress'

import {
    LinkPadraoSubmitEnviarPinEmail,
} from '../../business-components/Link/Link'

import {
    dataAtual,
    formataData,
    stringParaData,
    dataAtualSemHora,
} from '../../business-components/Date/Date'

import {
    delay,
} from '../../business-components/Delay/Delay'

//import {
//    ImgLogoSigninBackground,
//} from '../../business-components/Img/Img'

import {
    pesquisaList
} from '../../business-rules/List/ListService'

import codigoPromocionalList from '../../business-rules/List/Assinatura/codigoPromocionalList'

import {
    putConfiguracaoUsuario,
} from '../configuracao/ConfiguracaoService'

import {
    converteJsonConfiguracaoJsonImagem,
    obtemPaletaCores,
    identificaIndexIdWhiteLabel,
    ajustaInclusoesAlteracoesExclusoesJsonConfiguracao,
    ajustaInclusoesAlteracoesExclusoesJsonUsuario,
    ajustaInclusoesAlteracoesExclusoesJsonImagem,
} from '../configuracao/ConfiguracaoService'

import {
    redirecionarClienteForm,
} from '../clientes/ClienteListService'

import {
    LogoSession,
    TituloSession,
    WhatsAppSession,
} from './SessionComp'

import {
    getConfiguracaoURL,
} from './SessionService'



const Signin = () => {

    const location = useLocation()

    const [exibirLogin, setExibirLogin] = useState(false)

    const [loading, setLoading] = useState(false)

    const [loadingLinkEnviarPinEmail, setLoadingLinkEnviarPinEmail] = useState(false)

    const [userInfo, setUserInfo] = useState({
        email: '',
        senha: '',
        remember: true,
        validarNecessidadePin: true,
        pin: '',
    })

    const [signature, setSignature] = useState(null)

    const [message, setMessage] = useState('')

    const emailService = new EmailService()

    const usuarioService = new UsuariosService()

    const { variaveisGlobais } = useContext(AppContext)

    const processarLogin = async (response) => {

        if (AuthService.obterAcessoCliente(variaveisGlobais)) {

            AuthService.setarAcessoClienteChaves(variaveisGlobais,
                {
                    usuarioLogado: response.data.id,
                    emailUsuarioLogado: response.data.email,
                    identificaoUsuarioLogado: response.data.identificacao,
                    token: response.data.token,
                    jsonIntegracao: response.data.jsonIntegracao,
                    dataInicioFreetrackUsuarioLogado: response.data.dataInicioFreetrack,
                    usuarioSelecionado: [
                        {
                            id: response.data.id,
                            label: response.data.identificacao,
                            value: response.data.id,
                        }
                    ],
                    ultimoLogin: formataData(dataAtual())
                }
            )

        } else {

            AuthService.setarUsuarioLogado(response.data.id)
            AuthService.setarEmailUsuarioLogado(response.data.email)
            AuthService.setarIdentificacaoUsuarioLogado(response.data.identificacao)

            AuthService.setarToken(response.data.token)
            AuthService.setarDataInicioFreetrackUsuarioLogado(response.data.dataInicioFreetrack)
            AuthService.setarUsuarioSelecionado(
                [
                    {
                        id: response.data.id,
                        label: response.data.identificacao,
                        value: response.data.id,
                    }
                ]
            )

            AuthService.setarUltimoLogin(formataData(dataAtual()))
        }

    

        if (signature !== null) {

            const linhaCodigoPromocionalList = pesquisaList(codigoPromocionalList, signature)

            if (!linhaCodigoPromocionalList.id) {

                mensagemAlerta(MESSAGE().linkInvalido)
                await delay(5)

            } else {

                let configuracaoUsuario = {
                    jsonConfiguracao:
                        response.data.jsonConfiguracao
                            ?
                                JSON.parse(response.data.jsonConfiguracao)
                            :
                                {},
                    jsonImagem:
                        response.data.jsonImagem
                            ?
                                JSON.parse(response.data.jsonImagem)
                            :
                                {},
                    jsonPagina:
                        response.data.jsonPagina
                            ?
                                JSON.parse(response.data.jsonPagina)
                            :
                                {},
                    jsonGestaoPatrimonial:
                        response.data.jsonGestaoPatrimonial
                            ?
                                JSON.parse(response.data.jsonGestaoPatrimonial)
                            :
                                {},
                    jsonPagamento: {},
                    jsonUsuario:
                        response.data.jsonUsuario
                            ?
                                JSON.parse(response.data.jsonUsuario)
                            :
                                {},
                    dataUltimaCobrancaAte: response.data.dataUltimaCobrancaAte,
                }

                if (stringParaData(linhaCodigoPromocionalList.dataAssinaturaAte) <= dataAtualSemHora()) {

                    mensagemAlerta(MESSAGE().linkSignatureExpirou)
                    await delay(5)

                } else {

                    if (
                           // configuracaoUsuario.jsonConfiguracao.historicoAssinatura &&
                           // configuracaoUsuario.jsonConfiguracao.historicoAssinatura.length > 0
                           configuracaoUsuario.dataUltimaCobrancaAte
                    ) {

                        mensagemAlerta(MESSAGE().linkSignatureNaoPodeSerAplicado)
                        await delay(5)

                    } else {
            
                        configuracaoUsuario.jsonConfiguracao.grupo = linhaCodigoPromocionalList.grupo
                        configuracaoUsuario.jsonConfiguracao.subgrupo = linhaCodigoPromocionalList.subgrupo
                        configuracaoUsuario.jsonConfiguracao.dataAssinaturaAte =
                            linhaCodigoPromocionalList.dataAssinaturaAte

                        await putConfiguracaoUsuario(variaveisGlobais, configuracaoUsuario)

                        mensagemAlerta(MESSAGE().linkSignatureAplicadoComSucesso)
                        await delay(5)
                    }
                }
            }
        }

    

        let logo = null
        let logoTopbar = null
        let linkLogoTopbar = AuthService.obterAcessoCliente(variaveisGlobais) ? HARDCODE.urlFintracks : HARDCODE.urlAppFintracksDashboardInicial
        let tamanhoLogo = null
        let tamanhoLogoTopbar = null
        let logoLoginSidenav = null
        //let background = null
        let backgroundCapaRelatorio = null
        let backgroundCapaRelatorioPaisagem = null
        let backgroundPaginaRelatorio = null
        let backgroundPaginaRelatorioPaisagem = null
        let paletaCores = []
        let textoMorte = LABELESPECIAL.morte
        let tipoIdioma = HARDCODE.tipoIdiomaDefault
        let tipoMoeda = HARDCODE.tipoMoedaDefault

        const jsonConfiguracao = ajustaInclusoesAlteracoesExclusoesJsonConfiguracao(JSON.parse(response.data.jsonConfiguracao))

        const jsonUsuario = ajustaInclusoesAlteracoesExclusoesJsonUsuario(JSON.parse(response.data.jsonUsuario))

        if (response.data.jsonConfiguracao && response.data.jsonImagem) {

            const indexIdWhiteLabel = identificaIndexIdWhiteLabel(jsonConfiguracao, jsonUsuario)

            const jsonImagem = converteJsonConfiguracaoJsonImagem(jsonConfiguracao,
                ajustaInclusoesAlteracoesExclusoesJsonImagem(JSON.parse(response.data.jsonImagem)))

            if (jsonImagem[indexIdWhiteLabel].logo) {
                
                logo = jsonImagem[indexIdWhiteLabel].logo
                logoTopbar = jsonImagem[indexIdWhiteLabel].logo
            }

            if (jsonConfiguracao.linkLogoTopbar) {
                
                linkLogoTopbar = jsonConfiguracao.linkLogoTopbar
            }

            if (jsonConfiguracao.whiteLabel[indexIdWhiteLabel].tamanhoLogo) {
                
                tamanhoLogo = jsonConfiguracao.whiteLabel[indexIdWhiteLabel].tamanhoLogo
                tamanhoLogoTopbar = jsonConfiguracao.whiteLabel[indexIdWhiteLabel].tamanhoLogo
            }

            if (jsonImagem[indexIdWhiteLabel].logoLoginSidenav) {
                
                logoLoginSidenav = jsonImagem[indexIdWhiteLabel].logoLoginSidenav
            }

            //if (jsonImagem[indexIdWhiteLabel].background) {
                
            //    background = jsonImagem[indexIdWhiteLabel].background
            //}

            if (jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorio) {
                
                backgroundCapaRelatorio = jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorio
            }

            if (jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorioPaisagem) {
                
                backgroundCapaRelatorioPaisagem = jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorioPaisagem
            }

            if (jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorio) {
                
                backgroundPaginaRelatorio = jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorio
            }

            if (jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorioPaisagem) {
                
                backgroundPaginaRelatorioPaisagem = jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorioPaisagem
            }

            paletaCores = obtemPaletaCores(jsonConfiguracao, jsonUsuario)

            textoMorte = jsonConfiguracao.textoMorte ? jsonConfiguracao.textoMorte : textoMorte
            tipoIdioma = jsonConfiguracao.tipoIdioma ? jsonConfiguracao.tipoIdioma : tipoIdioma
            tipoMoeda = jsonConfiguracao.tipoMoeda ? jsonConfiguracao.tipoMoeda : tipoMoeda
            
            if (response.data.jsonConfiguracaoCobranca && response.data.jsonImagemCobranca) {

                const jsonConfiguracaoCobranca =
                    ajustaInclusoesAlteracoesExclusoesJsonConfiguracao(
                        JSON.parse(response.data.jsonConfiguracaoCobranca))

                const jsonImagemCobranca = converteJsonConfiguracaoJsonImagem(jsonConfiguracaoCobranca,
                    ajustaInclusoesAlteracoesExclusoesJsonImagem(JSON.parse(response.data.jsonImagemCobranca)))

                if (
                        jsonImagemCobranca.logo &&
                        jsonConfiguracaoCobranca.whiteLabel[indexIdWhiteLabel].exibirLogoTopbarParceiros
                ) {
                    
                    logoTopbar = jsonImagemCobranca.logo

                    if (jsonConfiguracaoCobranca.linkLogoTopbar) {
                
                        linkLogoTopbar = jsonConfiguracaoCobranca.linkLogoTopbar
                    }
    
                    if (jsonConfiguracaoCobranca.whiteLabel[indexIdWhiteLabel].tamanhoLogo) {
                        
                        tamanhoLogoTopbar = jsonConfiguracaoCobranca.whiteLabel[indexIdWhiteLabel].tamanhoLogo
                    }
                }
            }
        }

        if (AuthService.obterAcessoCliente(variaveisGlobais)) {

            AuthService.setarAcessoClienteChaves(variaveisGlobais,
                {
                    ...AuthService.obterAcessoClienteChaves(variaveisGlobais),
                    logo: logo,
                    logoTopbar: logoTopbar,
                    linkLogoTopbar: linkLogoTopbar,
                    tamanhoLogo: tamanhoLogo,
                    tamanhoLogoTopbar: tamanhoLogoTopbar,
                    logoLoginSidenav: logoLoginSidenav,
                    //background: background,
                    backgroundCapaRelatorio: backgroundCapaRelatorio,
                    backgroundCapaRelatorioPaisagem: backgroundCapaRelatorioPaisagem,
                    backgroundPaginaRelatorio: backgroundPaginaRelatorio,
                    backgroundPaginaRelatorioPaisagem: backgroundPaginaRelatorioPaisagem,
                }
            )
           
            if (
                    JSON.stringify(paletaCores) !== JSON.stringify(AuthService.obterPaletaCores()) ||
                    JSON.stringify(textoMorte) !== JSON.stringify(AuthService.obterTextoMorte()) ||
                    JSON.stringify(tipoIdioma) !== JSON.stringify(AuthService.obterTipoIdioma()) ||
                    JSON.stringify(tipoMoeda) !== JSON.stringify(AuthService.obterTipoMoeda())
            ) {

                AuthService.setarPaletaCores(paletaCores)
                AuthService.setarTextoMorte(textoMorte)
                AuthService.setarTipoIdioma(tipoIdioma)
                AuthService.setarTipoMoeda(tipoMoeda)
                AuthService.setarMoedaPrefixSuffix(tipoMoeda)

                window.location.reload() // Forçar carregar a paleta de cores para os clientes finais
            }

        } else {

            if (
                    JSON.stringify(logo) !== JSON.stringify(AuthService.obterLogo(variaveisGlobais)) ||
                    JSON.stringify(logoTopbar) !== JSON.stringify(AuthService.obterLogoTopbar(variaveisGlobais)) ||
                    JSON.stringify(linkLogoTopbar) !== JSON.stringify(AuthService.obterLinkLogoTopbar(variaveisGlobais)) ||
                    JSON.stringify(tamanhoLogo) !== JSON.stringify(AuthService.obterTamanhoLogo(variaveisGlobais)) ||
                    JSON.stringify(tamanhoLogoTopbar) !== JSON.stringify(AuthService.obterTamanhoLogoTopbar(variaveisGlobais)) ||
                    JSON.stringify(logoLoginSidenav) !== JSON.stringify(AuthService.obterLogoLoginSidenav(variaveisGlobais)) ||
                    //JSON.stringify(background) !== JSON.stringify(AuthService.obterBackground(variaveisGlobais)) ||
                    JSON.stringify(backgroundCapaRelatorio) !== JSON.stringify(AuthService.obterBackgroundCapaRelatorio(variaveisGlobais)) ||
                    JSON.stringify(backgroundCapaRelatorioPaisagem) !== JSON.stringify(AuthService.obterBackgroundCapaRelatorioPaisagem(variaveisGlobais)) ||
                    JSON.stringify(backgroundPaginaRelatorio) !== JSON.stringify(AuthService.obterBackgroundPaginaRelatorio(variaveisGlobais)) ||
                    JSON.stringify(backgroundPaginaRelatorioPaisagem) !== JSON.stringify(AuthService.obterBackgroundPaginaRelatorioPaisagem(variaveisGlobais)) ||
                    JSON.stringify(paletaCores) !== JSON.stringify(AuthService.obterPaletaCores()) ||
                    JSON.stringify(textoMorte) !== JSON.stringify(AuthService.obterTextoMorte()) ||
                    JSON.stringify(tipoIdioma) !== JSON.stringify(AuthService.obterTipoIdioma()) ||
                    JSON.stringify(tipoMoeda) !== JSON.stringify(AuthService.obterTipoMoeda())
            ) {

                AuthService.setarLogo(logo)
                AuthService.setarLogoTopbar(logoTopbar)
                AuthService.setarLinkLogoTopbar(linkLogoTopbar)
                AuthService.setarTamanhoLogo(tamanhoLogo)
                AuthService.setarTamanhoLogoTopbar(tamanhoLogoTopbar)
                AuthService.setarLogoLoginSidenav(logoLoginSidenav)
                //AuthService.setarBackground(background)
                AuthService.setarBackgroundCapaRelatorio(backgroundCapaRelatorio)
                AuthService.setarBackgroundCapaRelatorioPaisagem(backgroundCapaRelatorioPaisagem)
                AuthService.setarBackgroundPaginaRelatorio(backgroundPaginaRelatorio)
                AuthService.setarBackgroundPaginaRelatorioPaisagem(backgroundPaginaRelatorioPaisagem)
                AuthService.setarPaletaCores(paletaCores)
                AuthService.setarTextoMorte(textoMorte)
                AuthService.setarTipoIdioma(tipoIdioma)
                AuthService.setarTipoMoeda(tipoMoeda)
                AuthService.setarMoedaPrefixSuffix(tipoMoeda)
            }
        }

        let reload = true

        if (AuthService.obterAcessoCliente(variaveisGlobais)) {

            reload = false

            if (
                    response.data.jsonIntegracao &&
                    (
                        response.data.jsonIntegracao.integracaoInclusaoSiteInstitucional ||
                        response.data.jsonIntegracao.integracaoInclusaoSiteInstitucional2 ||
                        response.data.jsonIntegracao.integracaoInclusaoCaptacaoLeads
                    )
            ) {

                redirecionarClienteForm(variaveisGlobais, response.data.id, null,
                    HARDCODE.ultimaVersaoInicial, HARDCODE.jsonComparacaoEstudosInicial, HARDCODE.ultimaVersaoInicial,
                        null, null, response.data.jsonIntegracao, null)

            } else {

                redirecionarClienteForm(variaveisGlobais, response.data.id, response.data.idCliente,
                    response.data.ultimaVersao, response.data.jsonComparacaoEstudos, response.data.ultimaVersao, null,
                        response.data.jsonAcessoCliente, null, null)
            }

        } else {

            if (response.data.idClienteIntegracaoCRM && !response.data.mensagemErroIntegracao) {

                const integracaoCRM = {
                    idClienteIntegracaoCRM: response.data.idClienteIntegracaoCRM,
                    nomeClienteIntegracaoCRM: response.data.nomeClienteIntegracaoCRM,
                    cpfClienteIntegracaoCRM: response.data.cpfClienteIntegracaoCRM,
                }

                reload = false

                if (response.data.idCliente) {

                    redirecionarClienteForm(variaveisGlobais, response.data.id, response.data.idCliente,
                        response.data.ultimaVersao, response.data.jsonComparacaoEstudos, response.data.ultimaVersao,
                            integracaoCRM, null, null, null)

                } else {

                    redirecionarClienteForm(variaveisGlobais, response.data.id, null,
                        HARDCODE.ultimaVersaoInicial, HARDCODE.jsonComparacaoEstudosInicial, HARDCODE.ultimaVersaoInicial,
                            integracaoCRM, null, null, null)
                }

            } else {

                if (
                        jsonUsuario.nome &&
                        //jsonUsuario.nomeCurto &&
                        jsonUsuario.celular &&
                        jsonConfiguracao.razaoSocial &&
                        jsonConfiguracao.cidade
                ) {

                    if (response.data.mensagemErroIntegracao) {

                        mensagemAlerta(response.data.mensagemErroIntegracao)
                        await delay(5)
                    }

                    history.push(PATH.pageDashboardCliente)

                } else {

                    history.push(PATH.pageConfiguracaoForm)
                }
            }
        }

        if (reload) {
            
            window.location.reload() // para forçar a atualização da versão e a utilização das atualizações do LocalStorage realizadas acima
        }
    }

    const erroIntegracao = (email, mensagemErro) => {

        setExibirLogin(true)

        AuthService.setarEmailUsuarioLogado(email)

        setUserInfo({
            ...userInfo, 
            email: email
        })
        
        history.push(PATH.pageSignin)

        setMessage(mensagemErro)
    }

    useSingleton( async () => {

        setUserInfo({
            ...userInfo, 
            email: location.email ? location.email : AuthService.obterEmailUsuarioLogado(variaveisGlobais)
        })

        //await getConfiguracaoURL(variaveisGlobais) => movido para baixo

        if (AuthService.obterUltimoLogin(variaveisGlobais) === HARDCODE.sessaoExpirou) {

            AuthService.setarUltimoLogin("")

            setMessage(MESSAGE().sessaoExpirou)
        }

        if (AuthService.obterUltimoLogin(variaveisGlobais) === HARDCODE.sessaoExpirouToken) {

            AuthService.setarUltimoLogin("")

            setMessage(MESSAGE().sessaoExpirouToken)
        }

        const queryParams = new URLSearchParams(window.location.search)

        let signatureAux = queryParams.get('signature')

        if (signatureAux === null) {

            signatureAux = location.signature

            if (signatureAux === undefined) {

                signatureAux = null
            }
        }

        setSignature(signatureAux)

        let email
        let tokenIntegracao
        let chaveInvalida = false
        let ti

        const chaveCliente = queryParams.get('costumer')
        let chaveCaptacaoLeads
        let linkCaptacaoLeadsOrigem
        let linkCaptacaoLeadsFunil
        let linkCaptacaoLeadsEtapa

        if (chaveCliente) {

            const cryptr = new Cryptr(HARDCODE.costumer)

            let JSONChaveCliente

            try {

                JSONChaveCliente = JSON.parse(cryptr.decrypt(chaveCliente))

                email = JSONChaveCliente.email
                tokenIntegracao = JSONChaveCliente.tokenIntegracao

                AuthService.setarAcessoCliente(variaveisGlobais, true)

            } catch (erro) {

                erroIntegracao(email, MESSAGE().erroLinkInvalido)

                chaveInvalida = true

                setUserInfo({
                    ...userInfo, 
                    email: ''
                })
            }

        } else {

            chaveCaptacaoLeads = queryParams.get('cl')

            if (chaveCaptacaoLeads) {

                const cryptr = new Cryptr(HARDCODE.captacaoLeads)

                let JSONChaveCaptacaoLeads

                try {

                    JSONChaveCaptacaoLeads = JSON.parse(cryptr.decrypt(chaveCaptacaoLeads))

                    email = JSONChaveCaptacaoLeads.email
                    tokenIntegracao = JSONChaveCaptacaoLeads.tokenIntegracao
                    linkCaptacaoLeadsOrigem = JSONChaveCaptacaoLeads.origem
                    linkCaptacaoLeadsFunil = JSONChaveCaptacaoLeads.funil
                    linkCaptacaoLeadsEtapa = JSONChaveCaptacaoLeads.etapa

                    AuthService.setarAcessoCliente(variaveisGlobais, true)

                } catch (erro) {

                    erroIntegracao(email, MESSAGE().erroLinkInvalido)

                    chaveInvalida = true

                    setUserInfo({
                        ...userInfo, 
                        email: ''
                    })
                }

            } else {

                AuthService.setarAcessoCliente(variaveisGlobais, false)

                email = queryParams.get('email')
                tokenIntegracao = queryParams.get('tokenIntegracao')
                ti = queryParams.get('ti')

                if (ti === HARDCODE.tiInclusaoSiteInstitucional) {

                    AuthService.setarAcessoCliente(variaveisGlobais, true)
                }
            }
        }

        if (email && tokenIntegracao && !chaveInvalida) {
            
            const integracaoService = new IntegracaoService()

            try {

                const response = await integracaoService.autenticarTokenIntegracao(variaveisGlobais,
                    {
                        email: email,
                        tokenIntegracao: tokenIntegracao,
                        acessoCliente: AuthService.obterAcessoCliente(variaveisGlobais)
                    }
                )

                if (response.data.jsonIntegracao) {

                    response.data.jsonIntegracao = JSON.parse(response.data.jsonIntegracao)

                    response.data.jsonIntegracao = {
                        ...response.data.jsonIntegracao,
                        integracaoInclusaoSiteInstitucional:
                            response.data.jsonIntegracao.tipoIntegracao === HARDCODE.tipoIntegracaoInclusaoSiteInstitucional,
                        integracaoInclusaoSiteInstitucional2:
                            response.data.jsonIntegracao.tipoIntegracao === HARDCODE.tipoIntegracaoInclusaoSiteInstitucional2
                    }
                }

                if (chaveCaptacaoLeads) {

                    response.data.jsonIntegracao = {
                        integracaoInclusaoCaptacaoLeads: true,
                        linkCaptacaoLeadsOrigem: linkCaptacaoLeadsOrigem,
                        funil: linkCaptacaoLeadsFunil,
                        etapa: linkCaptacaoLeadsEtapa,
                    }
                }

                if (
                        ti === HARDCODE.tiInclusaoSiteInstitucional &&
                        (
                            !response.data.jsonIntegracao ||
                            (
                                !response.data.jsonIntegracao.integracaoInclusaoSiteInstitucional &&
                                !response.data.jsonIntegracao.integracaoInclusaoSiteInstitucional2
                            )
                        )
                ) {

                    erroIntegracao(email, MESSAGE().erroTipoIntegracao)

                } else {

                    await processarLogin(response)
                }

            } catch (erro) {

                erroIntegracao(email, mensagemErroErroTexto(erro))
            }
            
        } else {

            if (!chaveInvalida) {

                await getConfiguracaoURL(variaveisGlobais)

                setExibirLogin(true)
            }
        }
    })

    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo }
        temp[name] = value
        setUserInfo(temp)
        setMessage("")

    }

    const handleFormSubmit = async (event) => {

        setLoading(true)

        try {

            let response = await usuarioService.autenticar(variaveisGlobais,
                {
                    email: userInfo.email,
                    senha: userInfo.senha
                },
                userInfo.validarNecessidadePin, userInfo.pin, false
            )

            if (!response) {

                // Retentativa de login automática pela aplicação
                // Tratamento erro reportado pelos usuários no login: Objects are not valid as a React
                // child (found: TypeErro: Cannot read properties of undefined (readind 'id')
                // Não foi encontrada ainda a causa raiz, então trata-se de uma solução de contorno

                response = await usuarioService.autenticar(variaveisGlobais,
                    {
                        email: userInfo.email,
                        senha: userInfo.senha
                    },
                    userInfo.validarNecessidadePin, userInfo.pin, true
                )
            }

            if (userInfo.validarNecessidadePin && (response.data.qtdeLogins > 2 || response.data.mfa === 'S')) {

                await emailService.validacaoLogin(variaveisGlobais, userInfo.email)

                setUserInfo({
                    ...userInfo,
                    validarNecessidadePin: false,
                })

                setMessage(
                    response.data.mfa === 'S'
                        ?
                            MESSAGE().alertaMfa
                        :
                            MESSAGE().alertaQtdeLogins.replace("$qtdeLogins$", response.data.qtdeLogins)
                )

                setLoading(false)

            } else {

                await processarLogin(response)
            }

        } catch (erro) {

            setMessage(mensagemErroErroTexto(erro))
            setLoading(false)
        }
    }


    
    const enviarPinEmail = async () => {

        setLoadingLinkEnviarPinEmail(true)

        try {
            await emailService.validacaoLogin(variaveisGlobais, userInfo.email)

            setLoadingLinkEnviarPinEmail(false)

            setMessage(MESSAGE().pinEnviadoComSucesso)

            //setState({
            //    ...state,
            //    emailEnviado: state.email,
            //})

        } catch (erro) {

            setMessage(mensagemErroErroTexto(erro))

            setLoadingLinkEnviarPinEmail(false)
        }
    }



    return (
        exibirLogin
            ?
                <GridContainer
                    className="justify-center items-center max-w-full min-h-full-screen bg-login"
                >
                    {/*
                    <ImgLogoSigninBackground
                        variaveisGlobais={variaveisGlobais}
                        values={null}
                    />
                    */}

                    <GridItem7sm10xs10
                        className="position w-full"
                    >
                        <CardGridEntradaDados>
                            <GridContainerLogin>
                                <GridItem6sm12xs12>
                                    <LogoSession
                                        variaveisGlobais={variaveisGlobais}
                                    />
                                </GridItem6sm12xs12>
                                
                                <GridItem6sm12xs12>
                                    <div
                                        className="p-6 h-full"
                                    >
                                        <TituloSession
                                            titulo={LABEL.login}
                                        />

                                        <ValidatorForm
                                            onSubmit={handleFormSubmit}
                                        >
                                            <TextValidatorEmail
                                                name="email"
                                                label={LABEL.email}
                                                value={userInfo.email || ''}
                                                onChange={handleChange}
                                            />
                                            <TextValidatorSenha
                                                name="senha"
                                                label={LABEL.senha}
                                                value={userInfo.senha || ''}
                                                onChange={handleChange}
                                            />
                                            {/*
                                            <FormControlLabel
                                                className="mb-3 min-w-288"
                                                name="remember"
                                                onChange={handleChange}
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        onChange={({
                                                            target: { checked },
                                                        }) =>
                                                            handleChange({
                                                                target: {
                                                                    name: 'remember',
                                                                    value: checked,
                                                                },
                                                            })
                                                        }
                                                        checked={userInfo.remember}
                                                    />
                                                }
                                                label="Lembre me"
                                            />
                                            */}

                                            {!userInfo.validarNecessidadePin
                                                ?
                                                    <GridContainer>
                                                        <GridItem4sm12xs12>
                                                            <TextValidatorText
                                                                name="pin"
                                                                label={LABEL.pin}
                                                                value={userInfo.pin || ''}
                                                                onChange={handleChange}
                                                                tamMax={TAMMAX.pin}
                                                            />
                                                        </GridItem4sm12xs12>

                                                        <GridItem1sm3xs3 />

                                                        <GridItem7sm12xs12>
                                                            <div
                                                                className="relative"
                                                            >
                                                                <LinkPadraoSubmitEnviarPinEmail
                                                                    title={LABEL.linkReenviarPinEmail}
                                                                    onClick={enviarPinEmail}
                                                                    disabled={loadingLinkEnviarPinEmail}
                                                                />
                                                                {loadingLinkEnviarPinEmail && (
                                                                    <CircularProgressPadrao />
                                                                )}
                                                            </div>
                                                        </GridItem7sm12xs12>
                                                    </GridContainer>
                                                :
                                                    ''
                                            }
                                            
                                            {message && (
                                                <p
                                                    className="text-error"
                                                >
                                                    {message}
                                                </p>
                                            )}

                                            <div
                                                className="flex items-center"
                                            >
                                                <div
                                                    className="relative"
                                                >
                                                    <ButtonEntrar
                                                        disabled={loading}
                                                    />
                                                    {loading && (
                                                        <CircularProgressPadrao />
                                                    )}
                                                </div>
                                            </div>

                                            <ButtonCadastreSe
                                                onClick={() =>
                                                    history.push({
                                                        pathname: PATH.pageSignup,
                                                        email: userInfo.email,
                                                        signature: signature,
                                                    })
                                                }
                                            />

                                            <ButtonEsqueceuSenha
                                                onClick={() =>
                                                    history.push({
                                                        pathname: PATH.pageResetSenha,
                                                        email: userInfo.email,
                                                        signature: signature,
                                                    })
                                                }
                                            />
                                        </ValidatorForm>
                                        
                                        <WhatsAppSession />
                                    </div>
                                </GridItem6sm12xs12>
                            </GridContainerLogin>
                        </CardGridEntradaDados>
                    </GridItem7sm10xs10>
                </GridContainer>
            :
                ''
    )
}

export default Signin