//import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'



export const tipoRecursoFinanceiroList = [
    {
        id: 10,
        descricao: 'Carteira Total',
        ordem: 10,
        ordemConsumo: 40,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 10,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 20,
        descricao: 'Ações',
        ordem: 20,
        ordemConsumo: 90,
        tipoPeriodicidadeAporteDividendosDefault: 'M',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'S',
        labelDividendos: 'Dividendos',
        labelDividendosAteAposentadoria: 'Os dividendos serão recebidos até a ' + LABEL.aposentadoria.toLowerCase(),
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'S',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 20,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 30, // HARDCODE.idTipoRecursoFinanceiroInternacional
        descricao: 'Internacional',
        ordem: 96,
        ordemConsumo: 130,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 30,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 40,
        descricao: 'Espécie',
        ordem: 40,
        ordemConsumo: 10,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'N',
        tipoRecursoFinanceiroGrafico: 40,
        mediaPonderadaTaxaJuros: 'N',
        inventario: 'N',
        somenteITCMD: 'N',
    },
    {
        id: 70, // HARDCODE.idTipoRecursoFinanceiroFgtsSaldo
        descricao: 'FGTS - Saldo',
        ordem: 70,
        ordemConsumo: 120,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 90,
        mediaPonderadaTaxaJuros: 'N',
        inventario: 'N',
        somenteITCMD: 'N',
    },
    {
        id: 80, // HARDCODE.idTipoRecursoFinanceiroFgtsContribuicao
        descricao: 'FGTS - Contribuição',
        ordem: 80,
        ordemConsumo: 120,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'N',
        totalizaEntradaDados: 'N',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 90,
        mediaPonderadaTaxaJuros: 'N',
        inventario: 'N',
        somenteITCMD: 'N',
    },
    {
        id: 90,
        descricao: 'FGTS',
        ordem: 90,
        ordemConsumo: 120,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'N',
        totalizaEntradaDados: 'N',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 90,
        mediaPonderadaTaxaJuros: 'N',
        inventario: 'N',
        somenteITCMD: 'N',
    },
    {
        id: 93, // HARDCODE.idTipoRecursoFinanceiroInflacao
        descricao: 'Inflação',
        ordem: 93,
        ordemConsumo: 160,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 93,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 100,
        descricao: 'Fundo de Investimento',
        ordem: 100,
        ordemConsumo: 70,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'S',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'S',
        informaCnpjFundo: 'S',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 100,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 103, // HARDCODE.idTipoRecursoFinanceiroMultimercado
        descricao: 'Multimercado',
        ordem: 103,
        ordemConsumo: 160,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 103,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 106, // HARDCODE.idTipoRecursoFinanceiroPosFixado
        descricao: 'Pós Fixado',
        ordem: 106,
        ordemConsumo: 160,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 106,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 110,
        descricao: 'Poupança',
        ordem: 110,
        ordemConsumo: 20,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'S',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'S',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'S',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 110,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 115, // HARDCODE.idTipoRecursoFinanceiroPreFixado
        descricao: 'Pré Fixado',
        ordem: 115,
        ordemConsumo: 160,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 115,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 120, // HARDCODE.idTipoRecursoFinanceiroPrevidenciaPGBL
        descricao: 'Prev. Privada - PGBL',
        ordem: 120,
        ordemConsumo: 100,
        tipoPeriodicidadeAporteDividendosDefault: 'M',
        informaReservaAtual: 'S',
        informaAporteDividendos: 'S',
        informaAporteEmpresa: 'S',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'S',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'S',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'S',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'S',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 120,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',//HARDCODE.inventarioPrevidenciaPrivada,
        somenteITCMD: 'N',//'S', retirado em 13/12/2024 em função da decisão do STF por 11 x 0
    },
    {
        id: 130, // HARDCODE.idTipoRecursoFinanceiroPrevidenciaVGBL
        descricao: 'Prev. Privada - VGBL',
        ordem: 130,
        ordemConsumo: 110,
        tipoPeriodicidadeAporteDividendosDefault: 'M',
        informaReservaAtual: 'S',
        informaAporteDividendos: 'S',
        informaAporteEmpresa: 'S',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'S',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'S',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'S',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'S',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 130,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'N',//HARDCODE.inventarioPrevidenciaPrivada,
        somenteITCMD: 'N',
    },

    /* Inativado e convertido em ClienteService.inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para id 120
       nào reutilizar o id 140
        {
            id: 140, // HARDCODE.idTipoRecursoFinanceiroPrevidenciaPGBLEmpresarial
            descricao: 'Prev. Privada - PGBL Empresarial',
            ordem: 140,
            tipoPeriodicidadeAporteDividendosDefault: 'M',
            informaReservaAtual: 'S',
            informaAporteDividendos: 'S',
            informaAporteEmpresa: 'S',
            informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'S',
            informaPercRentabilidadeRecursoFinanceiro: 'S',
            //informaTaxaAnual: 'N',
            informaVencimento: 'N',
            informaInstituicao: 'S',
            informaNomeAcao: 'N',
            informaRegimeTributacao: 'S',
            informaNomeFundo: 'N',
            informaCnpjFundo: 'S',
            permiteEntradaDados: 'S',
            totalizaEntradaDados: 'S',
            atualizaTaxaJuros: 'S',
            tipoRecursoFinanceiroGrafico: 140,
            mediaPonderadaTaxaJuros: 'S',
            inventario: 'S',//HARDCODE.inventarioPrevidenciaPrivada,
            somenteITCMD: 'S',
        },
    */

    /* Inativado e convertido em ClienteService.inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para id 130
       nào reutilizar o id 145
        {
            id: 145, // HARDCODE.idTipoRecursoFinanceiroPrevidenciaVGBLEmpresarial
            descricao: 'Prev. Privada - VGBL Empresarial',
            ordem: 145,
            tipoPeriodicidadeAporteDividendosDefault: 'M',
            informaReservaAtual: 'S',
            informaAporteDividendos: 'S',
            informaAporteEmpresa: 'S',
            informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'S',
            informaPercRentabilidadeRecursoFinanceiro: 'S',
            //informaTaxaAnual: 'N',
            informaVencimento: 'N',
            informaInstituicao: 'S',
            informaNomeAcao: 'N',
            informaRegimeTributacao: 'S',
            informaNomeFundo: 'N',
            informaCnpjFundo: 'S',
            permiteEntradaDados: 'S',
            totalizaEntradaDados: 'S',
            atualizaTaxaJuros: 'S',
            tipoRecursoFinanceiroGrafico: 145,
            mediaPonderadaTaxaJuros: 'S',
            inventario: 'N',//HARDCODE.inventarioPrevidenciaPrivada,
            somenteITCMD: 'N',
        },
    */

    {
        id: 150,
        descricao: 'Renda Fixa',
        ordem: 150,
        ordemConsumo: 160,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'S',
        informaVencimento: 'S',
        informaInstituicao: 'S',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 150,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 152, // HARDCODE.idTipoRecursoFinanceiroRendaVariavel
        descricao: 'Renda Variável',
        ordem: 152,
        ordemConsumo: 160,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 152,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 155, // HARDCODE.idTipoRecursoFinanceiroSeguroVidaSobrevivencia
        descricao: 'Seguro de Vida - Sobrevivência',
        ordem: 155,
        ordemConsumo: 140,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'N',
        totalizaEntradaDados: 'N',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 155,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'N',
        somenteITCMD: 'N',
    },
    {
        id: 160,
        descricao: 'Título Público',
        ordem: 160,
        ordemConsumo: 80,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'S',
        informaVencimento: 'S',
        informaInstituicao: 'S',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        atualizaTaxaJuros: 'S',
        tipoRecursoFinanceiroGrafico: 160,
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 900, // HARDCODE.idTipoRecursoFinanceiroOutros
        descricao: 'Outros',
        ordem: 900,
        ordemConsumo: 30,
        tipoPeriodicidadeAporteDividendosDefault: '',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        tipoRecursoFinanceiroGrafico: 900,
        atualizaTaxaJuros: 'S',
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 910,
        descricao: 'Formação de Reserva',
        ordem: 910,
        ordemConsumo: 50,
        tipoPeriodicidadeAporteDividendosDefault: 'M',
        informaReservaAtual: 'S',
        informaAporteDividendos: 'S',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'S',
        informaPercRentabilidadeRecursoFinanceiro: 'S',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'S',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'S',
        totalizaEntradaDados: 'S',
        tipoRecursoFinanceiroGrafico: 910,
        atualizaTaxaJuros: 'S',
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 920, // HARDCODE.idTipoRecursoFinanceiroEconomiaReceitaMensal
        descricao: 'Economia Receita Mensal',
        ordem: 920,
        ordemConsumo: 55,
        tipoPeriodicidadeAporteDividendosDefault: 'M',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'N',
        totalizaEntradaDados: 'N',
        tipoRecursoFinanceiroGrafico: 920,
        atualizaTaxaJuros: 'S',
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 930, // HARDCODE.idTipoRecursoFinanceiroFaltaBalancoNegativoReceitaDespesaDiaDia
        descricao: 'Falta para Balanço Negativo Receita x Despesa e Dia a Dia',
        ordem: 930,
        ordemConsumo: null,
        tipoPeriodicidadeAporteDividendosDefault: 'M',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'N',
        totalizaEntradaDados: 'N',
        tipoRecursoFinanceiroGrafico: 930,
        atualizaTaxaJuros: 'S',
        mediaPonderadaTaxaJuros: 'N',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 940, // HARDCODE.idTipoRecursoFinanceiroAporteAtingirMetaInicioAposentadoria
        descricao: 'Aporte Atingir Meta Início ' + LABEL.aposentadoria,
        ordem: 940,
        ordemConsumo: 57,
        tipoPeriodicidadeAporteDividendosDefault: 'M',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'N',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'N',
        totalizaEntradaDados: 'N',
        tipoRecursoFinanceiroGrafico: 940,
        atualizaTaxaJuros: 'S',
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
    {
        id: 950, // HARDCODE.idTipoRecursoFinanceiroAporteResgateVidaInteira
        descricao: 'Aporte Resgate Vida Inteira',
        ordem: 950,
        ordemConsumo: null,
        tipoPeriodicidadeAporteDividendosDefault: 'M',
        informaReservaAtual: 'N',
        informaAporteDividendos: 'S',
        informaAporteEmpresa: 'N',
        informaAporteOriundoReceitaMensalOuRealocacaoCarteiraInvestimentos: 'N',
        informaPercRentabilidadeRecursoFinanceiro: 'N',
        //informaTaxaAnual: 'N',
        informaVencimento: 'N',
        informaInstituicao: 'N',
        informaNomeAcao: 'N',
        informaRegimeTributacao: 'N',
        informaNomeFundo: 'N',
        informaCnpjFundo: 'N',
        permiteEntradaDados: 'N',
        totalizaEntradaDados: 'N',
        tipoRecursoFinanceiroGrafico: 950,
        atualizaTaxaJuros: 'S',
        mediaPonderadaTaxaJuros: 'S',
        inventario: 'S',
        somenteITCMD: 'N',
    },
]

export default tipoRecursoFinanceiroList