import _ from 'lodash'

import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import LABELESPECIAL from '../../../business-const/Label/LabelEspecial'

import AuthService from '../../../business-api/AuthService'

import ClienteService from '../../../business-api/ClienteService'

import FamiliaService from '../../../business-api/FamiliaService'

import InfoclientService from '../../../business-api/InfoclientService'

import VwClienteAcessoService from '../../../business-api/VwClienteAcessoService'

import VwClienteAcessoFamiliaService from '../../../business-api/VwClienteAcessoFamiliaService'

import VwClienteAcessoAporteService from '../../../business-api/VwClienteAcessoAporteService'

import VwClienteAcessoPendenciaService from '../../../business-api/VwClienteAcessoPendenciaService'

import {
    comparaArraysJSON
} from '../../../business-components/Array/Array'

import {
    round
} from '../../../business-components/round/round'

import {
    mensagemErroErro
} from '../../../business-components/Toastr/Toastr'

import {
    nomeCurtoCliente,
    nomeCurtoConjuge, 
} from '../../../business-rules/String/nomeCurto'

import {
    dataAtual,
    dataValida,
    difDatasStringAnos,
    ano,
    mes,
    mesMesAno,
    anoMesAno,
    dataAnoMesDia,
    formataDataEmMesAnoSemBarra,
    formataMesAnoComBarra,
    calculaIdade,
    comparaData,
} from '../../../business-components/Date/Date'

import {
    pesquisaList,
    pesquisaIndexList,
} from '../../../business-rules/List/ListService'

import tipoContatoList from '../../../business-rules/List/tipoContatoList'

import tipoPeriodicidadeList from '../../../business-rules/List/tipoPeriodicidadeList'

import tipoDiaDiaList from '../../../business-rules/List/tipoDiaDiaList'

import tipoEducacaoList from '../../../business-rules/List/tipoEducacaoList'

import tipoFinanciamentoList from '../../../business-rules/List/tipoFinanciamentoList'

import expectativaVidaList from '../../../business-rules/List/expectativaVidaList'

import acumulacaoList from '../../../business-rules/List/acumulacaoList'

import {
    incrementaContEndereco,
} from '../cadastro-form/CadastroServiceEndereco'

import {
    idadeFamiliarDataSimulacao,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    incrementaContRecursosFinanceiros,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    incrementaContDiaDia,
} from '../despesas-form/DespesasServiceDiaDia'

import {
    incrementaContEducacao,
} from '../despesas-form/DespesasServiceEducacao'

import {
    incrementaContFinanciamento,
    calculaValorParcelaTabelaSAC,
} from '../despesas-form/DespesasServiceFinanciamento'

import {
    incrementaContProjetoVidaSonho,
} from '../projetoVidaSonho-form/ProjetoVidaSonhoServiceProjetoVidaSonho'

import {
    incluirCenario,
} from '../estudo-parametros/EstudoServiceCenarios'

import {
    trataMudancaNaoExibirMorteAteParaExibirMorteAte,
} from '../estudo-parametros/EstudoServiceConfiguracaoProtecao'

import {
    incluiAlteraExcluiConfiguracaoRelatorioReportList,
    inicializaConfiguracaoRelatorio,
} from '../estudo-parametros/EstudoServiceConfiguracaoRelatorio'

import {
    calculaMesesFluxoCaixa,
    temFluxoCaixa,
    calculaQtdeParcelas,
    calculaReajusteConsorcioEtc,
} from '../estudo-form/EstudoServiceCalculo'



const comparaZero = (valor1, valor2) => {

    return (
            valor1 === valor2 ||
            (
                (
                    !valor1 && !valor2
                ) ||
                (
                    valor1 === 0 && valor2 === 0
                )
            )
        )
}

const objetoClienteValor = (variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL, campo, isArrayJSON, usadoPeloCliente,
    compararZero, compararData) => {

    let valor = values[campo]

    if (valuesAntNULL) {

        if (AuthService.obterAcessoCliente(variaveisGlobais) && usadoPeloCliente) {

            valor = valuesAntNULL[campo]
        }

        if (jsonInfoclientNULL) {

            if (isArrayJSON) {

                if (_.isEqual(valor, valuesAntNULL[campo])) {

                    valor = jsonInfoclientNULL[campo]
                }
                
            } else {

                if (compararZero) {

                    if (comparaZero(valor, valuesAntNULL[campo])) {

                        valor = jsonInfoclientNULL[campo]
                    }

                } else {
                    
                    if (compararData) {

                        if (comparaData(valor, valuesAntNULL[campo])) {

                            valor = jsonInfoclientNULL[campo]
                        }

                    } else {

                        if (valor === valuesAntNULL[campo]) {

                            valor = jsonInfoclientNULL[campo]
                        }
                    }
                }
            }
        }
    }

    return valor
}

export const objetoCliente = (variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL) => {

    return {

        modoInteracaoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'modoInteracaoCliente', false, true, false, false),

        funil: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'funil', false, false, false, false),
        etapa: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'etapa', false, false, false, false),
        dataHoraPrevistaFunilEtapa: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'dataHoraPrevistaFunilEtapa', false, false, false, false),
        dataRealizacaoFunilEtapa: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'dataRealizacaoFunilEtapa', false, false, false, false),
        potencialNegocios: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'potencialNegocios', false, false, false, false),
        potencialNegociosSomar: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'potencialNegociosSomar', false, false, false, false),
        contFunisEtapas: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contFunisEtapas', false, false, false, false),
        funisEtapas: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'funisEtapas', true, false, false, false),

        compartilhamentoUsuario: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'compartilhamentoUsuario', true, false, false, false),

        idFormList: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idFormList', false, false, false, false),
        formListRadioGroup: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'formListRadioGroup', false, false, false, false),
        formulario: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'formulario', false, true, false, false),
        perguntas: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'perguntas', true, false, false, false),


        tabIndexClientePrimeiraReuniao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tabIndexClientePrimeiraReuniao', false, false, false, false),
        tabIndexClienteAprofundamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tabIndexClienteAprofundamento', false, false, false, false),
        tabIndexForm: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tabIndexForm', false, false, false, false),
        tabIndexAnaliseFinanceira: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tabIndexAnaliseFinanceira', false, false, false, false),
        tabIndexEstudo: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tabIndexEstudo', false, false, false, false),
        tabIndexEstudoParametros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tabIndexEstudoParametros', false, false, false, false),
        tabIndexEstudoRevisaoDados: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tabIndexEstudoRevisaoDados', false, false, false, false),
        tabIndexAcompanhamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tabIndexAcompanhamento', false, false, false, false),


        nomeCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'nomeCliente', false, false, false, false),
        nomeCurtoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'nomeCurtoCliente', false, false, false, false),
        cpfCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'cpfCliente', false, false, false, false),
        idClienteIntegracaoCRM: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idClienteIntegracaoCRM', false, false, false, false),
        nascimentoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'nascimentoCliente', false, false, false, true),
        idadeCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeCliente', false, false, true, false),
        sexoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'sexoCliente', false, false, false, false),
        tipoOrigemCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoOrigemCliente', false, false, false, false),
        clienteRecomendou: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'clienteRecomendou', false, false, false, false),
        tipoOrigemClienteOutras: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoOrigemClienteOutras', false, false, false, false),
        tipoCarteiraInvestimentoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoCarteiraInvestimentoCliente', false, false, false, false),
        momentoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'momentoCliente', false, false, false, false),
        //observacaoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
        //    'observacaoCliente', false, false, false, false),
        grauInstrucaoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'grauInstrucaoCliente', false, false, false, false),
        formacaoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'formacaoCliente', false, false, false, false),
        profissaoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'profissaoCliente', false, false, false, false),
        profissaoClienteOutras: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'profissaoClienteOutras', false, false, false, false),
        empresaCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'empresaCliente', false, false, false, false),
        possuiConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'possuiConjuge', false, false, false, false),
        tipoRegimeBens: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoRegimeBens', false, false, false, false),
        percMeacaoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percMeacaoCliente', false, false, false, false),
        percHerancaFamiliarDiferenteConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percHerancaFamiliarDiferenteConjuge', false, false, false, false),
        tipoPoliticaInvestimentoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoPoliticaInvestimentoCliente', false, false, false, false),
        avatarCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'avatarCliente', false, false, false, false),

        nomeConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'nomeConjuge', false, false, false, false),
        nomeCurtoConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'nomeCurtoConjuge', false, false, false, false),
        cpfConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'cpfConjuge', false, false, false, false),
        nascimentoConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'nascimentoConjuge', false, false, false, true),
        idadeConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeConjuge', false, false, true, false),
        sexoConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'sexoConjuge', false, false, false, false),
        grauInstrucaoConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'grauInstrucaoConjuge', false, false, false, false),
        formacaoConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'formacaoConjuge', false, false, false, false),
        profissaoConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'profissaoConjuge', false, false, false, false),
        profissaoConjugeOutras: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'profissaoConjugeOutras', false, false, false, false),
        empresaConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'empresaConjuge', false, false, false, false),
        avatarConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'avatarConjuge', false, false, false, false),

        dataInclusao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'dataInclusao', false, false, false, false),

        contFamiliar: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contFamiliar', false, false, false, false),
        familiares: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiares', true, false, false, false),

        contatos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contatos', true, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisContato:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisContato', true, false, false, false),
        
        contasInvestimentos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contasInvestimentos', true, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisContaInvestimentos:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisContaInvestimentos', true, false, false, false),

        contEndereco: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contEndereco', false, false, false, false),
        enderecos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'enderecos', true, false, false, false),
        processouRegistrosDefaultIniciaisEndereco:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'processouRegistrosDefaultIniciaisEndereco', true, false, false, false),
        cepResidencial: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'cepResidencial', false, false, false, false),
        idEnderecoCepResidencial: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idEnderecoCepResidencial', false, false, false, false),

        objetivosPrincipaisPreocupacoesPFCMLP: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'objetivosPrincipaisPreocupacoesPFCMLP', true, false, false, false),

        familiarHistoricoSaudeRiscos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarHistoricoSaudeRiscos', false, false, false, false),
        historicoSaudeRiscos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'historicoSaudeRiscos', true, false, false, false),

        esportesHobbiesRisco: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'esportesHobbiesRisco', true, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisEsporteHobbyRisco:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisEsporteHobbyRisco', true, false, false, false), 

        condicaoSaude: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'condicaoSaude', true, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisCondicaoSaude:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisCondicaoSaude', true, false, false, false),

        historicoSaudeFamiliares: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'historicoSaudeFamiliares', true, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisHistoricoSaudeFamiliares:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisHistoricoSaudeFamiliares', true, false, false, false),



        contReceitaMensal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contReceitaMensal', false, false, false, false),
        rowsPerPageReceitaMensal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'rowsPerPageReceitaMensal', false, false, false, false),
        pageReceitaMensal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageReceitaMensal', false, false, false, false),
        pageReceitaMensalList: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageReceitaMensalList', false, false, false, false),
        familiarReceitaMensal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarReceitaMensal', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisReceitaMensal:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisReceitaMensal', true, false, false, false),
        totalReceitaMensal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalReceitaMensal', true, false, false, false),
        receitaMensal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'receitaMensal', true, false, false, false),

        contDiaDia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contDiaDia', false, false, false, false),
        rowsPerPageDiaDia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'rowsPerPageDiaDia', false, false, false, false),
        pageDiaDia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageDiaDia', false, false, false, false),
        pageDiaDiaList: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageDiaDiaList', false, false, false, false),
        familiarDiaDia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarDiaDia', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisDiaDia:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisDiaDia', true, false, false, false),
        totalDiaDia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalDiaDia', true, false, false, false),
        diaDia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'diaDia', true, false, false, false),

        contEducacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contEducacao', false, false, false, false),
        rowsPerPageEducacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'rowsPerPageEducacao', false, false, false, false),
        pageEducacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageEducacao', false, false, false, false),
        pageEducacaoList: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageEducacaoList', false, false, false, false),
        familiarEducacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarEducacao', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisEducacao:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisEducacao', true, false, false, false),
        totalEducacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalEducacao', true, false, false, false),
        educacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'educacao', true, false, false, false),

        contFinanciamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contFinanciamento', false, false, false, false),
        rowsPerPageFinanciamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'rowsPerPageFinanciamento', false, false, false, false),
        pageFinanciamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageFinanciamento', false, false, false, false),
        pageFinanciamentoList: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageFinanciamentoList', false, false, false, false),
        familiarFinanciamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarFinanciamento', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisFinanciamento:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisFinanciamento', true, false, false, false),
        totalFinanciamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalFinanciamento', true, false, false, false),
        financiamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'financiamento', true, false, false, false),

        contConsorcio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contConsorcio', false, false, false, false),
        familiarConsorcio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarConsorcio', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisConsorcio:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisConsorcio', true, false, false, false),
        totalConsorcio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalConsorcio', true, false, false, false),
        consorcio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'consorcio', true, false, false, false),

        contProjetoVidaSonho: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contProjetoVidaSonho', false, false, false, false),
        rowsPerPageProjetoVidaSonho: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'rowsPerPageProjetoVidaSonho', false, false, false, false),
        pageProjetoVidaSonho: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageProjetoVidaSonho', false, false, false, false),
        pageProjetoVidaSonhoList: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageProjetoVidaSonhoList', false, false, false, false),
        familiarProjetoVidaSonho: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarProjetoVidaSonho', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisProjetoVidaSonho:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisProjetoVidaSonho', true, false, false, false),
        totalProjetoVidaSonho: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalProjetoVidaSonho', true, false, false, false),
        projetoVidaSonho: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'projetoVidaSonho', true, false, false, false),


        
        contPatrimonioImobilizado: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contPatrimonioImobilizado', false, false, false, false),
        rowsPerPagePatrimonioImobilizado: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'rowsPerPagePatrimonioImobilizado', false, false, false, false),
        pagePatrimonioImobilizado: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pagePatrimonioImobilizado', false, false, false, false),
        pagePatrimonioImobilizadoList: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pagePatrimonioImobilizadoList', false, false, false, false),
        familiarPatrimonioImobilizado: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarPatrimonioImobilizado', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisPatrimonioImobilizado:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisPatrimonioImobilizado', true, false, false, false),
        totalPatrimonioImobilizado: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalPatrimonioImobilizado', true, false, false, false),
        patrimonioImobilizado: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'patrimonioImobilizado', true, false, false, false),

        contRecursosFinanceiros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contRecursosFinanceiros', false, false, false, false),
        rowsPerPageRecursosFinanceiros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'rowsPerPageRecursosFinanceiros', false, false, false, false),
        pageRecursosFinanceiros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageRecursosFinanceiros', false, false, false, false),
        pageRecursosFinanceirosList: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pageRecursosFinanceirosList', false, false, false, false),
        familiarRecursosFinanceiros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarRecursosFinanceiros', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisRecursosFinanceiros:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisRecursosFinanceiros', true, false, false, false),
        totalRecursosFinanceiros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalRecursosFinanceiros', true, false, false, false),
        recursosFinanceiros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'recursosFinanceiros', true, false, false, false),
       
        contSeguroVidaCotacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contSeguroVidaCotacao', false, false, false, false),
        contSeguroVidaCotacaoCobertura: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contSeguroVidaCotacaoCobertura', false, false, false, false),
        familiarSeguroVidaCotacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarSeguroVidaCotacao', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisSeguroVidaCotacao:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisSeguroVidaCotacao', true, false, false, false),
        totalSeguroVidaCotacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalSeguroVidaCotacao', true, false, false, false),
        seguroVidaCotacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'seguroVidaCotacao', true, false, false, false),
        seguroVidaCotacaoCobertura: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'seguroVidaCotacaoCobertura', true, false, false, false),

        contSeguroVidaProposta: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contSeguroVidaProposta', false, false, false, false),
        contSeguroVidaPropostaCobertura: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contSeguroVidaPropostaCobertura', false, false, false, false),
        familiarSeguroVidaProposta: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarSeguroVidaProposta', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisSeguroVidaProposta:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisSeguroVidaProposta', true, false, false, false),
        totalSeguroVidaProposta: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalSeguroVidaProposta', true, false, false, false),
        seguroVidaProposta: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'seguroVidaProposta', true, false, false, false),
        seguroVidaPropostaCobertura: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'seguroVidaPropostaCobertura', true, false, false, false),

        contSeguroVidaApolice: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contSeguroVidaApolice', false, false, false, false),
        contSeguroVidaCobertura: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contSeguroVidaCobertura', false, false, false, false),
        familiarSeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'familiarSeguroVida', false, false, false, false),
        familiaresProcessouRegistrosDefaultIniciaisSeguroVida:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'familiaresProcessouRegistrosDefaultIniciaisSeguroVida', true, false, false, false),
        totalSeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'totalSeguroVida', true, false, false, false),
        seguroVidaApolice: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'seguroVidaApolice', true, false, false, false),
        seguroVidaCobertura: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'seguroVidaCobertura', true, false, false, false),



        descricaoEspecializacaoPeriodoSabatico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'descricaoEspecializacaoPeriodoSabatico', false, false, false, false),
        valorReceitaMensalAntesEspecializacaoPeriodoSabatico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'valorReceitaMensalAntesEspecializacaoPeriodoSabatico', false, false, false, false),
        valorDiaDiaAntesEspecializacaoPeriodoSabatico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'valorDiaDiaAntesEspecializacaoPeriodoSabatico', false, false, false, false),
        valorReceitaMensalDuranteEspecializacaoPeriodoSabatico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'valorReceitaMensalDuranteEspecializacaoPeriodoSabatico', false, false, false, false),
        valorDiaDiaDuranteEspecializacaoPeriodoSabatico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'valorDiaDiaDuranteEspecializacaoPeriodoSabatico', false, false, false, false),
        mesesEspecializacaoPeriodoSabatico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'mesesEspecializacaoPeriodoSabatico', false, false, true, false),
        valorReceitaMensalDepoisEspecializacaoPeriodoSabatico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'valorReceitaMensalDepoisEspecializacaoPeriodoSabatico', false, false, false, false),
        valorDiaDiaDepoisEspecializacaoPeriodoSabatico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'valorDiaDiaDepoisEspecializacaoPeriodoSabatico', false, false, false, false),

            

        objPrevidenciaPrivada: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'objPrevidenciaPrivada', true, false, false, false),



        objConsorcio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'objConsorcio', true, false, false, false),




        estrategia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'estrategia', false, false, false, false),

        consideracaoFinal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'consideracaoFinal', true, false, false, false),

        itemGestaoPatrimonialEstrategia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'itemGestaoPatrimonialEstrategia', false, false, false, false),
        itemGestaoPatrimonialPlanejamento: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'itemGestaoPatrimonialPlanejamento', false, false, false, false),

                
        processarCenariosDefaultJsonUsuario: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'processarCenariosDefaultJsonUsuario', false, false, false, false),

        contCenario: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contCenario', false, false, false, false),
        cenarios: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'cenarios', true, false, false, false),
        cenarioAtual: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'cenarioAtual', false, true, false, false),
        descricaoCenario: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'descricaoCenario', false, true, false, false),
        cenarioPrincipal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'cenarioPrincipal', false, false, false, false),

        morteInvalidezEstudo: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'morteInvalidezEstudo', false, false, false, false),

        taxaCDIAnual: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'taxaCDIAnual', false, false, false, false),
        retornoCDI: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'retornoCDI', false, false, false, false),
        taxaJurosAnual: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'taxaJurosAnual', false, false, false, false),
        tipoMomentoTributacaoIR: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoMomentoTributacaoIR', false, false, false, false),
        percIR: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percIR', false, false, false, false),
        tempoMinimoEntreAportesResgatesCalculoIRAnos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tempoMinimoEntreAportesResgatesCalculoIRAnos', false, false, true, false),
        taxaInflacaoAnual: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'taxaInflacaoAnual', false, false, false, false),
        taxaJurosAnualFgts: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'taxaJurosAnualFgts', false, false, false, false),

        taxaCDIAnualAposAposentadoria: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'taxaCDIAnualAposAposentadoria', false, false, false, false),
        retornoCDIAposAposentadoria: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'retornoCDIAposAposentadoria', false, false, false, false),
        taxaJurosAnualAposAposentadoria: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'taxaJurosAnualAposAposentadoria', false, false, false, false),
        taxaInflacaoAnualAposAposentadoria: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'taxaInflacaoAnualAposAposentadoria', false, false, false, false),
        taxaJurosAnualFgtsAposAposentadoria: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'taxaJurosAnualFgtsAposAposentadoria', false, false, false, false),

        dataSimulacaoEstudo: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'dataSimulacaoEstudo', false, false, false, true),

        utilizarPercRentabilidadeAnualRecursosFinanceiros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'utilizarPercRentabilidadeAnualRecursosFinanceiros', false, false, false, false),
        diferenciarTaxasAposAposentadoria: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'diferenciarTaxasAposAposentadoria', false, false, false, false),


        idadeVaiAposentarCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeVaiAposentarCliente', false, false, true, false),
        idadeInicioInssCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeInicioInssCliente', false, false, true, false),
        idadeExpectativaVidaCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeExpectativaVidaCliente', false, false, true, false),
        idadeSimulacaoMorteCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeSimulacaoMorteCliente', false, false, true, false),
        idadeDataSimulacaoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeDataSimulacaoCliente', false, false, true, false),
        idadeDataSimulacaoClientePrimeiraReuniao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeDataSimulacaoClientePrimeiraReuniao', false, false, true, false),
        percRespCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percRespCliente', false, false, false, false),
        //tipoIRCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
        //    'tipoIRCliente', false, false, false, false),
        alterarIdadesAutomaticasCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'alterarIdadesAutomaticasCliente', false, false, false, false),
        alterarPercRespAutomaticoCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'alterarPercRespAutomaticoCliente', false, false, false, false),
        idadeVaiAposentarConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeVaiAposentarConjuge', false, false, true, false),
        idadeInicioInssConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeInicioInssConjuge', false, false, true, false),
        idadeExpectativaVidaConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeExpectativaVidaConjuge', false, false, true, false),
        idadeSimulacaoMorteConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeSimulacaoMorteConjuge', false, false, true, false),
        idadeDataSimulacaoConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeDataSimulacaoConjuge', false, false, true, false),
        percRespConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percRespConjuge', false, false, false, false),
        //tipoIRConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
        //    'tipoIRConjuge', false, false, false, false),
        alterarIdadesAutomaticasConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'alterarIdadesAutomaticasConjuge', false, false, false, false),
        anoFinalVaiAposentarCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalVaiAposentarCliente', false, false, true, false),
        anoInicioInssCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoInicioInssCliente', false, false, true, false),
        anoFinalVaiAposentarConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalVaiAposentarConjuge', false, false, true, false),
        anoInicioInssConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoInicioInssConjuge', false, false, true, false),
        anoFinalVitalicioCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalVitalicioCliente', false, false, true, false),
        anoFinalVitalicioConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalVitalicioConjuge', false, false, true, false),
        anoFinalVitalicioClienteConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalVitalicioClienteConjuge', false, false, true, false),
        idadeFinalVitalicioClienteConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeFinalVitalicioClienteConjuge', false, false, true, false),
        anoFinalSimulacaoMorteCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalSimulacaoMorteCliente', false, false, true, false),
        anoFinalSimulacaoMorteConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalSimulacaoMorteConjuge', false, false, true, false),
        anoFinalSimulacaoMorteClienteConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalSimulacaoMorteClienteConjuge', false, false, true, false),
        anoFinalCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalCliente', false, false, true, false),
        anoFinalConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalConjuge', false, false, true, false),
        anoFinalClienteConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anoFinalClienteConjuge', false, false, true, false),



        mapaFinanceiro: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'mapaFinanceiro', true, false, false, false),
        incluirMapaFinanceiroRelatorioCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'incluirMapaFinanceiroRelatorioCliente', false, false, false, false),



        exclusaoFamiliares: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exclusaoFamiliares', true, false, false, false),

        exclusaoDespesasGarantias: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exclusaoDespesasGarantias', true, false, false, false),

        exibirTabelaEvolucaoValorResgateCarteiraInvestimentos:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'exibirTabelaEvolucaoValorResgateCarteiraInvestimentos', false, false, false, false),
        exibirCapitalSeguradoCarteiraInvestimentos:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'exibirCapitalSeguradoCarteiraInvestimentos', false, false, false, false),
        //exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos:
        //    objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
        //        'exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos', false, false, false, false),
        premioVidaInteiraOriundoCarteiraInvestimentos:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'premioVidaInteiraOriundoCarteiraInvestimentos', false, false, false, false),
        idRecursoFinanceiroOrigemOrigemPremioVidaInteira:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'idRecursoFinanceiroOrigemOrigemPremioVidaInteira', false, false, false, false),
        idadeSimularResgateVidaInteiraCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeSimularResgateVidaInteiraCliente', false, false, false, false),
        idadeSimularResgateVidaInteiraConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'idadeSimularResgateVidaInteiraConjuge', false, false, false, false),
        aplicarTaxaInflacaoSimulacaoResgateVidaInteira: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'aplicarTaxaInflacaoSimulacaoResgateVidaInteira', false, false, false, false),
        aplicarTaxaInflacaoCarteiraInvestimentos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'aplicarTaxaInflacaoCarteiraInvestimentos', false, false, false, false),
        exibirResumoSeguroVidaCotacao:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'exibirResumoSeguroVidaCotacao', false, false, false, false),
        exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado', false, false, false, false),
        exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira', false, false, false, false),
        //exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate:
        //    objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
        //        'exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate', false, false, false, false),
        aplicarTaxaInflacaoTabelaEvolucaoValorResgate: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'aplicarTaxaInflacaoTabelaEvolucaoValorResgate', false, false, false, false),
        calcularPremioAnualizadoAcumuladoSomaSimples: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'calcularPremioAnualizadoAcumuladoSomaSimples', false, false, false, false),
        exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao', false, false, false, false),
        limitarQtdeAnosExibidosGraficosTabelasEvolucao:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'limitarQtdeAnosExibidosGraficosTabelasEvolucao', false, false, false, false),
        anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao', false, false, true, false),
        exibirTabelaEvolucaoCS:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'exibirTabelaEvolucaoCS', false, false, false, false),
        exibirTabelaEvolucaoCancelamentoApolice:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'exibirTabelaEvolucaoCancelamentoApolice', false, false, false, false),
        exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira', false, false, false, false),
        graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR', false, false, false, false),
        graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR', false, false, false, false),
        graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais', false, false, false, false),
        graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd', false, false, false, false),
        graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio', false, false, false, false),
        incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia', false, false, false, false),
        utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia', false, false, false, false),

                

        calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria', false, false, false, false),
        valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria', false, false, false, false),
        aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria', false, false, false, false),
        percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria', false, false, false, false),
        deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria', false, false, false, false),
        naoExibirOpcaoAporteUnico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirOpcaoAporteUnico', false, false, false, false),
        utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira', false, false, false, false),
        naoCalcularAportesNecessariosRealizacaoPVS: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoCalcularAportesNecessariosRealizacaoPVS', false, false, false, false),
        naoConsiderarValorAcumulacaoJaRealizadoPVS: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoConsiderarValorAcumulacaoJaRealizadoPVS', false, false, false, false),
        naoExibirHipotesesRetornoRealAposentadoria: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirHipotesesRetornoRealAposentadoria', false, false, false, false),
        calcularHipotesesRetornoRealAposentadoriaViverRentabilidade:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'calcularHipotesesRetornoRealAposentadoriaViverRentabilidade', false, false, false, false),
        exibirHipotesesRetornoRealProjetoVidaSonho: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirHipotesesRetornoRealProjetoVidaSonho', false, false, false, false),
        naoExibirAcumulacaoAposentadoriaProjetoVidaSonho: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirAcumulacaoAposentadoriaProjetoVidaSonho', false, false, false, false),




        naoAplicarTaxaJurosSeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoAplicarTaxaJurosSeguroVida', false, false, false, false),
        naoExibirProtecaoRiscos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirProtecaoRiscos', false, false, false, false),
        exibirMorteAte: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirMorteAte', false, false, false, false),
        naoExibirMorteVitalicia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirMorteVitalicia', false, false, false, false),
        naoExibirMorteSucessaoVitalicia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirMorteSucessaoVitalicia', false, false, false, false),
        naoExibirDITDITA: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirDITDITA', false, false, false, false),
        naoExibirDoencasGraves: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirDoencasGraves', false, false, false, false),
        mesesSugestaoDoencasGraves: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'mesesSugestaoDoencasGraves', false, false, false, false),
        tipoCalculoSugestaoDoencasGraves: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoCalculoSugestaoDoencasGraves', false, false, false, false),
        despesasAdicionaisDoencasGravesCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'despesasAdicionaisDoencasGravesCliente', false, false, false, false),
        despesasAdicionaisDoencasGravesConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'despesasAdicionaisDoencasGravesConjuge', false, false, false, false),
        naoExibirInvalidez: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirInvalidez', false, false, false, false),
        naoExibirFuneral: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirFuneral', false, false, false, false),
        funeral: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'funeral', false, false, false, false),
        naoExibirDespesasAcumuladasSemDiaDia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'naoExibirDespesasAcumuladasSemDiaDia', false, false, false, false),



        contPaginaAdicional: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contPaginaAdicional', false, false, false, false),
        paginaAdicional: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'paginaAdicional', true, false, false, false),
        
            

        template: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'template', false, true, false, false),
        disposicaoRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'disposicaoRelatorio', false, true, false, false),
        reportImprimir: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'reportImprimir', true, true, false, false),
        reportNaoImprimir: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'reportNaoImprimir', true, true, false, false),

        

        exibirCapacidadePoupancaRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirCapacidadePoupancaRelatorio', false, true, false, false),
        exibirAportesRetiradasPlanejadosRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirAportesRetiradasPlanejadosRelatorio', false, true, false, false),
        naoExibirGraficoDiagnosticoAposentadoriaIndependenciaFinanceira:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'naoExibirGraficoDiagnosticoAposentadoriaIndependenciaFinanceira', false, true, false, false),
        naoExibirEvolucaoReservaFinanceira:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'naoExibirEvolucaoReservaFinanceira', false, true, false, false),
        exibirProtecaoSugeridaRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirProtecaoSugeridaRelatorio', false, true, false, false),
        exibirSucessaoVitaliciaRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirSucessaoVitaliciaRelatorio', false, true, false, false),
        exibirComparacaoCenariosRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirComparacaoCenariosRelatorio', false, true, false, false),
        exibirComparacaoCenariosRelatorioSemTabelaGrafico: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirComparacaoCenariosRelatorioSemTabelaGrafico', false, true, false, false),
        exibirComparacaoEstudosRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirComparacaoEstudosRelatorio', false, true, false, false),
        exibirConsideracoesFinaisRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirConsideracoesFinaisRelatorio', false, true, false, false),
        percCapacidadePoupancaSugestaoAporteMensal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percCapacidadePoupancaSugestaoAporteMensal', false, false, false, false),
        aporteMensalAntesAposentarCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'aporteMensalAntesAposentarCliente', false, false, false, false),
        retiradaMensalAntesAposentarCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'retiradaMensalAntesAposentarCliente', false, false, false, false),
        aporteMensalDepoisAposentarCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'aporteMensalDepoisAposentarCliente', false, false, false, false),
        retiradaMensalDepoisAposentarCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'retiradaMensalDepoisAposentarCliente', false, false, false, false),


        tipoRetornoFinanceiro: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoRetornoFinanceiro', false, false, false, false),
        exibirEvolucaoAporteMensalInflacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirEvolucaoAporteMensalInflacao', false, false, false, false),
        exibirEvolucaoRetiradaMensalInflacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'exibirEvolucaoRetiradaMensalInflacao', false, false, false, false),
        aporteRetiradaMensal: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'aporteRetiradaMensal', true, false, false, false),
        aporteRetiradaAnual: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'aporteRetiradaAnual', true, false, false, false),
        anosExibicaoRelatorioEvolucaoReservaFinanceira:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'anosExibicaoRelatorioEvolucaoReservaFinanceira', false, false, false, false),


        percDespesasAdvocaticias: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percDespesasAdvocaticias', false, false, false, false),
        percCustasJudiciais: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percCustasJudiciais', false, false, false, false),
        percInventarioItcmd: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'percInventarioItcmd', false, false, false, false),
        mesesEmergencialCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'mesesEmergencialCliente', false, false, false, false),
        emergencialCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'emergencialCliente', false, false, false, false),
        inventarioCliente: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'inventarioCliente', false, false, false, false),
        alterarValoresAutomaticosSucessaoVitaliciaCliente:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'alterarValoresAutomaticosSucessaoVitaliciaCliente', false, false, false, false),
        mesesEmergencialConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'mesesEmergencialConjuge', false, false, false, false),
        emergencialConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'emergencialConjuge', false, false, false, false),
        inventarioConjuge: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'inventarioConjuge', false, false, false, false),
        inventarioPatrimonioImobilizado: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'inventarioPatrimonioImobilizado', false, false, false, false),
        inventarioRecursosFinanceiros: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'inventarioRecursosFinanceiros', false, false, false, false),
        alterarValoresAutomaticosSucessaoVitaliciaConjuge:
            objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
                'alterarValoresAutomaticosSucessaoVitaliciaConjuge', false, false, false, false),


        comSemDiaDiaEstudo: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'comSemDiaDiaEstudo', false, false, false, false),
        tipoDiagnosticoAposentadoriaChart: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'tipoDiagnosticoAposentadoriaChart', false, false, false, false),



        objComparacaoFinanciamentos: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'objComparacaoFinanciamentos', true, false, false, false),



        //modoApresentacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
        //    'modoApresentacao', false, false, false, false),
        modoApresentacaoRelatorio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'modoApresentacaoRelatorio', false, false, false, false),
        modoApresentacaoAcumulacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'modoApresentacaoAcumulacao', false, false, false, false),
        modoApresentacaoProtecao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'modoApresentacaoProtecao', false, false, false, false),

        moreLessAcumulacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'moreLessAcumulacao', true, false, false, false),



        contPendencia: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'contPendencia', false, false, false, false),
        pendencias: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'pendencias', true, false, false, false),

        anotacoes: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'anotacoes', true, false, false, false),
    
        stepCadastro: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepCadastro', true, false, false, false),
        
        stepFluxoCaixa: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepFluxoCaixa', true, false, false, false),
        stepsValorFluxoCaixa: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValorFluxoCaixa', true, false, false, false),
        stepsValor2FluxoCaixa: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValor2FluxoCaixa', true, false, false, false),
        stepsValorSubTotal1FluxoCaixa: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValorSubTotal1FluxoCaixa', true, false, false, false),
        stepsValorSubTotal2FluxoCaixa: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValorSubTotal2FluxoCaixa', true, false, false, false),

        stepPatrimonio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepPatrimonio', true, false, false, false),
        stepsValorPatrimonio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValorPatrimonio', true, false, false, false),
        stepsValor2Patrimonio: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValor2Patrimonio', true, false, false, false),

        stepSeguroVidaCotacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepSeguroVidaCotacao', true, false, false, false),
        stepsValorSeguroVidaCotacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValorSeguroVidaCotacao', true, false, false, false),
        stepsValor2SeguroVidaCotacao: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValor2SeguroVidaCotacao', true, false, false, false),

        stepSeguroVidaProposta: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepSeguroVidaProposta', true, false, false, false),
        stepsValorSeguroVidaProposta: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValorSeguroVidaProposta', true, false, false, false),
        stepsValor2SeguroVidaProposta: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValor2SeguroVidaProposta', true, false, false, false),

        stepSeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepSeguroVida', true, false, false, false),
        stepsValorSeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValorSeguroVida', true, false, false, false),
        stepsValor2SeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValor2SeguroVida', true, false, false, false),

        stepAcompanhamentoSeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepAcompanhamentoSeguroVida', true, false, false, false),
        stepsValorAcompanhamentoSeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValorAcompanhamentoSeguroVida', true, false, false, false),
        stepsValor2AcompanhamentoSeguroVida: objetoClienteValor(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL,
            'stepsValor2AcompanhamentoSeguroVida', true, false, false, false),
    }
}

const calculaPerguntasSemExpanded = (perguntas) => {

    let perguntasSemExpanded = []

    perguntas?.forEach( (linha, ind) => {

        perguntasSemExpanded.push({
            ...linha,
            expanded: null,
        })

    })

    return perguntasSemExpanded

}

export const comparaCliente = (values, valuesAnt) => {
    
    const comparaCliente =
    
        values.modoInteracaoCliente === valuesAnt.modoInteracaoCliente &&

        values.funil === valuesAnt.funil &&
        values.etapa === valuesAnt.etapa &&
        comparaData(values.dataHoraPrevistaFunilEtapa, valuesAnt.dataHoraPrevistaFunilEtapa) &&
        comparaData(values.dataRealizacaoFunilEtapa, valuesAnt.dataRealizacaoFunilEtapa) &&
        values.potencialNegocios === valuesAnt.potencialNegocios &&
        values.potencialNegociosSomar === valuesAnt.potencialNegociosSomar &&
        values.contFunisEtapas === valuesAnt.contFunisEtapas &&
        comparaArraysJSON(values.funisEtapas, valuesAnt.funisEtapas) &&

        comparaArraysJSON(values.compartilhamentoUsuario, valuesAnt.compartilhamentoUsuario) &&

        values.formListRadioGroup === valuesAnt.formListRadioGroup &&
        values.formulario === valuesAnt.formulario &&
        comparaArraysJSON(calculaPerguntasSemExpanded(values.perguntas), calculaPerguntasSemExpanded(valuesAnt.perguntas)) &&


        //values.tabIndexClientePrimeiraReuniao === valuesAnt.tabIndexClientePrimeiraReuniao &&
        //values.tabIndexClienteAprofundamento === valuesAnt.tabIndexClienteAprofundamento &&
        //values.tabIndexForm === valuesAnt.tabIndexForm &&
        //values.tabIndexAnaliseFinanceira === valuesAnt.tabIndexAnaliseFinanceira &&
        //values.tabIndexEstudo === valuesAnt.tabIndexEstudo &&
        //values.tabIndexEstudoParametros === valuesAnt.tabIndexEstudoParametros &&
        //values.tabIndexEstudoRevisaoDados === valuesAnt.tabIndexEstudoRevisaoDados &&
        //values.tabIndexAcompanhamento === valuesAnt.tabIndexAcompanhamento &&

        
        values.nomeCliente === valuesAnt.nomeCliente &&
        values.nomeCurtoCliente === valuesAnt.nomeCurtoCliente &&
        values.cpfCliente === valuesAnt.cpfCliente &&
        values.idClienteIntegracaoCRM === valuesAnt.idClienteIntegracaoCRM &&
        comparaData(values.nascimentoCliente, valuesAnt.nascimentoCliente) &&
        comparaZero(values.idadeCliente, valuesAnt.idadeCliente) &&
        values.sexoCliente === valuesAnt.sexoCliente &&
        values.tipoOrigemCliente === valuesAnt.tipoOrigemCliente &&
        values.clienteRecomendou === valuesAnt.clienteRecomendou &&
        values.tipoOrigemClienteOutras === valuesAnt.tipoOrigemClienteOutras &&
        values.tipoCarteiraInvestimentoCliente === valuesAnt.tipoCarteiraInvestimentoCliente &&
        values.momentoCliente === valuesAnt.momentoCliente &&
        //values.observacaoCliente === valuesAnt.observacaoCliente &&
        values.grauInstrucaoCliente === valuesAnt.grauInstrucaoCliente &&
        values.formacaoCliente === valuesAnt.formacaoCliente &&
        values.profissaoCliente === valuesAnt.profissaoCliente &&
        values.profissaoClienteOutras === valuesAnt.profissaoClienteOutras &&
        values.empresaCliente === valuesAnt.empresaCliente &&
        values.possuiConjuge === valuesAnt.possuiConjuge &&
        values.tipoRegimeBens === valuesAnt.tipoRegimeBens &&
        values.percMeacaoCliente === valuesAnt.percMeacaoCliente &&
        values.percHerancaFamiliarDiferenteConjuge === valuesAnt.percHerancaFamiliarDiferenteConjuge &&
        values.tipoPoliticaInvestimentoCliente === valuesAnt.tipoPoliticaInvestimentoCliente &&
        values.avatarCliente === valuesAnt.avatarCliente &&

        values.nomeConjuge === valuesAnt.nomeConjuge &&
        values.nomeCurtoConjuge === valuesAnt.nomeCurtoConjuge &&
        values.cpfConjuge === valuesAnt.cpfConjuge &&
        comparaData(values.nascimentoConjuge, valuesAnt.nascimentoConjuge) &&
        comparaZero(values.idadeConjuge, valuesAnt.idadeConjuge) &&
        values.sexoConjuge === valuesAnt.sexoConjuge &&
        values.grauInstrucaoConjuge === valuesAnt.grauInstrucaoConjuge &&
        values.formacaoConjuge === valuesAnt.formacaoConjuge &&
        values.profissaoConjuge === valuesAnt.profissaoConjuge &&
        values.profissaoConjugeOutras === valuesAnt.profissaoConjugeOutras &&
        values.empresaConjuge === valuesAnt.empresaConjuge &&
        values.avatarConjuge === valuesAnt.avatarConjuge &&

        values.dataInclusao === valuesAnt.dataInclusao &&

        values.contFamiliar === valuesAnt.contFamiliar &&
        comparaArraysJSON(values.familiares, valuesAnt.familiares) &&

        comparaArraysJSON(values.contatos, valuesAnt.contatos) &&

        comparaArraysJSON(values.contasInvestimentos, valuesAnt.contasInvestimentos) &&

        values.contEndereco === valuesAnt.contEndereco &&
        comparaArraysJSON(values.enderecos, valuesAnt.enderecos) &&
        values.cepResidencial === valuesAnt.cepResidencial &&
        values.idEnderecoCepResidencial === valuesAnt.idEnderecoCepResidencial &&

        comparaArraysJSON(values.objetivosPrincipaisPreocupacoesPFCMLP, valuesAnt.objetivosPrincipaisPreocupacoesPFCMLP) &&



        comparaArraysJSON(values.historicoSaudeRiscos, valuesAnt.historicoSaudeRiscos) &&

        comparaArraysJSON(values.esportesHobbiesRisco, valuesAnt.esportesHobbiesRisco) &&

        comparaArraysJSON(values.condicaoSaude, valuesAnt.condicaoSaude) &&

        comparaArraysJSON(values.historicoSaudeFamiliares, valuesAnt.historicoSaudeFamiliares) &&


        
        values.contReceitaMensal === valuesAnt.contReceitaMensal &&
        values.rowsPerPageReceitaMensal === valuesAnt.rowsPerPageReceitaMensal &&
        values.pageReceitaMensal === valuesAnt.pageReceitaMensal &&
        comparaArraysJSON(values.pageReceitaMensalList, valuesAnt.pageReceitaMensalList) &&
        comparaArraysJSON(values.receitaMensal, valuesAnt.receitaMensal) &&

        values.contDiaDia === valuesAnt.contDiaDia &&
        values.rowsPerPageDiaDia === valuesAnt.rowsPerPageDiaDia &&
        values.pageDiaDia === valuesAnt.pageDiaDia &&
        comparaArraysJSON(values.pageDiaDiaList, valuesAnt.pageDiaDiaList) &&
        comparaArraysJSON(values.diaDia, valuesAnt.diaDia) &&

        values.contEducacao === valuesAnt.contEducacao &&
        values.rowsPerPageEducacao === valuesAnt.rowsPerPageEducacao &&
        values.pageEducacao === valuesAnt.pageEducacao &&
        comparaArraysJSON(values.pageEducacaoList, valuesAnt.pageEducacaoList) &&
        comparaArraysJSON(values.educacao, valuesAnt.educacao) &&

        values.contFinanciamento === valuesAnt.contFinanciamento &&
        values.rowsPerPageFinanciamento === valuesAnt.rowsPerPageFinanciamento &&
        values.pageFinanciamento === valuesAnt.pageFinanciamento &&
        comparaArraysJSON(values.pageFinanciamentoList, valuesAnt.pageFinanciamentoList) &&
        comparaArraysJSON(values.financiamento, valuesAnt.financiamento) &&

        values.contConsorcio === valuesAnt.contConsorcio &&
        comparaArraysJSON(values.consorcio, valuesAnt.consorcio) &&      

        values.contProjetoVidaSonho === valuesAnt.contProjetoVidaSonho &&
        values.rowsPerPageProjetoVidaSonho === valuesAnt.rowsPerPageProjetoVidaSonho &&
        values.pageProjetoVidaSonho === valuesAnt.pageProjetoVidaSonho &&
        comparaArraysJSON(values.pageProjetoVidaSonhoList, valuesAnt.pageProjetoVidaSonhoList) &&
        comparaArraysJSON(values.projetoVidaSonho, valuesAnt.projetoVidaSonho) &&


   
        values.contPatrimonioImobilizado === valuesAnt.contPatrimonioImobilizado &&
        values.rowsPerPagePatrimonioImobilizado === valuesAnt.rowsPerPagePatrimonioImobilizado &&
        values.pagePatrimonioImobilizado === valuesAnt.pagePatrimonioImobilizado &&
        comparaArraysJSON(values.pagePatrimonioImobilizadoList, valuesAnt.pagePatrimonioImobilizadoList) &&
        comparaArraysJSON(values.patrimonioImobilizado, valuesAnt.patrimonioImobilizado) &&

        values.contRecursosFinanceiros === valuesAnt.contRecursosFinanceiros &&
        values.rowsPerPageRecursosFinanceiros === valuesAnt.rowsPerPageRecursosFinanceiros &&
        values.pageRecursosFinanceiros === valuesAnt.pageRecursosFinanceiros &&
        comparaArraysJSON(values.pageRecursosFinanceirosList, valuesAnt.pageRecursosFinanceirosList) &&
        comparaArraysJSON(values.recursosFinanceiros, valuesAnt.recursosFinanceiros) &&

        values.contSeguroVidaCotacao === valuesAnt.contSeguroVidaCotacao &&
        values.contSeguroVidaCotacaoCobertura === valuesAnt.contSeguroVidaCotacaoCobertura &&
        comparaArraysJSON(values.seguroVidaCotacao, valuesAnt.seguroVidaCotacao) &&
        comparaArraysJSON(values.seguroVidaCotacaoCobertura, valuesAnt.seguroVidaCotacaoCobertura) &&

        values.contSeguroVidaProposta === valuesAnt.contSeguroVidaProposta &&
        values.contSeguroVidaPropostaCobertura === valuesAnt.contSeguroVidaPropostaCobertura &&
        comparaArraysJSON(values.seguroVidaProposta, valuesAnt.seguroVidaProposta) &&
        comparaArraysJSON(values.seguroVidaPropostaCobertura, valuesAnt.seguroVidaPropostaCobertura) &&

        values.contSeguroVidaApolice === valuesAnt.contSeguroVidaApolice &&
        values.contSeguroVidaCobertura === valuesAnt.contSeguroVidaCobertura &&
        comparaArraysJSON(values.seguroVidaApolice, valuesAnt.seguroVidaApolice) &&
        comparaArraysJSON(values.seguroVidaCobertura, valuesAnt.seguroVidaCobertura) &&

        

        values.descricaoEspecializacaoPeriodoSabatico === valuesAnt.descricaoEspecializacaoPeriodoSabatico &&
        values.valorReceitaMensalAntesEspecializacaoPeriodoSabatico === valuesAnt.valorReceitaMensalAntesEspecializacaoPeriodoSabatico &&
        values.valorDiaDiaAntesEspecializacaoPeriodoSabatico === valuesAnt.valorDiaDiaAntesEspecializacaoPeriodoSabatico &&
        values.valorReceitaMensalDuranteEspecializacaoPeriodoSabatico === valuesAnt.valorReceitaMensalDuranteEspecializacaoPeriodoSabatico &&
        values.valorDiaDiaDuranteEspecializacaoPeriodoSabatico === valuesAnt.valorDiaDiaDuranteEspecializacaoPeriodoSabatico &&
        comparaZero(values.mesesEspecializacaoPeriodoSabatico, valuesAnt.mesesEspecializacaoPeriodoSabatico) &&
        values.valorReceitaMensalDepoisEspecializacaoPeriodoSabatico === valuesAnt.valorReceitaMensalDepoisEspecializacaoPeriodoSabatico &&
        values.valorDiaDiaDepoisEspecializacaoPeriodoSabatico === valuesAnt.valorDiaDiaDepoisEspecializacaoPeriodoSabatico &&



        comparaArraysJSON([values.objPrevidenciaPrivada], [valuesAnt.objPrevidenciaPrivada]) &&



        comparaArraysJSON([values.objConsorcio], [valuesAnt.objConsorcio]) &&
        


        values.estrategia === valuesAnt.estrategia &&

        comparaArraysJSON(values.consideracaoFinal, valuesAnt.consideracaoFinal) &&

        values.itemGestaoPatrimonialEstrategia === valuesAnt.itemGestaoPatrimonialEstrategia &&
        values.itemGestaoPatrimonialPlanejamento === valuesAnt.itemGestaoPatrimonialPlanejamento &&

        
        values.processarCenariosDefaultJsonUsuario === valuesAnt.processarCenariosDefaultJsonUsuario &&

        values.contCenario === valuesAnt.contCenario &&
        comparaArraysJSON(values.cenarios, valuesAnt.cenarios) &&
        values.cenarioAtual === valuesAnt.cenarioAtual &&
        values.descricaoCenario === valuesAnt.descricaoCenario &&
        values.cenarioPrincipal === valuesAnt.cenarioPrincipal &&

        values.morteInvalidezEstudo === valuesAnt.morteInvalidezEstudo &&

        values.taxaCDIAnual === valuesAnt.taxaCDIAnual &&
        values.retornoCDI === valuesAnt.retornoCDI &&
        values.taxaJurosAnual === valuesAnt.taxaJurosAnual &&
        values.tipoMomentoTributacaoIR === valuesAnt.tipoMomentoTributacaoIR &&
        values.percIR === valuesAnt.percIR &&
        comparaZero(values.tempoMinimoEntreAportesResgatesCalculoIRAnos, valuesAnt.tempoMinimoEntreAportesResgatesCalculoIRAnos) &&
        values.taxaInflacaoAnual === valuesAnt.taxaInflacaoAnual &&
        values.taxaJurosAnualFgts === valuesAnt.taxaJurosAnualFgts &&

        values.taxaCDIAnualAposAposentadoria === valuesAnt.taxaCDIAnualAposAposentadoria &&
        values.retornoCDIAposAposentadoria === valuesAnt.retornoCDIAposAposentadoria &&
        values.taxaJurosAnualAposAposentadoria === valuesAnt.taxaJurosAnualAposAposentadoria &&
        values.taxaInflacaoAnualAposAposentadoria === valuesAnt.taxaInflacaoAnualAposAposentadoria &&
        values.taxaJurosAnualFgtsAposAposentadoria === valuesAnt.taxaJurosAnualFgtsAposAposentadoria &&

        comparaData(values.dataSimulacaoEstudo, valuesAnt.dataSimulacaoEstudo) &&

        values.utilizarPercRentabilidadeAnualRecursosFinanceiros === valuesAnt.utilizarPercRentabilidadeAnualRecursosFinanceiros &&
        values.diferenciarTaxasAposAposentadoria === valuesAnt.diferenciarTaxasAposAposentadoria &&
        
       
        comparaZero(values.idadeVaiAposentarCliente, valuesAnt.idadeVaiAposentarCliente) &&
        comparaZero(values.idadeInicioInssCliente, valuesAnt.idadeInicioInssCliente) &&
        comparaZero(values.idadeExpectativaVidaCliente, valuesAnt.idadeExpectativaVidaCliente) &&
        comparaZero(values.idadeSimulacaoMorteCliente, valuesAnt.idadeSimulacaoMorteCliente) &&
        comparaZero(values.idadeDataSimulacaoCliente, valuesAnt.idadeDataSimulacaoCliente) &&
        comparaZero(values.idadeDataSimulacaoClientePrimeiraReuniao, valuesAnt.idadeDataSimulacaoClientePrimeiraReuniao) &&
        values.percRespCliente === valuesAnt.percRespCliente &&
        //values.tipoIRCliente === valuesAnt.tipoIRCliente &&
        values.alterarIdadesAutomaticasCliente === valuesAnt.alterarIdadesAutomaticasCliente &&
        values.alterarPercRespAutomaticoCliente === valuesAnt.alterarPercRespAutomaticoCliente &&
        comparaZero(values.idadeVaiAposentarConjuge, valuesAnt.idadeVaiAposentarConjuge) &&
        comparaZero(values.idadeInicioInssConjuge, valuesAnt.idadeInicioInssConjuge) &&
        comparaZero(values.idadeExpectativaVidaConjuge, valuesAnt.idadeExpectativaVidaConjuge) &&
        comparaZero(values.idadeSimulacaoMorteConjuge, valuesAnt.idadeSimulacaoMorteConjuge) &&
        comparaZero(values.idadeDataSimulacaoConjuge, valuesAnt.idadeDataSimulacaoConjuge) &&
        values.percRespConjuge === valuesAnt.percRespConjuge &&
        //values.tipoIRConjuge === valuesAnt.tipoIRConjuge &&
        values.alterarIdadesAutomaticasConjuge === valuesAnt.alterarIdadesAutomaticasConjuge &&
        comparaZero(values.anoFinalVaiAposentarCliente, valuesAnt.anoFinalVaiAposentarCliente) &&
        comparaZero(values.anoInicioInssCliente, valuesAnt.anoInicioInssCliente) &&
        comparaZero(values.anoFinalVaiAposentarConjuge, valuesAnt.anoFinalVaiAposentarConjuge) &&
        comparaZero(values.anoInicioInssConjuge, valuesAnt.anoInicioInssConjuge) &&
        comparaZero(values.anoFinalVitalicioCliente, valuesAnt.anoFinalVitalicioCliente) &&
        comparaZero(values.anoFinalVitalicioConjuge, valuesAnt.anoFinalVitalicioConjuge) &&
        comparaZero(values.anoFinalVitalicioClienteConjuge, valuesAnt.anoFinalVitalicioClienteConjuge) &&
        comparaZero(values.idadeFinalVitalicioClienteConjuge, valuesAnt.idadeFinalVitalicioClienteConjuge) &&
        comparaZero(values.anoFinalSimulacaoMorteCliente, valuesAnt.anoFinalSimulacaoMorteCliente) &&
        comparaZero(values.anoFinalSimulacaoMorteConjuge, valuesAnt.anoFinalSimulacaoMorteConjuge) &&
        comparaZero(values.anoFinalSimulacaoMorteClienteConjuge, valuesAnt.anoFinalSimulacaoMorteClienteConjuge) &&
        comparaZero(values.anoFinalCliente, valuesAnt.anoFinalCliente) &&
        comparaZero(values.anoFinalConjuge, valuesAnt.anoFinalConjuge) &&
        comparaZero(values.anoFinalClienteConjuge, valuesAnt.anoFinalClienteConjuge) &&



        comparaArraysJSON(values.mapaFinanceiro, valuesAnt.mapaFinanceiro) &&
        values.incluirMapaFinanceiroRelatorioCliente === valuesAnt.incluirMapaFinanceiroRelatorioCliente &&


        
        comparaArraysJSON(values.exclusaoFamiliares, valuesAnt.exclusaoFamiliares) &&

        comparaArraysJSON(values.exclusaoDespesasGarantias, valuesAnt.exclusaoDespesasGarantias) &&

        values.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos ===
            valuesAnt.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos &&
        values.exibirCapitalSeguradoCarteiraInvestimentos ===
            valuesAnt.exibirCapitalSeguradoCarteiraInvestimentos &&
        //values.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos ===
        //    valuesAnt.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos &&
        values.premioVidaInteiraOriundoCarteiraInvestimentos === valuesAnt.premioVidaInteiraOriundoCarteiraInvestimentos &&
        values.idRecursoFinanceiroOrigemOrigemPremioVidaInteira === valuesAnt.idRecursoFinanceiroOrigemOrigemPremioVidaInteira &&
        values.idadeSimularResgateVidaInteiraCliente === valuesAnt.idadeSimularResgateVidaInteiraCliente &&
        values.idadeSimularResgateVidaInteiraConjuge === valuesAnt.idadeSimularResgateVidaInteiraConjuge &&
        values.aplicarTaxaInflacaoSimulacaoResgateVidaInteira === valuesAnt.aplicarTaxaInflacaoSimulacaoResgateVidaInteira &&
        values.aplicarTaxaInflacaoCarteiraInvestimentos === valuesAnt.aplicarTaxaInflacaoCarteiraInvestimentos &&
        values.exibirResumoSeguroVidaCotacao === valuesAnt.exibirResumoSeguroVidaCotacao &&
        values.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado ===
            valuesAnt.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado &&
        values.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira ===
            valuesAnt.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira &&
        //values.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate ===
        //    valuesAnt.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate &&
        values.aplicarTaxaInflacaoTabelaEvolucaoValorResgate === valuesAnt.aplicarTaxaInflacaoTabelaEvolucaoValorResgate &&
        values.calcularPremioAnualizadoAcumuladoSomaSimples === valuesAnt.calcularPremioAnualizadoAcumuladoSomaSimples &&
        values.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao === valuesAnt.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao &&
        values.limitarQtdeAnosExibidosGraficosTabelasEvolucao === valuesAnt.limitarQtdeAnosExibidosGraficosTabelasEvolucao &&
        comparaZero(values.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao,
            valuesAnt.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao) &&
        values.exibirTabelaEvolucaoCS === valuesAnt.exibirTabelaEvolucaoCS &&
        values.exibirTabelaEvolucaoCancelamentoApolice === valuesAnt.exibirTabelaEvolucaoCancelamentoApolice &&
        values.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira === valuesAnt.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira &&
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR ===
            valuesAnt.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR &&
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR ===
            valuesAnt.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR &&
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais ===
            valuesAnt.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais &&
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd ===
            valuesAnt.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd &&
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio ===
            valuesAnt.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio &&
        values.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia ===
            valuesAnt.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia &&
        values.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia ===
            valuesAnt.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia &&
        
        values.calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria ===
            valuesAnt.calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria &&
        values.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria ===
            valuesAnt.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria &&
        values.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria ===
            valuesAnt.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria &&
        values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria ===
            valuesAnt.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria &&
        values.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria ===
            valuesAnt.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria &&
        values.naoExibirOpcaoAporteUnico === valuesAnt.naoExibirOpcaoAporteUnico &&
        values.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira ===
            valuesAnt.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira &&
        values.naoCalcularAportesNecessariosRealizacaoPVS === valuesAnt.naoCalcularAportesNecessariosRealizacaoPVS &&
        values.naoConsiderarValorAcumulacaoJaRealizadoPVS === valuesAnt.naoConsiderarValorAcumulacaoJaRealizadoPVS &&
        values.naoExibirHipotesesRetornoRealAposentadoria === valuesAnt.naoExibirHipotesesRetornoRealAposentadoria &&
        values.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade === valuesAnt.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade &&
        values.exibirHipotesesRetornoRealProjetoVidaSonho === valuesAnt.exibirHipotesesRetornoRealProjetoVidaSonho &&
        values.naoExibirAcumulacaoAposentadoriaProjetoVidaSonho === valuesAnt.naoExibirAcumulacaoAposentadoriaProjetoVidaSonho &&
        
        values.naoAplicarTaxaJurosSeguroVida === valuesAnt.naoAplicarTaxaJurosSeguroVida &&
        values.naoExibirProtecaoRiscos === valuesAnt.naoExibirProtecaoRiscos &&
        values.exibirMorteAte === valuesAnt.exibirMorteAte &&
        values.naoExibirMorteVitalicia === valuesAnt.naoExibirMorteVitalicia &&
        values.naoExibirMorteSucessaoVitalicia === valuesAnt.naoExibirMorteSucessaoVitalicia &&
        values.naoExibirDITDITA === valuesAnt.naoExibirDITDITA &&
        values.naoExibirDoencasGraves === valuesAnt.naoExibirDoencasGraves &&
        values.mesesSugestaoDoencasGraves === valuesAnt.mesesSugestaoDoencasGraves &&
        values.tipoCalculoSugestaoDoencasGraves === valuesAnt.tipoCalculoSugestaoDoencasGraves &&
        values.despesasAdicionaisDoencasGravesCliente === valuesAnt.despesasAdicionaisDoencasGravesCliente &&
        values.despesasAdicionaisDoencasGravesConjuge === valuesAnt.despesasAdicionaisDoencasGravesConjuge &&
        values.naoExibirInvalidez === valuesAnt.naoExibirInvalidez &&
        values.naoExibirFuneral === valuesAnt.naoExibirFuneral &&
        values.funeral === valuesAnt.funeral &&
        values.naoExibirDespesasAcumuladasSemDiaDia === valuesAnt.naoExibirDespesasAcumuladasSemDiaDia &&



        values.contPaginaAdicional === valuesAnt.contPaginaAdicional &&
        comparaArraysJSON(values.paginaAdicional, valuesAnt.paginaAdicional) &&

            

        values.template === valuesAnt.template &&
        values.disposicaoRelatorio === valuesAnt.disposicaoRelatorio &&
        comparaArraysJSON(values.reportImprimir, valuesAnt.reportImprimir) &&
        comparaArraysJSON(values.reportNaoImprimir, valuesAnt.reportNaoImprimir) &&



        values.exibirCapacidadePoupancaRelatorio === valuesAnt.exibirCapacidadePoupancaRelatorio &&
        values.exibirAportesRetiradasPlanejadosRelatorio === valuesAnt.exibirAportesRetiradasPlanejadosRelatorio &&
        values.naoExibirGraficoDiagnosticoAposentadoriaIndependenciaFinanceira ===
            valuesAnt.naoExibirGraficoDiagnosticoAposentadoriaIndependenciaFinanceira &&
        values.naoExibirEvolucaoReservaFinanceira === valuesAnt.naoExibirEvolucaoReservaFinanceira &&
        values.exibirProtecaoSugeridaRelatorio === valuesAnt.exibirProtecaoSugeridaRelatorio &&
        values.exibirSucessaoVitaliciaRelatorio === valuesAnt.exibirSucessaoVitaliciaRelatorio &&
        values.exibirComparacaoCenariosRelatorio === valuesAnt.exibirComparacaoCenariosRelatorio &&
        values.exibirComparacaoCenariosRelatorioSemTabelaGrafico === valuesAnt.exibirComparacaoCenariosRelatorioSemTabelaGrafico &&
        values.exibirComparacaoEstudosRelatorio === valuesAnt.exibirComparacaoEstudosRelatorio &&
        values.exibirConsideracoesFinaisRelatorio === valuesAnt.exibirConsideracoesFinaisRelatorio &&
        values.percCapacidadePoupancaSugestaoAporteMensal === valuesAnt.percCapacidadePoupancaSugestaoAporteMensal &&
        values.aporteMensalAntesAposentarCliente === valuesAnt.aporteMensalAntesAposentarCliente &&
        values.retiradaMensalAntesAposentarCliente === valuesAnt.retiradaMensalAntesAposentarCliente &&
        values.aporteMensalDepoisAposentarCliente === valuesAnt.aporteMensalDepoisAposentarCliente &&
        values.retiradaMensalDepoisAposentarCliente === valuesAnt.retiradaMensalDepoisAposentarCliente &&
        values.tipoRetornoFinanceiro === valuesAnt.tipoRetornoFinanceiro &&
        values.exibirEvolucaoAporteMensalInflacao === valuesAnt.exibirEvolucaoAporteMensalInflacao &&
        values.exibirEvolucaoRetiradaMensalInflacao === valuesAnt.exibirEvolucaoRetiradaMensalInflacao &&
        comparaArraysJSON(values.aporteRetiradaMensal, valuesAnt.aporteRetiradaMensal) &&
        comparaArraysJSON(values.aporteRetiradaAnual, valuesAnt.aporteRetiradaAnual) &&
        values.anosExibicaoRelatorioEvolucaoReservaFinanceira === valuesAnt.anosExibicaoRelatorioEvolucaoReservaFinanceira &&



        values.percDespesasAdvocaticias === valuesAnt.percDespesasAdvocaticias &&
        values.percCustasJudiciais === valuesAnt.percCustasJudiciais &&
        values.percInventarioItcmd === valuesAnt.percInventarioItcmd &&
        values.mesesEmergencialCliente === valuesAnt.mesesEmergencialCliente &&
        values.emergencialCliente === valuesAnt.emergencialCliente &&
        values.inventarioCliente === valuesAnt.inventarioCliente &&
        values.alterarValoresAutomaticosSucessaoVitaliciaCliente === valuesAnt.alterarValoresAutomaticosSucessaoVitaliciaCliente &&
        values.mesesEmergencialConjuge === valuesAnt.mesesEmergencialConjuge &&
        values.emergencialConjuge === valuesAnt.emergencialConjuge &&
        values.inventarioConjuge === valuesAnt.inventarioConjuge &&
        values.inventarioPatrimonioImobilizado === valuesAnt.inventarioPatrimonioImobilizado &&
        values.inventarioRecursosFinanceiros === valuesAnt.inventarioRecursosFinanceiros &&
        values.alterarValoresAutomaticosSucessaoVitaliciaConjuge === valuesAnt.alterarValoresAutomaticosSucessaoVitaliciaConjuge &&



        //values.comSemDiaDiaEstudo === valuesAnt.comSemDiaDiaEstudo && // Foi retirado para não forçar a gravação automática
        //values.tipoDiagnosticoAposentadoriaChart === valuesAnt.tipoDiagnosticoAposentadoriaChart && // Foi retirado para não forçar a gravação automática



        comparaArraysJSON([values.objComparacaoFinanciamentos], [valuesAnt.objComparacaoFinanciamentos]) &&



        //values.modoApresentacao === valuesAnt.modoApresentacao &&
        values.modoApresentacaoRelatorio === valuesAnt.modoApresentacaoRelatorio &&
        values.modoApresentacaoAcumulacao === valuesAnt.modoApresentacaoAcumulacao &&
        values.modoApresentacaoProtecao === valuesAnt.modoApresentacaoProtecao &&

        comparaArraysJSON(values.moreLessAcumulacao, valuesAnt.moreLessAcumulacao) &&



        values.contPendencia === valuesAnt.contPendencia &&
        comparaArraysJSON(values.pendencias, valuesAnt.pendencias) &&

        comparaArraysJSON(values.anotacoes, valuesAnt.anotacoes)



    values.recalcularEstudo = !comparaCliente

    return comparaCliente
}



export const getClienteList = async ( variaveisGlobais, tipoBusca, valorMinimo, funil, etapa ) => {

    const vwClienteAcessoService = new VwClienteAcessoService()

    try {
        const response = await vwClienteAcessoService.buscarClienteList(variaveisGlobais, 
            {
                tipoBusca: tipoBusca,
                valorMinimo: valorMinimo,
                funil: funil,
                etapa: etapa
            }
        )

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getFamiliaClienteList = async ( variaveisGlobais, usuarioClienteSelecionadoNULL, nome, nascimento, sexo, cpf,
    mesDiaAniversarioInicial, mesDiaAniversarioFinal ) => {

    const vwClienteAcessoFamiliaService = new VwClienteAcessoFamiliaService()

    try {
        const response = await vwClienteAcessoFamiliaService.buscarClienteList(variaveisGlobais, usuarioClienteSelecionadoNULL,
            nome, nascimento, sexo, cpf, mesDiaAniversarioInicial, mesDiaAniversarioFinal)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getAporteClienteList = async ( variaveisGlobais, iClienteNULL, dataPrevistaAporteFinalNULL, statusAporteNULL ) => {

    const vwClienteAcessoAporteService = new VwClienteAcessoAporteService()

    try {
        const response = await vwClienteAcessoAporteService.buscarClienteList(variaveisGlobais, iClienteNULL,
            dataPrevistaAporteFinalNULL, statusAporteNULL)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getPendenciaClienteList = async ( variaveisGlobais, tipoPendenciaNULL, temPrevisaoNULL, pendenteNULL ) => {

    const vwClienteAcessoPendenciaService = new VwClienteAcessoPendenciaService()

    try {
        const response =
            await vwClienteAcessoPendenciaService.buscarClienteList(variaveisGlobais, tipoPendenciaNULL, temPrevisaoNULL, pendenteNULL)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getCountCliente = async ( variaveisGlobais, tipoCount, valorMinimo ) => {

    const vwClienteAcessoService = new VwClienteAcessoService()

    try {
        const response = await vwClienteAcessoService.countCliente(variaveisGlobais,
            {
                tipoCount: tipoCount,
                valorMinimo: valorMinimo,
            }
        )

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return ''
    }
}

export const getCountClienteFunilEtapas = async (variaveisGlobais) => {

    const vwClienteAcessoService = new VwClienteAcessoService()

    try {
        const response =
            await vwClienteAcessoService.countClienteFunilEtapas(variaveisGlobais)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getPendenciaCountClientePendencias = async (variaveisGlobais) => {

    const vwClienteAcessoPendenciaService = new VwClienteAcessoPendenciaService()

    try {
        const response =
            await vwClienteAcessoPendenciaService.countClientePendencias(variaveisGlobais)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getClienteRecomendouList = async (variaveisGlobais, values) => {

    const clienteService = new ClienteService()

    try {
        const response =
            await clienteService.buscarClienteRecomendouList(variaveisGlobais,
                ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
                    ClienteService.obterClienteSelecionado(variaveisGlobais))

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}



export const getIdentificaSexo = async (variaveisGlobais, nome) => {

    const familiaService = new FamiliaService()

    try {
        const response = await familiaService.identificaSexo(variaveisGlobais, nome)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return ''
    }
}



export const obtemPage = (pageList, familiar) => {

    let page

    if (familiar) {

        const pageAux = pesquisaList(pageList, familiar).page

        if (pageAux || pageAux === 0) {

            page = pageAux

        } else {

            page = HARDCODE.pageDefault
        }

    } else {

        page = HARDCODE.pageDefault
    }

    return page
}


export const salvaPage = (page, pageList, familiar) => {

    if (familiar && (page || page === 0)) {

        const index = pesquisaIndexList(pageList, familiar)

        if (index === null) {

            pageList.push(
                {
                    id: familiar,
                    page: page,
                }
            )

        } else {

            pageList[index].page = page
        }
    }
}

export const inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient = ( variaveisGlobaisNULL, jsonInfoclient, linhaTipoLicencaNULL,
    jsonPaginaNULL, jsonUsuarioNULL, jsonUsuarioAdminNULL, copia ) => {

    if (jsonInfoclient.pendencias) {

        jsonInfoclient.pendencias?.forEach( (linha, ind) => {

            if (!jsonInfoclient.pendencias[ind].dataPrevista) {

                jsonInfoclient.pendencias[ind].dataPrevista = null
            }
        })
    }

    jsonInfoclient.etapaAnt = jsonInfoclient.etapa
    
    if (!jsonInfoclient.funisEtapas) {

        jsonInfoclient.funisEtapas = []
    }



    //if (!jsonInfoclient.formListRadioGroup) {
    //    jsonInfoclient.formListRadioGroup = HARDCODE.formListPrimeiraReuniao
    //}


    
    if (!jsonInfoclient.tabIndexClientePrimeiraReuniao) {
        jsonInfoclient.tabIndexClientePrimeiraReuniao = 0
    }

    if (!jsonInfoclient.tabIndexClienteAprofundamento) {
        jsonInfoclient.tabIndexClienteAprofundamento = 0
    }

    if (!jsonInfoclient.tabIndexForm) {
        jsonInfoclient.tabIndexForm = 0
    }

    if (!jsonInfoclient.tabIndexAnaliseFinanceira) {
        jsonInfoclient.tabIndexAnaliseFinanceira = 0
    }

    if (!jsonInfoclient.tabIndexEstudo) {
        jsonInfoclient.tabIndexEstudo = 0
    }

    if (!jsonInfoclient.tabIndexEstudoParametros) {
        jsonInfoclient.tabIndexEstudoParametros = 0
    }

    if (!jsonInfoclient.tabIndexEstudoRevisaoDados) {
        jsonInfoclient.tabIndexEstudoRevisaoDados = 0
    }

    if (!jsonInfoclient.tabIndexAcompanhamento) {
        jsonInfoclient.tabIndexAcompanhamento = 0
    }



    if (!jsonInfoclient.funilEtapas) {

        jsonInfoclient.funilEtapas = []
    }



    if (!jsonInfoclient.nascimentoCliente) {
        jsonInfoclient.nascimentoCliente = null
    }

    if (!jsonInfoclient.possuiConjuge) {
        jsonInfoclient.possuiConjuge = false
    }

    if (!jsonInfoclient.percHerancaFamiliarDiferenteConjuge) {
        jsonInfoclient.percHerancaFamiliarDiferenteConjuge = false
    }

    if (!jsonInfoclient.nascimentoConjuge) {
        jsonInfoclient.nascimentoConjuge = null
    }

    if (!jsonInfoclient.dataSimulacaoEstudo) {
        jsonInfoclient.dataSimulacaoEstudo = dataAtual()
    }



    jsonInfoclient.contatos?.forEach( (linhaContato, index) => {

        if (
                linhaContato.tipoContato &&
                linhaContato.contato &&
                pesquisaList(tipoContatoList, linhaContato.tipoContato).formato === "email" &&
                linhaContato.contato !== linhaContato.contato.toLowerCase()
            ) {
            
            jsonInfoclient.contatos[index].contato = linhaContato.contato.toLowerCase()
        }
    })



    jsonInfoclient.enderecos?.forEach( (linhaEndereco, index) => {

        if (!linhaEndereco.id) {
            incrementaContEndereco(jsonInfoclient)
            jsonInfoclient.enderecos[index].id = jsonInfoclient.contEndereco
        }
    })

    

    if (!jsonInfoclient.objetivosPrincipaisPreocupacoesPFCMLP) {

        jsonInfoclient.objetivosPrincipaisPreocupacoesPFCMLP = [
            {
                tituloPagina: '',
                richText: '',
            }
        ]

        if (jsonInfoclient.objetivosPrincipaisPreocupacoes) {

            jsonInfoclient.objetivosPrincipaisPreocupacoesPFCMLP[0].richText = jsonInfoclient.objetivosPrincipaisPreocupacoes

            jsonInfoclient.objetivosPrincipaisPreocupacoes = ''
        }
    }



    if (!jsonInfoclient.estrategia) {
        jsonInfoclient.estrategia = '' 
    }
    
    if (!jsonInfoclient.consideracaoFinal) {

        jsonInfoclient.consideracaoFinal = []

        if (jsonInfoclient.consideracoesFinais) {
            jsonInfoclient.consideracaoFinal.push(jsonInfoclient.consideracoesFinais)
        }

        if (jsonInfoclient.consideracoesFinais2) {
            jsonInfoclient.consideracaoFinal.push(jsonInfoclient.consideracoesFinais2)
        }

        if (jsonInfoclient.consideracoesFinais3) {
            jsonInfoclient.consideracaoFinal.push(jsonInfoclient.consideracoesFinais3)
        }

        if (jsonInfoclient.consideracaoFinal.length === 0) {
            jsonInfoclient.consideracaoFinal.push('')
        }
    }

    if (jsonInfoclient.consideracaoFinal) {

        jsonInfoclient.consideracaoFinal?.forEach( (linha, ind) => {

            if (typeof jsonInfoclient.consideracaoFinal[ind] === 'string') {

                jsonInfoclient.consideracaoFinal[ind] = {
                    tituloPagina: '',
                    richText: linha,
                }
            }
        })
    }



    if (!jsonInfoclient.stepCadastro) {
        jsonInfoclient.stepCadastro = {
            activeStep: 0,
            completed: {},
        }
    }



    if (!jsonInfoclient.stepFluxoCaixa) {
        jsonInfoclient.stepFluxoCaixa = {
            activeStep: 0,
            completed: {},
        }
    }

    if (!jsonInfoclient.stepsValorFluxoCaixa || jsonInfoclient.stepsValorFluxoCaixa.length === 4) {
        jsonInfoclient.stepsValorFluxoCaixa = [0, 0, 0, 0, 0]
    }

    if (!jsonInfoclient.stepsValor2FluxoCaixa || jsonInfoclient.stepsValor2FluxoCaixa.length === 4) {
        jsonInfoclient.stepsValor2FluxoCaixa = ['', 0, '', '', '']
    }

    if (!jsonInfoclient.stepsValorSubTotal1FluxoCaixa || jsonInfoclient.stepsValorSubTotal1FluxoCaixa.length === 4) {
        jsonInfoclient.stepsValorSubTotal1FluxoCaixa = [
            {
                title: '',
                valor: 0
            },
            {
                title: '',
                valor: 0
            },
            {
                title: '',
                valor: 0
            },
            {
                title: '',
                valor: 0
            },
            {
                title: '',
                valor: 0
            },
        ]
    }
    
    if (!jsonInfoclient.stepsValorSubTotal2FluxoCaixa || jsonInfoclient.stepsValorSubTotal2FluxoCaixa.length === 4) {
        jsonInfoclient.stepsValorSubTotal2FluxoCaixa = [
            {
                title: '',
                valor: 0
            },
            {
                title: '',
                valor: 0
            },
            {
                title: '',
                valor: 0
            },
            {
                title: '',
                valor: 0
            },
            {
                title: '',
                valor: 0
            },
        ]
    }



    if (!jsonInfoclient.stepPatrimonio) {
        jsonInfoclient.stepPatrimonio = {
            activeStep: 0,
            completed: {},
        }
    } else {
        if (jsonInfoclient.stepPatrimonio.activeStep === 3) {
            jsonInfoclient.stepPatrimonio.activeStep = 0
        }
    }

    if (!jsonInfoclient.stepsValorPatrimonio || jsonInfoclient.stepsValorPatrimonio.length === 4) {
        jsonInfoclient.stepsValorPatrimonio = [0, 0, 0]
    }

    if (!jsonInfoclient.stepsValor2Patrimonio || jsonInfoclient.stepsValor2Patrimonio.length === 4) {
        jsonInfoclient.stepsValor2Patrimonio = ['', '', 0]
    }

    jsonInfoclient.processarIncluiReceitaMensalInssPensaoAluguelPatrimonioImobilizadoAluguel = true

    jsonInfoclient.processarIncluiRecursosFinanceirosFGTSContribuicaoReceitaMensalCLT = true



    if (!jsonInfoclient.stepSeguroVidaCotacao) {
        jsonInfoclient.stepSeguroVidaCotacao = {
            activeStep: 0,
            completed: {},
        }
    }
    
    if (!jsonInfoclient.stepsValorSeguroVidaCotacao) {
        jsonInfoclient.stepsValorSeguroVidaCotacao = [0, 0, 0, 0, 0, 0, 0]
    } else {
        if (jsonInfoclient.stepsValorSeguroVidaCotacao.length === 6) {
            jsonInfoclient.stepsValorSeguroVidaCotacao.push(0)
        }
    }

    if (!jsonInfoclient.stepsValor2SeguroVidaCotacao) {
        jsonInfoclient.stepsValor2SeguroVidaCotacao = [0, 0, 0, 0, 0, 0, 0]
    } else {
        if (jsonInfoclient.stepsValor2SeguroVidaCotacao.length === 6) {
            jsonInfoclient.stepsValor2SeguroVidaCotacao.push(0)
        }
    }
    


    if (!jsonInfoclient.stepSeguroVidaProposta) {
        jsonInfoclient.stepSeguroVidaProposta = {
            activeStep: 0,
            completed: {},
        }
    }
    
    if (!jsonInfoclient.stepsValorSeguroVidaProposta) {
        jsonInfoclient.stepsValorSeguroVidaProposta = [0, 0, 0, 0, 0, 0, 0]
    } else {
        if (jsonInfoclient.stepsValorSeguroVidaProposta.length === 6) {
            jsonInfoclient.stepsValorSeguroVidaProposta.push(0)
        }
    }

    if (!jsonInfoclient.stepsValor2SeguroVidaProposta) {
        jsonInfoclient.stepsValor2SeguroVidaProposta = [0, 0, 0, 0, 0, 0, 0]
    } else {
        if (jsonInfoclient.stepsValor2SeguroVidaProposta.length === 6) {
            jsonInfoclient.stepsValor2SeguroVidaProposta.push(0)
        }
    }
    


    if (!jsonInfoclient.stepSeguroVida) {
        jsonInfoclient.stepSeguroVida = {
            activeStep: 0,
            completed: {},
        }
    }

    if (!jsonInfoclient.stepsValorSeguroVida) {
        jsonInfoclient.stepsValorSeguroVida = [0, 0, 0, 0, 0, 0, 0]
    } else {
        if (jsonInfoclient.stepsValorSeguroVida.length === 6) {
            jsonInfoclient.stepsValorSeguroVida.push(0)
        }
    }

    if (!jsonInfoclient.stepsValor2SeguroVida) {
        jsonInfoclient.stepsValor2SeguroVida = [0, 0, 0, 0, 0, 0, 0]
    } else {
        if (jsonInfoclient.stepsValor2SeguroVida.length === 6) {
            jsonInfoclient.stepsValor2SeguroVida.push(0)
        }
    }
    
    jsonInfoclient.processarIncluiSeguroVidaSeguroVidaEmGrupo = true

    

    if (!jsonInfoclient.stepAcompanhamentoSeguroVida) {
        jsonInfoclient.stepAcompanhamentoSeguroVida = {
            activeStep: 0,
            completed: {},
        }
    }

    if (!jsonInfoclient.stepsValorAcompanhamentoSeguroVida) {
        jsonInfoclient.stepsValorAcompanhamentoSeguroVida = [0, 0, 0]
    }

    if (!jsonInfoclient.stepsValor2AcompanhamentoSeguroVida) {
        jsonInfoclient.stepsValor2AcompanhamentoSeguroVida = [0, 0, 0]
    }
    


    if (jsonInfoclient.nascimentoCliente) {
        jsonInfoclient.idadeCliente = calculaIdade(jsonInfoclient.nascimentoCliente)
    }

    if (jsonInfoclient.nascimentoConjuge) {
        jsonInfoclient.idadeConjuge = calculaIdade(jsonInfoclient.nascimentoConjuge)
    }

    jsonInfoclient.familiares?.forEach( (linhaFamiliar, index) => {

        if (linhaFamiliar.nascimentoFamiliar) {

            jsonInfoclient.familiares[index].idadeFamiliar = calculaIdade(linhaFamiliar.nascimentoFamiliar)
        }
    })



    if (!jsonInfoclient.contDiaDia) {

        jsonInfoclient.diaDia?.forEach( (linhaDiaDia, index) => {

            incrementaContDiaDia(jsonInfoclient)
            jsonInfoclient.diaDia[index].id = jsonInfoclient.contDiaDia
        })
    }

    if (!jsonInfoclient.rowsPerPageDiaDia) {

        jsonInfoclient.rowsPerPageDiaDia = HARDCODE.rowsPerPageDefault
    }

    if (!jsonInfoclient.pageDiaDiaList) {

        jsonInfoclient.pageDiaDiaList = []
    }

    jsonInfoclient.diaDia?.forEach( (linhaDiaDia, index) => {

        if (linhaDiaDia.anoFinal) {

            jsonInfoclient.diaDia[index].alterarPeriodoDiaDia = true
            jsonInfoclient.diaDia[index].mesAnoInicial =
                formataDataEmMesAnoSemBarra(jsonInfoclient.dataSimulacaoEstudo) 
            jsonInfoclient.diaDia[index].mesAnoFinal = '12' + linhaDiaDia.anoFinal
            jsonInfoclient.diaDia[index].anoFinal = ''
        }

        if (linhaDiaDia.vitalicio) {

            jsonInfoclient.diaDia[index].vitalicio = ''
        }

        if (!linhaDiaDia.grupoDespesa && linhaDiaDia.tipoDiaDia) {

            jsonInfoclient.diaDia[index].grupoDespesa = pesquisaList(tipoDiaDiaList, linhaDiaDia.tipoDiaDia).grupoDespesa
        }

        //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
        //das perguntas com o dia a dia quando idFormList = HARDCODE.idFormListDefault, que era até
        //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
        if (jsonInfoclient.idFormList === HARDCODE.idFormListDefault) {

            if (jsonInfoclient.diaDia[index].idPergunta) {

                jsonInfoclient.diaDia[index].observacao = ''
                jsonInfoclient.diaDia[index].idPergunta = ''
            }
        }
    })
    


    if (!jsonInfoclient.contEducacao) {

        jsonInfoclient.educacao?.forEach( (linhaEducacao, index) => {

            incrementaContEducacao(jsonInfoclient)
            jsonInfoclient.educacao[index].id = jsonInfoclient.contEducacao
        })
    }

    if (!jsonInfoclient.rowsPerPageEducacao) {

        jsonInfoclient.rowsPerPageEducacao = HARDCODE.rowsPerPageDefault
    }

    if (!jsonInfoclient.pageEducacaoList) {

        jsonInfoclient.pageEducacaoList = []
    }

    jsonInfoclient.educacao?.forEach( (linhaEducacao, index) => {

        if (!linhaEducacao.grupoDespesa && linhaEducacao.tipoEducacao) {

            jsonInfoclient.educacao[index].grupoDespesa = pesquisaList(tipoEducacaoList, linhaEducacao.tipoEducacao).grupoDespesa
        }
    })
    


    if (!jsonInfoclient.contFinanciamento) {

        jsonInfoclient.financiamento?.forEach( (linhaFinanciamento, index) => {

            incrementaContFinanciamento(jsonInfoclient)
            jsonInfoclient.financiamento[index].id = jsonInfoclient.contFinanciamento
        })
    }

    if (!jsonInfoclient.rowsPerPageFinanciamento) {

        jsonInfoclient.rowsPerPageFinanciamento = HARDCODE.rowsPerPageDefault
    }

    if (!jsonInfoclient.pageFinanciamentoList) {

        jsonInfoclient.pageFinanciamentoList = []
    }

    jsonInfoclient.financiamento?.forEach( (linhaFinanciamento, index) => {

        if (linhaFinanciamento.mesAnoInicial && !linhaFinanciamento.financiamentoFuturo) {

            jsonInfoclient.financiamento[index].financiamentoFuturo = true
        }

        if (!linhaFinanciamento.grupoDespesa && linhaFinanciamento.tipoFinanciamento) {

            jsonInfoclient.financiamento[index].grupoDespesa = pesquisaList(tipoFinanciamentoList, linhaFinanciamento.tipoFinanciamento).grupoDespesa
        }
    })
    


    if (!jsonInfoclient.contProjetoVidaSonho) {

        jsonInfoclient.projetoVidaSonho?.forEach( (linhaProjetoVidaSonho, index) => {

            incrementaContProjetoVidaSonho(jsonInfoclient)
            jsonInfoclient.projetoVidaSonho[index].id = jsonInfoclient.contProjetoVidaSonho
        })
    }
    
    if (!jsonInfoclient.rowsPerPageProjetoVidaSonho) {

        jsonInfoclient.rowsPerPageProjetoVidaSonho = HARDCODE.rowsPerPageDefault
    }

    if (!jsonInfoclient.pageProjetoVidaSonhoList) {

        jsonInfoclient.pageProjetoVidaSonhoList = []
    }

    jsonInfoclient.projetoVidaSonho?.forEach( (linhaProjetoVidaSonho, index) => {

        if (linhaProjetoVidaSonho.tracarRota && !linhaProjetoVidaSonho.tracarMeta) {

            jsonInfoclient.projetoVidaSonho[index].tracarRota = ''
            jsonInfoclient.projetoVidaSonho[index].tracarMeta = true
            jsonInfoclient.projetoVidaSonho[index].mesAnoInicioAcumulacao =
                linhaProjetoVidaSonho.mesAnoInicialPreparacao
            jsonInfoclient.projetoVidaSonho[index].mesAnoFimAcumulacao =
                linhaProjetoVidaSonho.mesAnoFinalPreparacao
        }

        if (jsonInfoclient.projetoVidaSonho[index].tracarMeta && !jsonInfoclient.projetoVidaSonho[index].tipoPeriodicidadeAporte) {

            jsonInfoclient.projetoVidaSonho[index].tipoPeriodicidadeAporte = HARDCODE.idTipoPeriodicidadeMensal
        }
    })



    if (!jsonInfoclient.rowsPerPagePatrimonioImobilizado) {

        jsonInfoclient.rowsPerPagePatrimonioImobilizado = HARDCODE.rowsPerPageDefault
    }

    if (!jsonInfoclient.pagePatrimonioImobilizadoList) {

        jsonInfoclient.pagePatrimonioImobilizadoList = []
    }

    jsonInfoclient.patrimonioImobilizado?.forEach( (linhaPatrimonioImobilizado, index) => {

        //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
        //das perguntas com o patrimônio quando idFormList = HARDCODE.idFormListDefault, que era até
        //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
        if (jsonInfoclient.idFormList === HARDCODE.idFormListDefault) {

            if (jsonInfoclient.patrimonioImobilizado[index].idPergunta) {

                jsonInfoclient.patrimonioImobilizado[index].observacao = ''
                jsonInfoclient.patrimonioImobilizado[index].idPergunta = ''
            }
        }
    })


    
    if (!jsonInfoclient.rowsPerPageReceitaMensal) {

        jsonInfoclient.rowsPerPageReceitaMensal = HARDCODE.rowsPerPageDefault
    }

    if (!jsonInfoclient.pageReceitaMensalList) {

        jsonInfoclient.pageReceitaMensalList = []
    }

    jsonInfoclient.receitaMensal?.forEach( (linhaReceitaMensal, index) => {

        if (
                !linhaReceitaMensal.alterarValorInssPensaoMorteAposentadoria &&
                (
                    linhaReceitaMensal.valorInssPensaoInvalidez ||
                    linhaReceitaMensal.valorInssPensaoMorte ||
                    linhaReceitaMensal.valorInssPensaoAposentadoria
                ) &&
                (
                    linhaReceitaMensal.valorInssPensaoInvalidez !== linhaReceitaMensal.valorInssPensaoMorte ||
                    linhaReceitaMensal.valorInssPensaoInvalidez !== linhaReceitaMensal.valorInssPensaoAposentadoria
                )
        ) {
            jsonInfoclient.receitaMensal[index].alterarValorInssPensaoMorteAposentadoria = true
        }

        //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
        //das perguntas com a receita mensal quando idFormList = HARDCODE.idFormListDefault, que era até
        //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
        if (jsonInfoclient.idFormList === HARDCODE.idFormListDefault) {

            if (jsonInfoclient.receitaMensal[index].idPergunta) {

                jsonInfoclient.receitaMensal[index].observacao = ''
                jsonInfoclient.receitaMensal[index].idPergunta = ''
            }
        }
    })



    if (!jsonInfoclient.contRecursosFinanceiros) {

        jsonInfoclient.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {

            incrementaContRecursosFinanceiros(jsonInfoclient)
            jsonInfoclient.recursosFinanceiros[index].id = jsonInfoclient.contRecursosFinanceiros
        })
    }
    
    if (!jsonInfoclient.rowsPerPageRecursosFinanceiros) {

        jsonInfoclient.rowsPerPageRecursosFinanceiros = HARDCODE.rowsPerPageDefault
    }

    if (!jsonInfoclient.pageRecursosFinanceirosList) {

        jsonInfoclient.pageRecursosFinanceirosList = []
    }

    jsonInfoclient.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {

        if (
                linhaRecursosFinanceiros.tipoRecursoFinanceiro === 
                    HARDCODE.idTipoRecursoFinanceiroPrevidenciaPGBLEmpresarial
        ) {

            jsonInfoclient.recursosFinanceiros[index].tipoRecursoFinanceiro =
                HARDCODE.idTipoRecursoFinanceiroPrevidenciaPGBL

        } else {

            if (
                linhaRecursosFinanceiros.tipoRecursoFinanceiro === 
                    HARDCODE.idTipoRecursoFinanceiroPrevidenciaVGBLEmpresarial
            ) {

                jsonInfoclient.recursosFinanceiros[index].tipoRecursoFinanceiro =
                    HARDCODE.idTipoRecursoFinanceiroPrevidenciaVGBL
            }
        }

        if (linhaRecursosFinanceiros.idadeLimiteContribuicao) {

            jsonInfoclient.recursosFinanceiros[index].mesAnoInicialAporteDividendos =
                formataDataEmMesAnoSemBarra(jsonInfoclient.dataSimulacaoEstudo) 
            jsonInfoclient.recursosFinanceiros[index].mesAnoFinalAporteDividendos = '12' + 
                calculaAnoIdadeInformada(jsonInfoclient, linhaRecursosFinanceiros.idadeLimiteContribuicao,
                    idadeFamiliarDataSimulacao(jsonInfoclient, linhaRecursosFinanceiros.familiar)
                )
            jsonInfoclient.recursosFinanceiros[index].idadeLimiteContribuicao = ''
        }

        //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
        //das perguntas com os recursos financeiros quando idFormList = HARDCODE.idFormListDefault, que era até
        //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
        if (jsonInfoclient.idFormList === HARDCODE.idFormListDefault) {

            if (jsonInfoclient.recursosFinanceiros[index].idPergunta) {

                jsonInfoclient.recursosFinanceiros[index].observacao = ''
                jsonInfoclient.recursosFinanceiros[index].idPergunta = ''
            }
        }
    })



    jsonInfoclient.seguroVidaCobertura?.forEach( (linhaSeguroVidaCobertura, index) => {

        if (
                (
                    linhaSeguroVidaCobertura.prazoRestanteAnos ||
                    linhaSeguroVidaCobertura.tipoCoberturaSeguroVida === HARDCODE.idTipoCoberturaSeguroVidaVidaInteira ||
                    linhaSeguroVidaCobertura.tipoCoberturaSeguroVida === HARDCODE.idTipoCoberturaSeguroVidaDIH
                ) &&
                !linhaSeguroVidaCobertura.dataTerminoCobertura
         ) {

            if (linhaSeguroVidaCobertura.prazoRestanteAnos) {

                jsonInfoclient.seguroVidaCobertura[index].dataTerminoCobertura =
                    dataAnoMesDia(ano(jsonInfoclient.dataSimulacaoEstudo) + linhaSeguroVidaCobertura.prazoRestanteAnos - 1,
                        mes(jsonInfoclient.dataSimulacaoEstudo), 1)

            } else {

                if (linhaSeguroVidaCobertura.tipoCoberturaSeguroVida) {

                    if (linhaSeguroVidaCobertura.tipoCoberturaSeguroVida === HARDCODE.idTipoCoberturaSeguroVidaVidaInteira) {

                        if (
                                pesquisaList(jsonInfoclient.seguroVidaApolice, linhaSeguroVidaCobertura.idSeguroVidaApolice).familiar ===
                                    HARDCODE.idFamiliarCliente
                        ) {

                            jsonInfoclient.seguroVidaCobertura[index].dataTerminoCobertura =
                                dataAnoMesDia(ano(jsonInfoclient.dataSimulacaoEstudo) + 100 - jsonInfoclient.idadeDataSimulacaoCliente,
                                    mes(jsonInfoclient.dataSimulacaoEstudo), 1)

                        } else {

                            if (
                                    pesquisaList(jsonInfoclient.seguroVidaApolice, linhaSeguroVidaCobertura.idSeguroVidaApolice).familiar ===
                                        HARDCODE.idFamiliarConjuge
                            ) {

                                jsonInfoclient.seguroVidaCobertura[index].dataTerminoCobertura =
                                    dataAnoMesDia(ano(jsonInfoclient.dataSimulacaoEstudo) + 100 - jsonInfoclient.idadeDataSimulacaoConjuge,
                                        mes(jsonInfoclient.dataSimulacaoEstudo), 1)
                            }
                        }

                    } else {

                        if (linhaSeguroVidaCobertura.tipoCoberturaSeguroVida === HARDCODE.idTipoCoberturaSeguroVidaDIH) {

                            jsonInfoclient.seguroVidaCobertura[index].dataTerminoCobertura =
                                dataAnoMesDia(ano(jsonInfoclient.dataSimulacaoEstudo) + 1, mes(jsonInfoclient.dataSimulacaoEstudo), 1)
                        }
                    }
                }
            }

            jsonInfoclient.seguroVidaCobertura[index].prazoRestanteAnos = ''

        }
    })

    
    
    if (!jsonInfoclient.objPrevidenciaPrivada) {

        jsonInfoclient.objPrevidenciaPrivada = {}
    }
    
    
    
    if (!jsonInfoclient.objConsorcio) {

        jsonInfoclient.objConsorcio = {}

    } else {

        if (jsonInfoclient.objConsorcio.simulacao) {

            jsonInfoclient.objConsorcio.simulacao?.forEach( (item, ind) => {

                if (item.taxaAdministracaoTotal) {

                    item.taxaAdministracao = item.taxaAdministracaoTotal

                    item.taxaAdministracaoTotal = ''
                }

                if (item.primeirasParcelas) {

                    item.parcelasAdesao = item.primeirasParcelas

                    item.primeirasParcelas = ''
                }
            })
        }
    }
    
    

    if (!jsonInfoclient.id && !copia) {

        jsonInfoclient.processarCenariosDefaultJsonUsuario = true
    }

    if (!jsonInfoclient.cenarios || jsonInfoclient.cenarios.length === 0) {
        
        incluirCenario(jsonInfoclient)
    }

    if (!jsonInfoclient.taxaCDIAnual && jsonInfoclient.taxaCDIAnual !== 0) {
        jsonInfoclient.taxaCDIAnual = HARDCODE.taxaCDIAnualDefault
    }

    if (!jsonInfoclient.retornoCDI && jsonInfoclient.retornoCDI !== 0) {
        jsonInfoclient.retornoCDI = HARDCODE.retornoCDIDefault
    }

    if (!jsonInfoclient.tipoRetornoFinanceiro) {
        jsonInfoclient.tipoRetornoFinanceiro =  HARDCODE.tipoRetornoFinanceiroReal
    }
    
    if (!jsonInfoclient.anosExibicaoRelatorioEvolucaoReservaFinanceira) {
        jsonInfoclient.anosExibicaoRelatorioEvolucaoReservaFinanceira =  1
    }

    if (!jsonInfoclient.taxaJurosAnual && jsonInfoclient.taxaJurosAnual !== 0) {
        jsonInfoclient.taxaJurosAnual = HARDCODE.taxaJurosAnualDefault
    }

    if (!jsonInfoclient.taxaInflacaoAnual && jsonInfoclient.taxaInflacaoAnual !== 0) {
        jsonInfoclient.taxaInflacaoAnual = HARDCODE.taxaInflacaoAnualDefault
    }

    if (!jsonInfoclient.taxaJurosAnualFgts && jsonInfoclient.taxaJurosAnualFgts !== 0) {
        jsonInfoclient.taxaJurosAnualFgts = HARDCODE.taxaJurosAnualFgtsDefault
    }

    if (!jsonInfoclient.idadeSimulacaoMorteCliente && jsonInfoclient.idadeSimulacaoMorteCliente !== 0) {
        jsonInfoclient.idadeSimulacaoMorteCliente = jsonInfoclient.idadeVaiAposentarCliente
    }

    if (jsonInfoclient.alterarParametrosAutomaticosCliente) {
        if (!jsonInfoclient.alterarIdadesAutomaticasCliente) {
            jsonInfoclient.alterarIdadesAutomaticasCliente = true
        }

        if (!jsonInfoclient.alterarPercRespCliente) {
            jsonInfoclient.alterarPercRespCliente = true
        }

        jsonInfoclient.alterarParametrosAutomaticosCliente = false
    }

    if (jsonInfoclient.alterarParametrosAutomaticosConjuge) {
        if (!jsonInfoclient.alterarIdadesAutomaticasConjuge) {
            jsonInfoclient.alterarIdadesAutomaticasConjuge = true
        }

        jsonInfoclient.alterarParametrosAutomaticosConjuge = false
    }
    
    if (!jsonInfoclient.idadeInicioInssCliente && jsonInfoclient.idadeVaiAposentarCliente) {
        jsonInfoclient.idadeInicioInssCliente = jsonInfoclient.idadeVaiAposentarCliente + 1
    }
    
    if (!jsonInfoclient.idadeInicioInssConjuge && jsonInfoclient.idadeVaiAposentarConjuge) {
        jsonInfoclient.idadeInicioInssConjuge = jsonInfoclient.idadeVaiAposentarConjuge + 1
    }



    trataMudancaNaoExibirMorteAteParaExibirMorteAte(jsonInfoclient)


    
    if (!jsonInfoclient.disposicaoRelatorio) {

        jsonInfoclient.disposicaoRelatorio = HARDCODE.disposicaoRelatorioRetrato
    }

    if (variaveisGlobaisNULL && linhaTipoLicencaNULL && jsonUsuarioNULL) {

        let inicializarConfiguracaoRelatorio = false

        if (!jsonInfoclient.reportImprimir || linhaTipoLicencaNULL.exibirSomentePrevidenciaPrivada === 'S') {

            jsonInfoclient.reportImprimir = []

            inicializarConfiguracaoRelatorio = true
        }

        if (!jsonInfoclient.reportNaoImprimir || linhaTipoLicencaNULL.exibirSomentePrevidenciaPrivada === 'S') {

            jsonInfoclient.reportNaoImprimir = []

            inicializarConfiguracaoRelatorio = true
        }

        if (inicializarConfiguracaoRelatorio) {

            inicializaConfiguracaoRelatorio(variaveisGlobaisNULL, jsonInfoclient, jsonInfoclient.idFormList, linhaTipoLicencaNULL, jsonPaginaNULL,
                jsonUsuarioNULL, jsonUsuarioAdminNULL, null)

        } else {

            incluiAlteraExcluiConfiguracaoRelatorioReportList(jsonInfoclient.idFormList, /*null*/linhaTipoLicencaNULL, jsonPaginaNULL, jsonInfoclient.paginaAdicional,
                jsonInfoclient.reportImprimir, jsonInfoclient.reportNaoImprimir)
        }

        jsonInfoclient.cenarios?.forEach( (linhaCenario, index) => {

            if (!linhaCenario.idadeInicioInssCliente && linhaCenario.idadeVaiAposentarCliente) {
                linhaCenario.idadeInicioInssCliente = linhaCenario.idadeVaiAposentarCliente + 1
            }
            
            if (!linhaCenario.idadeInicioInssConjuge && linhaCenario.idadeVaiAposentarConjuge) {
                linhaCenario.idadeInicioInssConjuge = linhaCenario.idadeVaiAposentarConjuge + 1
            }

            if (!linhaCenario.objComparacaoFinanciamentos) {

                linhaCenario.objComparacaoFinanciamentos = {}
            }
        })
    }

    if (!jsonInfoclient.percDespesasAdvocaticias && jsonInfoclient.percDespesasAdvocaticias !== 0) {
        jsonInfoclient.percDespesasAdvocaticias = HARDCODE.percInventarioDespesasJudiciaisDefault
    }
    
    if (!jsonInfoclient.percCustasJudiciais && jsonInfoclient.percCustasJudiciais !== 0) {
        jsonInfoclient.percCustasJudiciais = HARDCODE.percInventarioCustasJudiciaisDefault
    }

    if (!jsonInfoclient.mesesEmergencialCliente) {
        jsonInfoclient.mesesEmergencialCliente = HARDCODE.idMesesEmergencialDefault
    }
    
    if (!jsonInfoclient.mesesEmergencialConjuge) {
        jsonInfoclient.mesesEmergencialConjuge = HARDCODE.idMesesEmergencialDefault
    }
    
    if (!jsonInfoclient.morteInvalidezEstudo) {
        jsonInfoclient.morteInvalidezEstudo =  "M"
    }

    if (!jsonInfoclient.comSemDiaDiaEstudo) {
        jsonInfoclient.comSemDiaDiaEstudo =  "C"
    }

    if (!jsonInfoclient.tipoDiagnosticoAposentadoriaChart) {
        jsonInfoclient.tipoDiagnosticoAposentadoriaChart =  "A"
    }

    
    
    if (!jsonInfoclient.objComparacaoFinanciamentos) {

        jsonInfoclient.objComparacaoFinanciamentos = {}
    }


    
    if (!jsonInfoclient.moreLessAcumulacao) {

        jsonInfoclient.moreLessAcumulacao = []
    }

    if (jsonInfoclient.moreLessAcumulacao.length !== acumulacaoList(linhaTipoLicencaNULL).length) {

        acumulacaoList(linhaTipoLicencaNULL)?.forEach( (linha, ind) => {

            if (!pesquisaList(jsonInfoclient.moreLessAcumulacao, linha.id).id) {

                jsonInfoclient.moreLessAcumulacao.push({
                    id: linha.id,
                    expanded: true,//ind === 0 ? true : false,
                })
            }
        })
    }

    if (!jsonInfoclient.anotacoes) {

        jsonInfoclient.anotacoes = [
            {
                tituloAnotacao: '',
                dataAnotacao: null,
                richText: '',
            }
        ]

        if (jsonInfoclient.observacaoCliente) {

            jsonInfoclient.anotacoes[0].richText = jsonInfoclient.observacaoCliente

            jsonInfoclient.observacaoCliente = ''
        }
    }

    return jsonInfoclient
}

export const inicializaControleVersao = ( jsonInfoclient, inicializaVersaoUltimaVersao ) => {

    if (inicializaVersaoUltimaVersao) {

        jsonInfoclient.ultimaVersao = HARDCODE.ultimaVersaoInicial
        jsonInfoclient.jsonComparacaoEstudos = JSON.parse(HARDCODE.jsonComparacaoEstudosInicial)
        jsonInfoclient.versao = HARDCODE.ultimaVersaoInicial

    } else {

        jsonInfoclient.jsonComparacaoEstudos = JSON.parse(jsonInfoclient.jsonComparacaoEstudos)
    }
}

export const getInfoclient = async ( variaveisGlobais, usuarioClienteSelecionadoNULL, idCliente, versao ) => {

    const infoclientService = new InfoclientService()

    try {
        const response =
            await infoclientService.buscarByIdClienteMandatorio(variaveisGlobais,
                usuarioClienteSelecionadoNULL
                    ?
                        usuarioClienteSelecionadoNULL
                    :
                        ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
                idCliente, versao)

        return JSON.parse(response.data.jsonInfoclient)

    } catch (erro) {

        mensagemErroErro(erro)
        
        return {}
    }
}

export const obtemJsonClienteAlteracao = async (variaveisGlobais, usuarioClienteSelecionadoNULL, idCliente, ultimaVersao,
    jsonComparacaoEstudos) => {
                        
    let json = await getInfoclient(variaveisGlobais, usuarioClienteSelecionadoNULL, idCliente, ultimaVersao)

    json.id = idCliente
    json.ultimaVersao = ultimaVersao
    json.jsonComparacaoEstudos = jsonComparacaoEstudos
    json.versao = ultimaVersao

    json = inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient(null, json, null, null, null, null, false)

    return json
}

export const getInfoclientVersoes = async ( variaveisGlobais, idCliente, ultimaVersao, linhaTipoLicenca, jsonPagina, jsonUsuario,
    jsonUsuarioAdmin ) => {

    const infoclientService = new InfoclientService()

    try {
        const response = await infoclientService.buscarVersoesInfoclientList(variaveisGlobais,
            ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais), idCliente, ultimaVersao)

        const versoesInfoclient = response.data
                
        versoesInfoclient?.forEach( (linhaVersoesInfoclient, indexVersoesInfoclient) => {
            
            versoesInfoclient[indexVersoesInfoclient].jsonInfoclient =
                inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient(variaveisGlobais, JSON.parse(linhaVersoesInfoclient.jsonInfoclient),
                    linhaTipoLicenca, jsonPagina, jsonUsuario, jsonUsuarioAdmin, false)
        })

        return versoesInfoclient

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const objetoClienteInfoclient = ( variaveisGlobais, usuarioClienteSelecionadoNULL, idCliente, values, valuesAntNULL,
    jsonInfoclientNULL ) => {

    return {
        id: idCliente,
        idUsuario:
            usuarioClienteSelecionadoNULL
                ?
                    usuarioClienteSelecionadoNULL
                :
                    ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
        ultimaVersao: values.ultimaVersao,
        jsonComparacaoEstudos: JSON.stringify(values.jsonComparacaoEstudos),
        versao: values.versao,
        jsonInfoclient: JSON.stringify(objetoCliente(variaveisGlobais, values, valuesAntNULL, jsonInfoclientNULL))
    }
}

export const putClienteInfoclient = async ( variaveisGlobais, usuarioClienteSelecionadoNULL,
    values, valuesAntNULL, jsonInfoclientNULL, arquivoArquivoAcessoDTOAssociarClienteNULL) => {

    const clienteService = new ClienteService()

    let response

    try {

        const idCliente = ClienteService.obterClienteSelecionado(variaveisGlobais)

        const clienteInfoclient = objetoClienteInfoclient(variaveisGlobais, usuarioClienteSelecionadoNULL, idCliente, values,
            valuesAntNULL, jsonInfoclientNULL)

        if (idCliente) {

            response = await clienteService.atualizarClienteInfoclient(variaveisGlobais, clienteInfoclient,
                arquivoArquivoAcessoDTOAssociarClienteNULL)

            AuthService.validaTokenLogarNovamente(variaveisGlobais, response)

        } else {

            response = await clienteService.salvarClienteInfoclient(variaveisGlobais, clienteInfoclient,
                arquivoArquivoAcessoDTOAssociarClienteNULL)

            values.id = response.data.id

            ClienteService.setarClienteSelecionado(variaveisGlobais, values.id)
        }
        
        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}

export const deleteClienteInfoclient = async ( variaveisGlobais, idCliente ) => {

    const clienteService = new ClienteService()

    try {

        await clienteService.deletarClienteInfoclient(variaveisGlobais,
            ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais), idCliente)

        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}

export const putTransferirCliente = async ( variaveisGlobais, idCliente, idUsuarioOrigem, idUsuarioDestino ) => {

    const clienteService = new ClienteService()

    try {

        await clienteService.transferirCliente(variaveisGlobais, idCliente, idUsuarioOrigem, idUsuarioDestino)

        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}



export const dadosCompletosCliente = ( values ) => {

    let dadosCompletosClienteAux = false

    if (
            (values.nomeCliente || values.nomeCurtoCliente) &&
            (values.idadeCliente === 0 || values.idadeCliente) &&
            values.sexoCliente
    ) {
        dadosCompletosClienteAux = true
    }

    return dadosCompletosClienteAux
}



export const dadosCompletosConjuge = ( values, naoConsiderarExclusaoFamiliares ) => {
    
    let dadosCompletosConjugeAux = false

    if (
            (values.nomeConjuge || values.nomeCurtoConjuge) &&
            (values.idadeConjuge === 0 || values.idadeConjuge) &&
            values.sexoConjuge &&
            (
                naoConsiderarExclusaoFamiliares ||
                !pesquisaList(values.exclusaoFamiliares, HARDCODE.idFamiliarConjuge).id
            )
    ) {
        dadosCompletosConjugeAux = true
    }

    return dadosCompletosConjugeAux
}



export const tituloCliente = (values) => {

    let titulo = null

    if (values.nomeCliente) {

        titulo = nomeCurtoCliente(values)

    } else {

        titulo = LABEL.cliente
    }

    return titulo
}

export const tituloConjuge = (values) => {

    let titulo = null

    if (values.nomeConjuge) {

        titulo = nomeCurtoConjuge(values)

    } else {

        titulo = LABEL.conjuge
    }

    return titulo
}

export const tituloClienteConjuge = (values, idFamiliar) => {

    let titulo = null

    if (idFamiliar === HARDCODE.idFamiliarCliente) {

        titulo = tituloCliente(values)

    } else {
        
        if (idFamiliar === HARDCODE.idFamiliarConjuge) {
        
            titulo = tituloConjuge(values)
        }
    }

    return titulo
}



export const calcDifPerc100 = ( perc ) => {
    return round(100 - perc, 2)
}

export const calculaValorPerc = (valor, perc) => {
    return round(valor * perc / 100, 2)
}



export const calculaValorMensal = (valor, tipoPeriodicidadeNull) => {
    let valorMensal = valor ? valor : 0

    let fatorConversaoMensal = 
        tipoPeriodicidadeNull
            ? pesquisaList(tipoPeriodicidadeList(false, false), tipoPeriodicidadeNull).fatorConversaoMensal 
            : 1

    valorMensal = round(valorMensal / fatorConversaoMensal, 2)

    return valorMensal
}

export const calculaValorAnual = (valor, tipoPeriodicidade) => {

    return round(valor * (12 / pesquisaList(tipoPeriodicidadeList(false, false), tipoPeriodicidade).fatorConversaoMensal), 2)
}



export const calculaRound2NULLZero = (valorNULL) => {

    return valorNULL ? round(valorNULL, 2) : 0
}

export const converteMesesTipoPeriodicidade = (meses, tipoPeriodicidade) => {
    
    return Math.trunc(meses / pesquisaList(tipoPeriodicidadeList(false, false), tipoPeriodicidade).fatorConversaoMensal) +
        (meses % pesquisaList(tipoPeriodicidadeList(false, false), tipoPeriodicidade).fatorConversaoMensal > 0 ? 1 : 0)
}
  
export const calculaTempoAcumulado = (values, tipoPeriodicidade, mesInicialNull, anoInicial, mesFinalNull, anoFinal) => {
    
    let anoAtual = ano(values.dataSimulacaoEstudo)
    let mesAtual = mes(values.dataSimulacaoEstudo)

    let meses

    let mesInicialAjustado
    let anoInicialAjustado
    let mesFinalAjustado = mesFinalNull ? mesFinalNull : 12

    if (anoFinal < anoAtual ||
        anoInicial > anoFinal) {

        meses = 0

    } else {

        if (anoInicial < anoAtual) {

            mesInicialAjustado = mesAtual
            anoInicialAjustado = anoAtual

        } else {

            if (mesInicialNull && mesInicialNull < mesAtual && anoInicial === anoAtual) {

                mesInicialAjustado = mesAtual

            } else {

                if (!mesInicialNull) {
                    
                    if (anoInicial === anoAtual) {

                        mesInicialAjustado = mesAtual

                    } else {

                        mesInicialAjustado = 1
                    }

                } else {

                    mesInicialAjustado = mesInicialNull
                }
            }

            anoInicialAjustado = anoInicial
        }

        if (anoInicialAjustado === anoFinal && mesInicialAjustado > mesFinalAjustado ) {

            meses = 0

        } else {

            if (anoInicialAjustado === anoFinal) {
                
                meses = mesFinalAjustado - mesInicialAjustado + 1

            } else {

                meses = (12 - mesInicialAjustado + 1) + mesFinalAjustado + ((anoFinal - anoInicialAjustado - 1) * 12)
            }
        }
    }

    return converteMesesTipoPeriodicidade(meses, tipoPeriodicidade)
}

export const calculaValorAcumulado = (values, valor, tipoPeriodicidade, mesInicialNull, anoInicial, mesFinalNull, anoFinal) => {

    return valor * calculaTempoAcumulado(values, tipoPeriodicidade, mesInicialNull, anoInicial, mesFinalNull, anoFinal)
}

export const calculaTempoMesAnoInicialFinal = (values, tipoPeriodicidade, mesAnoInicial, mesAnoFinal) => {

    return calculaTempoAcumulado(values, tipoPeriodicidade,
        mesMesAno(mesAnoInicial), anoMesAno(mesAnoInicial), mesMesAno(mesAnoFinal), anoMesAno(mesAnoFinal))
}

export const calculaJurosMensal = (valorAporteRetirada, tipoPeriodicidade, saldo, mesInicialNull, anoInicial, mesFinalNull,
    anoFinal, anoCalc, values, estudo, taxaJurosAnual, taxaJurosAnualAposAposentadoria,
        qtdeParcelaTabelaSACNULL, taxaAnualTabelaSACConsorcioEtcNULL, mesAnoPrimeiroReajusteConsorcioEtcNULL,
            evolucaoReservaFinanceiraEstudoNULL, fluxoCaixaMensalNULL, index) => {

    //let mesFinalTabelaSAC
    let mesInicialTabelaSAC
    let qtdeParcelasTabelaSAC
    let valorAporteRetiradaCalc

    if (!mesInicialNull) {

        if (anoInicial === estudo.current.anoAtual && anoInicial === anoCalc) {

            mesInicialNull = estudo.current.mesAtual

        } else {

            mesInicialNull = 1
        }
    }

    if (!mesFinalNull) {

        mesFinalNull = 12

    }

    let mesAux

    if (anoCalc === anoInicial) {

        mesAux = mesInicialNull

    } else {
        
        mesAux = 1
    }

    let mesFinal

    if (anoCalc === anoFinal) {

        mesFinal = mesFinalNull

    } else {

        mesFinal = 12
    }

    if (qtdeParcelaTabelaSACNULL) {

        mesInicialTabelaSAC = mesAux
        
        //if (anoCalc === anoFinal) {

        //    mesFinalTabelaSAC = mesFinal ? mesFinal : 12

        //} else {

        //    mesFinalTabelaSAC = 12
        //}
    }

    let saldoAux = saldo

    let rendimentoMensal
    let rendimentoMensalTotal = 0

    const mesesFluxoCaixa = calculaMesesFluxoCaixa(tipoPeriodicidade, mesInicialNull, estudo.current.mesAtual) 

    while (mesAux <= mesFinal) {

        let linhaFluxoCaixaMensalNULL
        let idFluxoCaixaMensalNULL = formataMesAnoComBarra(mesAux, anoCalc)

        if (fluxoCaixaMensalNULL) {

            linhaFluxoCaixaMensalNULL = pesquisaList(fluxoCaixaMensalNULL, idFluxoCaixaMensalNULL)

            if (!linhaFluxoCaixaMensalNULL.id) {

                fluxoCaixaMensalNULL.push(
                    {
                        id: idFluxoCaixaMensalNULL,
                        valor: 0,
                        saldo: 0,
                    }
                )

                linhaFluxoCaixaMensalNULL = fluxoCaixaMensalNULL[fluxoCaixaMensalNULL.length - 1]
            }
        }

        if (temFluxoCaixa(tipoPeriodicidade, mesesFluxoCaixa, mesAux)) {

            if (qtdeParcelaTabelaSACNULL) {

                qtdeParcelasTabelaSAC = qtdeParcelaTabelaSACNULL -
                    calculaQtdeParcelas(
                        mesInicialNull ? mesInicialNull : anoInicial === anoCalc ? estudo.current.mesAtual : 1,
                            anoInicial, mesInicialTabelaSAC/*mesFinalTabelaSAC*/, anoCalc) + 1
                
                valorAporteRetiradaCalc =
                    calculaValorParcelaTabelaSAC(valorAporteRetirada, qtdeParcelasTabelaSAC, taxaAnualTabelaSACConsorcioEtcNULL)

                //mesFinalTabelaSAC--
                mesInicialTabelaSAC++

            } else {

                valorAporteRetiradaCalc = valorAporteRetirada

                if (mesAnoPrimeiroReajusteConsorcioEtcNULL) {
                
                    valorAporteRetiradaCalc = calculaReajusteConsorcioEtc(valorAporteRetiradaCalc, taxaAnualTabelaSACConsorcioEtcNULL,
                        mesAnoPrimeiroReajusteConsorcioEtcNULL, mesAux, anoCalc)
                }
            }

            if (evolucaoReservaFinanceiraEstudoNULL) {

                if (valorAporteRetiradaCalc > 0) {

                    evolucaoReservaFinanceiraEstudoNULL[index].aporteTotal = 
                        (evolucaoReservaFinanceiraEstudoNULL[index].aporteTotal
                            ?
                                evolucaoReservaFinanceiraEstudoNULL[index].aporteTotal
                            :
                                0
                        ) + valorAporteRetiradaCalc

                } else {

                    evolucaoReservaFinanceiraEstudoNULL[index].retiradaTotal = 
                        (evolucaoReservaFinanceiraEstudoNULL[index].retiradaTotal
                            ?
                                evolucaoReservaFinanceiraEstudoNULL[index].retiradaTotal
                            :
                                0
                        ) + valorAporteRetiradaCalc
                }

            }

            saldoAux += valorAporteRetiradaCalc
        }

        rendimentoMensal = saldoAux *
            (Math.pow(1 +
                (
                    (anoCalc > values.anoFinalVaiAposentarCliente
                        ?
                            taxaJurosAnualAposAposentadoria
                        :
                            taxaJurosAnual
                    ) / 100
                ), 1 / 12) - 1
            )

        rendimentoMensalTotal += rendimentoMensal

        saldoAux += rendimentoMensal

        if (fluxoCaixaMensalNULL) {

            linhaFluxoCaixaMensalNULL.valor += valorAporteRetiradaCalc
            linhaFluxoCaixaMensalNULL.saldo += round(saldoAux, 2)
        }

        mesAux++
    }

    saldoAux = round(saldoAux, 2)

    return { rendimentoMensalTotal, saldoAux }
}

export const calculaJurosMensalSaldo = (valorAporteRetirada, tipoPeriodicidade, saldo, mesInicialNull, anoInicial, mesFinalNull,
    anoFinal, anoCalc, values, estudo, taxaJurosAnual, taxaJurosAnualAposAposentadoria,
        qtdeParcelaTabelaSACNULL, taxaAnualTabelaSACConsorcioEtcNULL, mesAnoPrimeiroReajusteConsorcioEtcNULL,
            evolucaoReservaFinanceiraEstudoNULL, fluxoCaixaMensalNULL, index) => {

    const { saldoAux } =
        calculaJurosMensal(valorAporteRetirada, tipoPeriodicidade, saldo, mesInicialNull, anoInicial, mesFinalNull,
            anoFinal, anoCalc, values, estudo, taxaJurosAnual, taxaJurosAnualAposAposentadoria,
                qtdeParcelaTabelaSACNULL, taxaAnualTabelaSACConsorcioEtcNULL, mesAnoPrimeiroReajusteConsorcioEtcNULL,
                    evolucaoReservaFinanceiraEstudoNULL, fluxoCaixaMensalNULL, index)

    return saldoAux
}



export const calculaAnoIdadeInformada = ( values, idadeInformada, idadeFamiliar) => {

    let anoAtual = ano(values.dataSimulacaoEstudo)

    return anoAtual + idadeInformada - idadeFamiliar
}

export const calculaIntervaloTempoAnos = (values, anoFinal, anoAtualNull) => {

    const anoAtual = anoAtualNull ? anoAtualNull : ano(values.dataSimulacaoEstudo)

    return !anoFinal ? '' : anoFinal - anoAtual + 1 
}

export const calculaIntervaloTempoAnosMorteAteVitalicio = (values) => {

    const anoAtual = ano(values.dataSimulacaoEstudo)

    return values.anoFinalClienteConjuge - anoAtual + 1 
}

export const calculaIntervaloTempoAnosMesesMorteAteVitalicio = (values) => {

    let anos = values.anoFinalClienteConjuge - ano(values.dataSimulacaoEstudo)

    let meses = null

    if (mes(values.dataSimulacaoEstudo) === 1) {

        anos++

    } else {

        meses = 12 - mes(values.dataSimulacaoEstudo) + 1

    }

    return anos + ' ' + LABELESPECIAL.anos + (meses ? LABEL.e + meses + ' ' + (meses === 1 ? LABELESPECIAL.mes : LABELESPECIAL.meses) : '') 
}


export const calculaIdadeDataInformada = ( dataInformada, dataNascimento, idade) => {

    let idadeDataInformada = idade

    if (dataInformada && dataValida(dataNascimento)) {
        
        idadeDataInformada = difDatasStringAnos (dataNascimento, dataInformada)
    }

    return idadeDataInformada
}

export const calculaIdadeExpectativaVida = ( idade, sexo) => {
    let idadeExpectativaVida = ''

    if ((idade === 0 || idade) && sexo) {
        const linhaExpectativaVidaList = pesquisaList(expectativaVidaList, idade)

        if (sexo === HARDCODE.idSexoMasculino) {
            idadeExpectativaVida = linhaExpectativaVidaList.idadeExpectativaVidaHomem
        }

        if (sexo === HARDCODE.idSexoFeminino) {
            idadeExpectativaVida = linhaExpectativaVidaList.idadeExpectativaVidaMulher
        }
    }

    return idadeExpectativaVida
}

export const calculaIdadeVaiAposentar = ( sexo ) => {
    let idadeVaiAposentar = ''

    if (sexo === HARDCODE.idSexoMasculino) {
        idadeVaiAposentar = HARDCODE.idadeVaiAposentarHomem
    }

    if (sexo === HARDCODE.idSexoFeminino) {
        idadeVaiAposentar = HARDCODE.idadeVaiAposentarMulher
    }

    return idadeVaiAposentar
}

export const anoFinalVaiAposentarInicioInss = ( values, idadeVaiAposentarInicioInss,
    idadeDataSimulacao ) => {

    return ano(values.dataSimulacaoEstudo) + idadeVaiAposentarInicioInss - idadeDataSimulacao
}

export const anoFinalClienteConjuge = ( values, anoFinalSimulacaoMorte, anoFinalVitalicio ) => {

    return values.morteInvalidezEstudo === "A"
        ?
            anoFinalSimulacaoMorte
        :
            anoFinalVitalicio
}

export const calculaIdadesDataSimulacao = ( values ) => {

    values.idadeDataSimulacaoCliente = 
        calculaIdadeDataInformada(values.dataSimulacaoEstudo, values.nascimentoCliente, values.idadeCliente)

    values.idadeDataSimulacaoConjuge = 
        calculaIdadeDataInformada(values.dataSimulacaoEstudo, values.nascimentoConjuge, values.idadeConjuge)

    if (!values.alterarIdadesAutomaticasCliente) {

        values.idadeVaiAposentarCliente = calculaIdadeVaiAposentar(values.sexoCliente)

        if (values.idadeVaiAposentarCliente <= values.idadeDataSimulacaoCliente) {

            values.idadeVaiAposentarCliente = values.idadeDataSimulacaoCliente - 1
        }
        
        values.idadeInicioInssCliente = values.idadeVaiAposentarCliente + 1

        values.idadeExpectativaVidaCliente =
            calculaIdadeExpectativaVida(values.idadeDataSimulacaoCliente, values.sexoCliente)

        values.idadeSimulacaoMorteCliente = values.idadeVaiAposentarCliente

        if (
                values.alterarIdadesAutomaticasConjuge &&
                values.idadeExpectativaVidaConjuge > calculaIdadeExpectativaVida(values.idadeDataSimulacaoConjuge, values.sexoConjuge)
        ) {

            values.idadeExpectativaVidaCliente = values.idadeExpectativaVidaConjuge -
                values.idadeDataSimulacaoConjuge + values.idadeDataSimulacaoCliente

            values.idadeExpectativaVidaCliente =
                Math.max(values.idadeExpectativaVidaCliente, calculaIdadeExpectativaVida(values.idadeDataSimulacaoCliente, values.sexoCliente))
        }
    }
    
    if (!values.alterarIdadesAutomaticasConjuge) {
        
        values.idadeVaiAposentarConjuge = calculaIdadeVaiAposentar(values.sexoConjuge)

        if (values.idadeVaiAposentarConjuge <= values.idadeDataSimulacaoConjuge) {

            values.idadeVaiAposentarConjuge = values.idadeDataSimulacaoConjuge - 1
        }

        values.idadeInicioInssConjuge = values.idadeVaiAposentarConjuge + 1

        values.idadeExpectativaVidaConjuge = 
                calculaIdadeExpectativaVida(values.idadeDataSimulacaoConjuge, values.sexoConjuge)

        if (
                values.alterarIdadesAutomaticasCliente &&
                values.idadeExpectativaVidaCliente > calculaIdadeExpectativaVida(values.idadeDataSimulacaoCliente, values.sexoCliente)
        ) {

            values.idadeExpectativaVidaConjuge = values.idadeExpectativaVidaCliente -
                values.idadeDataSimulacaoCliente + values.idadeDataSimulacaoConjuge

            values.idadeExpectativaVidaConjuge =
                Math.max(values.idadeExpectativaVidaConjuge, calculaIdadeExpectativaVida(values.idadeDataSimulacaoConjuge, values.sexoConjuge))
        }
    }

    values.idadeSimulacaoMorteConjuge = values.idadeDataSimulacaoConjuge + values.idadeSimulacaoMorteCliente -
        values.idadeDataSimulacaoCliente

    values.anoFinalVaiAposentarCliente =
        anoFinalVaiAposentarInicioInss (values, values.idadeVaiAposentarCliente,
            values.idadeDataSimulacaoCliente)

    values.anoInicioInssCliente =
        anoFinalVaiAposentarInicioInss (values, values.idadeInicioInssCliente,
            values.idadeDataSimulacaoCliente)

    values.anoFinalVaiAposentarConjuge =
        anoFinalVaiAposentarInicioInss (values, values.idadeVaiAposentarConjuge,
            values.idadeDataSimulacaoConjuge)

    values.anoInicioInssConjuge =
        anoFinalVaiAposentarInicioInss (values, values.idadeInicioInssConjuge,
            values.idadeDataSimulacaoConjuge)



    values.anoFinalVitalicioCliente = ano(values.dataSimulacaoEstudo) + 
        values.idadeExpectativaVidaCliente - values.idadeDataSimulacaoCliente

    values.anoFinalVitalicioConjuge = ano(values.dataSimulacaoEstudo) + 
        (dadosCompletosConjuge(values)
            ?
                values.idadeExpectativaVidaConjuge - values.idadeDataSimulacaoConjuge
            :
                0
        )

    values.anoFinalVitalicioClienteConjuge =
        Math.max(values.anoFinalVitalicioCliente, values.anoFinalVitalicioConjuge)

    values.idadeFinalVitalicioClienteConjuge = values.idadeDataSimulacaoCliente +
        (values.anoFinalVitalicioClienteConjuge - ano(values.dataSimulacaoEstudo))



    values.anoFinalSimulacaoMorteCliente = ano(values.dataSimulacaoEstudo) +
        values.idadeSimulacaoMorteCliente - values.idadeDataSimulacaoCliente

    values.anoFinalSimulacaoMorteConjuge = ano(values.dataSimulacaoEstudo) +
        (dadosCompletosConjuge(values)
            ?
                values.idadeSimulacaoMorteConjuge - values.idadeDataSimulacaoConjuge
            :
                0
        )

    values.anoFinalSimulacaoMorteClienteConjuge = 
        Math.max(values.anoFinalSimulacaoMorteCliente, values.anoFinalSimulacaoMorteConjuge)



    values.anoFinalCliente =
        anoFinalClienteConjuge(values, values.anoFinalSimulacaoMorteCliente, values.anoFinalVitalicioCliente)

    values.anoFinalConjuge =
        anoFinalClienteConjuge(values, values.anoFinalSimulacaoMorteConjuge, values.anoFinalVitalicioConjuge)

    values.anoFinalClienteConjuge = anoFinalClienteConjuge(values,
        values.anoFinalSimulacaoMorteClienteConjuge, values.anoFinalVitalicioClienteConjuge)

    values.familiares?.forEach( (linhaFamiliares, index) => {
        values.familiares[index].idadeFamiliarDataSimulacao = 
            calculaIdadeDataInformada(values.dataSimulacaoEstudo, linhaFamiliares.nascimentoFamiliar,
                linhaFamiliares.idadeFamiliar)

        values.familiares[index].idadeExpectativaVidaFamiliar =
            calculaIdadeExpectativaVida(values.familiares[index].idadeFamiliarDataSimulacao, linhaFamiliares.sexoFamiliar)
    })
}