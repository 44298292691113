import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    dadosCompletosLinhaSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'



export const incrementaContSeguroVidaCotacao = ( values ) => {

    if (!values.contSeguroVidaCotacao) {

        values.contSeguroVidaCotacao = 0
    }

    values.contSeguroVidaCotacao = values.contSeguroVidaCotacao + 1
}



export const processaValoresDefaultIniciaisSeguroVidaCotacao = ( item ) => {

    if (!item.tipoPeriodicidadePagtoPremio) {

        item.tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeMensal
    }

    if (!item.dataCotacao) {

        item.dataCotacao = null
    }
}



export const dadosCompletosSeguroVidaCotacao = ( values ) => {

    let dadosCompletosSeguroVidaCotacaoAux = true

    values.seguroVidaCotacao?.forEach( (linhaSeguroVidaCotacao, index) => {
        
        if (!dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)) {
            dadosCompletosSeguroVidaCotacaoAux = false
        }
    })

    return dadosCompletosSeguroVidaCotacaoAux
}



export const existeTabelaEvolucaoValorResgate = (values, familiar) => {

    let existe = false

    if (values.seguroVidaCotacao) {

        values.seguroVidaCotacao?.forEach( (linhaSeguroVidaCotacao, indexSeguroVidaCotacao) => {

            if (
                linhaSeguroVidaCotacao.tabelaEvolucaoValorResgate &&
                linhaSeguroVidaCotacao.tabelaEvolucaoValorResgate.length > 0 &&
                (
                    !familiar ||
                    linhaSeguroVidaCotacao.familiar === familiar
                ) &&
                dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)
            ) {

                existe = true
            }
        })
    }

    return existe
}



export const possuiCotacaoSeguroVidaApenasComparacaoPreco = (values, seguradora, utilizarApenasComparacaoPreco) => {

    let possui = false

    if (values.seguroVidaCotacao) {

        values.seguroVidaCotacao?.forEach( (linhaSeguroVidaCotacao, indexSeguroVidaCotacao) => {

            if (
                    linhaSeguroVidaCotacao.seguradora === seguradora &&
                    (
                        (
                                utilizarApenasComparacaoPreco &&
                                linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco
                        ) ||
                        (
                                !utilizarApenasComparacaoPreco &&
                                !linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco
                        )
                    ) &&
                    dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)
            ) {

                possui = true
            }
        })
    }

    return possui

}