const tipoCoberturaSeguroVidaList = [
    {
        id: 10, // HARDCODE.idTipoCoberturaSeguroVidaAcidental
        descricao: 'Acidental',
        ordem: 10,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -1,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",

    },
    {
        id: 20, // HARDCODE.idTipoCoberturaSeguroVidaDotal
        descricao: 'Dotal',
        ordem: 20,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "S",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 25, // HARDCODE.idTipoCoberturaSeguroVidaDotalMisto
        descricao: 'Dotal Misto',
        ordem: 25,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "S",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 30,
        descricao: 'Pecúlio',
        ordem: 30,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "N",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 40,
        descricao: 'Renda',
        ordem: 40,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "Renda",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "N",
        informaTipoPrazoRecebtoRenda: "S",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 45, // HARDCODE.idTipoCoberturaSeguroVidaRendaFamiliar
        descricao: 'Renda Familiar',
        ordem: 45,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 50, // HARDCODE.idTipoCoberturaSeguroVidaTemporario
        descricao: 'Temporário',
        ordem: 50,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 60, // HARDCODE.idTipoCoberturaSeguroVidaTemporarioDecrescente
        descricao: 'Temporário Decrescente',
        ordem: 60,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 65, // HARDCODE.idTipoCoberturaSeguroVidaTradicional
        descricao: 'Tradicional',
        ordem: 65,
        grupoCoberturaSeguroVida: 0, // Morte
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 70, // HARDCODE.idTipoCoberturaSeguroVidaVidaInteira
        descricao: 'Vida Inteira',
        ordem: 70,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -3,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 71, // HARDCODE.idTipoCoberturaSeguroVidaVidaInteiraDescrescente
        descricao: 'Vida Inteira Decrescente',
        ordem: 71,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -3,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 73, // HARDCODE.idTipoCoberturaSeguroVidaValorSaldado
        descricao: 'Valor Saldado',
        ordem: 73,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 76, // HARDCODE.idTipoCoberturaSeguroVidaBeneficioProlongado
        descricao: 'Benefício Prolongado',
        ordem: 76,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 80, // HARDCODE.idTipoCoberturaSeguroVidaEmGrupoMorte
        descricao: 'Vida em Grupo',
        ordem: 80,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "S",
        cobreInvalidez: "N",
    },
    {
        id: 90, // HARDCODE.idTipoCoberturaSeguroVidaEmGrupoMorteAcidental
        descricao: 'Vida em Grupo - Acidental',
        ordem: 90,
        grupoCoberturaSeguroVida: 0, // Morte
        grupoCoberturaSeguroVidaDetalhado: -3,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 100, // HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial
        descricao: 'Total ou Parcial',
        ordem: 100,
        grupoCoberturaSeguroVida: 1, // Invalidez por Acidente
        grupoCoberturaSeguroVidaDetalhado: 1,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 103,
        descricao: 'Total',
        ordem: 103,
        grupoCoberturaSeguroVida: 1, // Invalidez por Acidente
        grupoCoberturaSeguroVidaDetalhado: 1,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 105, // HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial
        descricao: 'Parcial',
        ordem: 105,
        grupoCoberturaSeguroVida: 1, // Invalidez por Acidente
        grupoCoberturaSeguroVidaDetalhado: 1,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 110, // HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteRenda
        descricao: 'Renda',
        ordem: 110,
        grupoCoberturaSeguroVida: 1, // Invalidez por Acidente
        grupoCoberturaSeguroVidaDetalhado: 1,
        tipoValorInformado: "Renda",
        informaCSSobrevivencia: "N",
        //informaDataTerminoCobertura: "N",
        //informaTipoPrazoRecebtoRenda: "V",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 120, // HARDCODE.idTipoCoberturaSeguroVidaEmGrupoInvalidezPorAcidente
        descricao: 'Vida em Grupo - Acidente',
        ordem: 120,
        grupoCoberturaSeguroVida: 1, // Invalidez por Acidente
        grupoCoberturaSeguroVidaDetalhado: 1,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 130, // HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca
        descricao: 'Funcional por Doença',
        ordem: 130,
        grupoCoberturaSeguroVida: 2, // Invalidez por Doença
        grupoCoberturaSeguroVidaDetalhado: 2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "N",
        informaDataTerminoCoberturaOpcional: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 135, // HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca
        descricao: 'Permanente por Doença',
        ordem: 135,
        grupoCoberturaSeguroVida: 2, // Invalidez por Doença
        grupoCoberturaSeguroVidaDetalhado: 2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "N",
        informaDataTerminoCoberturaOpcional: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 140, // HARDCODE.idTipoCoberturaSeguroVidaEmGrupoInvalidezFuncionalPorDoenca
        descricao: 'Vida em Grupo - Funcional por Doença',
        ordem: 140,
        grupoCoberturaSeguroVida: 2, // Invalidez por Doença
        grupoCoberturaSeguroVidaDetalhado: 2,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 150, // HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves
        descricao: 'Doenças Graves',
        ordem: 150,
        grupoCoberturaSeguroVida: 3, // Doenças
        grupoCoberturaSeguroVidaDetalhado: 3,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 153, // HARDCODE.idTipoCoberturaSeguroVidaCirurgia
        descricao: 'Cirurgia',
        ordem: 153,
        grupoCoberturaSeguroVida: 3, // Doenças
        grupoCoberturaSeguroVidaDetalhado: 3,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 156, // HARDCODE.idTipoCoberturaSeguroVidaQuebraOssos
        descricao: 'Quebra de Ossos',
        ordem: 156,
        grupoCoberturaSeguroVida: 3, // Doenças
        grupoCoberturaSeguroVidaDetalhado: 3,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 160, // HARDCODE.idTipoCoberturaSeguroVidaDIH
        descricao: 'Diária Internação Hospitalar - DIH',
        ordem: 191,
        grupoCoberturaSeguroVida: 4, // Incapacidade Temp.
        grupoCoberturaSeguroVidaDetalhado: 4,
        tipoValorInformado: "Diaria",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 162, // HARDCODE.idTipoCoberturaSeguroVidaAdicionalUTI
        descricao: 'Adicional UTI',
        ordem: 192,
        grupoCoberturaSeguroVida: 4, // Incapacidade Temp.
        grupoCoberturaSeguroVidaDetalhado: 4,
        tipoValorInformado: "Diaria",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 164, // HARDCODE.idTipoCoberturaSeguroVidaFeridasAcidentais
        descricao: 'Feridas Acidentais',
        ordem: 194,
        grupoCoberturaSeguroVida: 4, // Incapacidade Temp.
        grupoCoberturaSeguroVidaDetalhado: 4,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 166, // HARDCODE.idTipoCoberturaSeguroVidaReembolsoAmbulancia
        descricao: 'Reembolso Ambulância',
        ordem: 196,
        grupoCoberturaSeguroVida: 4, // Incapacidade Temp.
        grupoCoberturaSeguroVidaDetalhado: 4,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 168, // HARDCODE.idTipoCoberturaSeguroVidaReembolsoEmergencia
        descricao: 'Reembolso Emergência',
        ordem: 198,
        grupoCoberturaSeguroVida: 4, // Incapacidade Temp.
        grupoCoberturaSeguroVidaDetalhado: 4,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 170, // HARDCODE.idTipoCoberturaSeguroVidaDIT
        descricao: 'Perda de Renda - DIT',
        ordem: 170,
        grupoCoberturaSeguroVida: 4, // Incapacidade Temp.
        grupoCoberturaSeguroVidaDetalhado: 4,
        tipoValorInformado: "Diaria",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",//"N",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 180, // HARDCODE.idTipoCoberturaSeguroVidaDITA
        descricao: 'Perda de Renda - DITA',
        ordem: 180,
        grupoCoberturaSeguroVida: 4, // Incapacidade Temp.
        grupoCoberturaSeguroVidaDetalhado: 4,
        tipoValorInformado: "Diaria",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",//"N",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 190, // HARDCODE.idTipoCoberturaSeguroVidaSERIT
        descricao: 'Perda de Renda - SERIT',
        ordem: 190,
        grupoCoberturaSeguroVida: 4, // Incapacidade Temp.
        grupoCoberturaSeguroVidaDetalhado: 4,
        tipoValorInformado: "Diaria",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",//"N",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "S",
    },
    {
        id: 200, // HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral
        descricao: 'Assistência Funeral',
        ordem: 200,
        grupoCoberturaSeguroVida: 5, // Funeral
        grupoCoberturaSeguroVidaDetalhado: 5,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "N",
        informaDataTerminoCoberturaOpcional: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 210, // HARDCODE.idTipoCoberturaSeguroVidaEmGrupoFuneral
        descricao: 'Vida em Grupo',
        ordem: 210,
        grupoCoberturaSeguroVida: 5, // Funeral
        grupoCoberturaSeguroVidaDetalhado: 5,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 310, // HARDCODE.idTipoCoberturaSeguroVidaAssistenciaAutoMoto
        descricao: 'Auto e Moto',
        ordem: 310,
        grupoCoberturaSeguroVida: 6, // Assistências
        grupoCoberturaSeguroVidaDetalhado: 6,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 320, // HARDCODE.idTipoCoberturaSeguroVidaAssistenciaResidencial
        descricao: 'Residencial',
        ordem: 320,
        grupoCoberturaSeguroVida: 6, // Assistências
        grupoCoberturaSeguroVidaDetalhado: 6,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 330, // HARDCODE.idTipoCoberturaSeguroVidaAssistenciaDescontoMedicamentos
        descricao: 'Desconto em Medicamentos',
        ordem: 330,
        grupoCoberturaSeguroVida: 6, // Assistências
        grupoCoberturaSeguroVidaDetalhado: 6,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
    {
        id: 340, // HARDCODE.idTipoCoberturaSeguroVidaAssistenciaTelessaude
        descricao: 'Telessaúde',
        ordem: 340,
        grupoCoberturaSeguroVida: 6, // Assistências
        grupoCoberturaSeguroVidaDetalhado: 6,
        tipoValorInformado: "CS",
        informaCSSobrevivencia: "N",
        informaDataTerminoCobertura: "S",
        informaTipoPrazoRecebtoRenda: "N",
        cobreMorte: "N",
        cobreInvalidez: "N",
    },
]

export default tipoCoberturaSeguroVidaList