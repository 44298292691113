const grupoCoberturaSeguroVidaDetalhadoList = [
    {
        id: -3,
        descricao: 'Morte Vitalícia',
        ordem: -3,
    },
    {
        id: -2,
        descricao: 'Morte Temporária',
        ordem: -2,
    },
    {
        id: -1,
        descricao: 'Morte Acidental',
        ordem: -1,
    },
    {
        id: 1,
        descricao: 'Invalidez por Acidente',
        ordem: 1,
    },
    {
        id: 2,
        descricao: 'Invalidez por Doença',
        ordem: 2,
    },
    {
        id: 3,
        descricao: 'Doenças',
        ordem: 3,
    },
    {
        id: 4,
        descricao: 'Incapacidade Temp.',
        ordem: 4,
    },
    {
        id: 5,
        descricao: 'Funeral',
        ordem: 5,
    },
    {
        id: 6,
        descricao: 'Assistências',
        ordem: 6,
    },
]

export default grupoCoberturaSeguroVidaDetalhadoList