// alterado

import axios from 'axios'

import HARDCODE from '../business-const/HardCode/HardCode'

import AuthService from './AuthService'

import {
    usuarioInfo,
} from './UsuarioService'



export const httpClient = axios.create({
    baseURL: HARDCODE.conexao.apiUrl,
    withCredentials: true,
    //
    //mode: 'no-cors',
    //headers: {
    //    'Access-Control-Allow-Origin': '*',
    //    'Access-Control-Allow-Headers': '*',
    //    'Access-Control-Allow-Credentials': 'true',
    //    'Content-Type': 'application/json',
    //},
    //credentials: 'same-origin',
})



class ApiService {

    constructor(apiurl){
        this.apiurl = apiurl;
    }

    registrarToken(variaveisGlobais, naoTemToken) {

        //const token = AuthService.obterToken(variaveisGlobais)

        //if (!httpClient.defaults.headers.common['Access-Control-Allow-Origin'] && token) {

        //    httpClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
        //    httpClient.defaults.headers.common['Access-Control-Allow-Headers'] = '*'
        //    httpClient.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true'
        //    httpClient.defaults.headers.common['Content-Type'] = 'application/json'
        //}

        //if (!httpClient.defaults.headers.common['Authorization'] && token && !naoTemToken) {

            //httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
        //}

        if (naoTemToken) {

            httpClient.defaults.headers.common['Authorization'] = ''
            httpClient.defaults.headers.common['Access-Control-Allow-Origin'] = ''
            httpClient.defaults.headers.common['Access-Control-Allow-Methods'] = ''

        } else {

            httpClient.defaults.headers.common['Authorization'] = `Bearer ${AuthService.obterToken(variaveisGlobais)}`
            httpClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
            httpClient.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,POST,OPTIONS,DELETE,PUT'
        }
    }

    tokenOK(variaveisGlobais) {

        if (AuthService.obterToken(variaveisGlobais)) {
            
            return !AuthService.usuarioPrecisaLogarNovamente(variaveisGlobais)

        } else {

            return true
        }
    }

    post(variaveisGlobais, url, objeto, naoTemToken){

        this.registrarToken(variaveisGlobais, naoTemToken)

        const requestUrl = `${this.apiurl}${url}`

        return this.tokenOK(variaveisGlobais) ? httpClient.post(requestUrl, objeto) : false
    }

    put(variaveisGlobais, url, objeto, naoTemToken){

        this.registrarToken(variaveisGlobais, naoTemToken)

        const requestUrl = `${this.apiurl}${url}`

        return this.tokenOK(variaveisGlobais) ? httpClient.put(requestUrl, objeto) : false
    }

    delete(variaveisGlobais, url, naoTemToken){

        this.registrarToken(variaveisGlobais, naoTemToken)

        const requestUrl = `${this.apiurl}${url}`

        return this.tokenOK(variaveisGlobais) ? httpClient.delete(requestUrl) : false
    }

    get(variaveisGlobais, url, naoTemToken){

        this.registrarToken(variaveisGlobais, naoTemToken)

        const requestUrl = `${this.apiurl}${url}`

        return this.tokenOK(variaveisGlobais) ? httpClient.get(requestUrl) : false
    }
 
    getParams(variaveisGlobais, url, params, naoTemToken){

        this.registrarToken(variaveisGlobais, naoTemToken)

        const requestUrl = `${this.apiurl}${url}`

        return this.tokenOK(variaveisGlobais) ? httpClient.get(requestUrl, params) : false
    }

    async calculaParamsFiltroList(variaveisGlobais, campoAgrupamentoNULL, filtroList, incluirUsuarioSelecionadoList) {

        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        if (incluirUsuarioSelecionadoList) {
        
            params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioSelecionadoList(variaveisGlobais)}`
        }

        if (campoAgrupamentoNULL) {
        
            params = `${params}&campoAgrupamento=${campoAgrupamentoNULL}`
        }

        params = `${params}&jsonFiltroList=${encodeURIComponent(JSON.stringify(filtroList))}`

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return { params, paramsAux }
    }
}


export default ApiService;