import HARDCODE from '../../../business-const/HardCode/HardCode'

import familiaresList from '../../../business-rules/List/familiaresList'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import parentescoList from '../../../business-rules/List/parentescoList'

import tipoEducacaoList from '../../../business-rules/List/tipoEducacaoList'

import {
    calculaValorAcumulado,
    calculaAnoIdadeInformada,
} from '../cliente-form/ClienteService'

import {
    idadeFamiliarDataSimulacao,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'




export const incrementaContEducacao = ( values ) => {

    if (!values.contEducacao) {

        values.contEducacao = 0
    }

    values.contEducacao++
}



export const processaRegistrosDefaultIniciaisEducacao = ( values ) => {

    let idade = ''

    if (!values.familiaresProcessouRegistrosDefaultIniciaisEducacao) {
        values.familiaresProcessouRegistrosDefaultIniciaisEducacao = []
    }

    if (!values.educacao) {
        values.educacao = []
    }
    
    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {

        if (
                !pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisEducacao, linhaFamiliaresList.id).id &&
                //(
                //!linhaFamiliaresList.parentesco ||
                linhaFamiliaresList.parentesco &&
                pesquisaList(parentescoList(true), linhaFamiliaresList.parentesco).gerarEducacao === 'S'
                //)
        ) {

            values.familiaresProcessouRegistrosDefaultIniciaisEducacao.push(
                {
                    id: linhaFamiliaresList.id
                }
            )

            idade = idadeFamiliarDataSimulacao(values, linhaFamiliaresList.id)

            let incluiuEducacaoTipoEducacaoList = false

            tipoEducacaoList?.forEach( (linhaTipoEducacaoList, index) => {
                if (linhaTipoEducacaoList.valorDefault && 
                    idade <= linhaTipoEducacaoList.idadeFinalDefault) {

                    incrementaContEducacao(values)
                    values.educacao.push(
                        {
                            id: values.contEducacao,
                            familiar: linhaFamiliaresList.id,
                            tipoEducacao: linhaTipoEducacaoList.id,
                            tipoPeriodicidadePagto: linhaTipoEducacaoList.tipoPeriodicidadePagtoDefault,
                            valor: linhaTipoEducacaoList.valorDefault,
                            idadeInicial: 
                                idade >= linhaTipoEducacaoList.idadeInicialDefault 
                                    ? 
                                        idade 
                                    : 
                                        linhaTipoEducacaoList.idadeInicialDefault,
                            idadeFinal: linhaTipoEducacaoList.idadeFinalDefault,
                        }
                    )

                    calculaEducacaoGrupoDespesa(values.educacao[values.educacao.length - 1])

                    incluiuEducacaoTipoEducacaoList = true
                }
            })

            if (!incluiuEducacaoTipoEducacaoList) {
                incrementaContEducacao(values)
                values.educacao.push(
                    {
                        id: values.contEducacao,
                        familiar: linhaFamiliaresList.id,
                    }
                )
            }
        }
    })
}



export const calculaEducacaoFilhaoEnteadaoBaseOutraosFilhaosEnteadaos = ( values ) => {

    values.idFilhaoEnteadaoBase = ''

    values.idOutraosFilhaosEnteadaos = []

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {

        if (
                linhaFamiliaresList.parentesco === HARDCODE.idParentescoFilhao ||
                linhaFamiliaresList.parentesco === HARDCODE.idParentescoEnteadao
        ) {
            if (values.idFilhaoEnteadaoBase) {

                let adicionar = false

                values.educacao?.forEach( (linhaEducacao, indexLinhaEducacao) => {

                    if (linhaEducacao.familiar === linhaFamiliaresList.id) {
            
                        values.educacao?.forEach( (linhaEducacaoFilhaoEnteadaoBase, indexLinhaEducacaoFilhaoEnteadaoBase) => {
            
                            if (
                                    linhaEducacaoFilhaoEnteadaoBase.familiar === values.idFilhaoEnteadaoBase &&
                                    linhaEducacaoFilhaoEnteadaoBase.tipoEducacao === linhaEducacao.tipoEducacao &&
                                    (
                                        linhaEducacaoFilhaoEnteadaoBase.tipoPeriodicidadePagto !==
                                            linhaEducacao.tipoPeriodicidadePagto ||
                                        linhaEducacaoFilhaoEnteadaoBase.valor !== linhaEducacao.valor
                                    )
                            ) {
            
                                    adicionar = true
                            }
                        })
                    }
                })

                if (adicionar) {

                    values.idOutraosFilhaosEnteadaos.push({
                        id: linhaFamiliaresList.id
                    })
                }

            } else {

                values.idFilhaoEnteadaoBase = linhaFamiliaresList.id
            }
        }
    })
}



export const processaCopiaEducacaoFilhaoBaseOutraosFilhaos = ( values, familiar ) => {
    values.educacao?.forEach( (linhaEducacao, indexLinhaEducacao) => {

        if (linhaEducacao.familiar === familiar) {

            values.educacao?.forEach( (linhaEducacaoFilhaoEnteadaoBase, indexLinhaEducacaoFilhaoEnteadaoBase) => {

                if (linhaEducacaoFilhaoEnteadaoBase.familiar === values.idFilhaoEnteadaoBase &&
                    linhaEducacaoFilhaoEnteadaoBase.tipoEducacao === linhaEducacao.tipoEducacao) {

                        values.educacao[indexLinhaEducacao].tipoPeriodicidadePagto = 
                            linhaEducacaoFilhaoEnteadaoBase.tipoPeriodicidadePagto
                        values.educacao[indexLinhaEducacao].valor = 
                            linhaEducacaoFilhaoEnteadaoBase.valor
                    }
            })
        }
    })
}



export const processaValoresDefaultIniciaisEducacao = ( item ) => {
    if (!item.temSeguro) {
        item.temSeguro = "N"
    }
}



const calculaEducacaoTipoPeriodicidadePagto = ( item ) => {

    if (!item.tipoPeriodicidadePagto && item.tipoEducacao) {

        item.tipoPeriodicidadePagto = pesquisaList(tipoEducacaoList, item.tipoEducacao).tipoPeriodicidadePagtoDefault
    }
}

const calculaEducacaoGrupoDespesa = ( item ) => {

    if (!item.grupoDespesa && item.tipoEducacao) {
        
        item.grupoDespesa = pesquisaList(tipoEducacaoList, item.tipoEducacao).grupoDespesa
    }
}

export const processaAlteracaoEducacaoTipoEducacao = ( item ) => {

    calculaEducacaoTipoPeriodicidadePagto(item)

    calculaEducacaoGrupoDespesa(item)
}



export const recalculaEducacaoIdadeInicialAlteracaoIdadeFinal = (item, values) => {
    const linhaTipoEducacao = pesquisaList(tipoEducacaoList, item.tipoEducacao)

    values.educacao?.forEach( (linhaEducacao, index) => {

        const linhaTipoEducacaoLoop = pesquisaList(tipoEducacaoList, linhaEducacao.tipoEducacao)

        if (
                linhaEducacao.familiar === item.familiar &&
                pesquisaList(linhaTipoEducacao.alteraIdadeAutomaticamente, linhaEducacao.tipoEducacao).id > 0 &&
                item.idadeFinal + 1 > linhaEducacao.idadeInicial &&
                item.idadeFinal >= item.idadeInicial &&
                item.idadeInicial &&
                linhaEducacao.idadeInicial &&
                linhaEducacao.idadeFinal - linhaEducacao.idadeInicial <=
                    linhaTipoEducacaoLoop.idadeFinalDefault - linhaTipoEducacaoLoop.idadeInicialDefault + 2
        ) {

            let anos = linhaEducacao.idadeFinal - linhaEducacao.idadeInicial

            values.educacao[index].idadeInicial = item.idadeFinal + 1
            values.educacao[index].idadeFinal = values.educacao[index].idadeInicial + anos

            recalculaEducacaoIdadeInicialAlteracaoIdadeFinal(linhaEducacao, values)
        }
    })
}

export const processaAlteracaoEducacaoIdadeFinal = (item, values) => {

    recalculaEducacaoIdadeInicialAlteracaoIdadeFinal(item, values)
}



const calculaEducacaoTipoSegurado = ( item ) => {
    if (item.temSeguro === "N") {
        item.tipoSegurado = null
    }
}

export const processaAlteracaoEducacaoTemSeguro = ( item ) => {
    calculaEducacaoTipoSegurado(item)
}



export const dadosCompletosLinhaEducacao = ( linhaEducacao, inputDados ) => {
    return  linhaEducacao.valor && 
            linhaEducacao.tipoPeriodicidadePagto &&
            (
                linhaEducacao.idadeInicial ||
                linhaEducacao.idadeInicial === 0
            ) &&
            (
                linhaEducacao.idadeFinal ||
                linhaEducacao.idadeFinal === 0
            ) &&
            (
                linhaEducacao.temSeguro !== "S" || 
                linhaEducacao.tipoSegurado ||
                inputDados
            )
}

export const calculaValorAcumuladoEducacao = ( values, linhaEducacao, linhaFamiliaresList ) => {

    return calculaValorAcumulado (values, linhaEducacao.valor, linhaEducacao.tipoPeriodicidadePagto, 
        null, calculaAnoIdadeInformada(values, linhaEducacao.idadeInicial, linhaFamiliaresList.idadeDataSimulacao), 
            null, calculaAnoIdadeInformada(values, linhaEducacao.idadeFinal, linhaFamiliaresList.idadeDataSimulacao))
}

export const calculaTotaisEducacao = ( values ) => {

    const indexStepsValorFluxoCaixa = HARDCODE.indexFluxoCaixaEducacao

    values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] = 0

    values.totalEducacao = []

    let valorAcumulado = 0

    let valorAcumuladoTotal = 0

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        valorAcumuladoTotal = 0

        if (linhaFamiliaresList.idade || linhaFamiliaresList.idade === 0) {
            values.educacao?.forEach( (linhaEducacao, index) => {

                if (linhaEducacao.familiar === linhaFamiliaresList.id) {

                    if (dadosCompletosLinhaEducacao(linhaEducacao, true)) {

                        valorAcumulado = calculaValorAcumuladoEducacao (values, linhaEducacao, linhaFamiliaresList )

                        values.educacao[index].valorAcumulado = valorAcumulado

                        valorAcumuladoTotal = valorAcumuladoTotal + valorAcumulado
                    } else {
                        
                        values.educacao[index].valorAcumulado = 0
                    }
                }
            })
        }

        values.totalEducacao.push(
            {
                id: linhaFamiliaresList.id,
                descricao: linhaFamiliaresList.descricao,
                valor: valorAcumuladoTotal,
            }
        )

        values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] =
            values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] + valorAcumuladoTotal
    })
}



export const dadosCompletosEducacao = ( values ) => {

    let dadosIncompletos = ''
    let familiares = []

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        values.educacao?.forEach( (linhaEducacao, index) => {

            if (
                    linhaEducacao.familiar === linhaFamiliaresList.id &&
                    linhaEducacao.tipoEducacao &&
                    !dadosCompletosLinhaEducacao(linhaEducacao, false)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaFamiliaresList,
                    dadosIncompletos, pesquisaDescricaoList(tipoEducacaoList, linhaEducacao.tipoEducacao))
            }
        })
    })

    return dadosIncompletos
}