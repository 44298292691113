import NUMMIN from '../NumMinMax/NumMin'

import NUMMAX from '../NumMinMax/NumMax'

import TAMMAX from '../TamMax/TamMax'

import {
    GridItem6xs12,
    GridItem12,
} from '../../business-components/Grid/Grid'

import formPrimeiraReuniaoPadraoList from './formPrimeiraReuniaoPadraoList'

import simNaoList from "./simNaoList"



const formPrimeiraReuniaoInvestimento50List = () => {

    const formList = [...formPrimeiraReuniaoPadraoList]
    
    formList.push(
        {
            id: 140,
            descricao: 'Mantém uma reserva de emergência?',
            default: false,
            grupoPergunta: '',
            type: 'SimNao',
            list: simNaoList,
            ordem: 140,
        },
        {
            id: 150,
            descricao: 'A sua reserva de emergência equivale a quantos meses?',
            default: false,
            grupoPergunta: '',
            type: 'Inteiro',
            numMin: NUMMIN.meses,
            numMax: NUMMAX.meses,
            perguntaCondicionadaSim: 140,
            ordem: 150,
        },
        {
            id: 160,
            descricao: 'Os seus recursos financeiros já estão sob a nossa gestão?',
            default: false,
            grupoPergunta: '',
            type: 'SimNao',
            list: simNaoList,
            ordem: 160,
        },
        {
            id: 170,
            descricao: 'Quais são os seus objetivos com investimentos?',
            default: false,
            grupoPergunta: '',
            type: 'Radio',
            list: [
                {id: 'PE', descricao: 'Projeto específico', ordem: 10},
                {id: 'IF', descricao: 'Aposentadoria, independência financeira', ordem: 20},
                {id: 'TH', descricao: 'Transmissão de herança a herdeiros', ordem: 30},
                {id: 'OU', descricao: 'Outros', ordem: 40},
            ],
            idOutros: 'OU',
            tamMaxOutros: 100,
            GridItem: GridItem12,
            ordem: 170,
        },
        {
            id: 180,
            descricao: 'Tem pessoas que dependem de você?',
            default: false,
            grupoPergunta: '',
            type: 'SimNao',
            list: simNaoList,
            ordem: 180,
        },
        {
            id: 182,
            descricao: 'Possui um testamento?',
            default: false,
            grupoPergunta: '',
            type: 'SimNao',
            list: simNaoList,
            ordem: 182,
        },
        {
            id: 184,
            descricao: 'Já pensou em estratégias para sucessão patrimonial? (Detalhar)',
            default: false,
            grupoPergunta: '',
            type: 'MultiLine',
            tamMax: TAMMAX.observacao,
            ordem: 184,
        },
        {
            id: 186,
            descricao: 'Tem herdeiros diretos? (Especificar)',
            default: false,
            grupoPergunta: '',
            type: 'MultiLine',
            tamMax: TAMMAX.observacao,
            ordem: 186,
        },
        {
            id: 188,
            descricao: 'Utiliza estruturas como holding familiar ou fundos exclusivos para fins de sucessão?',
            default: false,
            grupoPergunta: '',
            type: 'SimNao',
            list: simNaoList,
            ordem: 188,
        },
        {
            id:  190,
            descricao: 'Possui seguros contratados?',
            default: false,
            grupoPergunta: '',
            type: 'MultiList',
            list: [
                {id: 'SV', descricao: 'Seguro de vida', ordem: 10},
                {id: 'SR', descricao: 'Seguro residencial', ordem: 20},
                {id: 'SS', descricao: 'Seguro saúde', ordem: 30},
                {id: 'SA', descricao: 'Seguro automotivo', ordem: 40},
                {id: 'NP', descricao: 'Não possuo', ordem: 50},
                {id: 'OU', descricao: 'Outro', ordem: 60},
            ],
            labelButtonIncluirLinha: 'Incluir Seguro',
            idOutros: 'OU',
            tamMaxOutros: 100,
            ordem: 190,
        },
        {
            id: 200,
            descricao: 'Contribui para o INSS?',
            default: false,
            grupoPergunta: '',
            type: 'SimNao',
            list: simNaoList,
            ordem: 200,
        },
        {
            id: 210,
            descricao: 'Possui algum plano de previdência complementar?',
            default: false,
            grupoPergunta: '',
            type: 'Radio',
            list: [
                {id: 'PG', descricao: 'Sim, PGBL', ordem: 10},
                {id: 'VG', descricao: 'Sim, VGBL', ordem: 20},
                {id: 'PV', descricao: 'Sim, PGBL e VGBL', ordem: 30},
                {id: 'NA', descricao: 'Não', ordem: 40},
            ],
            GridItem: GridItem6xs12,
            ordem: 210,
        },
        {
            id: 220,
            descricao: 'Qual o valor aportado em PGBL e/ou PGBL?',
            default: false,
            grupoPergunta: '',
            type: 'Valor',
            perguntaCondicionadaRadio: 210,
            perguntaCondicionadaRadioList: [
                {id: 'PG'},
                {id: 'VG'},
                {id: 'PV'},
            ],
            ordem: 220,
        },
        {
            id:  222,
            descricao: 'Utiliza estratégias para redução de impostos?',
            default: false,
            grupoPergunta: '',
            type: 'Radio',
            list: [
                {id: 'S', descricao: 'Sim (especificar)', ordem: 10},
                {id: 'N', descricao: 'Não', ordem: 20},
            ],
            idOutros: 'S',
            tamMaxOutros: 100,
            GridItem: GridItem12,
            ordem: 222,
        },
        {
            id: 224,
            descricao: 'Possui bens declarados fora do país?',
            default: false,
            grupoPergunta: '',
            type: 'SimNao',
            list: simNaoList,
            ordem: 224,
        },
        {
            id: 230,
            descricao: 'Existem situações específicas que você gostaria de abordar no planejamento financeiro?',
            default: false,
            grupoPergunta: '',
            type: 'MultiLine',
            tamMax: TAMMAX.observacao,
            ordem: 230,
        },
        {
            id: 240,
            descricao: 'Alguma preocupação ou prioridade que não foi abordada acima?',
            default: false,
            grupoPergunta: '',
            type: 'MultiLine',
            tamMax: TAMMAX.observacao,
            ordem: 240,
        },
    )

    return formList
}
 
export default formPrimeiraReuniaoInvestimento50List