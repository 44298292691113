import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    formataCpf,
} from '../../../business-components/Formata/Formata'

import {
    indexAtualJsonRegistros,
    obtemDadoXLSX,
    timeSeguradora,
    idAgenteTimeSeguradora,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
} from './IntegracaoClienteArquivoService'



export const importaListaTransferenciaClientesOutras = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)


    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (XLSX[linha][0]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 0, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][1])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

           
            
            if (usuarioClienteSelecionado) {
            
                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
    
            } else {
        
                erros.push(MESSAGE().agenteNaoEncontradoCpfSeguradoCliente
                    .replace("$cpfSeguradoCliente$", formataCpf(jsonRegistros.jsonRegistros[indexJsonRegistros].cpfSeguradoCliente))
                        .replace("$codigoAgente$", XLSX[linha][1])
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }

    return jsonRegistros.jsonRegistros
}



export const importaListaClientesAgentesOutras = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (XLSX[linha][0]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 0, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2, HARDCODE.importacaoInstituicaoFinanceira, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 3, HARDCODE.importacaoNumeroConta, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 4, HARDCODE.importacaoCodigoAgente, true, null, erros, nomeArquivo)
            
            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++

    }

    return jsonRegistros.jsonRegistros
}



export const importaParcelas = (values, item, linhaIntegracaoCotacao, XLSX) => {

    let linha = linhaIntegracaoCotacao.cabecalho + 1

    values.parcelasImportadas = []

    while (linha < XLSX.length) {

        if (XLSX[linha][0]) {

            values.parcelasImportadas.push(XLSX[linha][0])
        }

        linha++
    }
}