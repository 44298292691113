import LABEL from '../../../business-const/Label/Label'

import {
    formataData,
} from '../../../business-components/Date/Date'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import {
    dadosCompletosCliente,
    calculaIdadesDataSimulacao,
} from '../cliente-form/ClienteService'

import {
    trataMudancaNaoExibirMorteAteParaExibirMorteAte,
} from '../estudo-parametros/EstudoServiceConfiguracaoProtecao'



const incrementaContCenario = ( values ) => {

    if (!values.contCenario) {

        values.contCenario = 0
    }

    values.contCenario = values.contCenario + 1
}



export const processaCenariosDefaultJsonUsuario = ( values, jsonUsuario, jsonUsuarioAdmin ) => {

    if (values.processarCenariosDefaultJsonUsuario && dadosCompletosCliente(values) && values.cenarios.length === 1) {

        let configuracaoCenarios = null

        if (jsonUsuario.configuracaoCenarios && jsonUsuario.configuracaoCenarios.length > 0) {

            configuracaoCenarios = jsonUsuario.configuracaoCenarios
        }

        if (!configuracaoCenarios) {

            jsonUsuarioAdmin.jsonUsuarioAdmin?.forEach( (linhaJsonUsuarioAdmin, indexJsonUsuarioAdmin) => {

                if (
                        linhaJsonUsuarioAdmin.jsonUsuario.compartilharCenarios &&
                        linhaJsonUsuarioAdmin.jsonUsuario.configuracaoCenarios &&
                        linhaJsonUsuarioAdmin.jsonUsuario.configuracaoCenarios.length > 0
                ) {

                    configuracaoCenarios = linhaJsonUsuarioAdmin.jsonUsuario.configuracaoCenarios
                }
            })
        }

        if (configuracaoCenarios) {

            calculaIdadesDataSimulacao(values)

            atualizarCenario(values)

            configuracaoCenarios?.forEach( (linhaConfiguracaoCenarios, indexConfiguracaoCenarios) => {

                if (indexConfiguracaoCenarios > 0) {
                    
                    incluirCenario(values)
                }
            })

            configuracaoCenarios?.forEach( (linhaConfiguracaoCenarios, indexConfiguracaoCenarios) => {

                values.cenarioAtual = values.cenarios[indexConfiguracaoCenarios].id

                obterCenario(values, values.cenarioAtual)

                if (linhaConfiguracaoCenarios.descricaoCenario || linhaConfiguracaoCenarios.descricaoCenario === 0) {

                    values.descricaoCenario = linhaConfiguracaoCenarios.descricaoCenario
                }

                if (linhaConfiguracaoCenarios.taxaCDIAnual || linhaConfiguracaoCenarios.taxaCDIAnual === 0) {

                    values.taxaCDIAnual = linhaConfiguracaoCenarios.taxaCDIAnual
                }

                if (linhaConfiguracaoCenarios.retornoCDI || linhaConfiguracaoCenarios.retornoCDI === 0) {

                    values.retornoCDI = linhaConfiguracaoCenarios.retornoCDI
                }
                
                if (linhaConfiguracaoCenarios.taxaJurosAnual || linhaConfiguracaoCenarios.taxaJurosAnual === 0) {

                    values.taxaJurosAnual = linhaConfiguracaoCenarios.taxaJurosAnual
                }
                
                if (linhaConfiguracaoCenarios.tipoMomentoTributacaoIR) {

                    values.tipoMomentoTributacaoIR = linhaConfiguracaoCenarios.tipoMomentoTributacaoIR
                }
                
                if (linhaConfiguracaoCenarios.percIR || linhaConfiguracaoCenarios.percIR === 0) {

                    values.percIR = linhaConfiguracaoCenarios.percIR
                }

                if (
                        linhaConfiguracaoCenarios.tempoMinimoEntreAportesResgatesCalculoIRAnos ||
                        linhaConfiguracaoCenarios.tempoMinimoEntreAportesResgatesCalculoIRAnos === 0
                ) {

                    values.tempoMinimoEntreAportesResgatesCalculoIRAnos = 
                        linhaConfiguracaoCenarios.tempoMinimoEntreAportesResgatesCalculoIRAnos
                }

                if (linhaConfiguracaoCenarios.taxaInflacaoAnual || linhaConfiguracaoCenarios.taxaInflacaoAnual === 0) {

                    values.taxaInflacaoAnual = linhaConfiguracaoCenarios.taxaInflacaoAnual
                }
                
                if (linhaConfiguracaoCenarios.idadeExpectativaVidaCliente || linhaConfiguracaoCenarios.idadeExpectativaVidaCliente === 0) {

                    values.idadeExpectativaVidaCliente = linhaConfiguracaoCenarios.idadeExpectativaVidaCliente

                    values.alterarIdadesAutomaticasCliente = true
                }
                
                if (linhaConfiguracaoCenarios.percDespesasAdvocaticias || linhaConfiguracaoCenarios.percDespesasAdvocaticias === 0) {

                    values.percDespesasAdvocaticias = linhaConfiguracaoCenarios.percDespesasAdvocaticias
                }
                
                if (linhaConfiguracaoCenarios.percCustasJudiciais || linhaConfiguracaoCenarios.percCustasJudiciais === 0) {

                    values.percCustasJudiciais = linhaConfiguracaoCenarios.percCustasJudiciais
                }

                if (linhaConfiguracaoCenarios.percInventarioItcmd || linhaConfiguracaoCenarios.percInventarioItcmd === 0) {

                    values.percInventarioItcmd = linhaConfiguracaoCenarios.percInventarioItcmd
                }
                
                if (linhaConfiguracaoCenarios.naoAplicarTaxaJurosSeguroVida) {

                    values.naoAplicarTaxaJurosSeguroVida = linhaConfiguracaoCenarios.naoAplicarTaxaJurosSeguroVida
                }

                if (linhaConfiguracaoCenarios.exibirMorteAte) {

                    values.exibirMorteAte = linhaConfiguracaoCenarios.exibirMorteAte
                }

                if (linhaConfiguracaoCenarios.naoExibirFuneral) {

                    values.naoExibirFuneral = linhaConfiguracaoCenarios.naoExibirFuneral
                }

                if (linhaConfiguracaoCenarios.funeral) {

                    values.funeral = linhaConfiguracaoCenarios.funeral
                }

                if (linhaConfiguracaoCenarios.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos) {

                    values.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos =
                        linhaConfiguracaoCenarios.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos
                }

                if (linhaConfiguracaoCenarios.exibirCapitalSeguradoCarteiraInvestimentos) {

                    values.exibirCapitalSeguradoCarteiraInvestimentos =
                        linhaConfiguracaoCenarios.exibirCapitalSeguradoCarteiraInvestimentos
                }
                
                //if (linhaConfiguracaoCenarios.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos) {

                //    values.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos =
                //        linhaConfiguracaoCenarios.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos
                //}

                if (linhaConfiguracaoCenarios.aplicarTaxaInflacaoSimulacaoResgateVidaInteira) {

                    values.aplicarTaxaInflacaoSimulacaoResgateVidaInteira =
                        linhaConfiguracaoCenarios.aplicarTaxaInflacaoSimulacaoResgateVidaInteira
                }
                
                if (linhaConfiguracaoCenarios.aplicarTaxaInflacaoCarteiraInvestimentos) {

                    values.aplicarTaxaInflacaoCarteiraInvestimentos =
                        linhaConfiguracaoCenarios.aplicarTaxaInflacaoCarteiraInvestimentos
                }
                
                if (linhaConfiguracaoCenarios.exibirResumoSeguroVidaCotacao) {

                    values.exibirResumoSeguroVidaCotacao =
                        linhaConfiguracaoCenarios.exibirResumoSeguroVidaCotacao
                }
                
                if (linhaConfiguracaoCenarios.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado) {

                    values.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado =
                        linhaConfiguracaoCenarios.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado
                }
                
                if (linhaConfiguracaoCenarios.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira) {

                    values.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira =
                        linhaConfiguracaoCenarios.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira
                }

                //if (linhaConfiguracaoCenarios.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate) {

                //    values.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate =
                //        linhaConfiguracaoCenarios.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate
                //}
                
                if (linhaConfiguracaoCenarios.aplicarTaxaInflacaoTabelaEvolucaoValorResgate) {

                    values.aplicarTaxaInflacaoTabelaEvolucaoValorResgate =
                        linhaConfiguracaoCenarios.aplicarTaxaInflacaoTabelaEvolucaoValorResgate
                }
                
                if (linhaConfiguracaoCenarios.calcularPremioAnualizadoAcumuladoSomaSimples) {

                    values.calcularPremioAnualizadoAcumuladoSomaSimples =
                        linhaConfiguracaoCenarios.calcularPremioAnualizadoAcumuladoSomaSimples
                }
                
                if (linhaConfiguracaoCenarios.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao) {

                    values.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao =
                        linhaConfiguracaoCenarios.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao
                }
                
                if (linhaConfiguracaoCenarios.limitarQtdeAnosExibidosGraficosTabelasEvolucao) {

                    values.limitarQtdeAnosExibidosGraficosTabelasEvolucao =
                        linhaConfiguracaoCenarios.limitarQtdeAnosExibidosGraficosTabelasEvolucao
                }
                
                if (
                        linhaConfiguracaoCenarios.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao ||
                        linhaConfiguracaoCenarios.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao === 0
                ) {

                    values.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao =
                        linhaConfiguracaoCenarios.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao
                }

                if (linhaConfiguracaoCenarios.exibirTabelaEvolucaoCS) {

                    values.exibirTabelaEvolucaoCS =
                        linhaConfiguracaoCenarios.exibirTabelaEvolucaoCS
                }
                
                if (linhaConfiguracaoCenarios.exibirTabelaEvolucaoCancelamentoApolice) {

                    values.exibirTabelaEvolucaoCancelamentoApolice =
                        linhaConfiguracaoCenarios.exibirTabelaEvolucaoCancelamentoApolice
                }
                
                if (linhaConfiguracaoCenarios.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira) {

                    values.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira =
                        linhaConfiguracaoCenarios.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira
                }

                if (linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR) {

                    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR =
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR
                }
                
                if (
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR ||
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR === 0
                ) {

                    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR =
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR
                }

                if (linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais) {

                    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais =
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais
                }

                if (linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd) {

                    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd =
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd
                }

                if (
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio ||
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio === 0
                ) {

                    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio =
                        linhaConfiguracaoCenarios.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio
                }
                
                if (linhaConfiguracaoCenarios.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia) {

                    values.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia =
                        linhaConfiguracaoCenarios.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia
                }

                if (linhaConfiguracaoCenarios.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria) {

                    values.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria =
                        linhaConfiguracaoCenarios.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria
                }

                if (linhaConfiguracaoCenarios.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria) {

                    values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria =
                        linhaConfiguracaoCenarios.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria
                }

                if (linhaConfiguracaoCenarios.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria) {

                    values.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria =
                        linhaConfiguracaoCenarios.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria
                }

                if (linhaConfiguracaoCenarios.naoExibirOpcaoAporteUnico) {

                    values.naoExibirOpcaoAporteUnico = linhaConfiguracaoCenarios.naoExibirOpcaoAporteUnico
                }

                if (linhaConfiguracaoCenarios.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira) {

                    values.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira =
                        linhaConfiguracaoCenarios.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira
                }


                if (linhaConfiguracaoCenarios.naoExibirHipotesesRetornoRealAposentadoria) {

                    values.naoExibirHipotesesRetornoRealAposentadoria = 
                        linhaConfiguracaoCenarios.naoExibirHipotesesRetornoRealAposentadoria
                }
                
                if (linhaConfiguracaoCenarios.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade) {

                    values.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade = 
                        linhaConfiguracaoCenarios.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade
                }

                if (linhaConfiguracaoCenarios.exibirHipotesesRetornoRealProjetoVidaSonho) {

                    values.exibirHipotesesRetornoRealProjetoVidaSonho =
                        linhaConfiguracaoCenarios.exibirHipotesesRetornoRealProjetoVidaSonho
                }
                

                atualizarCenario(values)
            })

            values.cenarioAtual = values.cenarios[0].id

            obterCenario(values, values.cenarioAtual)
        }

        values.processarCenariosDefaultJsonUsuario = false
    }
}



export const processaValoresDefaultIniciaisCenarios = ( values ) => {
}



const objetoComparacaoEstudos = (values) => {

    return {
        id: values.versao,
        descricao: formataData(values.dataSimulacaoEstudo),
        cenarios: values.cenarios,
        cenarioPrincipal: values.cenarioPrincipal,
        descricaoCenarioPrincipal: pesquisaDescricaoList(values.cenarios, values.cenarioPrincipal),
    }
}

export const calculaComparacaoEstudos = (values) => {

    atribruiCenarioPrincipalDefault(values)

    let index = values.jsonComparacaoEstudos.estudos.length - 1

    let jaExiste = false

    while (index >= 0) {

        if (values.jsonComparacaoEstudos.estudos[index].id === values.versao) {

            values.jsonComparacaoEstudos.estudos[index] = objetoComparacaoEstudos(values)

            jaExiste = true
        }

        index -= 1
    }

    if (!jaExiste) {

        values.jsonComparacaoEstudos.estudos.push(objetoComparacaoEstudos(values))
    }
}


const atribuiIdadesCliente = (values) => {

    values.idadesCliente = [values.idadeDataSimulacaoCliente, values.idadeVaiAposentarCliente, values.idadeExpectativaVidaCliente]
}



export const incluirCenario = ( values ) => {

    if (!values.cenarios) {

        values.cenarios = []
    }

    incrementaContCenario(values)

    values.descricaoCenario = LABEL.cenario + ' ' + values.contCenario

    values.cenarios = JSON.parse(JSON.stringify(values.cenarios))/*[...values.cenarios]*/ // Tratamento gravação concorrência de usuários. Foi necessário fazer isso para conseguir identificar as alterações nas inclusões de cenários

    values.cenarios.push(
        {
            id: values.contCenario.toString(),
            descricao: values.descricaoCenario,
            ordem: values.contCenario,
            morteInvalidezEstudo: values.morteInvalidezEstudo,
            taxaCDIAnual: values.taxaCDIAnual,
            retornoCDI: values.retornoCDI,
            taxaJurosAnual: values.taxaJurosAnual,
            tipoMomentoTributacaoIR: values.tipoMomentoTributacaoIR,
            percIR: values.percIR,
            tempoMinimoEntreAportesResgatesCalculoIRAnos: values.tempoMinimoEntreAportesResgatesCalculoIRAnos,
            taxaInflacaoAnual: values.taxaInflacaoAnual,
            taxaJurosAnualFgts: values.taxaJurosAnualFgts,
            taxaCDIAnualAposAposentadoria: values.taxaCDIAnualAposAposentadoria,
            retornoCDIAposAposentadoria: values.retornoCDIAposAposentadoria,
            taxaJurosAnualAposAposentadoria: values.taxaJurosAnualAposAposentadoria,
            taxaInflacaoAnualAposAposentadoria: values.taxaInflacaoAnualAposAposentadoria,
            taxaJurosAnualFgtsAposAposentadoria: values.taxaJurosAnualFgtsAposAposentadoria,
            dataSimulacaoEstudo: values.dataSimulacaoEstudo,
            utilizarPercRentabilidadeAnualRecursosFinanceiros: values.utilizarPercRentabilidadeAnualRecursosFinanceiros,
            diferenciarTaxasAposAposentadoria: values.diferenciarTaxasAposAposentadoria,
            idadeVaiAposentarCliente: values.idadeVaiAposentarCliente,
            idadeInicioInssCliente: values.idadeInicioInssCliente,
            idadeExpectativaVidaCliente: values.idadeExpectativaVidaCliente,
            idadeSimulacaoMorteCliente: values.idadeSimulacaoMorteCliente,
            idadeDataSimulacaoCliente: values.idadeDataSimulacaoCliente,
            percRespCliente: values.percRespCliente,
            //tipoIRCliente: values.tipoIRCliente,
            alterarIdadesAutomaticasCliente: values.alterarIdadesAutomaticasCliente,
            alterarPercRespAutomaticoCliente: values.alterarPercRespAutomaticoCliente,
            idadeVaiAposentarConjuge: values.idadeVaiAposentarConjuge,
            idadeInicioInssConjuge: values.idadeInicioInssConjuge,
            idadeExpectativaVidaConjuge: values.idadeExpectativaVidaConjuge,
            idadeSimulacaoMorteConjuge: values.idadeSimulacaoMorteConjuge,
            idadeDataSimulacaoConjuge: values.idadeDataSimulacaoConjuge,
            percRespConjuge: values.percRespConjuge,
            //tipoIRConjuge: values.tipoIRConjuge,
            alterarIdadesAutomaticasConjuge: values.alterarIdadesAutomaticasConjuge,
            anoFinalVaiAposentarCliente: values.anoFinalVaiAposentarCliente,
            anoInicioInssCliente: values.anoInicioInssCliente,
            anoFinalVaiAposentarConjuge: values.anoFinalVaiAposentarConjuge,
            anoInicioInssConjuge: values.anoInicioInssConjuge,
            anoFinalVitalicioClienteConjuge: values.anoFinalVitalicioClienteConjuge,
            anoFinalSimulacaoMorteClienteConjuge: values.anoFinalSimulacaoMorteClienteConjuge,
            anoFinalClienteConjuge: values.anoFinalClienteConjuge,
            exclusaoFamiliares: values.exclusaoFamiliares,
            exclusaoDespesasGarantias: values.exclusaoDespesasGarantias,
            exibirTabelaEvolucaoValorResgateCarteiraInvestimentos: values.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos,
            exibirCapitalSeguradoCarteiraInvestimentos: values.exibirCapitalSeguradoCarteiraInvestimentos,
            //exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos: values.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos,
            premioVidaInteiraOriundoCarteiraInvestimentos: values.premioVidaInteiraOriundoCarteiraInvestimentos,
            idRecursoFinanceiroOrigemOrigemPremioVidaInteira: values.idRecursoFinanceiroOrigemOrigemPremioVidaInteira,
            idadeSimularResgateVidaInteiraCliente: values.idadeSimularResgateVidaInteiraCliente,
            idadeSimularResgateVidaInteiraConjuge: values.idadeSimularResgateVidaInteiraConjuge,
            aplicarTaxaInflacaoSimulacaoResgateVidaInteira: values.aplicarTaxaInflacaoSimulacaoResgateVidaInteira,
            aplicarTaxaInflacaoCarteiraInvestimentos: values.aplicarTaxaInflacaoCarteiraInvestimentos,
            exibirResumoSeguroVidaCotacao: values.exibirResumoSeguroVidaCotacao,
            exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado: values.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado,
            exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira: values.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira,
            //exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate:
            //    values.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate,
            aplicarTaxaInflacaoTabelaEvolucaoValorResgate: values.aplicarTaxaInflacaoTabelaEvolucaoValorResgate,
            calcularPremioAnualizadoAcumuladoSomaSimples: values.calcularPremioAnualizadoAcumuladoSomaSimples,
            exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao: values.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao,
            limitarQtdeAnosExibidosGraficosTabelasEvolucao: values.limitarQtdeAnosExibidosGraficosTabelasEvolucao,
            anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao: values.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao,
            exibirTabelaEvolucaoCS: values.exibirTabelaEvolucaoCS,
            exibirTabelaEvolucaoCancelamentoApolice: values.exibirTabelaEvolucaoCancelamentoApolice,
            exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira: values.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira,
            graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR:
                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR,
            graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR: values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR,
            graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais:
                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais,
            graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd:
                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd,
            graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio:
                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio,
            incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia:
                values.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia,
            utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia:
                values.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia,
            naoExibirDespesasAcumuladasSemDiaDia: values.naoExibirDespesasAcumuladasSemDiaDia,
            calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria:
                values.calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria,
            valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria:
                values.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria,
            aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria:
                values.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria,
            percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria:
                values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria,
            deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria:
                values.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria,
            naoExibirOpcaoAporteUnico: values.naoExibirOpcaoAporteUnico,
            utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira:
                values.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira,
            naoCalcularAportesNecessariosRealizacaoPVS: values.naoCalcularAportesNecessariosRealizacaoPVS,
            naoConsiderarValorAcumulacaoJaRealizadoPVS: values.naoConsiderarValorAcumulacaoJaRealizadoPVS,
            naoExibirHipotesesRetornoRealAposentadoria: values.naoExibirHipotesesRetornoRealAposentadoria,
            calcularHipotesesRetornoRealAposentadoriaViverRentabilidade:
                values.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade,
            exibirHipotesesRetornoRealProjetoVidaSonho: values.exibirHipotesesRetornoRealProjetoVidaSonho,
            naoExibirAcumulacaoAposentadoriaProjetoVidaSonho: values.naoExibirAcumulacaoAposentadoriaProjetoVidaSonho,
            naoAplicarTaxaJurosSeguroVida: values.naoAplicarTaxaJurosSeguroVida,
            naoExibirProtecaoRiscos: values.naoExibirProtecaoRiscos,
            exibirMorteAte: values.exibirMorteAte,
            naoExibirMorteVitalicia: values.naoExibirMorteVitalicia,
            naoExibirMorteSucessaoVitalicia: values.naoExibirMorteSucessaoVitalicia,
            naoExibirDITDITA: values.naoExibirDITDITA,
            naoExibirDoencasGraves: values.naoExibirDoencasGraves,
            mesesSugestaoDoencasGraves: values.mesesSugestaoDoencasGraves,
            tipoCalculoSugestaoDoencasGraves: values.tipoCalculoSugestaoDoencasGraves,
            despesasAdicionaisDoencasGravesCliente: values.despesasAdicionaisDoencasGravesCliente,
            despesasAdicionaisDoencasGravesConjuge: values.despesasAdicionaisDoencasGravesConjuge,
            naoExibirInvalidez: values.naoExibirInvalidez,
            naoExibirFuneral: values.naoExibirFuneral,
            funeral: values.funeral,
            percCapacidadePoupancaSugestaoAporteMensal: values.percCapacidadePoupancaSugestaoAporteMensal,
            aporteMensalAntesAposentarCliente: values.aporteMensalAntesAposentarCliente,
            retiradaMensalAntesAposentarCliente: values.retiradaMensalAntesAposentarCliente,
            aporteMensalDepoisAposentarCliente: values.aporteMensalDepoisAposentarCliente,
            retiradaMensalDepoisAposentarCliente: values.retiradaMensalDepoisAposentarCliente,
            tipoRetornoFinanceiro: values.tipoRetornoFinanceiro,
            exibirEvolucaoAporteMensalInflacao: values.exibirEvolucaoAporteMensalInflacao,
            exibirEvolucaoRetiradaMensalInflacao: values.exibirEvolucaoRetiradaMensalInflacao,
            aporteRetiradaMensal: values.aporteRetiradaMensal,
            aporteRetiradaAnual: values.aporteRetiradaAnual,
            anosExibicaoRelatorioEvolucaoReservaFinanceira: values.anosExibicaoRelatorioEvolucaoReservaFinanceira,
            percDespesasAdvocaticias: values.percDespesasAdvocaticias,
            percCustasJudiciais: values.percCustasJudiciais,
            percInventarioItcmd: values.percInventarioItcmd,
            mesesEmergencialCliente: values.mesesEmergencialCliente,
            emergencialCliente: values.emergencialCliente,
            inventarioCliente: values.inventarioCliente,
            alterarValoresAutomaticosSucessaoVitaliciaCliente: values.alterarValoresAutomaticosSucessaoVitaliciaCliente,
            mesesEmergencialConjuge: values.mesesEmergencialConjuge,
            emergencialConjuge: values.emergencialConjuge,
            inventarioConjuge: values.inventarioConjuge,
            alterarValoresAutomaticosSucessaoVitaliciaConjuge: values.alterarValoresAutomaticosSucessaoVitaliciaConjuge,
            comSemDiaDiaEstudo: values.comSemDiaDiaEstudo,
            tipoDiagnosticoAposentadoriaChart: values.tipoDiagnosticoAposentadoriaChart,
            objComparacaoFinanciamentos: values.objComparacaoFinanciamentos,
        }
    )

    values.cenarioAtual = values.contCenario.toString()

    calculaComparacaoEstudos(values)

    atribuiIdadesCliente(values)
}



export const obterCenario = ( values, cenarioAtual ) => {

    let linhaCenario = pesquisaList(values.cenarios, cenarioAtual)

    values.descricaoCenario = linhaCenario.descricao
    values.morteInvalidezEstudo = linhaCenario.morteInvalidezEstudo
    values.taxaCDIAnual = linhaCenario.taxaCDIAnual
    values.retornoCDI = linhaCenario.retornoCDI
    values.taxaJurosAnual = linhaCenario.taxaJurosAnual
    values.tipoMomentoTributacaoIR = linhaCenario.tipoMomentoTributacaoIR
    values.percIR = linhaCenario.percIR
    values.tempoMinimoEntreAportesResgatesCalculoIRAnos = linhaCenario.tempoMinimoEntreAportesResgatesCalculoIRAnos
    values.taxaInflacaoAnual = linhaCenario.taxaInflacaoAnual
    values.taxaJurosAnualFgts = linhaCenario.taxaJurosAnualFgts
    values.taxaCDIAnualAposAposentadoria = linhaCenario.taxaCDIAnualAposAposentadoria
    values.retornoCDIAposAposentadoria = linhaCenario.retornoCDIAposAposentadoria
    values.taxaJurosAnualAposAposentadoria = linhaCenario.taxaJurosAnualAposAposentadoria
    values.taxaInflacaoAnualAposAposentadoria = linhaCenario.taxaInflacaoAnualAposAposentadoria
    values.taxaJurosAnualFgtsAposAposentadoria = linhaCenario.taxaJurosAnualFgtsAposAposentadoria
    values.dataSimulacaoEstudo = linhaCenario.dataSimulacaoEstudo
    values.utilizarPercRentabilidadeAnualRecursosFinanceiros = linhaCenario.utilizarPercRentabilidadeAnualRecursosFinanceiros
    values.diferenciarTaxasAposAposentadoria = linhaCenario.diferenciarTaxasAposAposentadoria
    values.idadeVaiAposentarCliente = linhaCenario.idadeVaiAposentarCliente
    values.idadeInicioInssCliente = linhaCenario.idadeInicioInssCliente
    values.idadeExpectativaVidaCliente = linhaCenario.idadeExpectativaVidaCliente
    values.idadeSimulacaoMorteCliente = linhaCenario.idadeSimulacaoMorteCliente
    values.idadeDataSimulacaoCliente = linhaCenario.idadeDataSimulacaoCliente
    values.percRespCliente = linhaCenario.percRespCliente
    //values.tipoIRCliente = linhaCenario.tipoIRCliente
    values.alterarIdadesAutomaticasCliente = linhaCenario.alterarIdadesAutomaticasCliente
    values.alterarPercRespAutomaticoCliente = linhaCenario.alterarPercRespAutomaticoCliente
    values.idadeVaiAposentarConjuge = linhaCenario.idadeVaiAposentarConjuge
    values.idadeInicioInssConjuge = linhaCenario.idadeInicioInssConjuge
    values.idadeExpectativaVidaConjuge = linhaCenario.idadeExpectativaVidaConjuge
    values.idadeSimulacaoMorteConjuge = linhaCenario.idadeSimulacaoMorteConjuge
    values.idadeDataSimulacaoConjuge = linhaCenario.idadeDataSimulacaoConjuge
    values.percRespConjuge = linhaCenario.percRespConjuge
    //values.tipoIRConjuge = linhaCenario.tipoIRConjuge
    values.alterarIdadesAutomaticasConjuge = linhaCenario.alterarIdadesAutomaticasConjuge
    values.anoFinalVaiAposentarCliente = linhaCenario.anoFinalVaiAposentarCliente
    values.anoInicioInssCliente = linhaCenario.anoInicioInssCliente
    values.anoFinalVaiAposentarConjuge = linhaCenario.anoFinalVaiAposentarConjuge
    values.anoInicioInssConjuge = linhaCenario.anoInicioInssConjuge
    values.anoFinalVitalicioClienteConjuge = linhaCenario.anoFinalVitalicioClienteConjuge
    values.anoFinalSimulacaoMorteClienteConjuge = linhaCenario.anoFinalSimulacaoMorteClienteConjuge
    values.anoFinalClienteConjuge = linhaCenario.anoFinalClienteConjuge
    values.exclusaoFamiliares = linhaCenario.exclusaoFamiliares
    values.exclusaoDespesasGarantias = linhaCenario.exclusaoDespesasGarantias
    values.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos = linhaCenario.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos
    values.exibirCapitalSeguradoCarteiraInvestimentos = linhaCenario.exibirCapitalSeguradoCarteiraInvestimentos
    //values.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos = linhaCenario.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos
    values.premioVidaInteiraOriundoCarteiraInvestimentos = linhaCenario.premioVidaInteiraOriundoCarteiraInvestimentos
    values.idRecursoFinanceiroOrigemOrigemPremioVidaInteira = linhaCenario.idRecursoFinanceiroOrigemOrigemPremioVidaInteira
    values.idadeSimularResgateVidaInteiraCliente = linhaCenario.idadeSimularResgateVidaInteiraCliente
    values.idadeSimularResgateVidaInteiraConjuge = linhaCenario.idadeSimularResgateVidaInteiraConjuge
    values.aplicarTaxaInflacaoSimulacaoResgateVidaInteira = linhaCenario.aplicarTaxaInflacaoSimulacaoResgateVidaInteira
    values.aplicarTaxaInflacaoCarteiraInvestimentos = linhaCenario.aplicarTaxaInflacaoCarteiraInvestimentos
    values.exibirResumoSeguroVidaCotacao = linhaCenario.exibirResumoSeguroVidaCotacao
    values.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado =
        linhaCenario.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado
    values.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira =
        linhaCenario.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira
    //values.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate =
    //    linhaCenario.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate
    values.aplicarTaxaInflacaoTabelaEvolucaoValorResgate = linhaCenario.aplicarTaxaInflacaoTabelaEvolucaoValorResgate
    values.calcularPremioAnualizadoAcumuladoSomaSimples = linhaCenario.calcularPremioAnualizadoAcumuladoSomaSimples
    values.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao = linhaCenario.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao
    values.limitarQtdeAnosExibidosGraficosTabelasEvolucao = linhaCenario.limitarQtdeAnosExibidosGraficosTabelasEvolucao
    values.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao =
        linhaCenario.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao
    values.exibirTabelaEvolucaoCS = linhaCenario.exibirTabelaEvolucaoCS
    values.exibirTabelaEvolucaoCancelamentoApolice = linhaCenario.exibirTabelaEvolucaoCancelamentoApolice
    values.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira = linhaCenario.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira
    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR =
        linhaCenario.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR
    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR = linhaCenario.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR
    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais =
        linhaCenario.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais
    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd =
        linhaCenario.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd
    values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio =
        linhaCenario.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio
    values.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia =
        linhaCenario.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia
    values.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia =
        linhaCenario.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia
    values.calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria =
        linhaCenario.calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria
    values.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria =
        linhaCenario.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria
    values.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria =
        linhaCenario.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria
    values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria =
        linhaCenario.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria
    values.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria =
        linhaCenario.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria
    values.naoExibirOpcaoAporteUnico = linhaCenario.naoExibirOpcaoAporteUnico
    values.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira =
        linhaCenario.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira
    values.naoCalcularAportesNecessariosRealizacaoPVS = linhaCenario.naoCalcularAportesNecessariosRealizacaoPVS
    values.naoConsiderarValorAcumulacaoJaRealizadoPVS = linhaCenario.naoConsiderarValorAcumulacaoJaRealizadoPVS
    values.naoExibirHipotesesRetornoRealAposentadoria = linhaCenario.naoExibirHipotesesRetornoRealAposentadoria
    values.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade =
        linhaCenario.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade
    values.exibirHipotesesRetornoRealProjetoVidaSonho = linhaCenario.exibirHipotesesRetornoRealProjetoVidaSonho
    values.naoAplicarTaxaJurosSeguroVida = linhaCenario.naoAplicarTaxaJurosSeguroVida
    values.naoExibirProtecaoRiscos = linhaCenario.naoExibirProtecaoRiscos
    values.naoExibirAcumulacaoAposentadoriaProjetoVidaSonho = linhaCenario.naoExibirAcumulacaoAposentadoriaProjetoVidaSonho
    values.exibirMorteAte = linhaCenario.exibirMorteAte
    values.naoExibirMorteVitalicia = linhaCenario.naoExibirMorteVitalicia
    values.naoExibirMorteSucessaoVitalicia = linhaCenario.naoExibirMorteSucessaoVitalicia
    values.naoExibirDITDITA = linhaCenario.naoExibirDITDITA
    values.naoExibirDoencasGraves = linhaCenario.naoExibirDoencasGraves
    values.mesesSugestaoDoencasGraves = linhaCenario.mesesSugestaoDoencasGraves
    values.tipoCalculoSugestaoDoencasGraves = linhaCenario.tipoCalculoSugestaoDoencasGraves
    values.despesasAdicionaisDoencasGravesCliente = linhaCenario.despesasAdicionaisDoencasGravesCliente
    values.despesasAdicionaisDoencasGravesConjuge = linhaCenario.despesasAdicionaisDoencasGravesConjuge
    values.naoExibirInvalidez = linhaCenario.naoExibirInvalidez
    values.naoExibirFuneral = linhaCenario.naoExibirFuneral
    values.funeral = linhaCenario.funeral
    values.naoExibirDespesasAcumuladasSemDiaDia = linhaCenario.naoExibirDespesasAcumuladasSemDiaDia
    values.percCapacidadePoupancaSugestaoAporteMensal = linhaCenario.percCapacidadePoupancaSugestaoAporteMensal
    values.aporteMensalAntesAposentarCliente = linhaCenario.aporteMensalAntesAposentarCliente
    values.retiradaMensalAntesAposentarCliente = linhaCenario.retiradaMensalAntesAposentarCliente
    values.aporteMensalDepoisAposentarCliente = linhaCenario.aporteMensalDepoisAposentarCliente
    values.retiradaMensalDepoisAposentarCliente = linhaCenario.retiradaMensalDepoisAposentarCliente
    values.tipoRetornoFinanceiro = linhaCenario.tipoRetornoFinanceiro
    values.exibirEvolucaoAporteMensalInflacao = linhaCenario.exibirEvolucaoAporteMensalInflacao
    values.exibirEvolucaoRetiradaMensalInflacao = linhaCenario.exibirEvolucaoRetiradaMensalInflacao
    values.aporteRetiradaMensal = linhaCenario.aporteRetiradaMensal
    values.aporteRetiradaAnual = linhaCenario.aporteRetiradaAnual
    values.anosExibicaoRelatorioEvolucaoReservaFinanceira = linhaCenario.anosExibicaoRelatorioEvolucaoReservaFinanceira
    values.percDespesasAdvocaticias = linhaCenario.percDespesasAdvocaticias
    values.percCustasJudiciais = linhaCenario.percCustasJudiciais
    values.percInventarioItcmd = linhaCenario.percInventarioItcmd
    values.mesesEmergencialCliente = linhaCenario.mesesEmergencialCliente
    values.emergencialCliente = linhaCenario.emergencialCliente
    values.inventarioCliente = linhaCenario.inventarioCliente
    values.alterarValoresAutomaticosSucessaoVitaliciaCliente =
        linhaCenario.alterarValoresAutomaticosSucessaoVitaliciaCliente
    values.mesesEmergencialConjuge = linhaCenario.mesesEmergencialConjuge
    values.emergencialConjuge = linhaCenario.emergencialConjuge
    values.inventarioConjuge = linhaCenario.inventarioConjuge
    values.alterarValoresAutomaticosSucessaoVitaliciaConjgue =
        linhaCenario.alterarValoresAutomaticosSucessaoVitaliciaConjuge
    values.comSemDiaDiaEstudo = linhaCenario.comSemDiaDiaEstudo
    values.tipoDiagnosticoAposentadoriaChart = linhaCenario.tipoDiagnosticoAposentadoriaChart
    values.objComparacaoFinanciamentos = linhaCenario.objComparacaoFinanciamentos

    calculaComparacaoEstudos(values)

    atribuiIdadesCliente(values)

    trataMudancaNaoExibirMorteAteParaExibirMorteAte(values)
}



export const atualizarCenario = ( values ) => {

    let indexCenario = 0 // Tratamento erro implantação concorrência de usuários na gravação que não estava reconhecendo a inclusão de novos cenários

    values.cenarios?.forEach( ( linhaCenario, index ) => {

        if (linhaCenario.id === values.cenarioAtual) {

            indexCenario = index
        }
    })

    values.cenarios[indexCenario] = {...values.cenarios[indexCenario]} // Tratamento erro inclusão novas versões: sobreposição de parâmetros de versões anteriores

    values.cenarios[indexCenario].descricao = values.descricaoCenario
    values.cenarios[indexCenario].morteInvalidezEstudo = values.morteInvalidezEstudo
    values.cenarios[indexCenario].taxaCDIAnual = values.taxaCDIAnual
    values.cenarios[indexCenario].retornoCDI = values.retornoCDI
    values.cenarios[indexCenario].taxaJurosAnual = values.taxaJurosAnual
    values.cenarios[indexCenario].tipoMomentoTributacaoIR = values.tipoMomentoTributacaoIR
    values.cenarios[indexCenario].percIR = values.percIR
    values.cenarios[indexCenario].tempoMinimoEntreAportesResgatesCalculoIRAnos =
        values.tempoMinimoEntreAportesResgatesCalculoIRAnos
    values.cenarios[indexCenario].taxaInflacaoAnual = values.taxaInflacaoAnual
    values.cenarios[indexCenario].taxaJurosAnualFgts = values.taxaJurosAnualFgts
    values.cenarios[indexCenario].taxaCDIAnualAposAposentadoria = values.taxaCDIAnualAposAposentadoria
    values.cenarios[indexCenario].retornoCDIAposAposentadoria = values.retornoCDIAposAposentadoria
    values.cenarios[indexCenario].taxaJurosAnualAposAposentadoria = values.taxaJurosAnualAposAposentadoria
    values.cenarios[indexCenario].taxaInflacaoAnualAposAposentadoria = values.taxaInflacaoAnualAposAposentadoria
    values.cenarios[indexCenario].taxaJurosAnualFgtsAposAposentadoria = values.taxaJurosAnualFgtsAposAposentadoria
    values.cenarios[indexCenario].dataSimulacaoEstudo = values.dataSimulacaoEstudo
    values.cenarios[indexCenario].utilizarPercRentabilidadeAnualRecursosFinanceiros =
        values.utilizarPercRentabilidadeAnualRecursosFinanceiros
    values.cenarios[indexCenario].diferenciarTaxasAposAposentadoria = values.diferenciarTaxasAposAposentadoria
    values.cenarios[indexCenario].idadeVaiAposentarCliente = values.idadeVaiAposentarCliente
    values.cenarios[indexCenario].idadeInicioInssCliente = values.idadeInicioInssCliente
    values.cenarios[indexCenario].idadeExpectativaVidaCliente = values.idadeExpectativaVidaCliente
    values.cenarios[indexCenario].idadeSimulacaoMorteCliente = values.idadeSimulacaoMorteCliente
    values.cenarios[indexCenario].idadeDataSimulacaoCliente = values.idadeDataSimulacaoCliente
    values.cenarios[indexCenario].percRespCliente = values.percRespCliente
    //values.cenarios[indexCenario].tipoIRCliente = values.tipoIRCliente
    values.cenarios[indexCenario].alterarIdadesAutomaticasCliente = values.alterarIdadesAutomaticasCliente
    values.cenarios[indexCenario].alterarPercRespAutomaticoCliente = values.alterarPercRespAutomaticoCliente
    values.cenarios[indexCenario].idadeVaiAposentarConjuge = values.idadeVaiAposentarConjuge
    values.cenarios[indexCenario].idadeInicioInssConjuge = values.idadeInicioInssConjuge
    values.cenarios[indexCenario].idadeExpectativaVidaConjuge = values.idadeExpectativaVidaConjuge
    values.cenarios[indexCenario].idadeSimulacaoMorteConjuge = values.idadeSimulacaoMorteConjuge
    values.cenarios[indexCenario].idadeDataSimulacaoConjuge = values.idadeDataSimulacaoConjuge
    values.cenarios[indexCenario].percRespConjuge = values.percRespConjuge
    //values.cenarios[indexCenario].tipoIRConjuge = values.tipoIRConjuge
    values.cenarios[indexCenario].alterarIdadesAutomaticasConjuge = values.alterarIdadesAutomaticasConjuge
    values.cenarios[indexCenario].anoFinalVaiAposentarCliente = values.anoFinalVaiAposentarCliente
    values.cenarios[indexCenario].anoInicioInssCliente = values.anoInicioInssCliente
    values.cenarios[indexCenario].anoFinalVaiAposentarConjuge = values.anoFinalVaiAposentarConjuge
    values.cenarios[indexCenario].anoInicioInssConjuge = values.anoInicioInssConjuge
    values.cenarios[indexCenario].anoFinalVitalicioClienteConjuge = values.anoFinalVitalicioClienteConjuge
    values.cenarios[indexCenario].anoFinalSimulacaoMorteClienteConjuge = values.anoFinalSimulacaoMorteClienteConjuge
    values.cenarios[indexCenario].anoFinalClienteConjuge = values.anoFinalClienteConjuge
    values.cenarios[indexCenario].exclusaoFamiliares = values.exclusaoFamiliares
    values.cenarios[indexCenario].exclusaoDespesasGarantias = values.exclusaoDespesasGarantias
    values.cenarios[indexCenario].exibirTabelaEvolucaoValorResgateCarteiraInvestimentos =
        values.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos
    values.cenarios[indexCenario].exibirCapitalSeguradoCarteiraInvestimentos =
        values.exibirCapitalSeguradoCarteiraInvestimentos
    //values.cenarios[indexCenario].exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos =
    //    values.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos
    values.cenarios[indexCenario].premioVidaInteiraOriundoCarteiraInvestimentos =
        values.premioVidaInteiraOriundoCarteiraInvestimentos
    values.cenarios[indexCenario].idRecursoFinanceiroOrigemOrigemPremioVidaInteira =
        values.idRecursoFinanceiroOrigemOrigemPremioVidaInteira
    values.cenarios[indexCenario].idadeSimularResgateVidaInteiraCliente =
        values.idadeSimularResgateVidaInteiraCliente
    values.cenarios[indexCenario].idadeSimularResgateVidaInteiraConjuge =
        values.idadeSimularResgateVidaInteiraConjuge
    values.cenarios[indexCenario].aplicarTaxaInflacaoSimulacaoResgateVidaInteira =
        values.aplicarTaxaInflacaoSimulacaoResgateVidaInteira
    values.cenarios[indexCenario].aplicarTaxaInflacaoCarteiraInvestimentos = values.aplicarTaxaInflacaoCarteiraInvestimentos
    values.cenarios[indexCenario].exibirResumoSeguroVidaCotacao = values.exibirResumoSeguroVidaCotacao
    values.cenarios[indexCenario].exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado =
        values.exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado
    values.cenarios[indexCenario].exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira =
        values.exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira
    //values.cenarios[indexCenario].exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate =
    //    values.exibirEconomiaInventarioSeguroVidaTabelaEvolucaoValorResgate
    values.cenarios[indexCenario].aplicarTaxaInflacaoTabelaEvolucaoValorResgate =
        values.aplicarTaxaInflacaoTabelaEvolucaoValorResgate
    values.cenarios[indexCenario].calcularPremioAnualizadoAcumuladoSomaSimples =
        values.calcularPremioAnualizadoAcumuladoSomaSimples
    values.cenarios[indexCenario].exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao =
        values.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao
    values.cenarios[indexCenario].limitarQtdeAnosExibidosGraficosTabelasEvolucao =
        values.limitarQtdeAnosExibidosGraficosTabelasEvolucao
    values.cenarios[indexCenario].anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao =
        values.anosLimitarQtdeAnosExibidosGraficosTabelasEvolucao
    values.cenarios[indexCenario].exibirTabelaEvolucaoCS = values.exibirTabelaEvolucaoCS
    values.cenarios[indexCenario].exibirTabelaEvolucaoCancelamentoApolice = values.exibirTabelaEvolucaoCancelamentoApolice
    values.cenarios[indexCenario].exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira =
        values.exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira
    values.cenarios[indexCenario].graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR =
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR
    values.cenarios[indexCenario].graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR =
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR
    values.cenarios[indexCenario].graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais =
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais
    values.cenarios[indexCenario].graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd =
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd
    values.cenarios[indexCenario].graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio =
        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio
    values.cenarios[indexCenario].incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia =
        values.incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia
    values.cenarios[indexCenario].utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia =
        values.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia
    values.cenarios[indexCenario].calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria =
        values.calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria
    values.cenarios[indexCenario].valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria =
        values.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria
    values.cenarios[indexCenario].aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria =
        values.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria
    values.cenarios[indexCenario].percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria =
        values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria
    values.cenarios[indexCenario].deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria =
        values.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria
    values.cenarios[indexCenario].naoExibirOpcaoAporteUnico = values.naoExibirOpcaoAporteUnico
    values.cenarios[indexCenario].utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira =
        values.utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira
    values.cenarios[indexCenario].naoCalcularAportesNecessariosRealizacaoPVS = values.naoCalcularAportesNecessariosRealizacaoPVS
    values.cenarios[indexCenario].naoConsiderarValorAcumulacaoJaRealizadoPVS = values.naoConsiderarValorAcumulacaoJaRealizadoPVS
    values.cenarios[indexCenario].naoExibirHipotesesRetornoRealAposentadoria = values.naoExibirHipotesesRetornoRealAposentadoria
    values.cenarios[indexCenario].calcularHipotesesRetornoRealAposentadoriaViverRentabilidade =
        values.calcularHipotesesRetornoRealAposentadoriaViverRentabilidade
    values.cenarios[indexCenario].exibirHipotesesRetornoRealProjetoVidaSonho = values.exibirHipotesesRetornoRealProjetoVidaSonho
    values.cenarios[indexCenario].naoExibirAcumulacaoAposentadoriaProjetoVidaSonho =
        values.naoExibirAcumulacaoAposentadoriaProjetoVidaSonho
    values.cenarios[indexCenario].naoAplicarTaxaJurosSeguroVida = values.naoAplicarTaxaJurosSeguroVida
    values.cenarios[indexCenario].naoExibirProtecaoRiscos = values.naoExibirProtecaoRiscos
    values.cenarios[indexCenario].exibirMorteAte = values.exibirMorteAte
    values.cenarios[indexCenario].naoExibirMorteVitalicia = values.naoExibirMorteVitalicia
    values.cenarios[indexCenario].naoExibirMorteSucessaoVitalicia = values.naoExibirMorteSucessaoVitalicia
    values.cenarios[indexCenario].naoExibirDITDITA = values.naoExibirDITDITA
    values.cenarios[indexCenario].naoExibirDoencasGraves = values.naoExibirDoencasGraves
    values.cenarios[indexCenario].mesesSugestaoDoencasGraves = values.mesesSugestaoDoencasGraves
    values.cenarios[indexCenario].tipoCalculoSugestaoDoencasGraves = values.tipoCalculoSugestaoDoencasGraves
    values.cenarios[indexCenario].despesasAdicionaisDoencasGravesCliente = values.despesasAdicionaisDoencasGravesCliente
    values.cenarios[indexCenario].despesasAdicionaisDoencasGravesConjuge = values.despesasAdicionaisDoencasGravesConjuge
    values.cenarios[indexCenario].naoExibirInvalidez = values.naoExibirInvalidez
    values.cenarios[indexCenario].naoExibirFuneral = values.naoExibirFuneral
    values.cenarios[indexCenario].funeral = values.funeral
    values.cenarios[indexCenario].naoExibirDespesasAcumuladasSemDiaDia = values.naoExibirDespesasAcumuladasSemDiaDia
    values.cenarios[indexCenario].percCapacidadePoupancaSugestaoAporteMensal = values.percCapacidadePoupancaSugestaoAporteMensal
    values.cenarios[indexCenario].aporteMensalAntesAposentarCliente = values.aporteMensalAntesAposentarCliente
    values.cenarios[indexCenario].retiradaMensalAntesAposentarCliente = values.retiradaMensalAntesAposentarCliente
    values.cenarios[indexCenario].aporteMensalDepoisAposentarCliente = values.aporteMensalDepoisAposentarCliente
    values.cenarios[indexCenario].retiradaMensalDepoisAposentarCliente = values.retiradaMensalDepoisAposentarCliente
    values.cenarios[indexCenario].tipoRetornoFinanceiro = values.tipoRetornoFinanceiro
    values.cenarios[indexCenario].exibirEvolucaoAporteMensalInflacao = values.exibirEvolucaoAporteMensalInflacao
    values.cenarios[indexCenario].exibirEvolucaoRetiradaMensalInflacao = values.exibirEvolucaoRetiradaMensalInflacao
    values.cenarios[indexCenario].aporteRetiradaMensal = values.aporteRetiradaMensal
    values.cenarios[indexCenario].aporteRetiradaAnual = values.aporteRetiradaAnual
    values.cenarios[indexCenario].anosExibicaoRelatorioEvolucaoReservaFinanceira = values.anosExibicaoRelatorioEvolucaoReservaFinanceira
    values.cenarios[indexCenario].percDespesasAdvocaticias = values.percDespesasAdvocaticias
    values.cenarios[indexCenario].percCustasJudiciais = values.percCustasJudiciais
    values.cenarios[indexCenario].percInventarioItcmd = values.percInventarioItcmd
    values.cenarios[indexCenario].mesesEmergencialCliente = values.mesesEmergencialCliente
    values.cenarios[indexCenario].emergencialCliente = values.emergencialCliente
    values.cenarios[indexCenario].inventarioCliente = values.inventarioCliente
    values.cenarios[indexCenario].alterarValoresAutomaticosSucessaoVitaliciaCliente =
        values.alterarValoresAutomaticosSucessaoVitaliciaCliente
    values.cenarios[indexCenario].mesesEmergencialConjuge = values.mesesEmergencialConjuge
    values.cenarios[indexCenario].emergencialConjuge = values.emergencialConjuge
    values.cenarios[indexCenario].inventarioConjuge = values.inventarioConjuge
    values.cenarios[indexCenario].alterarValoresAutomaticosSucessaoVitaliciaConjgue =
        values.alterarValoresAutomaticosSucessaoVitaliciaConjuge
    values.cenarios[indexCenario].comSemDiaDiaEstudo = values.comSemDiaDiaEstudo
    values.cenarios[indexCenario].tipoDiagnosticoAposentadoriaChart = values.tipoDiagnosticoAposentadoriaChart
    values.cenarios[indexCenario].objComparacaoFinanciamentos = values.objComparacaoFinanciamentos

    calculaComparacaoEstudos(values)

    atribuiIdadesCliente(values)
}

export const excluirCenario = ( values ) => {

    values.cenarios = [...values.cenarios] // Tratamento gravação concorrência de usuários. Foi necessário fazer isso para conseguir identificar as alterações nas inclusões de cenários

    let index = values.cenarios.length - 1

    while (index >= 0) {

        if (values.cenarios[index].id === values.cenarioAtual) {

            values.cenarios.splice(index, 1)
        }

        index -= 1
    }

    values.cenarioAtual = values.cenarios[0].id

    obterCenario(values, values.cenarioAtual)
}

const atribruiCenarioPrincipalDefault = (values) => {

    if (!values.cenarioPrincipal || !pesquisaList(values.cenarios, values.cenarioPrincipal).id) {

        values.cenarioPrincipal = values.cenarioAtual
    }
}

export const informarCenarioPrincipal = (values) => {

    const informarCenarioPrincipalAux = values.cenarios.length > 1 ? true : false

    atribruiCenarioPrincipalDefault(values)

    return informarCenarioPrincipalAux
}