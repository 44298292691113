import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import NUMMIN from '../NumMinMax/NumMin'

import NUMMAX from '../NumMinMax/NumMax'

import TAMMAX from '../TamMax/TamMax'

import simNaoList from "./simNaoList"

import tipoPilarList from "./tipoPilarList"

import tipoInvestimentoList from "./tipoInvestimentoList"

const formPrimeiraReuniaoInvestimento20List = [
    {
        id: 510,
        descricao: 'Neste momento, este projeto faz sentido para você?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        ordem: 10,
    },
    {
        id: 520,
        descricao: 'Já abriu a sua conta?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 510,
        ordem: 20,
    },
    {
        id:  530,
        descricao: 'Quais dos 4 pilares faz mas sentido neste momento?',
        default: true,
        grupoPergunta: '',
        type: 'MultiList',
        list: tipoPilarList,
        labelButtonIncluirLinha: LABEL.buttonIncluirTipoPilar,
        perguntaCondicionadaSim: 510,
        ordem: 30,
    },
    {
        id: 540,
        descricao: 'Qual a sua disponibilidade para investimento?',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        perguntaCondicionadaSim: 510,
        ordem: 40,
    },
    {
        id: 700,
        descricao: 'Deseja informar mais detalhes?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 510,
        ordem: 100,
    },
    {
        id: 10,
        descricao: 'Já possui experiência com investimento?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 700,
        ordem: 110,
    },
    {
        id:  20,
        descricao: 'Quais tipos de investimento você possui?',
        default: true,
        grupoPergunta: '',
        type: 'MultiList',
        list: tipoInvestimentoList,
        labelButtonIncluirLinha: LABEL.buttonIncluirTipoInvestimento,
        perguntaCondicionadaSim: 700,
        ordem: 120,
    },
    {
        id: 30,
        descricao: 'Em qual Banco ou Corretora você investe hoje?',
        default: true,
        grupoPergunta: '',
        type: 'String',
        tamMax: TAMMAX.instituicao,
        perguntaCondicionadaSim: 700,
        ordem: 130,
    },
    {
        id: 40,
        descricao: 'Quanto você possui disponível para investir atualmente?',
        descricaoResumida: 'Quanto possui para investir',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        perguntaCondicionadaSim: 700,
        ordem: 140,
    },
    {
        id: 50,
        descricao: 'Qual o valor total dos seus investimentos hoje?',
        descricaoResumida: 'Recursos Financeiros',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaRecursosFinanceiros,
        perguntaCondicionadaSim: 700,
        ordem: 150,
    },
    {
        id: 60,
        descricao: 'Você tem algum dependente financeiro? Se sim, quantos',
        descricaoResumida: 'Dependentes Financeiros',
        default: true,
        grupoPergunta: '',
        type: 'Inteiro',
        numMin: NUMMIN.qtdeDependentes,
        numMax: NUMMAX.qtdeDependentes,
        perguntaCondicionadaSim: 700,
        ordem: 160,
    },
    {
        id: 70,
        descricao: 'Qual é o seu gasto mensal?',
        descricaoResumida: 'Gasto Anual',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaDiaDia,
        naoAtualiza: 135,
        perguntaCondicionadaSim: 700,
        ordem: 170,
    },
    {
        id: 80,
        descricao: 'Qual é a sua renda mensal?',
        descricaoResumida: 'Receita Anual',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaReceitaMensal,
        perguntaCondicionadaSim: 700,
        ordem: 180,
    },
    {
        id: 90,
        descricao: 'Terá necessidade de utilizar parte do capital investido nos próximos 5 anos?',
        descricaoResumida: 'Necessidade de utilização próximos 5 anos',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 700,
        ordem: 190,
    },
    {
        id: 100,
        descricao: 'Qual o valor de todo o seu patrimônio imobilizado?',
        descricaoResumida: 'Patrimônio (Imobilizado)',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaPatrimonioImobilizado,
        perguntaCondicionadaSim: 700,
        ordem: 200,
    },
    {
        id: 105,
        descricao: 'Possui financiamentos?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeFinanciamento,
        compoeCompleto: true,
        perguntaCondicionadaSim: 700,
        ordem: 205,
    },
    {
        id: 110,
        descricao: 'Qual a renda que você gostaria de ter na ' + LABEL.aposentadoria + '?',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        perguntaCondicionadaSim: 700,
        ordem: 210,
    },
    {
        id: 120,
        descricao: 'Você precisará utilizar a renda mensal dos seus investimentos?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 700,
        ordem: 220,
    },
    {
        id: 130,
        descricao: 'Pretente fazer algum aporte mensal?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 700,
        ordem: 230,
    },
    {
        id: 135,
        descricao: 'Qual renda mensal você gostaria de deixar para a família na sua falta, e por quanto tempo?',
        descricaoResumida: 'Renda Mensal gostaria deixar para a Família na sua Falta',
        default: true,
        grupoPergunta: '',
        type: 'ValorAnos',
        atualiza: HARDCODE.formListAtualizaDiaDia,
        perguntaCondicionadaSim: 700,
        ordem: 235,
    },
    {
        id: 140,
        descricao: 'Você já tem algum plano de proteção para a sua família e seu patrimônio?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 700,
        ordem: 240,
    },
    {
        id: 150,
        descricao: 'A nossa equipe de seguros pode analisar as suas apólices para ver se conseguimos um custo/benefício mais interessante?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 140,
        ordem: 250,
    },
]
 
export default formPrimeiraReuniaoInvestimento20List