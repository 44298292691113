import HARDCODE from '../../business-const/HardCode/HardCode'

import AuthService from '../../business-api/AuthService'

import ConfiguracaoService from '../../business-api/ConfiguracaoService'

import RespostaSellFluxService from '../../business-api/RespostaSellFluxService'

import UsuarioService from '../../business-api/UsuarioService'

import {
    delay,
} from '../../business-components/Delay/Delay'

import {
    mensagemErroErro,
} from '../../business-components/Toastr/Toastr'

//import {
//    pesquisaList
//} from '../../business-rules/List/ListService'

import whiteLabelList from '../../business-rules/List/whiteLabelList'

import {
    converteJsonConfiguracaoJsonImagem,
    obtemPaletaCores,
    calculaPaletaCores,
    identificaIndexIdWhiteLabel,
    ajustaInclusoesAlteracoesExclusoesJsonConfiguracao,
    ajustaInclusoesAlteracoesExclusoesJsonImagem,
} from '../configuracao/ConfiguracaoService'



export const configuracaoHTTPS = () => {

    const https = window.location.href

    ////const URL = https.substr(8, https.indexOf(HARDCODE.fintracksDotCom) + 14 - 8)
    //const URL = 'www.desenvolvimento.fin-tracks.com'

    ////const idConfiguracao = pesquisaList(whiteLabelList, URL).idConfiguracao

    let configuracao = {
        idConfiguracao: null,
        idWhiteLabel: null
    }

    whiteLabelList?.forEach( (linha, ind) => {

        if (https.indexOf(linha.id) !== -1) {

            configuracao = {
                idConfiguracao:linha.idConfiguracao,
                idWhiteLabel: linha.idWhiteLabel
            }
        }
    })

    return configuracao
}



export const getConfiguracaoURL = async (variaveisGlobais) => {

    const configuracao = configuracaoHTTPS()

    if (configuracao.idConfiguracao) {

        const configuracaoService = new ConfiguracaoService()

        try {
            const response =
                await configuracaoService.buscarConfiguracaoURL(variaveisGlobais, configuracao.idConfiguracao)

            const jsonConfiguracao = ajustaInclusoesAlteracoesExclusoesJsonConfiguracao(JSON.parse(response.data.jsonConfiguracao))

            const indexIdWhiteLabel = identificaIndexIdWhiteLabel(jsonConfiguracao, {idWhiteLabel: configuracao.idWhiteLabel})

            const jsonImagem = converteJsonConfiguracaoJsonImagem(jsonConfiguracao,
                ajustaInclusoesAlteracoesExclusoesJsonImagem(JSON.parse(response.data.jsonImagem)))

            const paletaCores =
                jsonConfiguracao.whiteLabel//paletaCores
                    ?
                        obtemPaletaCores(jsonConfiguracao, {})
                    :
                        calculaPaletaCores(jsonConfiguracao)

            if (
                    JSON.stringify(jsonImagem[indexIdWhiteLabel].logo ? jsonImagem[indexIdWhiteLabel].logo : null) !==
                        JSON.stringify(AuthService.obterLogo(variaveisGlobais)) ||
                    JSON.stringify(jsonConfiguracao.whiteLabel[indexIdWhiteLabel].tamanhoLogo ? jsonConfiguracao.whiteLabel[indexIdWhiteLabel].tamanhoLogo : null) !==
                        JSON.stringify(AuthService.obterTamanhoLogo(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem[indexIdWhiteLabel].logoLoginSidenav ? jsonImagem[indexIdWhiteLabel].logoLoginSidenav : null) !==
                        JSON.stringify(AuthService.obterLogoLoginSidenav(variaveisGlobais)) ||
                    //JSON.stringify(jsonImagem[indexIdWhiteLabel].background ? jsonImagem[indexIdWhiteLabel].background : null) !==
                    //    JSON.stringify(AuthService.obterBackground(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorio ? jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorio : null) !==
                        JSON.stringify(AuthService.obterBackgroundCapaRelatorio(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorioPaisagem ? jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorioPaisagem : null) !==
                        JSON.stringify(AuthService.obterBackgroundCapaRelatorioPaisagem(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorio ? jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorio : null) !==
                        JSON.stringify(AuthService.obterBackgroundPaginaRelatorio(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorioPaisagem ? jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorioPaisagem : null) !==
                        JSON.stringify(AuthService.obterBackgroundPaginaRelatorioPaisagem(variaveisGlobais)) ||
                    JSON.stringify(paletaCores) !== JSON.stringify(AuthService.obterPaletaCores())
            ) {

                AuthService.setarLogo(jsonImagem[indexIdWhiteLabel].logo ? jsonImagem[indexIdWhiteLabel].logo : null)

                AuthService.setarTamanhoLogo(jsonConfiguracao.whiteLabel[indexIdWhiteLabel].tamanhoLogo ? jsonConfiguracao.whiteLabel[indexIdWhiteLabel].tamanhoLogo : null)

                AuthService.setarLogoLoginSidenav(
                    jsonImagem[indexIdWhiteLabel].logoLoginSidenav ? jsonImagem[indexIdWhiteLabel].logoLoginSidenav : null)

                //AuthService.setarBackground(jsonImagem[indexIdWhiteLabel].background ? jsonImagem[indexIdWhiteLabel].background : null)
                
                AuthService.setarBackgroundCapaRelatorio(
                    jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorio ? jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorio : null)
                
                AuthService.setarBackgroundCapaRelatorioPaisagem(
                    jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorioPaisagem ? jsonImagem[indexIdWhiteLabel].backgroundCapaRelatorioPaisagem : null)
                
                AuthService.setarBackgroundPaginaRelatorio(
                    jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorio ? jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorio : null)
                
                AuthService.setarBackgroundPaginaRelatorioPaisagem(
                    jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorioPaisagem ? jsonImagem[indexIdWhiteLabel].backgroundPaginaRelatorioPaisagem : null)

                AuthService.setarPaletaCores(paletaCores)

                window.location.reload()

                await delay(5)
            }

            return true

        } catch (erro) {

            mensagemErroErro(erro)

            return false
        }

    } else {

        return true
    }
}



export const postRespostaSellFlux = async (variaveisGlobais, idSellFlux, email, nome, celular) => {

    const respostaSellFluxService = new RespostaSellFluxService()

    let respostaOk = false

    try {

        if (idSellFlux) {

            respostaOk = await respostaSellFluxService.respostaSellFlux(variaveisGlobais, idSellFlux, email, nome, celular)
        }

    } catch (erro) {

        mensagemErroErro(erro)
    }

    return respostaOk
}

export const putReiniciarFreetrack = async (variaveisGlobais, email) => {

    const usuarioService = new UsuarioService()

    let respostaOk = false

    try {

        await usuarioService.reiniciarFreetrack(variaveisGlobais, email)

        respostaOk = true

    } catch (erro) {

        mensagemErroErro(erro)
    }

    return respostaOk
}



export const senhaOK = (senha) => {

    const regex = new RegExp(HARDCODE.regExpValidaSenha)

    return regex.test(senha)
}