const statusParcelaList = [
    {
        id: 'P',
        descricao: 'Paga',
        chaves: [
            {
                id: 'PAGA',
            },
            {
                id: 'pago',
            },
        ],
        aberta: 'N',
        cancelada: 'N',
        paga: 'S',
        ordem: 10,
    },
    {
        id: 'C',
        descricao: 'Cancelada',
        chaves: [
            {
                id: 'CANCELADA',
            },
        ],
        aberta: 'N',
        cancelada: 'S',
        paga: 'N',
        ordem: 20,
    },
    {
        id: 'E',
        descricao: 'Pendente',
        chaves: [
            {
                id: 'EMITIDA',
            },
            {
                id: 'pendente',
            },
        ],
        aberta: 'S',
        cancelada: 'N',
        paga: 'N',
        ordem: 30,
    },
    {
        id: 'T',
        descricao: 'Em Trânsito',
        chaves: [
            {
                id: 'EM_TRANSITO',
            },
        ],
        aberta: 'S',
        cancelada: 'N',
        paga: 'N',
        ordem: 40,
    },
    {
        id: 'I',
        descricao: 'Inconsistente',
        chaves: [
            {
                id: 'INCONSISTENTE',
            },
        ],
        aberta: 'N',
        cancelada: 'S',
        paga: 'N',
        ordem: 50,
    },
    {
        id: 'R',
        descricao: 'Recusada',
        chaves: [
            {
                id: 'RECUSADA',
            },
        ],
        aberta: 'N',
        cancelada: 'S',
        paga: 'N',
        ordem: 60,
    },
    {
        id: 'B',
        descricao: 'Reembolsada',
        chaves: [
            {
                id: 'Reembolsado',
            },
        ],
        aberta: 'N',
        cancelada: 'S',
        paga: 'N',
        ordem: 70,
    },
]

export default statusParcelaList