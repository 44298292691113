import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    formataData,
} from '../../../business-components/Date/Date'

import {
    formataValor,
    formataPerc,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'



export const obtemPagina = (pagina) => {
    pagina.current++

    return pagina.current
}



export const paginas = ( array, tamPagina, primPagina ) => {

    let paginasAux = []

    if (!tamPagina) {

        tamPagina = HARDCODE.reportLinhasSemGraficoRetrato
    }

    if (!primPagina) {
        
        primPagina = tamPagina
    }

    let qtdePaginas = 1
    
    if (array.length > primPagina) {

        qtdePaginas += Math.floor((array.length - primPagina) / tamPagina)

        if ((array.length - primPagina) % tamPagina !== 0) {

            qtdePaginas++
        }
    }

    let ind = 0

    while (qtdePaginas > 0) {

        const tamPaginaAux = ind === 0 ? primPagina : tamPagina

        paginasAux.push(
            {
                indInicial: ind,
                indFinal: ind +  tamPaginaAux - 1,
            }
        )

        ind += tamPaginaAux
        qtdePaginas--
    }

    return paginasAux
}



export const calcEstudoReportCapaTitulo1 = (vars) => {

    return (
        vars.jsonConfiguracao && vars.jsonConfiguracao.whiteLabel[vars.indexIdWhiteLabel].estudoReportCapaTitulo1
            ?
                vars.jsonConfiguracao.whiteLabel[vars.indexIdWhiteLabel].estudoReportCapaTitulo1
            :
                MESSAGE().estudoReportCapaTitulo1
    )
}

export const calcProfissaoQualificacao = (jsonUsuario) => {

    return (
        jsonUsuario && jsonUsuario.profissaoQualificacao
            ?
                jsonUsuario.profissaoQualificacao
            :
                MESSAGE().profissaoQualificacao
    )
}



export const reportLinhasSemGrafico = (values) => {

    return values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato
        ?
            HARDCODE.reportLinhasSemGraficoRetrato
        :
            HARDCODE.reportLinhasSemGraficoPaisagem
}

export const reportLinhasComGrafico = (values) => {

    return values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato
        ?
            HARDCODE.reportLinhasComGraficoRetrato
        :
            HARDCODE.reportLinhasComGraficoPaisagem
}

export const calculaJsonPaginaPaginaAdicional = (values, vars) => {

    const jsonPaginaPaginaAdicional = []

    if (vars.jsonPagina.configuracaoPagina) {
        jsonPaginaPaginaAdicional.push(
            ...vars.jsonPagina.configuracaoPagina
        )
    }
    if (values.paginaAdicional) {
        jsonPaginaPaginaAdicional.push(
            ...values.paginaAdicional
        )
    }

    return jsonPaginaPaginaAdicional
}

export const calcularPaginas = (values, jsonPaginaPaginaAdicional, linhaReportImprimirId) => {

    let temContasInvestimentos = false

    pesquisaList(jsonPaginaPaginaAdicional,
        linhaReportImprimirId.substring(
            HARDCODE.estudoFormPagina.length)).itemPagina?.forEach(
                (linhaItemPagina, indexItemPagina) => {

        if (
                linhaItemPagina.tipoItemPagina === HARDCODE.idTipoItemPaginaTextoContasInvestimentos ||
                linhaItemPagina.tipoItemPagina === HARDCODE.idTipoItemPaginaHTMLContasInvestimentos
        ) {

            temContasInvestimentos = true
        }
    })

    let linhaContasInvestimentos = []

    if (temContasInvestimentos) {

        values.contasInvestimentos?.forEach( (linha, ind) => {

            if (linha.posicao) {

                linhaContasInvestimentos.push(
                    linha
                )
            }
        })

    } else {

        linhaContasInvestimentos.push(null)
    }

    return linhaContasInvestimentos
}

export const substituirVariaveis = (tipoItemPagina, richTextHtml, values, estudo, linhaContasInvestimentos) => {

    if (
            tipoItemPagina === HARDCODE.idTipoItemPaginaTextoVariavel ||
            tipoItemPagina === HARDCODE.idTipoItemPaginaHTMLVariavel ||
            tipoItemPagina === HARDCODE.idTipoItemPaginaTextoContasInvestimentos ||
            tipoItemPagina === HARDCODE.idTipoItemPaginaHTMLContasInvestimentos
    ) {

        richTextHtml = richTextHtml.replaceAll('$nomeCliente$', values.nomeCliente)
        richTextHtml = richTextHtml.replaceAll('$nomeConjuge$', values.nomeConjuge)
    
    }

    if (
            (
                tipoItemPagina === HARDCODE.idTipoItemPaginaTextoContasInvestimentos ||
                tipoItemPagina === HARDCODE.idTipoItemPaginaHTMLContasInvestimentos
            ) &&
            linhaContasInvestimentos &&
            linhaContasInvestimentos.posicao
    ) {

        richTextHtml = richTextHtml.replaceAll('$patrimonioTotalBruto$',
            formataValor(linhaContasInvestimentos.posicao.patrimonioTotalBruto, 2))

        richTextHtml = richTextHtml.replaceAll('$rentabilidadeMes$',
            formataPerc(linhaContasInvestimentos.posicao.rentabilidadeMes, 2))

        richTextHtml = richTextHtml.replaceAll('$ganhoMes$',
            formataValor(linhaContasInvestimentos.posicao.ganhoMes, 2))

        richTextHtml = richTextHtml.replaceAll('$rentabilidade24MesesOuDesdeInicio$',
            formataPerc(linhaContasInvestimentos.posicao.rentabilidade24MesesOuDesdeInicio, 2))

        richTextHtml = richTextHtml.replaceAll('$ganho24MesesOuDesdeInicio$',
            formataValor(linhaContasInvestimentos.posicao.ganho24MesesOuDesdeInicio, 2))

        richTextHtml = richTextHtml.replaceAll('$mesesPositivos$', linhaContasInvestimentos.posicao.mesesPositivos)

        richTextHtml = richTextHtml.replaceAll('$mesesNegativos$', linhaContasInvestimentos.posicao.mesesNegativos)

        richTextHtml = richTextHtml.replaceAll('$mesesTotal$', linhaContasInvestimentos.posicao.mesesTotal)

        richTextHtml = richTextHtml.replaceAll('$dataReferencia$', formataData(linhaContasInvestimentos.posicao.dataReferencia))
    }

    return richTextHtml
}