import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import NUMMIN from '../NumMinMax/NumMin'

import NUMMAX from '../NumMinMax/NumMax'

import TAMMAX from '../TamMax/TamMax'

import simNaoList from "./simNaoList"

import tipoInvestimentoList from "./tipoInvestimentoList"

const formPrimeiraReuniaoInvestimento10List = [
    {
        id: 10,
        descricao: 'Já possui experiência com investimento?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        ordem: 10,
    },
    {
        id:  20,
        descricao: 'Quais tipos de investimento você possui?',
        default: true,
        grupoPergunta: '',
        type: 'MultiList',
        list: tipoInvestimentoList,
        labelButtonIncluirLinha: LABEL.buttonIncluirTipoInvestimento,
        ordem: 20,
    },
    {
        id: 30,
        descricao: 'Em qual Banco ou Corretora você investe hoje?',
        default: true,
        grupoPergunta: '',
        type: 'String',
        tamMax: TAMMAX.instituicao,
        ordem: 30,
    },
    {
        id: 40,
        descricao: 'Quanto você possui disponível para investir atualmente?',
        descricaoResumida: 'Quanto possui para investir',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        ordem: 40,
    },
    {
        id: 50,
        descricao: 'Qual o valor total dos seus investimentos hoje?',
        descricaoResumida: 'Recursos Financeiros',
        default: true,
        obrigatorio: true,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaRecursosFinanceiros,
        ordem: 50,
    },
    {
        id: 60,
        descricao: 'Você tem algum dependente financeiro? Se sim, quantos',
        descricaoResumida: 'Dependentes Financeiros',
        default: true,
        grupoPergunta: '',
        type: 'Inteiro',
        numMin: NUMMIN.qtdeDependentes,
        numMax: NUMMAX.qtdeDependentes,
        ordem: 60,
    },
    {
        id: 70,
        descricao: 'Qual é o seu gasto mensal?',
        descricaoResumida: 'Gasto Anual',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaDiaDia,
        naoAtualiza: 135,
        ordem: 70,
    },
    {
        id: 80,
        descricao: 'Qual é a sua renda mensal?',
        descricaoResumida: 'Receita Anual',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaReceitaMensal,
        ordem: 80,
    },
    {
        id: 90,
        descricao: 'Terá necessidade de utilizar parte do capital investido nos próximos 5 anos?',
        descricaoResumida: 'Necessidade de utilização próximos 5 anos',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        ordem: 90,
    },
    {
        id: 100,
        descricao: 'Qual o valor de todo o seu patrimônio imobilizado?',
        descricaoResumida: 'Patrimônio (Imobilizado)',
        default: true,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaPatrimonioImobilizado,
        ordem: 100,
    },
    {
        id: 105,
        descricao: 'Possui financiamentos?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeFinanciamento,
        compoeCompleto: true,
        ordem: 105,
    },
    {
        id: 110,
        descricao: 'Qual a renda que você gostaria de ter na ' + LABEL.aposentadoria.toLowerCase() + '?',
        default: true,
        obrigatorio: true,
        grupoPergunta: '',
        type: 'Valor',
        ordem: 110,
    },
    {
        id: 120,
        descricao: 'Você precisará utilizar a renda mensal dos seus investimentos?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        ordem: 120,
    },
    {
        id: 130,
        descricao: 'Pretente fazer algum aporte mensal?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        ordem: 130,
    },
    {
        id: 135,
        descricao: 'Qual renda mensal você gostaria de deixar para a família na sua falta, e por quanto tempo?',
        descricaoResumida: 'Renda Mensal gostaria deixar para a Família na sua Falta',
        default: true,
        grupoPergunta: '',
        type: 'ValorAnos',
        atualiza: HARDCODE.formListAtualizaDiaDia,
        ordem: 135,
    },
    {
        id: 140,
        descricao: 'Você já tem algum plano de proteção para a sua família e seu patrimônio?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        ordem: 140,
    },
    {
        id: 150,
        descricao: 'A nossa equipe de seguros pode analisar as suas apólices para ver se conseguimos um custo/benefício mais interessante?',
        default: true,
        grupoPergunta: '',
        type: 'SimNao',
        list: simNaoList,
        perguntaCondicionadaSim: 140,
        ordem: 150,
    },
    {
        id: 160,
        descricao: 'Possui despesas do dia a dia?',
        default: false,
        grupoPergunta: '',
        type: HARDCODE.formTypeDiaDia,
        compoeCompleto: true,
        ordem: 160,
    },
    {
        id: 170,
        descricao: 'Possui despesas com educação?',
        default: false,
        grupoPergunta: '',
        type: HARDCODE.formTypeEducacao,
        compoeCompleto: true,
        ordem: 170,
    },
    {
        id: 190,
        descricao: 'Possui projetos de vida/sonho?',
        default: false,
        grupoPergunta: '',
        type: HARDCODE.formTypeProjetoVidaSonho,
        compoeCompleto: true,
        ordem: 190,
    },
    {
        id: 200,
        descricao: 'Possui patrimônio imobilizado?',
        default: false,
        grupoPergunta: '',
        type: HARDCODE.formTypePatrimonioImobilizado,
        compoeCompleto: true,
        ordem: 200,
    },
    {
        id: 210,
        descricao: 'Possui receita mensal?',
        default: false,
        grupoPergunta: '',
        type: HARDCODE.formTypeReceitaMensal,
        compoeCompleto: true,
        ordem: 210,
    },
    {
        id: 220,
        descricao: 'Possui recursos financeiros?',
        default: false,
        grupoPergunta: '',
        type: HARDCODE.formTypeRecursosFinanceiros,
        compoeCompleto: true,
        ordem: 220,
    },
]
 
export default formPrimeiraReuniaoInvestimento10List