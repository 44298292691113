import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import LABELESPECIAL from '../../../business-const/Label/LabelEspecial'

import {
    STYLEHTML
} from '../../../business-const/Style/Style'

import {
    round
} from '../../../business-components/round/round'

import {
    gerarArray,
} from '../../../business-components/Array/Array'

import {
    dataValida,
    ano,
    mes,
    formataMesAnoComBarra,
    formataMesAnoSemBarra,
    calculaMeses,
} from '../../../business-components/Date/Date'

import {
    nvl,
    formataValor,
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosTextoIdade,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosPerc,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosSubTotalPerc,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import tipoPeriodicidadeList from 'app/business-rules/List/tipoPeriodicidadeList'

import tipoGrupoDespesaList from 'app/business-rules/List/tipoGrupoDespesaList'

import tipoRecursoFinanceiroList from '../../../business-rules/List/tipoRecursoFinanceiroList'

import familiaresList from '../../../business-rules/List/familiaresList'

import instituicaoFinanceiraList from '../../../business-rules/List/instituicaoFinanceiraList'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    calculaJurosMensalSaldo,
    calculaIdadesDataSimulacao,
    calculaValorAcumulado,
} from '../cliente-form/ClienteService'

import {
    calculaTotalPanel
} from '../estudo-panel/EstudoServicePanel'

import {
    calculaSimulacaoFinanceiraEvolucaoReservaFinanceiraGraficosPlanilha
} from '../simulacaoFinanceira-form/SimulacaoFinanceiraService'

import {
    processaRegistrosDefaultIniciaisPatrimonioImobilizado,
} from '../garantias-form/GarantiasServicePatrimonioImobilizado'

import {
    processaRegistrosDefaultIniciaisReceitaMensal,
    incluiReceitaMensalInssPensaoAluguelPatrimonioImobilizadoAluguel,
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    processaRegistrosDefaultIniciaisRecursosFinanceiros,
    incluiRecursosFinanceirosFGTSContribuicaoReceitaMensalCLT,
    calculaTotaisRecursosFinanceiros,
    calculaRecursosFinanceiroFamiliarTipoRecursoFinaceiro,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    calculaValorCSCotacao,
} from '../seguroVidaCotacao-form/SeguroVidaCotacaoService'

import {
    existeTabelaEvolucaoValorResgate,
} from '../seguroVidaCotacao-form/SeguroVidaCotacaoServiceCotacao'

import {
    incluiSeguroVidaSeguroVidaEmGrupo,
    calculaValorCS,
} from '../seguroVida-form/SeguroVidaService'

import {
    dadosCompletosLinhaSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'

import {
    processaRegistrosDefaultIniciaisDiaDia
} from '../despesas-form/DespesasServiceDiaDia'

import {
    processaRegistrosDefaultIniciaisEducacao,
} from '../despesas-form/DespesasServiceEducacao'

import {
    processaRegistrosDefaultIniciaisFinanciamento,
    calculaValorParcelaTabelaSAC,
} from '../despesas-form/DespesasServiceFinanciamento'

import {
    processaRegistrosDefaultIniciaisProjetoVidaSonho
} from '../projetoVidaSonho-form/ProjetoVidaSonhoServiceProjetoVidaSonho'

import {
    calculaGraficoComparacaoFinanciamento,
} from '../estudo-form/EstudoServiceComparacaoFinanciamentos'

import {
    calculaRealocacaoCarteiraInvestimentos,
    processaRecursoFinanceiro,
} from '../estudo-form/EstudoServiceGarantiasRecursosFinanceiros'

import {
    calculaItcmdEnderecoResidencial,
} from '../estudo-form/EstudoServiceSucessaoVitalicia'

import {
    atualizarCenario,
} from '../estudo-parametros/EstudoServiceCenarios'

import {
    processaCalculoTaxas,
} from '../estudo-parametros/EstudoServiceCenariosTaxas'

import {
    calculaClienteConjugePercRespConjuge
} from '../estudo-parametros/EstudoServiceClienteConjuge'

import {
    calculaDespesasEducacao,
} from './EstudoServiceDespesasEducacao'

import {
    calculaDespesasFinanciamento,
} from './EstudoServiceDespesasFinanciamento'
 
import {
    calculaDespesasDiaDiaDespesasTemporarias,
} from './EstudoServiceDespesasDiaDiaDespesasTemporarias'

import {
    calculaProjetoVidaSonho,
} from './EstudoServiceProjetoVidaSonho'

import {
    processaAlteracaoSucessaoVitaliciaMesesEmergencialCliente,
    processaAlteracaoSucessaoVitaliciaMesesEmergencialConjuge,
    calculaSucessaoVitalicia,
    calculaSucessaoVitaliciaAtualizaGraficoSucessaoVitaliciaTaxaPonderada,
} from './EstudoServiceSucessaoVitalicia'

import {
    calculaDespesasDiaDiaDespesasVitalicias,
} from './EstudoServiceDespesasDiaDiaDespesasVitalicias'

import {
    calculaGarantiasPatrimonioImobilizado,
} from './EstudoServiceGarantiasPatrimonioImobilizado'

import {
    calculaGarantiasReceitaMensal,
} from './EstudoServiceGarantiasReceitaMensal'

import {
    acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa,
    calculaGarantiasRecursosFinanceiros,
    incluiAportePlanejadoAposentadoria,
} from './EstudoServiceGarantiasRecursosFinanceiros'

import {
    calculaSeguroVida,
} from './EstudoServiceSeguroVida'

import {
    gerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico,
} from './EstudoServicoPlanejarEspecializacaoPeriodoSabatico'

import {
    calculaNecessidadeProtecao,
} from './EstudoServiceNecessidadeProtecao'

import {
    calculaAlertaCamposObrigatorios
} from './EstudoServiceAlertaCamposObrigatorios'

import {
    calculaIndexAposentadoria,
    calculaDiagnosticoAposentadoria
} from './EstudoServiceDiagnosticoAposentadoria'

import {
    calculaTaxaRetornoLiquida,
    calculaTaxaJurosAnual,
    converteTaxaAnualEmMensal,
    corrigeInflacaoAnual,
} from './EstudoServiceMatematicaFinanceira'

import {
    calculaIdadeCorrespondenteAno,
} from './EstudoService'



const calculaGraficoAnosInicializaSeries = ( values, estudo ) => {
    let anoAux = ano(values.dataSimulacaoEstudo)
    let idadeAux = values.idadeDataSimulacaoCliente

    while (anoAux <= values.anoFinalVitalicioClienteConjuge) {
        estudo.current.graficoAnos.push(
            anoAux
        )

        estudo.current.graficoIdades.push(
            idadeAux
        )

        estudo.current.graficoDiaDiaDespesasVitalicias.push(
            0
        )

        estudo.current.graficoEducacao.push(
            0
        )

        estudo.current.graficoFinanciamento.push(
            0
        )

        estudo.current.graficoDiaDiaDespesasTemporarias.push(
            0
        )

        estudo.current.graficoProjetoVidaSonho.push(
            0
        )

        //////estudo.current.graficoSucessaoVitalicia.push(
        //////    0
        //////)
        estudo.current.graficoSucessaoVitaliciaRecursosFinanceiros.push(
            0
        )

        //////estudo.current.graficoSucessaoVitaliciaPatrimonioImobilizado.push(
        //////    0
        //////)

        //////estudo.current.graficoSucessaoVitaliciaRecursosFinanceiros.push(
        //////    0
        //////)

        estudo.current.graficoDespesasAcumuladas.push( // não está sendo usado ainda
            0
        )
        
        estudo.current.graficoReceitaAnualTotal.push(
            0
        )

        estudo.current.graficoReceitaAnualTotalMedia.push(
            0
        )

        estudo.current.graficoReceitaMensal.push( // usado apenas para o cálculo do valor presente da receita mensal
            0
        )
        
        estudo.current.graficoDespesaAnualTotal.push(
            0
        )

        estudo.current.graficoDespesaAnualTotalMedia.push(
            0
        )

        estudo.current.graficoProjetoVidaSonhoAnualTotal.push(
            0
        )
        
        estudo.current.graficoAporteAnualTotal.push(
            0
        )
        
        estudo.current.graficoAporteAposentadoriaEconomiaReceitaMensalTotal.push(
            0
        )
        
        //estudo.current.graficoRetiradaReservaAposentadoriaComplementoReceitaMensalTotal.push(
        //    0
        //)

        estudo.current.graficoAporteAtingirMetaInicioAposentadoriaTotal.push(
            0
        )

        estudo.current.graficoBalancoAnualTotal.push(
            0
        )

        estudo.current.graficoBalancoAnualTotalComTracarMetaAporte.push(
            0
        )

        estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento.push(
            0
        )

        estudo.current.graficoRecursosFinanceirosAcumuladosTotal.push(
            0
        )

        estudo.current.graficoDespesasAcumuladasExcedentes.push(
            0
        )

        estudo.current.graficoLiquidezFinanceiraAcumulada.push(
            0
        )

        estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumulado.push(
            0
        )

        estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumulado.push(
            0
        )

        estudo.current.graficoIndependenciaFinaceiraRetiradaMaximaConsumirRecursosFinanceiros.push(
            0
        )

        estudo.current.graficoIndependenciaFinaceiraRetiradaMaximaPreservarRecursosFinanceiros.push(
            0
        )

        estudo.current.evolucaoReservaFinanceiraEstudo.push({
            ano: anoAux,
            idade: idadeAux,
        })

        estudo.current.evolucaoReservaFinanceira.push({
            ano: anoAux,
            idade: idadeAux,
        })
        
        anoAux++
        idadeAux++
    }
}

const calculaMaiorPeriodoGraficoIdadesInicializa = () => {

    let anoAux = 9999
    let idadeAux = 999
    let anoFinalVitalicioClienteConjuge = 0

    return {anoAux, idadeAux, anoFinalVitalicioClienteConjuge}
}

const calculaMaiorPeriodoGraficoIdadesGraficoIdades = ( anoAux, idadeAux, anoFinalVitalicioClienteConjuge ) => {

    const graficoIdades = []

    while (anoAux <= anoFinalVitalicioClienteConjuge) {

        graficoIdades.push(
            idadeAux
        )

        anoAux++
        idadeAux++
    }

    return graficoIdades
}

export const calculaMaiorPeriodoGraficoIdadesCenarios = ( values ) => {

    let { anoAux, idadeAux, anoFinalVitalicioClienteConjuge } = calculaMaiorPeriodoGraficoIdadesInicializa()

    values.cenarios?.forEach( ( linhaCenario, index ) => {

        if (ano(linhaCenario.dataSimulacaoEstudo) < anoAux) {

            anoAux = ano(linhaCenario.dataSimulacaoEstudo)
        }

        if (linhaCenario.idadeDataSimulacaoCliente < idadeAux) {

            idadeAux = linhaCenario.idadeDataSimulacaoCliente
        }

        if (linhaCenario.anoFinalVitalicioClienteConjuge > anoFinalVitalicioClienteConjuge) {

            anoFinalVitalicioClienteConjuge = linhaCenario.anoFinalVitalicioClienteConjuge
        }
    })

    return calculaMaiorPeriodoGraficoIdadesGraficoIdades(anoAux, idadeAux, anoFinalVitalicioClienteConjuge)
}


export const calculaMaiorPeriodoGraficoIdadesEstudos = ( values ) => {

    let { anoAux, idadeAux, anoFinalVitalicioClienteConjuge } = calculaMaiorPeriodoGraficoIdadesInicializa()

    values.jsonComparacaoEstudos.estudos?.forEach( (linhaEstudos, indexEstudos) => {

        const cenarioPrincipal = pesquisaList(linhaEstudos.cenarios, linhaEstudos.cenarioPrincipal)

        if (ano(cenarioPrincipal.dataSimulacaoEstudo) < anoAux) {

            anoAux = ano(cenarioPrincipal.dataSimulacaoEstudo)
        }

        if (cenarioPrincipal.idadeDataSimulacaoCliente < idadeAux) {

            idadeAux = cenarioPrincipal.idadeDataSimulacaoCliente
        }

        if (cenarioPrincipal.anoFinalVitalicioClienteConjuge > anoFinalVitalicioClienteConjuge) {

            anoFinalVitalicioClienteConjuge = cenarioPrincipal.anoFinalVitalicioClienteConjuge
        }
    })

    return calculaMaiorPeriodoGraficoIdadesGraficoIdades(anoAux, idadeAux, anoFinalVitalicioClienteConjuge)
}


export const inicializaSerieGraficoDespesa = (linha, idDetalhe, descricaoDetalhe) => {
    
    const linhaTipoGrupoDespesaList = pesquisaList(tipoGrupoDespesaList, linha.grupoDespesa)

    return {
        id: linha.grupoDespesa + (idDetalhe ? '-' + idDetalhe : ''),
        descricao:
            linha.grupoDespesa === HARDCODE.idGrupoDespesaOutras && descricaoDetalhe
                ?
                    descricaoDetalhe
                :
                    linhaTipoGrupoDespesaList.descricao +
                        (
                            descricaoDetalhe
                                ?
                                    LABEL.traco + descricaoDetalhe
                                :
                                    ''
                        ),
        ordem: linhaTipoGrupoDespesaList.ordem,
    }
}

export const inicializaSerieGrafico = ( values, estudoGrafico, linhaTipo, idOutrosNULL, descricaoOutrosNULL ) => {

    let anoAux = ano(values.dataSimulacaoEstudo)

    let indexGrafico = ''

    const id = idOutrosNULL ? idOutrosNULL : linhaTipo.id

    estudoGrafico?.forEach( (linhaEstudoGrafico, index) => {

        if (linhaEstudoGrafico.id === id) {
            indexGrafico = index
        }
    })

    if (!indexGrafico && indexGrafico !==0) {
        
        estudoGrafico.push(
            {
                id: id,
                descricao: descricaoOutrosNULL ? descricaoOutrosNULL : linhaTipo.descricao,
                ordem: linhaTipo.ordem,
                serie: [],
            }
        )

        indexGrafico = estudoGrafico.length - 1

        while (anoAux <= values.anoFinalVitalicioClienteConjuge) {
            estudoGrafico[indexGrafico].serie.push(
                0
            )

            anoAux++
        }
    }

    return indexGrafico
}

export const graficoPossuiValor = ( estudoGrafico ) => {

    let possuiValor = false

    estudoGrafico?.forEach( (linhaEstudoGrafico, index) => {
        if (linhaEstudoGrafico) {
            possuiValor = true
        }
    })

    return possuiValor
}

export const graficoTransformaZeroNull = ( estudoGrafico ) => {

    estudoGrafico?.forEach( (linhaEstudoGrafico, index) => {

        if (linhaEstudoGrafico === 0) {

            estudoGrafico[index] = null
        }
    })
}

export const ajustaValorNegativo = (valor, tamanho, index) => {

    if (valor < 0 && valor > -5 && tamanho - 1 === index) {

        valor = 0.01
    }

    return valor
}

export const graficoAjustaUltimoValorNegativo = ( grafico, index ) => {

    grafico[index] = ajustaValorNegativo(grafico[index], grafico.length, index)
}

export const inicializaEstudo = (/*jsonConfiguracao*/) => {
    return {
        graficoAnos: [],
        graficoIdades: [],
        graficoDiaDiaDespesasVitalicias: [],
        graficoEducacao: [],
        graficoFinanciamento: [],
        graficoDiaDiaDespesasTemporarias: [],
        graficoProjetoVidaSonho: [],
        //////graficoSucessaoVitalicia: [],
        graficoSucessaoVitaliciaRecursosFinanceiros: [],
        //////graficoSucessaoVitaliciaPatrimonioImobilizado: [],
        //////graficoSucessaoVitaliciaRecursosFinanceiros: [],
        graficoDespesasAcumuladas: [], // não está sendo usada ainda

        graficoSeguroVidaCobertura: [],

        graficoReceitaAnual: [],
        graficoReceitaAnualMedia: [],
        graficoReceitaAnualTotal: [],
        graficoReceitaAnualTotalMedia: [],
        graficoDespesaAnual: [],
        graficoDespesaAnualMedia: [],
        graficoDespesaAnualTotal: [],
        graficoDespesaAnualTotalMedia: [],
        graficoConsumoReservaAposentadoria: [],
        graficoProjetoVidaSonhoAnualTotal: [],
        graficoAporteAnualTotal: [],
        graficoAporteAposentadoriaEconomiaReceitaMensalTotal: [],
        //graficoRetiradaReservaAposentadoriaComplementoReceitaMensalTotal: [],
        graficoAporteAtingirMetaInicioAposentadoriaTotal: [],
        graficoBalancoAnualTotal: [],
        graficoBalancoAnualTotalComTracarMetaAporte: [],
        necessidadeAposentadoria: [],
        aportePlanejadoAposentadoria: [],

        graficoReceitaMensal: [], // usado apenas para o cálculo do valor presente da receita mensal

        graficoRecursosFinanceirosAcumulados: [],
        //graficoEstrategiasFinanceirasRecursosFinanceirosAcumulados: [],
        graficoEstrategiasFinanceirasTabelaEvolucaoValorResgate: [],
        graficoEstrategiasFinanceirasCapitalSegurado: [],
        //graficoEstrategiasFinanceirasEconomiaInventarioSeguroVida: [],
        estrategiasFinanceirasTabelaEvolucaoValorResgate: [],
        estrategiasFinanceirasTabelaEvolucaoValorResgateSeguradoras: [],
        graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento: [],
        graficoRecursosFinanceirosAcumuladosTotal: [],
        graficoDespesasAcumuladasExcedentes: [],
        graficoLiquidezFinanceiraAcumulada: [],

        
        graficoDiagnosticoAposentadoriaRecursosFinanceirosAcumulados: [],
        graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual: [],
        graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtualSuperavit: [],
        graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtualDeficit: [],
        graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaViverRentabilidade: [],
        graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaIdeal: [],
        graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumulado: [],
        graficoDiagnosticoAposentadoriaDepositoAcumulado: [],
        graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumuladoAteAposentadoria: [],
        graficoDiagnosticoAposentadoriaDepositoAcumuladoAteAposentadoria: [],

        graficoIndependenciaFinanceiraSituacaoAtual: [],
        graficoIndependenciaFinanceiraSituacaoAtualSuperavit: [],
        graficoIndependenciaFinanceiraSituacaoAtualDeficit: [],
        graficoIndependenciaFinanceiraViverRentabilidade: [],
        graficoIndependenciaFinanceiraSituacaoIdeal: [],
        graficoIndependenciaFinanceiraPreservarReservaAposentadoria: [],
        graficoIndependenciaFinaceiraRetiradaMaximaConsumirRecursosFinanceiros: [],
        graficoIndependenciaFinaceiraRetiradaMaximaPreservarRecursosFinanceiros: [],
        graficoIndependenciaFinanceiraConsumirReservaAposentadoria: [],

        

        evolucaoReservaFinanceiraEstudo: [],



        evolucaoReservaFinanceira: [],
        graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal: [],
        graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoRealSuperavit: [],
        graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoRealDeficit: [],
        graficoIndependenciaFinanceiraPrimeiraReuniaoViverRentabilidade: [],
        graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoIdeal: [],
        graficoIndependenciaFinanceiraPrimeiraReuniaoPreservarRecursosFinanceiros: [],
        graficoIndependenciaFinanceiraPrimeiraReuniaoConsumirRecursosFinanceiros: [],



        inventario: '',

        diaDiaDespesasVitalicias: '',
        educacao: '',
        financiamento: '',
        diaDiaDespesasTemporarias: '',

        panelDespesasTotais: [{}, {}, {}, {}, {}, {}],



        patrimonioImobilizado: '',
        receitaMensal: '',
        recursosFinanceiros: '',
        seguroVida: '',

        panelGarantiasAtuais: [{}, {}, {}, {}, {}],

        //jsonConfiguracaoEstudo: {
        //    considerarPrevidenciaPrivadaInventario:
        //        jsonConfiguracao ? jsonConfiguracao.considerarPrevidenciaPrivadaInventario : false
        //},

        calculou: false,
    }
}

export const getEstudo = (values, estudo, report, origem/*, jsonConfiguracao*/, calculaComparacaoFinanciamento) => {

    estudo.current = inicializaEstudo(/*jsonConfiguracao*/)

    

    estudo.current.mesAtual = mes(values.dataSimulacaoEstudo)
    estudo.current.anoAtual = ano(values.dataSimulacaoEstudo)
    estudo.current.mesesAnoAtual = 12 - estudo.current.mesAtual + 1



    calculaGraficoAnosInicializaSeries(values, estudo)



    if (
            (origem !== HARDCODE.origemCalculoEstudoEstudo && origem !== HARDCODE.origemCalculoEstudoEstudoReport) ||
            (
                values.tabEstudoFormList !== LABEL.previdenciaPrivada &&
                (
                    !values.tabClienteFormList ||
                    values.tabClienteFormList !== LABEL.tabClienteFormListPrevidenciaPrivada
                )
            )
    ) {

        gerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico(values)



        calculaSucessaoVitalicia(values, estudo, report)


        inicializaDespesaMensalDetalhada(estudo)

        calculaDespesasDiaDiaDespesasVitalicias(values, estudo, report)
        calculaDespesasDiaDiaDespesasTemporarias(values, estudo, report)
        calculaDespesasEducacao(values, estudo, report)
        calculaDespesasFinanciamento(values, estudo, report)

        processaDespesaMensalDetalhada(values, estudo, report)


        calculaProjetoVidaSonho(values, estudo, report)

        estudo.current.panelDespesasTotais[HARDCODE.indexEstudoTotalDespesas] =
            calculaTotalPanel(estudo.current.panelDespesasTotais, LABEL.despesasTotais)



        calculaGarantiasPatrimonioImobilizado(values, estudo, report)
        calculaGarantiasReceitaMensal(values, estudo, report)
        calculaGarantiasRecursosFinanceiros(values, estudo, report)
        calculaSeguroVida(values, estudo, report)

        estudo.current.ativos = estudo.current.valorPatrimonioImobilizadoDetalhadoBensMoveisImoveis +
            estudo.current.valorPatrimonioImobilizadoDetalhadoNegocios + estudo.current.valorRecursosFinanceirosDetalhado


        estudo.current.panelGarantiasAtuais[HARDCODE.indexEstudoTotalGarantias] =
            calculaTotalPanel(estudo.current.panelGarantiasAtuais, LABEL.garantiasTotais)



        values.receitaMensalMediaAtual = round(estudo.current.graficoReceitaAnualTotalMedia[0] / estudo.current.mesesAnoAtual, 2)
        values.despesaMensalMediaAtual = round(estudo.current.graficoDespesaAnualTotalMedia[0] / estudo.current.mesesAnoAtual, 2)
        values.capacidadePoupancaMensalMediaAtual = values.receitaMensalMediaAtual - values.despesaMensalMediaAtual



        calculaGraficoEstrategiasFinanceiras(values, estudo)



        calculaNecessidadeProtecao(values, estudo)



        calculaGraficoBalancoAnualTotalComTracarMeta(values, estudo)
        calculaAporteAtingirMetaRecursosFinanceirosInicioAposentadoria(values, estudo)
        calculaAplicacaoDeducaoConsumoReservaAposentadoria(values, estudo)
        calculaGraficoLiquidezFinanceiraAcumulada(values, estudo)



        calculaDiagnosticoAposentadoria(values, estudo/*, report*/)
        calculaGraficoDiagnosticoAposentadoria(values, estudo, report)



        calculaSucessaoVitaliciaAtualizaGraficoSucessaoVitaliciaTaxaPonderada(values, estudo)



        calculaEvolucaoReservaFinanceiraEstudo(values, estudo)



        if (
                origem === HARDCODE.origemCalculoEstudoPrimeiraReuniaoPlanilha ||
                origem === HARDCODE.origemCalculoEstudoPrimeiraReuniaoReport ||
                origem === HARDCODE.origemCalculoEstudoEstudo ||
                origem === HARDCODE.origemCalculoEstudoEstudoReport
        ) {

            calculaSimulacaoFinanceiraEvolucaoReservaFinanceiraGraficosPlanilha(values, estudo, report, origem)
        }



        if (calculaComparacaoFinanciamento) {

            calculaGraficoComparacaoFinanciamento(values, estudo) 
        }



        graficoTransformaZeroNull(estudo.current.graficoDiaDiaDespesasVitalicias)
        graficoTransformaZeroNull(estudo.current.graficoEducacao)
        graficoTransformaZeroNull(estudo.current.graficoFinanciamento)
        graficoTransformaZeroNull(estudo.current.graficoDiaDiaDespesasTemporarias)
        graficoTransformaZeroNull(estudo.current.graficoProjetoVidaSonho)
        graficoTransformaZeroNull(estudo.current.graficoProjetoVidaSonhoAnualTotal)
        graficoTransformaZeroNull(estudo.current.graficoAporteAnualTotal)
        graficoTransformaZeroNull(estudo.current.graficoAporteAposentadoriaEconomiaReceitaMensalTotal)
        graficoTransformaZeroNull(estudo.current.graficoAporteAtingirMetaInicioAposentadoriaTotal)
        //////graficoTransformaZeroNull(estudo.current.graficoSucessaoVitalicia)
        graficoTransformaZeroNull(estudo.current.graficoSucessaoVitaliciaRecursosFinanceiros)
        //////graficoTransformaZeroNull(estudo.current.graficoSucessaoVitaliciaPatrimonioImobilizado)
        //////graficoTransformaZeroNull(estudo.current.graficoSucessaoVitaliciaRecursosFinanceiros)
        
        estudo.current.graficoReceitaAnual?.forEach( (linhaGraficoReceitaAnual, index) => {
            graficoTransformaZeroNull(linhaGraficoReceitaAnual.serie)
        })

        estudo.current.graficoDespesaAnual?.forEach( (linhaGraficoDespesaAnual, index) => {
            graficoTransformaZeroNull(linhaGraficoDespesaAnual.serie)
        })
        
        estudo.current.graficoConsumoReservaAposentadoria?.forEach( (linhaGraficoConsumoReservaAposentadoria, index) => {
            graficoTransformaZeroNull(linhaGraficoConsumoReservaAposentadoria.serie)
        })

        graficoTransformaZeroNull(estudo.current.graficoEstrategiasFinanceirasTabelaEvolucaoValorResgate)
        graficoTransformaZeroNull(estudo.current.graficoEstrategiasFinanceirasCapitalSegurado)
        //graficoTransformaZeroNull(estudo.current.graficoEstrategiasFinanceirasEconomiaInventarioSeguroVida)
    }



    estudo.current.calculou = true
}



const inicializaDespesaMensalDetalhada = (estudo) => {

    estudo.current.despesaMensalDetalhadaTemp = []
    estudo.current.despesaMensalDetalhada = []
}

export const incluiDespesaMensalDetalhadaTemp = (values, estudo, linhaDespesaMensal, descricaoTipoDespesaMensal, valor,
    tipoPeriodicidadePagto, valorMensal, valorTotal, mesInicial, anoInicial, mesFinal, anoFinal) => {
    
    const linhaTipoGrupoDespesaList = pesquisaList(tipoGrupoDespesaList, linhaDespesaMensal.grupoDespesa)

    const descricaoGrupo =
        linhaDespesaMensal.descricaoGrupoDespesaOutras
            ?
                linhaDespesaMensal.descricaoGrupoDespesaOutras
            :
                linhaTipoGrupoDespesaList.descricao

    const grupo = ("0000" + linhaDespesaMensal.grupoDespesa).slice(-4) + descricaoGrupo

    estudo.current.despesaMensalDetalhadaTemp.push({
        ordem: grupo + ("0000" + linhaDespesaMensal.id).slice(-4),
        grupo: grupo,
        descricaoGrupo: descricaoGrupo, 
        descricaoTipoDespesaMensal: descricaoTipoDespesaMensal,
        valor: valor,
        tipoPeriodicidadePagto: tipoPeriodicidadePagto,
        valorMensal: anoInicial === estudo.current.anoAtual ? valorMensal : 0,
        valorTotal:
            valorTotal
                ?
                    valorTotal
                :
                    calculaValorAcumulado (values, valor, tipoPeriodicidadePagto, mesInicial, anoInicial, mesFinal, anoFinal),
        mesAnoInicial: formataMesAnoComBarra(mesInicial, anoInicial),
        idadeInicial: calculaIdadeCorrespondenteAno(values, estudo, anoInicial, null),
        mesAnoFinal: formataMesAnoComBarra(mesFinal, anoFinal),
        idadeFinal: calculaIdadeCorrespondenteAno(values, estudo, anoFinal, null),
    })

}

const processaDespesaMensalDetalhada = (values, estudo, report) => {

    estudo.current.despesaMensalDetalhadaTemp = sortAsc(estudo.current.despesaMensalDetalhadaTemp)

    let valorMensalTotal = 0

    estudo.current.despesaMensalDetalhadaTemp.forEach( (linhaDespesaMensal, index) => {

        valorMensalTotal += linhaDespesaMensal.valorMensal
    })

    let grupoAnt = null

    estudo.current.despesaMensalDetalhadaTemp.forEach( (linhaDespesaMensal, index) => {

        if (linhaDespesaMensal.grupo !== grupoAnt) {
            
            grupoAnt = linhaDespesaMensal.grupo

            let valorMensal = 0

            let valorTotal = 0

            estudo.current.despesaMensalDetalhadaTemp.forEach( (linhaDespesaMensalTotal, index) => {

                if (linhaDespesaMensalTotal.grupo === linhaDespesaMensal.grupo) {

                    valorMensal += linhaDespesaMensalTotal.valorMensal
                    valorTotal += linhaDespesaMensalTotal.valorTotal
                }
            })

            estudo.current.despesaMensalDetalhada.push({
                descricao: 
                    formataTableCellBodyExibeDadosSubTotalTitulo(linhaDespesaMensal.descricaoGrupo, report),
                valorMensal: 
                    formataTableCellBodyExibeDadosSubTotalValor(valorMensal, report),
                valorTotal: 
                    formataTableCellBodyExibeDadosSubTotalValor(valorTotal, report),
                percMensal:
                    formataTableCellBodyExibeDadosSubTotalPerc(round(valorMensal / valorMensalTotal * 100, 2), report),
            })
        }

        estudo.current.despesaMensalDetalhada.push({
            descricao: formataTableCellBodyExibeDadosTitulo("          " + linhaDespesaMensal.descricaoTipoDespesaMensal, report),
            valor: formataTableCellBodyExibeDadosValor(linhaDespesaMensal.valor, report),
            descricaoTipoPeriodicidadePagto: formataTableCellBodyExibeDadosTexto(
                pesquisaDescricaoList(tipoPeriodicidadeList(false, false), linhaDespesaMensal.tipoPeriodicidadePagto), report),
            valorMensal: formataTableCellBodyExibeDadosValor(linhaDespesaMensal.valorMensal, report),
            percMensal: formataTableCellBodyExibeDadosPerc(round(linhaDespesaMensal.valorMensal / valorMensalTotal * 100, 2), report),
            valorTotal: formataTableCellBodyExibeDadosValor(linhaDespesaMensal.valorTotal, report),
            mesAnoInicial: formataTableCellBodyExibeDadosTextoIdade(
                linhaDespesaMensal.mesAnoInicial, linhaDespesaMensal.idadeInicial, report),
            mesAnoFinal: formataTableCellBodyExibeDadosTextoIdade(
                linhaDespesaMensal.mesAnoFinal, linhaDespesaMensal.idadeFinal, report),
        })
    })

    estudo.current.despesaMensalDetalhada.push({
        descricao:
            formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
        valorMensal:
            formataTableCellBodyExibeDadosTotalValor(valorMensalTotal, report),
    })
}



const calculaClienteConjugeReceitaMensal = ( values ) => {
    values.receitaMensalCliente = pesquisaList(values.totalReceitaMensal, HARDCODE.idFamiliarCliente).valor
        
    values.receitaMensalConjuge = pesquisaList(values.totalReceitaMensal, HARDCODE.idFamiliarConjuge).valor
}

const calculaClienteConjugePercRespCliente = ( values ) => {
    
    calculaClienteConjugeReceitaMensal(values)

    if (!values.alterarPercRespAutomaticoCliente) {
        if ((values.receitaMensalCliente === 0 || values.receitaMensalCliente) &&
            (values.receitaMensalConjuge === 0 || values.receitaMensalConjuge) &&
            (values.receitaMensalCliente > 0 || values.receitaMensalConjuge > 0)) {

            values.percRespCliente =
                Number(values.receitaMensalCliente / (values.receitaMensalCliente + values.receitaMensalConjuge)
                    * 100).toFixed(2)
        } else {
            values.percRespCliente = 100
        }
    }

    calculaClienteConjugePercRespConjuge(values)
}



export const processaCamposCalculados = ( values ) => {

    calculaIdadesDataSimulacao(values)

    calculaClienteConjugePercRespCliente(values)

    processaCalculoTaxas(values)
}

export const processaCalculoEstudo = ( values, handleSubmitAutomatico, estudo, report, sempreCalcular, origem,
    salvar/*, jsonConfiguracao*/, calculaComparacaoFinanciamento ) => {

    if (
            dataValida(values.dataSimulacaoEstudo) &&
            (
                values.recalcularEstudo ||
                !estudo.current.calculou ||
                values.tabIndexEstudo !== values.tabIndexEstudoAnt ||
                values.cenarioAtual !== values.cenarioAtualAnt ||
                report ||
                sempreCalcular
            )
    ) {

        atualizarCenario(values)

        processaCamposCalculados(values)

        calculaAlertaCamposObrigatorios(values/*, estudo*/)

        if (
                origem === HARDCODE.origemCalculoEstudoPrimeiraReuniaoPlanilha || 
                origem === HARDCODE.origemCalculoEstudoPrimeiraReuniaoReport || 
                origem === HARDCODE.origemCalculoEstudoPlanejamentoEspecializacaoPeriodoSabatico || 
                (
                    (origem === HARDCODE.origemCalculoEstudoEstudo || origem === HARDCODE.origemCalculoEstudoEstudoReport) &&
                    //values.tabEstudoFormList !== LABEL.revisaoDados &&
                    //values.tabEstudoFormList !== LABEL.parametros &&
                    //values.tabEstudoFormList !== LABEL.objetivos &&
                    //values.tabEstudoFormList !== LABEL.perguntas &&
                    values.tabEstudoFormList !== LABEL.consorcio
                )
        ) {
            //console.log('entrou')

            if (!values.diaDia) {
            
                processaRegistrosDefaultIniciaisDiaDia(values, HARDCODE.idFamiliarCliente)
            }

            processaRegistrosDefaultIniciaisEducacao(values)

            if (!values.financiamento) {
                processaRegistrosDefaultIniciaisFinanciamento(values, HARDCODE.idFamiliarCliente)
            }

            if (!values.projetoVidaSonho) {
                
                processaRegistrosDefaultIniciaisProjetoVidaSonho(values, HARDCODE.idFamiliarCliente)
            }

            if (!values.patrimonioImobilizado) {
                
                processaRegistrosDefaultIniciaisPatrimonioImobilizado(values, HARDCODE.idFamiliarCliente)
            }

            if (!values.receitaMensal) {
                
                processaRegistrosDefaultIniciaisReceitaMensal(values, HARDCODE.idFamiliarCliente)
            }

            if (!values.recursosFinanceiros) {
                
                processaRegistrosDefaultIniciaisRecursosFinanceiros(values, HARDCODE.idFamiliarCliente)
            }

            incluiReceitaMensalInssPensaoAluguelPatrimonioImobilizadoAluguel(values)

            incluiRecursosFinanceirosFGTSContribuicaoReceitaMensalCLT(values)

            incluiSeguroVidaSeguroVidaEmGrupo(values)

            if (calculaJaAposentou(values, ano(values.dataSimulacaoEstudo))) {

                calculaTotaisRecursosFinanceiros(values)
            }

            processaAlteracaoSucessaoVitaliciaMesesEmergencialCliente(values)
            processaAlteracaoSucessaoVitaliciaMesesEmergencialConjuge(values)
            
            getEstudo(values, estudo, report, origem/*, jsonConfiguracao*/, calculaComparacaoFinanciamento)

            atualizarCenario(values) // Foi necessário para evitar dupla gravação quando faz mudanças em Revisão de Dados e Parâmetros
        }

        if (values.id && salvar) {

            //handleSubmitAutomatico(values)
            handleSubmitAutomatico(values)
        }
    }
}

export const calculaMesesFluxoCaixa = (tipoPeriodicidade, mesInicial, mesAtual) => {

    let mesesFluxoCaixa

    if (tipoPeriodicidade && tipoPeriodicidade !== HARDCODE.idTipoPeriodicidadeMensal) {

        mesesFluxoCaixa = pesquisaList(tipoPeriodicidadeList(false, true),
            tipoPeriodicidade).mesesFluxoCaixa[(mesInicial ? mesInicial : mesAtual) - 1]

    } else {

        mesesFluxoCaixa = null
    }

    return mesesFluxoCaixa
}

export const temFluxoCaixa = (tipoPeriodicidade, mesesFluxoCaixa, mesAux) => {

    return !tipoPeriodicidade || tipoPeriodicidade === HARDCODE.idTipoPeriodicidadeMensal || pesquisaList(mesesFluxoCaixa, mesAux).id
}

export const calculaQtdeParcelas = (mesInicial, anoInicial, mesFinal, anoFinal) => {

    let qtdeParcelas

    if (anoInicial === anoFinal) {

        qtdeParcelas = mesFinal - mesInicial + 1

    } else {

        qtdeParcelas = 12 - mesInicial + 1 + ((anoFinal - anoInicial - 1) * 12) + mesFinal 
    }

    return qtdeParcelas
}

export const calculaMesesValorPresente = (mesInicial, anoInicial, mesFinal, anoFinal, mesAtual, anoAtual, anoCalc, values) => {

    let meses
    let mesesAux
    let mesFinalAux
    let mesesAntesAposentadoria
    let mesesAposAposentadoria

    if (mesInicial && anoCalc === anoInicial) {

        if (anoInicial === anoAtual) {

            meses = 12 - mesAtual + 1

            mesesAux = 12 - mesInicial + 1//meses

            if (mesFinal && anoFinal === anoAtual) {

                meses = meses - 12 + mesFinal

                mesesAux = mesFinal - mesInicial + 1
            }

        } else {

            meses = 12 - mesAtual + 1 + ((anoCalc - anoAtual) * 12)

            mesesAux = 12 - mesInicial + 1

            if (mesFinal && anoCalc === anoFinal) {

                meses = meses - 12 + mesFinal
                mesesAux = mesesAux - 12 + mesFinal
            }
        }

    } else {

        if (mesFinal && anoCalc === anoFinal) {

            if (anoFinal === anoInicial) {

                meses = mesFinal - mesAtual + 1
                mesesAux = meses

            } else {

                meses = 12 - mesAtual + 1 + ((anoCalc - anoAtual - 1) * 12) + mesFinal
                mesesAux = mesFinal
            }

        } else {

            if (anoAtual === anoCalc) {

                meses = 12 - mesAtual + 1
                mesesAux = meses

            } else {

                meses = 12 - mesAtual + 1
                meses = meses + ((anoCalc - anoAtual) * 12)
                mesesAux = 12
            }
        }
    }

    if (anoCalc > values.anoFinalVaiAposentarCliente) {

        mesesAntesAposentadoria = 12 - mesAtual + 1
        mesesAntesAposentadoria = mesesAntesAposentadoria + ((values.anoFinalVaiAposentarCliente - anoAtual) * 12)
        mesesAposAposentadoria = meses - mesesAntesAposentadoria

    } else {

        mesesAntesAposentadoria = meses
        mesesAposAposentadoria =  0
    }

    if (mesFinal && anoFinal === anoCalc) {

        mesFinalAux = mesFinal
    
    } else {

        mesFinalAux = 12
    }

    return {mesesAux, mesFinalAux, mesesAntesAposentadoria, mesesAposAposentadoria}
}

export const calculaReajusteConsorcioEtc = (valorAporteRetiradaCalc, taxaAnualTabelaSACConsorcioEtcNULL,
    mesAnoPrimeiroReajusteConsorcioEtcNULL, mesAux, anoCalc) => {

    const mesesReajusteConsorcio =
        calculaMeses(mesAnoPrimeiroReajusteConsorcioEtcNULL, formataMesAnoSemBarra(mesAux, anoCalc))

    if (mesesReajusteConsorcio > 0) {

        let anosReajusteConsorcio = mesesReajusteConsorcio / 12

        if (!Number.isInteger(anosReajusteConsorcio)) {

            anosReajusteConsorcio = Math.trunc(anosReajusteConsorcio) + 1
        }

        valorAporteRetiradaCalc =
            round(corrigeInflacaoAnual(valorAporteRetiradaCalc, taxaAnualTabelaSACConsorcioEtcNULL, anosReajusteConsorcio), 2)
    }

    return valorAporteRetiradaCalc
}

export const calculaValorPresente = (valor, tipoPeriodicidade, mesInicial, anoInicial, mesFinal, anoFinal, mesAtual, anoAtual, anoCalc, values,
    taxaValorPresenteAnual, taxaValorPresenteAnualAposAposentadoria, qtdeParcelaTabelaSACNULL,
        taxaAnualTabelaSACConsorcioEtcNULL, mesAnoPrimeiroReajusteConsorcioEtcNULL) => {
    
    let taxa
    let valorFluxoCaixa
    let mesFinalTabelaSAC
    let qtdeParcelasTabelaSAC
    let valorCalc
    let valorAux

    // meses = quantidade de meses até o mês inicial
    // mexAux = quantidade de meses a serem calculados no anoCalc

    let { mesesAux, mesFinalAux, mesesAntesAposentadoria, mesesAposAposentadoria } =
        calculaMesesValorPresente(mesInicial, anoInicial, mesFinal, anoFinal, mesAtual, anoAtual, anoCalc, values)

    if (qtdeParcelaTabelaSACNULL) {
        
        if (anoCalc === anoFinal) {

            mesFinalTabelaSAC = mesFinal ? mesFinal : 12

        } else {

            mesFinalTabelaSAC = 12
        }
    }

    valorFluxoCaixa = 0

    const mesesFluxoCaixa = calculaMesesFluxoCaixa(tipoPeriodicidade, mesInicial, mesAtual) 
    
    while (mesesAux > 0) {

        if (temFluxoCaixa(tipoPeriodicidade, mesesFluxoCaixa, mesFinalAux - mesesAux + 1)) {

            if (qtdeParcelaTabelaSACNULL) {

                qtdeParcelasTabelaSAC = qtdeParcelaTabelaSACNULL -
                    calculaQtdeParcelas(mesInicial ? mesInicial : anoInicial === anoCalc ? mesAtual : 1,
                        anoInicial, mesFinalTabelaSAC, anoCalc) + 1
                
                valorCalc =
                    calculaValorParcelaTabelaSAC(valor, qtdeParcelasTabelaSAC, taxaAnualTabelaSACConsorcioEtcNULL)

                mesFinalTabelaSAC--

            } else {

                valorCalc = valor
                
                if (mesAnoPrimeiroReajusteConsorcioEtcNULL) {
                
                    valorCalc = calculaReajusteConsorcioEtc(valorCalc, taxaAnualTabelaSACConsorcioEtcNULL,
                        mesAnoPrimeiroReajusteConsorcioEtcNULL, mesFinalAux - mesesAux + 1, anoCalc)
                }
            }

            if (mesesAposAposentadoria > 0) {

                taxa = Math.pow(1 + (taxaValorPresenteAnualAposAposentadoria/*taxaValorPresenteAnual*/ / 100), (mesesAposAposentadoria - mesesAux) / 12)

                valorAux = valorCalc / taxa

                taxa = Math.pow(1 + (taxaValorPresenteAnual/*taxaValorPresenteAnualAposAposentadoria*/ / 100), mesesAntesAposentadoria / 12)

                valorFluxoCaixa += valorAux / taxa

            } else {

                taxa = Math.pow(1 + (taxaValorPresenteAnual / 100), (mesesAntesAposentadoria - mesesAux) / 12)

                valorFluxoCaixa += valorCalc / taxa
            }
        }

        mesesAux--
    }

    valorFluxoCaixa = round(valorFluxoCaixa, 2)

    return valorFluxoCaixa
}

export const calculaIndexGraficoAnosIdades = (graficoAnosIdades, anoIdade) => {

    let indexGraficoAnosIdades

    graficoAnosIdades?.forEach( (linhaGraficoAnosIdades, index) => {

        if (linhaGraficoAnosIdades === anoIdade) {

            indexGraficoAnosIdades = index
        }
    })

    return indexGraficoAnosIdades
}

export const calculaGraficoValorPresente = (mesInicial, anoInicial, mesFinal, anoFinal, valor, tipoPeriodicidade, graficoAnos,
    grafico, graficoTotalNull, values, estudo, taxaValorPresenteAnual, taxaValorPresenteAnualAposAposentadoria,
        qtdeParcelaTabelaSACNULL, taxaAnualTabelaSACConsorcioEtcNULL, mesAnoPrimeiroReajusteConsorcioEtcNULL, calculoSeguro) => {

    let anoCalc = anoFinal

    let  indexGraficoAnos = calculaIndexGraficoAnosIdades(graficoAnos, anoFinal)

    let saldo = 0

    while (anoCalc >= estudo.current.anoAtual) {
        
        if (anoCalc >= anoInicial) {

            const valorAcumuladoComTaxa =
                calculaValorPresente(valor, tipoPeriodicidade, mesInicial, anoInicial, mesFinal,
                    anoFinal, estudo.current.mesAtual, estudo.current.anoAtual, anoCalc, values, taxaValorPresenteAnual,
                        taxaValorPresenteAnualAposAposentadoria, qtdeParcelaTabelaSACNULL, taxaAnualTabelaSACConsorcioEtcNULL,
                            mesAnoPrimeiroReajusteConsorcioEtcNULL)

            saldo += valorAcumuladoComTaxa

        } else {

            if (!calculoSeguro) {

                const { mesesAux } = calculaMesesValorPresente(mesInicial, anoInicial, mesFinal, anoFinal, estudo.current.mesAtual,
                    estudo.current.anoAtual, anoCalc, values) 
                    
                saldo = saldo /
                    Math.pow(1 + (anoCalc > values.anoFinalVaiAposentarCliente ? taxaValorPresenteAnualAposAposentadoria : taxaValorPresenteAnual / 100),
                        mesesAux / 12)
            }
        }
        
        grafico[indexGraficoAnos] =
            round(grafico[indexGraficoAnos] + saldo, 2) // foi necessário colocar o arredondamento pois estava gerando mais de 2 casas decimais na soma

        if (graficoTotalNull) {

            graficoTotalNull[indexGraficoAnos] =
                round(graficoTotalNull[indexGraficoAnos] + saldo, 2) // foi necessário colocar o arredondamento pois estava gerando mais de 2 casas decimais na soma
        }

        anoCalc--
        indexGraficoAnos--
    }
}

export const calculaGraficoAcumulacaoMensal = (mesInicialNull, anoInicial, mesFinalNull, anoFinal, saldo, 
    valorMensal, tipoPeriodicidade, graficoAnos, graficoNULL, graficoTotalNULL, values, estudo, taxaJurosAnual, taxaJurosAnualAposAposentadoria,
        acumulaSaldo, qtdeParcelaTabelaSACNULL, taxaAnualTabelaSACConsorcioEtcNULL, mesAnoPrimeiroReajusteConsorcioEtcNULL,
            evolucaoReservaFinanceiraEstudoNULL, fluxoCaixaMensalNULL) => {
    
    graficoAnos?.forEach( (linhaGraficoAnos, index) => {

        if (linhaGraficoAnos >= anoInicial && linhaGraficoAnos <= anoFinal) {

            saldo =
                calculaJurosMensalSaldo(valorMensal, tipoPeriodicidade, (acumulaSaldo ? saldo : 0), mesInicialNull, anoInicial,
                    mesFinalNull, anoFinal, linhaGraficoAnos,
                        values, estudo, taxaJurosAnual, taxaJurosAnualAposAposentadoria,
                            qtdeParcelaTabelaSACNULL, taxaAnualTabelaSACConsorcioEtcNULL, mesAnoPrimeiroReajusteConsorcioEtcNULL,
                                evolucaoReservaFinanceiraEstudoNULL, fluxoCaixaMensalNULL, index)
            
            if (graficoNULL) {
                graficoNULL[index] = round(graficoNULL[index] + saldo, 2) // foi necessário colocar o arredondamento pois estava gerando mais de 2 casas decimais na soma
            }

            if (graficoTotalNULL) {
                graficoTotalNULL[index] = round(graficoTotalNULL[index] + saldo, 2) // foi necessário colocar o arredondamento pois estava gerando mais de 2 casas decimais na soma
            }
        }
    })

    return saldo
}

const calculaGraficoBalancoAnualTotalComTracarMeta = (values, estudo) => {
    
    estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {

        const saldoAnual = estudo.current.graficoReceitaAnualTotal[index] -
            estudo.current.graficoDespesaAnualTotal[index]

        estudo.current.graficoBalancoAnualTotal[index] = saldoAnual

        estudo.current.graficoBalancoAnualTotalComTracarMetaAporte[index] =
            saldoAnual - estudo.current.graficoProjetoVidaSonhoAnualTotal[index] - estudo.current.graficoAporteAnualTotal[index]
    })
}

export const calculaJaAposentou = (values, anoAtual) => {

    return values.anoFinalVaiAposentarCliente < anoAtual ? true : false
}

export const calculaAnosDepoisAposentar = (values, anoAtual, jaAposentou) => {

    return values.anoFinalVitalicioClienteConjuge - (jaAposentou ? anoAtual - 1 : values.anoFinalVaiAposentarCliente)
}

export const calculaMesesDepoisAposentar = (mesAtual, jaAposentou, anosDepoisAposentar) => {

    return jaAposentou
        ?
            ((anosDepoisAposentar - 1) * 12) + 12 - mesAtual + 1
        :
            anosDepoisAposentar * 12
}

export const calculaMesesAteAposentar = (values, anoAtual, mesAtual, jaAposentou) => {

    return jaAposentou ? 0 : ((values.anoFinalVaiAposentarCliente - anoAtual) * 12) + 12 - mesAtual + 1
}

const calculaAporteAtingirMetaRecursosFinanceirosInicioAposentadoria = (values, estudo) => {

    if (
            values.calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria &&
            values.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria
    ) {

        //const index = estudo.current.graficoLiquidezFinanceiraAcumulada.length - 1
        const recursosFinanceirosAcumulados = estudo.current.graficoRecursosFinanceirosAcumuladosTotal[calculaIndexAposentadoria(values, estudo)/*index*/]

        const anoAtual = ano(values.dataSimulacaoEstudo)
        const mesAtual = mes(values.dataSimulacaoEstudo)
        const anoVaiAposentar = values.anoFinalVaiAposentarCliente
        const anoVitalicio = values.anoFinalVitalicioClienteConjuge
        
        const jaAposentou = calculaJaAposentou(values, anoAtual)

        //const anosDepoisAposentar = calculaAnosDepoisAposentar(values, anoAtual, jaAposentou)
        //const mesesDepoisAposentar = calculaMesesDepoisAposentar(mesAtual, jaAposentou, anosDepoisAposentar) 

        const mesesAteAposentar = calculaMesesAteAposentar(values, anoAtual, mesAtual, jaAposentou)

        const taxaRetornoAnualReal = values.taxaRetornoAnualReal
        const taxaRetornoAnualRealAposAposentadoria = values.taxaRetornoAnualRealAposAposentadoria

        const fatorTaxaRetornoMensalReal = converteTaxaAnualEmMensal(values.taxaRetornoAnualReal) / 100
        //const fatorTaxaRetornoMensalRealAposAposentadoria = converteTaxaAnualEmMensal(values.taxaRetornoAnualRealAposAposentadoria) / 100

        let saldo

        let saldoCalculoMediaPonderadaTaxa

        const valorPresenteReservaInicioAposentadoria = recursosFinanceirosAcumulados ? recursosFinanceirosAcumulados : 0//round(recursosFinanceirosAcumulados / 
            //Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoria, mesesDepoisAposentar), 2)

        values.valorAporteAtingirMetaRecursosFinanceirosInicioAposentadoria =
            round((round(values.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria, 2) -
                valorPresenteReservaInicioAposentadoria) *
                    (fatorTaxaRetornoMensalReal / (Math.pow(1 + fatorTaxaRetornoMensalReal, mesesAteAposentar) - 1)) *
                        (1 / (1 + fatorTaxaRetornoMensalReal)), 2)

        if (values.valorAporteAtingirMetaRecursosFinanceirosInicioAposentadoria < 0) {

            values.valorAporteAtingirMetaRecursosFinanceirosInicioAposentadoria = 0

        } else {

            const linhaTipoRecursoFinanceiroGrafico =
                pesquisaList(tipoRecursoFinanceiroList,
                    HARDCODE.idTipoRecursoFinanceiroAporteAtingirMetaInicioAposentadoria)

            estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {

                if (linhaGraficoAnos <= anoVaiAposentar) {

                    if (linhaGraficoAnos === anoAtual) {
                            
                        estudo.current.graficoAporteAtingirMetaInicioAposentadoriaTotal[index] =
                            round(values.valorAporteAtingirMetaRecursosFinanceirosInicioAposentadoria * (12 - mesAtual + 1), 2)

                    } else {

                        estudo.current.graficoAporteAtingirMetaInicioAposentadoriaTotal[index] =
                            round(values.valorAporteAtingirMetaRecursosFinanceirosInicioAposentadoria * 12, 2)
                    }

                    estudo.current.graficoBalancoAnualTotalComTracarMetaAporte[index] -=
                        estudo.current.graficoAporteAtingirMetaInicioAposentadoriaTotal[index]

                    const indexGraficoRecursosFinanceirosAcumulados =
                        inicializaSerieGrafico(values, estudo.current.graficoRecursosFinanceirosAcumulados,
                            linhaTipoRecursoFinanceiroGrafico, linhaTipoRecursoFinanceiroGrafico.id + '-N-' +
                                linhaTipoRecursoFinanceiroGrafico.descricao, linhaTipoRecursoFinanceiroGrafico.descricao)

                    saldo = calculaGraficoAcumulacaoMensal(null, linhaGraficoAnos, null, linhaGraficoAnos, 0,
                        values.valorAporteAtingirMetaRecursosFinanceirosInicioAposentadoria, null, estudo.current.graficoAnos,
                            estudo.current.graficoRecursosFinanceirosAcumulados[
                                indexGraficoRecursosFinanceirosAcumulados].serie,
                                    estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                        values, estudo, taxaRetornoAnualReal, taxaRetornoAnualRealAposAposentadoria,
                                            true, null, null, null, estudo.current.evolucaoReservaFinanceiraEstudo, null)
                                            
                    saldoCalculoMediaPonderadaTaxa = 
                        calculaGraficoAcumulacaoMensal(null, linhaGraficoAnos + 1, null, anoVitalicio,
                            saldo, 0, null, estudo.current.graficoAnos, estudo.current.graficoRecursosFinanceirosAcumulados[
                                indexGraficoRecursosFinanceirosAcumulados].serie,
                                    estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                        values, estudo, taxaRetornoAnualReal, taxaRetornoAnualRealAposAposentadoria, true,
                                            null, null, null, null, null)

                    acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa(values, linhaTipoRecursoFinanceiroGrafico,
                        saldoCalculoMediaPonderadaTaxa, taxaRetornoAnualReal/*, anoVitalicio*/)
                }
            })

            incluiAportePlanejadoAposentadoria(estudo, LABEL.metaInicioAposentadoria, LABEL.metaInicioAposentadoria.replace("$1$",
                formataValor(round(values.valorCalcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria, 2), 2)),
                    mesAtual, anoAtual, values.idadeDataSimulacaoCliente, 12, anoVaiAposentar, values.idadeVaiAposentarCliente,
                        linhaTipoRecursoFinanceiroGrafico.tipoPeriodicidadeAporteDividendosDefault,
                            values.valorAporteAtingirMetaRecursosFinanceirosInicioAposentadoria)
        }
    }
}

const calculaAplicacaoDeducaoConsumoReservaAposentadoria = (values, estudo) => {

    const anoInicial = ano(values.dataSimulacaoEstudo)
    const mesInicial = mes(values.dataSimulacaoEstudo)
    const anoVaiAposentar = values.anoFinalVaiAposentarCliente
    const anoVitalicio = values.anoFinalVitalicioClienteConjuge

    const taxaJurosAnual = values.taxaJurosAnual
    const taxaInflacaoAnual = values.taxaInflacaoAnual
    const taxaRetornoAnualReal = values.taxaRetornoAnualReal
    const taxaRetornoAnualRealAposAposentadoria = values.taxaRetornoAnualRealAposAposentadoria

    let valorMensal
    let saldo

    let saldoCalculoMediaPonderadaTaxa

    let linhaTipoRecursoFinanceiroGrafico
    let indexGraficoRecursosFinanceirosAcumulados

    if (values.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria) {

        linhaTipoRecursoFinanceiroGrafico =
            pesquisaList(tipoRecursoFinanceiroList,
                HARDCODE.idTipoRecursoFinanceiroEconomiaReceitaMensal)

        estudo.current.graficoBalancoAnualTotalComTracarMetaAporte?.forEach(
            (linhaGraficoBalancoAnualTotalComTracarMetaAporte, index) => {

            if (
                    linhaGraficoBalancoAnualTotalComTracarMetaAporte > 0 &&
                    estudo.current.graficoAnos[index] <= anoVaiAposentar
            ) {
                
                if (estudo.current.graficoAnos[index] === anoInicial) {
                    
                    valorMensal = round(linhaGraficoBalancoAnualTotalComTracarMetaAporte / (12 - mesInicial + 1), 2)
                
                } else {
                    
                    valorMensal = round(linhaGraficoBalancoAnualTotalComTracarMetaAporte / 12, 2)
                }

                if (values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria) {

                    valorMensal =
                        round(valorMensal *
                            (round(values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria, 2) / 100),
                        2)

                    values.percAplicarBalancoReceitaXDespesaPositivoAteAposentadoriaRecursosFinanceirosUtilizado =
                        values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria

                } else {

                    values.percAplicarBalancoReceitaXDespesaPositivoAteAposentadoriaRecursosFinanceirosUtilizado = 100
                }
    
                if (estudo.current.graficoAnos[index] === anoInicial) {
                    
                    estudo.current.graficoAporteAposentadoriaEconomiaReceitaMensalTotal[index] =
                        round(valorMensal * (12 - mesInicial + 1), 2)

                } else {

                    estudo.current.graficoAporteAposentadoriaEconomiaReceitaMensalTotal[index] =
                        round(valorMensal * 12, 2)
                }

                estudo.current.graficoBalancoAnualTotalComTracarMetaAporte[index] -=
                    estudo.current.graficoAporteAposentadoriaEconomiaReceitaMensalTotal[index]

                indexGraficoRecursosFinanceirosAcumulados =
                    inicializaSerieGrafico(values, estudo.current.graficoRecursosFinanceirosAcumulados,
                        linhaTipoRecursoFinanceiroGrafico, linhaTipoRecursoFinanceiroGrafico.id + '-N-' +
                            linhaTipoRecursoFinanceiroGrafico.descricao, linhaTipoRecursoFinanceiroGrafico.descricao)

                saldo = calculaGraficoAcumulacaoMensal(null, estudo.current.graficoAnos[index],
                    null, estudo.current.graficoAnos[index], 0,
                        valorMensal, null, estudo.current.graficoAnos,
                            estudo.current.graficoRecursosFinanceirosAcumulados[
                                indexGraficoRecursosFinanceirosAcumulados].serie,
                                    estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                        values, estudo, taxaRetornoAnualReal, taxaRetornoAnualRealAposAposentadoria,
                                            true, null, null, null, estudo.current.evolucaoReservaFinanceiraEstudo, null)
                                        
                saldoCalculoMediaPonderadaTaxa = 
                    calculaGraficoAcumulacaoMensal(null, estudo.current.graficoAnos[index] + 1, null, anoVitalicio,
                        saldo, 0, null, estudo.current.graficoAnos, estudo.current.graficoRecursosFinanceirosAcumulados[
                            indexGraficoRecursosFinanceirosAcumulados].serie,
                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                    values, estudo, taxaRetornoAnualReal, taxaRetornoAnualRealAposAposentadoria, true,
                                        null, null, null, null, null)

                acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa(values, linhaTipoRecursoFinanceiroGrafico,
                    saldoCalculoMediaPonderadaTaxa, taxaRetornoAnualReal/*, anoVitalicio*/)
            }
        })

        incluiAportePlanejadoAposentadoria(estudo, LABEL.economiaReceitaMensal, LABEL.economiaReceitaMensal,
            mesInicial, anoInicial, values.idadeDataSimulacaoCliente, 12, anoVaiAposentar, values.idadeVaiAposentarCliente,
                linhaTipoRecursoFinanceiroGrafico.tipoPeriodicidadeAporteDividendosDefault,
                    values.percAplicarBalancoReceitaXDespesaPositivoAteAposentadoriaRecursosFinanceirosUtilizado)

    } else {
        
        values.percAplicarBalancoReceitaXDespesaPositivoAteAposentadoriaRecursosFinanceirosUtilizado = ''
    }
    
    if (values.utilizarPercRentabilidadeAnualRecursosFinanceiros) {

        values.taxaRetornoAnualRealPonderada =
            round(values.totalRecursosFinanceirosVezesTaxaCalculoMediaPonderadaTaxa /
                values.totalRecursosFinanceirosCalculoMediaPonderadaTaxa, 2)

        values.taxaRetornoAnualRealAposAposentadoriaPonderada = values.taxaRetornoAnualRealPonderada

        values.taxaJurosAnualPonderada = calculaTaxaJurosAnual(values.taxaRetornoAnualRealPonderada, taxaInflacaoAnual)

    } else {
        
        values.taxaRetornoAnualRealPonderada = taxaRetornoAnualReal
        values.taxaRetornoAnualRealAposAposentadoriaPonderada = taxaRetornoAnualRealAposAposentadoria

        values.taxaJurosAnualPonderada = taxaJurosAnual
    }

    const linhaRetiradaReservaAposentadoriaComplementoReceitaMensal = {
        id: LABEL.retiradaReservaAposentadoriaComplementoReceitaMensal,
        descricao: LABEL.retiradaReservaAposentadoriaComplementoReceitaMensal,
        ordem: 1,
    }

    const indexGraficoConsumoReservaAposentadoria =
        inicializaSerieGrafico(values, estudo.current.graficoConsumoReservaAposentadoria,
            linhaRetiradaReservaAposentadoriaComplementoReceitaMensal, null, null)

    if (values.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria || true) {

        estudo.current.graficoBalancoAnualTotalComTracarMetaAporte?.forEach(
            (linhaGraficoBalancoAnualTotalComTracarMetaAporte, index) => {

            if (
                    linhaGraficoBalancoAnualTotalComTracarMetaAporte < 0 &&
                    estudo.current.graficoAnos[index] <= anoVaiAposentar
            ) {

                if (estudo.current.graficoAnos[index] === anoInicial) {
                    
                    valorMensal = round(linhaGraficoBalancoAnualTotalComTracarMetaAporte / (12 - mesInicial + 1), 2)
                
                    //estudo.current.graficoRetiradaReservaAposentadoriaComplementoReceitaMensalTotal[index] =
                    //    round(valorMensal * (12 - mesInicial + 1) * -1, 2)

                } else {
                    
                    valorMensal = round(linhaGraficoBalancoAnualTotalComTracarMetaAporte / 12, 2)

                    //estudo.current.graficoRetiradaReservaAposentadoriaComplementoReceitaMensalTotal[index] =
                    //    round(valorMensal * 12 * -1, 2)
                }

                calculaGraficoAcumulacaoMensal(estudo.current.graficoAnos[index] === anoInicial ? estudo.current.mesAtual : null,
                    estudo.current.graficoAnos[index], null, estudo.current.graficoAnos[index], 0,
                        valorMensal * -1, null, estudo.current.graficoAnos,
                            estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                                null, values, estudo, 0, 0, false, null, null, null, null, null)

                //estudo.current.graficoBalancoAnualTotalComTracarMetaAporte[index] +=
                //    estudo.current.graficoRetiradaReservaAposentadoriaComplementoReceitaMensalTotal[index]

                saldo = calculaGraficoAcumulacaoMensal(null, estudo.current.graficoAnos[index], null,
                    estudo.current.graficoAnos[index], 0,
                        valorMensal, null, estudo.current.graficoAnos,
                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                    values, estudo, values.taxaRetornoAnualRealPonderada,
                                        values.taxaRetornoAnualRealAposAposentadoriaPonderada, true,
                                            null, null, null, estudo.current.evolucaoReservaFinanceiraEstudo, null)

                calculaGraficoAcumulacaoMensal(null,
                    estudo.current.graficoAnos[index] + 1, null,
                        anoVitalicio, saldo, 0, null, estudo.current.graficoAnos,
                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                    values, estudo, values.taxaRetornoAnualRealPonderada,
                                        values.taxaRetornoAnualRealAposAposentadoriaPonderada, true,
                                            null, null, null, null, null)
            }
        })
    }

    let tipoRecursoFinanceiroListAux = []

    estudo.current.graficoRecursosFinanceirosAcumulados?.forEach( (linhaGraficoRecursosFinanceirosAcumulados,
        index) => {

        const indexTraco = (linhaGraficoRecursosFinanceirosAcumulados.id + '').indexOf('-')

        let id

        if (indexTraco === -1) {

            id = linhaGraficoRecursosFinanceirosAcumulados.id

        } else {

            id = Number.parseInt(linhaGraficoRecursosFinanceirosAcumulados.id.substring(0, indexTraco))
        }

        tipoRecursoFinanceiroListAux.push({
            id: linhaGraficoRecursosFinanceirosAcumulados.id,
            index: index,
            ordem: pesquisaList(tipoRecursoFinanceiroList, id).ordemConsumo,
        })
    })

    tipoRecursoFinanceiroListAux = sortAsc(tipoRecursoFinanceiroListAux)

    tipoRecursoFinanceiroListAux?.forEach( (linhaTipoRecursoFinanceiroListAux,
        index) => {

        estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {

            if (estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[index] < 0) {

                if (
                        estudo.current.graficoRecursosFinanceirosAcumulados[
                            linhaTipoRecursoFinanceiroListAux.index].serie[index] + 
                                estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[index] >= 0
                ) {

                    estudo.current.graficoRecursosFinanceirosAcumulados[
                        linhaTipoRecursoFinanceiroListAux.index].serie[index] +=
                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[index]

                    estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[index] = 0

                } else {

                    estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[index] +=
                        estudo.current.graficoRecursosFinanceirosAcumulados[
                            linhaTipoRecursoFinanceiroListAux.index].serie[index]

                    estudo.current.graficoRecursosFinanceirosAcumulados[
                        linhaTipoRecursoFinanceiroListAux.index].serie[index] = 0
                }
            }
        })
    })

    estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento?.forEach(
        (linhaGraficoRecursosFinanceirosAcumuladosDiaDia, index) => {

        if (estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[index] < 0) {

            linhaTipoRecursoFinanceiroGrafico =
                pesquisaList(tipoRecursoFinanceiroList,
                    HARDCODE.idTipoRecursoFinanceiroFaltaBalancoNegativoReceitaDespesaDiaDia)

            indexGraficoRecursosFinanceirosAcumulados =
                inicializaSerieGrafico(values, estudo.current.graficoRecursosFinanceirosAcumulados,
                    linhaTipoRecursoFinanceiroGrafico, linhaTipoRecursoFinanceiroGrafico.id + '-N-' +
                    linhaTipoRecursoFinanceiroGrafico.descricao, linhaTipoRecursoFinanceiroGrafico.descricao)

            estudo.current.graficoRecursosFinanceirosAcumulados[
                indexGraficoRecursosFinanceirosAcumulados].serie[index] =
                    estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[index]
            
            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[index] = 0
        }
    })
}

const calculaGraficoLiquidezFinanceiraAcumulada = (values, estudo) => {

    estudo.current.graficoRecursosFinanceirosAcumuladosTotal?.forEach( (linhaGraficoRecursosFinanceirosAcumuladosTotal, index) => {

        estudo.current.graficoLiquidezFinanceiraAcumulada[index] = linhaGraficoRecursosFinanceirosAcumuladosTotal
    })
    
    let anoInicial = values.anoFinalVaiAposentarCliente + 1
    let anoFinal = values.anoFinalVitalicioClienteConjuge

    let saldoDespesas = 0
    let saldoLiquidez = 0

    let indexGraficoConsumoReservaAposentadoria

    const linhaDespesasExcedentes = {
        id: LABEL.despesasExcedentesMensalAposentadoria,
        descricao: LABEL.despesasExcedentesMensalAposentadoria,
        ordem: 1000,
    }

    let saldoMensal

    estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {

        if (/*estudo.current.graficoBalancoAnualTotal[index] <= 0 &&*/ linhaGraficoAnos >= anoInicial) {

            saldoMensal = estudo.current.graficoBalancoAnualTotalComTracarMetaAporte/*graficoBalancoAnualTotal*/[index] > 0 ? 0 :
                estudo.current.graficoBalancoAnualTotalComTracarMetaAporte/*graficoBalancoAnualTotal*/[index] /
                (linhaGraficoAnos === estudo.current.anoAtual ? 12 - estudo.current.mesAtual + 1 : 12)

            saldoDespesas = calculaJurosMensalSaldo(saldoMensal, null,
                saldoDespesas, null, anoInicial, null, anoFinal, linhaGraficoAnos, values, estudo,
                    values.taxaRetornoAnualRealPonderada, values.taxaRetornoAnualRealAposAposentadoriaPonderada, null, null,
                    null, null, null, null)

            estudo.current.graficoDespesasAcumuladasExcedentes[index] = saldoDespesas

            saldoLiquidez = calculaJurosMensalSaldo(saldoMensal, null,
                saldoLiquidez, null, anoInicial, null, anoFinal, linhaGraficoAnos, values, estudo,
                    values.taxaRetornoAnualRealPonderada, values.taxaRetornoAnualRealAposAposentadoriaPonderada, null, null,
                        null, null, null, null)

            estudo.current.graficoLiquidezFinanceiraAcumulada[index] =
                round(estudo.current.graficoLiquidezFinanceiraAcumulada[index] + saldoLiquidez, 2)

            indexGraficoConsumoReservaAposentadoria =
                inicializaSerieGrafico(values, estudo.current.graficoConsumoReservaAposentadoria, linhaDespesasExcedentes, null, null)

            calculaGraficoAcumulacaoMensal(linhaGraficoAnos === estudo.current.anoAtual ? estudo.current.mesAtual : null,
                linhaGraficoAnos, null, linhaGraficoAnos, 0,
                    saldoMensal * -1, null, estudo.current.graficoAnos,
                        estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                            null, values, estudo, 0, 0, false, null, null, null, null, null)

            calculaGraficoAcumulacaoMensal(linhaGraficoAnos === estudo.current.anoAtual ? estudo.current.mesAtual : null,
                linhaGraficoAnos, null, linhaGraficoAnos, 0,
                    saldoMensal, null, estudo.current.graficoAnos,
                        null, null, values, estudo, 0, 0, false, null, null, null, estudo.current.evolucaoReservaFinanceiraEstudo, null)
        }
    })
}

const calculaGraficoDiagnosticoAposentadoria = ( values, estudo, report ) => {

    let anoInicial = ano(values.dataSimulacaoEstudo)
    const anoVaiAposentar = values.anoFinalVaiAposentarCliente
    const anoVitalicio = values.anoFinalVitalicioClienteConjuge

    //const jaAposentou = calculaJaAposentou(values, anoInicial)

    let valorDeposito
    let saldo



    valorDeposito = values.valorAporteMensalPreservarReservaAposentadoria

    //if (jaAposentou) {

    //    saldo = values.valorDepositoUnicoViverRentabilidade

    //} else {
    
        saldo = calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoVaiAposentar, 0, valorDeposito, null,
            estudo.current.graficoAnos, estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumulado, null,
                values, estudo, values.taxaRetornoAnualRealPonderada, values.taxaRetornoAnualRealAposAposentadoriaPonderada, true,
                    null, null, null, null, null)
    //}

    calculaGraficoAcumulacaoMensal(null, anoVaiAposentar + 1, null, anoVitalicio, saldo, 0, null,
        estudo.current.graficoAnos, estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumulado, null,
            values, estudo, values.taxaRetornoAnualRealPonderada, values.taxaRetornoAnualRealAposAposentadoriaPonderada, true,
                null, null, null, null, null)



    valorDeposito = values.valorPresenteDeficitSimulacaoMensal

    saldo = calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoVaiAposentar, 0, valorDeposito, null,
        estudo.current.graficoAnos, estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumulado, null,
            values, estudo, values.taxaRetornoAnualRealPonderada, values.taxaRetornoAnualRealAposAposentadoriaPonderada, true,
                null, null, null, null, null)

    calculaGraficoAcumulacaoMensal(null, anoVaiAposentar + 1, null, anoVitalicio, saldo, 0, null,
        estudo.current.graficoAnos, estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumulado, null,
            values, estudo, values.taxaRetornoAnualRealPonderada, values.taxaRetornoAnualRealAposAposentadoriaPonderada, true,
                null, null, null, null, null)

    let serie

    estudo.current.graficoRecursosFinanceirosAcumulados?.forEach( (linhaGraficoRecursosFinanceirosAcumulados,
        index) => {

        serie = []

        estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {

            serie.push(
                linhaGraficoAnos <= anoVaiAposentar
                    ?
                        linhaGraficoRecursosFinanceirosAcumulados.serie[index]
                    :
                        0,
            )

        })
        
        estudo.current.graficoDiagnosticoAposentadoriaRecursosFinanceirosAcumulados.push(
            {
                id: linhaGraficoRecursosFinanceirosAcumulados.id,
                descricao: linhaGraficoRecursosFinanceirosAcumulados.descricao,
                ordem: linhaGraficoRecursosFinanceirosAcumulados.ordem,
                serie: serie,
            },
        )
    })

    estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {

        estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual.push(
            linhaGraficoAnos > anoVaiAposentar
                ?
                    estudo.current.graficoLiquidezFinanceiraAcumulada[index]
                :
                    0,
        )

        estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaViverRentabilidade.push(
            linhaGraficoAnos > anoVaiAposentar
                ?
                    round(estudo.current.graficoLiquidezFinanceiraAcumulada[index] +
                        estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumulado[index], 2)
                :
                    0,
        )

        estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumuladoAteAposentadoria.push(
            linhaGraficoAnos <= anoVaiAposentar
                ?
                    estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumulado[index] === 0
                        ?
                            null
                        :
                            estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumulado[index]
                :
                    0,
        )

        estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaIdeal.push(
            linhaGraficoAnos > anoVaiAposentar
                ?
                    round(estudo.current.graficoLiquidezFinanceiraAcumulada[index] +
                        estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumulado[index], 2)
                :
                    0,
        )

        estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumuladoAteAposentadoria.push(
            linhaGraficoAnos <= anoVaiAposentar
                ?
                    estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumulado[index] === 0
                        ?
                            null
                        :
                            estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumulado[index]
                :
                    0,
        )

        estudo.current.graficoIndependenciaFinanceiraSituacaoAtual.push(
            estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual[index]
        )

        estudo.current.graficoIndependenciaFinanceiraViverRentabilidade.push(
            round(estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaViverRentabilidade[index] +
                (estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumuladoAteAposentadoria[index]
                    ?
                        estudo.current.graficoDiagnosticoAposentadoriaDepositoViverRentabilidadeAcumuladoAteAposentadoria[index]
                    :
                        0
                ), 2)
        )

        estudo.current.graficoIndependenciaFinanceiraSituacaoIdeal.push(
            round(estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaIdeal[index] +
                (estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumuladoAteAposentadoria[index]
                    ?
                        estudo.current.graficoDiagnosticoAposentadoriaDepositoAcumuladoAteAposentadoria[index]
                    :
                        0
                ), 2)
        )

        
    })


    estudo.current.graficoDiagnosticoAposentadoriaRecursosFinanceirosAcumulados?.forEach( (
        linhaGraficoDiagnosticoAposentadoriaRecursosFinanceirosAcumulados, index) => {
       
        linhaGraficoDiagnosticoAposentadoriaRecursosFinanceirosAcumulados.serie?.forEach( (
            linhaSerie, index) => {

                estudo.current.graficoIndependenciaFinanceiraSituacaoAtual[index] =
                    round(estudo.current.graficoIndependenciaFinanceiraSituacaoAtual[index] + linhaSerie, 2)
                estudo.current.graficoIndependenciaFinanceiraViverRentabilidade[index] =
                    round(estudo.current.graficoIndependenciaFinanceiraViverRentabilidade[index] + linhaSerie, 2)
                estudo.current.graficoIndependenciaFinanceiraSituacaoIdeal[index] =
                    round(estudo.current.graficoIndependenciaFinanceiraSituacaoIdeal[index] + linhaSerie, 2)
            
                graficoAjustaUltimoValorNegativo(estudo.current.graficoIndependenciaFinanceiraSituacaoIdeal, index)
                
        })
    })

    values.valorDepositoViverRentabilidadeSaldoInicioAposentadoria =
        estudo.current.graficoIndependenciaFinanceiraViverRentabilidade[
            nvl(calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
                values.idadeVaiAposentarCliente), 0)]

    values.recursosFinanceirosAcumuladosAposentadoriaIdadeAte = ''

    estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual?.forEach(
        (linhaGraficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual, index) => {

            if (estudo.current.graficoAnos[index] <= anoVaiAposentar) {

                estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtualSuperavit.push(
                    linhaGraficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual
                )

                estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtualDeficit.push(
                    linhaGraficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual
                )

            } else {

                if (linhaGraficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual > 0) {

                    estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtualSuperavit.push(
                        linhaGraficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual
                    )

                    estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtualDeficit.push(
                        0
                    )

                } else {

                    estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtualSuperavit.push(
                        0
                    )

                    estudo.current.graficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtualDeficit.push(
                        linhaGraficoDiagnosticoAposentadoriaLiquidezFinanceiraAcumuladaAtual
                    )

                    if (!values.recursosFinanceirosAcumuladosAposentadoriaIdadeAte) {

                        values.recursosFinanceirosAcumuladosAposentadoriaIdadeAte = estudo.current.graficoIdades[index - 1]
                    }
                }
            }
    })

    

    values.necessidadeAposentadoria = ''

    let necessidadeAposentadoriaAnt = ''

    estudo.current.necessidadeAposentadoria?.forEach( (linhaNecessidadeAposentadoria, indexNecessidadeAposentadoria) => {

        if (
                linhaNecessidadeAposentadoria.despesasExcedentesMensal !== LABEL.tracoXtraco &&
                linhaNecessidadeAposentadoria.despesasExcedentesMensal !== necessidadeAposentadoriaAnt
        ) {

            if (values.necessidadeAposentadoria) {

                values.necessidadeAposentadoria += ' / '
            }

            values.necessidadeAposentadoria += formataValor(linhaNecessidadeAposentadoria.despesasExcedentesMensal, 2) +
                ' <span ' + STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraIdade + '>' + LABEL.de +
                    ' ' + linhaNecessidadeAposentadoria.idadeDe + ' ' +
                            LABEL.ate + ' ' + linhaNecessidadeAposentadoria.idadeAte + ' ' + LABELESPECIAL.anos + '</span>'

            necessidadeAposentadoriaAnt = linhaNecessidadeAposentadoria.despesasExcedentesMensal
        }
    })

    
    values.necessidadeAposentadoriaLimiteReserva = ''

    let necessidadeAposentadoriaLimiteReservaAnt = ''

    estudo.current.necessidadeAposentadoria?.forEach( (linhaNecessidadeAposentadoria, indexNecessidadeAposentadoria) => {

        if (
                linhaNecessidadeAposentadoria.idadeDe <= values.recursosFinanceirosAcumuladosAposentadoriaIdadeAte ||
                !values.recursosFinanceirosAcumuladosAposentadoriaIdadeAte ||
                !values.necessidadeAposentadoriaLimiteReserva
        ) {

            if (
                    linhaNecessidadeAposentadoria.despesasExcedentesMensal !== LABEL.tracoXtraco &&
                    linhaNecessidadeAposentadoria.despesasExcedentesMensal !== necessidadeAposentadoriaLimiteReservaAnt
            ) {

                if (values.necessidadeAposentadoriaLimiteReserva) {

                    values.necessidadeAposentadoriaLimiteReserva += ' / '
                }

                values.necessidadeAposentadoriaLimiteReserva += formataValor(linhaNecessidadeAposentadoria.despesasExcedentesMensal, 2) +
                    ' <span ' + STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraIdade + '>' + LABEL.de +
                        ' ' + linhaNecessidadeAposentadoria.idadeDe + ' ' +
                                LABEL.ate + ' ' +
                                    (
                                        linhaNecessidadeAposentadoria.idadeAte <= values.recursosFinanceirosAcumuladosAposentadoriaIdadeAte ||
                                        !values.recursosFinanceirosAcumuladosAposentadoriaIdadeAte
                                            ?
                                                linhaNecessidadeAposentadoria.idadeAte
                                            :
                                                values.recursosFinanceirosAcumuladosAposentadoriaIdadeAte
                                    ) + ' ' + LABELESPECIAL.anos + '</span>'

                necessidadeAposentadoriaLimiteReservaAnt = linhaNecessidadeAposentadoria.despesasExcedentesMensal
            }
        }
    })



    let primeiro = true

    estudo.current.graficoIndependenciaFinanceiraSituacaoAtual?.forEach(
        (linhaGraficoIndependenciaFinanceiraSituacaoAtual, index) => {

            if (linhaGraficoIndependenciaFinanceiraSituacaoAtual >= 0) {

                estudo.current.graficoIndependenciaFinanceiraSituacaoAtualSuperavit.push(
                    linhaGraficoIndependenciaFinanceiraSituacaoAtual
                )

                estudo.current.graficoIndependenciaFinanceiraSituacaoAtualDeficit.push(
                    null
                )

            } else {

                if (primeiro) {

                    if (index > 0) {

                        estudo.current.graficoIndependenciaFinanceiraSituacaoAtualDeficit[index -1] =
                            estudo.current.graficoIndependenciaFinanceiraSituacaoAtual[index - 1]
                    }

                    primeiro = false
                }

                estudo.current.graficoIndependenciaFinanceiraSituacaoAtualSuperavit.push(
                    null
                )

                estudo.current.graficoIndependenciaFinanceiraSituacaoAtualDeficit.push(
                    linhaGraficoIndependenciaFinanceiraSituacaoAtual
                )
            }

            //values.valorSaldoFinalSituacaoAtual = linhaGraficoIndependenciaFinanceiraSituacaoAtual
    })

    anoInicial = anoVaiAposentar + 1
    const anoFinal = values.anoFinalVitalicioClienteConjuge

    calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
        values.valorRetiradaMensalMaximaConsumirReservaAposentadoria, null, estudo.current.graficoAnos,
            estudo.current.graficoIndependenciaFinaceiraRetiradaMaximaConsumirRecursosFinanceiros,
                null, values, estudo, values.taxaRetornoAnualRealPonderada,
                    values.taxaRetornoAnualRealAposAposentadoriaPonderada, true, null, null, null, null, null)

    /**/
    calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
        values.valorRetiradaMensalMaximaPreservarReservaAposentadoria, null, estudo.current.graficoAnos,
            estudo.current.graficoIndependenciaFinaceiraRetiradaMaximaPreservarRecursosFinanceiros,
                null, values, estudo, values.taxaRetornoAnualRealPonderada,
                    values.taxaRetornoAnualRealAposAposentadoriaPonderada, true, null, null, null, null, null)
    /**/

    estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {
 
        //estudo.current.graficoIndependenciaFinanceiraPreservarReservaAposentadoria.push(
        //    linhaGraficoAnos <= anoVaiAposentar
        //        ?
        //            estudo.current.graficoIndependenciaFinanceiraSituacaoAtual[index]
        //        :
        //            values.anoFinalVaiAposentarCliente < ano(values.dataSimulacaoEstudo) && linhaGraficoAnos === ano(values.dataSimulacaoEstudo)
        //                ?
        //                   estudo.current.graficoIndependenciaFinanceiraSituacaoAtualSuperavit[index]
        //                :
        //                    estudo.current.graficoIndependenciaFinanceiraPreservarReservaAposentadoria[index -1],
        //)

        
        estudo.current.graficoIndependenciaFinanceiraConsumirReservaAposentadoria.push(
            linhaGraficoAnos >= anoVaiAposentar
                ?
                    round(estudo.current.graficoRecursosFinanceirosAcumuladosTotal[index] -
                        estudo.current.graficoIndependenciaFinaceiraRetiradaMaximaConsumirRecursosFinanceiros[index], 2)
                :
                    null,
        )

        /**/
        estudo.current.graficoIndependenciaFinanceiraPreservarReservaAposentadoria.push(
            linhaGraficoAnos >= anoVaiAposentar
                ?
                    round(estudo.current.graficoRecursosFinanceirosAcumuladosTotal[index] -
                        estudo.current.graficoIndependenciaFinaceiraRetiradaMaximaPreservarRecursosFinanceiros[index], 2)
                :
                    null,
        )
        /**/
    })

    values.valorRetiradaMaximaPreservarRecursosFinanceirosSaldoInicioAposentadoria =
        estudo.current.graficoIndependenciaFinanceiraPreservarReservaAposentadoria[
            nvl(calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
                values.idadeVaiAposentarCliente), 0)]
}



const calculaEvolucaoReservaFinanceiraEstudo = (values, estudo) => {

    values.totalAportesAteAposentadoriaEstudo = 0
    values.totalRetiradasAteAposentadoriaEstudo = 0
    values.reservaInicioAposentadoriaEstudo = 0

    estudo.current.evolucaoReservaFinanceiraEstudo?.forEach( (linha, ind) => {

        estudo.current.evolucaoReservaFinanceiraEstudo[ind].recursosFinanceirosInicio =
            ind === 0
                ?
                    values.reservaInicialEstudo
                :
                    estudo.current.graficoIndependenciaFinanceiraSituacaoAtual[ind - 1]

        const meses = linha.ano === estudo.current.anoAtual ? 12 - estudo.current.mesAtual + 1 : 12

        estudo.current.evolucaoReservaFinanceiraEstudo[ind].aporteMedio =
            estudo.current.evolucaoReservaFinanceiraEstudo[ind].aporteTotal
                ?
                    round(estudo.current.evolucaoReservaFinanceiraEstudo[ind].aporteTotal / meses, 2)
                :
                    ''

        if (linha.idade <= values.idadeVaiAposentarCliente) {
        
            values.totalAportesAteAposentadoriaEstudo += nvl(estudo.current.evolucaoReservaFinanceiraEstudo[ind].aporteTotal, 0)
            values.totalRetiradasAteAposentadoriaEstudo += nvl(estudo.current.evolucaoReservaFinanceiraEstudo[ind].retiradaTotal, 0)
        }

        estudo.current.evolucaoReservaFinanceiraEstudo[ind].retiradaMedia =
            estudo.current.evolucaoReservaFinanceiraEstudo[ind].retiradaTotal
                ?
                    round(estudo.current.evolucaoReservaFinanceiraEstudo[ind].retiradaTotal / meses, 2)
                :
                    ''

        estudo.current.evolucaoReservaFinanceiraEstudo[ind].recursosFinanceirosFinal =
            estudo.current.graficoIndependenciaFinanceiraSituacaoAtual[ind]

        estudo.current.evolucaoReservaFinanceiraEstudo[ind].rendimentoTotal =
            estudo.current.evolucaoReservaFinanceiraEstudo[ind].recursosFinanceirosFinal -
                estudo.current.evolucaoReservaFinanceiraEstudo[ind].recursosFinanceirosInicio -
                    nvl(estudo.current.evolucaoReservaFinanceiraEstudo[ind].aporteTotal, 0) -
                        nvl(estudo.current.evolucaoReservaFinanceiraEstudo[ind].retiradaTotal, 0)

        estudo.current.evolucaoReservaFinanceiraEstudo[ind].rendimentoMedio =
            estudo.current.evolucaoReservaFinanceiraEstudo[ind].rendimentoTotal
                ?
                    round(estudo.current.evolucaoReservaFinanceiraEstudo[ind].rendimentoTotal / meses, 2)
                :
                    ''

        if (linha.idade === values.idadeVaiAposentarCliente) {

            estudo.current.evolucaoReservaFinanceiraEstudo[ind].boldAposentadoria = true

            values.reservaInicioAposentadoriaEstudo = estudo.current.evolucaoReservaFinanceiraEstudo[ind].recursosFinanceirosFinal
        }
    })
}



const idadeReferencia = (values, familiar, idadeFamiliar) => {

    //let idadeReferenciaAux

    //if (familiar === HARDCODE.idFamiliarCliente) {

    //    idadeReferenciaAux = idadeFamiliar

    //} else {
        
    //    idadeReferenciaAux = idadeFamiliar + (values.idadeDataSimulacaoCliente - values.idadeDataSimulacaoConjuge)
    //}

    //return idadeReferenciaAux
    
    return idadeFamiliar + (values.idadeDataSimulacaoCliente - pesquisaList(familiaresList(values), familiar).idadeDataSimulacao)
}

const calculaGraficoEstrategiasFinanceirasFamiliar = (values, estudo, familiar, idadeSimularResgateVidaInteira) => {

    values.seguroVidaCotacao?.forEach( (linhaSeguroVidaCotacao, indexSeguroVidaCotacao) => {

        if (
                linhaSeguroVidaCotacao.tabelaEvolucaoValorResgate &&
                linhaSeguroVidaCotacao.tabelaEvolucaoValorResgate.length > 0 &&
                linhaSeguroVidaCotacao.familiar === familiar &&
                dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)
        ) {

            if (estudo.current.graficoEstrategiasFinanceirasTabelaEvolucaoValorResgate.length === 0) {
                
                estudo.current.graficoIdades?.forEach( (linhaGraficoIdades, indexGraficoIdades) => {

                    estudo.current.graficoEstrategiasFinanceirasTabelaEvolucaoValorResgate.push(0)
                    estudo.current.graficoEstrategiasFinanceirasCapitalSegurado.push(0)
                    //estudo.current.graficoEstrategiasFinanceirasEconomiaInventarioSeguroVida.push(0)
                })
            }

            if (
                    !pesquisaList(estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgateSeguradoras,
                        linhaSeguroVidaCotacao.seguradora).id
            ) {

                const linhaInstituicaoFinanceira = pesquisaList(instituicaoFinanceiraList, linhaSeguroVidaCotacao.seguradora)

                estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgateSeguradoras.push(
                    {
                        id: linhaSeguroVidaCotacao.seguradora,
                        descricao: linhaInstituicaoFinanceira.descricao,
                        utilizarApenasComparacaoPreco: linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco,
                        SvgLogoInstituicaoFinanceira: linhaInstituicaoFinanceira.SvgLogo,
                        ordem: (linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco ? '2' : '1') + '-' +
                            linhaInstituicaoFinanceira.descricao,
                    }
                )
            }

            estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate.push(
                {
                    id: linhaSeguroVidaCotacao.id,
                    familiar: linhaSeguroVidaCotacao.familiar,
                    nomeFamiliar: pesquisaDescricaoList(familiaresList(values), linhaSeguroVidaCotacao.familiar),
                    seguradora: linhaSeguroVidaCotacao.seguradora,
                    graficoInvestimentoPremioAnualizado: gerarArray(0, estudo.current.graficoIdades.length),
                    utilizarApenasComparacaoPreco: linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco,
                    tabelaEvolucaoValorResgate: [],
                }
            )

            const indexEstrategiasFinanceirasTabelaEvolucaoValorResgate =
                estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate.length - 1

            estudo.current.graficoIdades?.forEach( (linhaGraficoIdades, indexGraficoIdades) => {

                estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate[
                    indexEstrategiasFinanceirasTabelaEvolucaoValorResgate].tabelaEvolucaoValorResgate.push(null)
            })

            let valorCS = calculaValorCSCotacao(values, linhaSeguroVidaCotacao.id, HARDCODE.idTipoCoberturaSeguroVidaVidaInteira)

            if (!linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco) {

                if (familiar === HARDCODE.idFamiliarCliente) {

                    values.valorCSVidaInteiraCotacaoCliente += valorCS
                }
            
                if (familiar === HARDCODE.idFamiliarConjuge) {
            
                    values.valorCSVidaInteiraCotacaoConjuge += valorCS
                }
            }

            linhaSeguroVidaCotacao.tabelaEvolucaoValorResgate?.forEach(
                (linhaTabelaEvolucaoValorResgate, indexTabelaEvolucaoValorResgate) => {

                valorCS = linhaTabelaEvolucaoValorResgate.valorCS ? linhaTabelaEvolucaoValorResgate.valorCS : valorCS

                let idadeReferenciaAux = idadeReferencia(values, familiar, linhaTabelaEvolucaoValorResgate.idade)

                let index
                
                if (idadeReferenciaAux >= values.idadeFinalVitalicioClienteConjuge) {

                    if (
                            //indexTabelaEvolucaoValorResgate === linhaSeguroVidaCotacao.tabelaEvolucaoValorResgate.length - 1
                            idadeReferenciaAux === values.idadeFinalVitalicioClienteConjuge
                    ) {
                    
                        idadeReferenciaAux = values.idadeFinalVitalicioClienteConjuge

                        index = estudo.current.graficoIdades.length - 1
                    }

                } else {

                    index = calculaIndexGraficoAnosIdades(estudo.current.graficoIdades, idadeReferenciaAux)
                }

                if (index || index === 0) {

                    const valorResgate = linhaTabelaEvolucaoValorResgate.valorResgate ? linhaTabelaEvolucaoValorResgate.valorResgate : 0

                    if (!linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco) {

                        if (
                                (
                                    !idadeSimularResgateVidaInteira ||
                                    linhaTabelaEvolucaoValorResgate.idade < idadeSimularResgateVidaInteira
                                ) &&
                                (
                                    familiar === HARDCODE.idFamiliarCliente ||
                                    familiar === HARDCODE.idFamiliarConjuge
                                )
                        ) {
                            
                            estudo.current.graficoEstrategiasFinanceirasTabelaEvolucaoValorResgate[index] += valorResgate
                        }

                        //if (estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate[index] === null) {

                        if (
                                values.exibirCapitalSeguradoCarteiraInvestimentos &&
                                (
                                    !idadeSimularResgateVidaInteira ||
                                    linhaTabelaEvolucaoValorResgate.idade < idadeSimularResgateVidaInteira
                                ) &&
                                (
                                    familiar === HARDCODE.idFamiliarCliente ||
                                    familiar === HARDCODE.idFamiliarConjuge
                                )
                        ) {
                        
                            if (values.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos) {

                                estudo.current.graficoEstrategiasFinanceirasCapitalSegurado[index] += valorCS - valorResgate

                            } else {

                                estudo.current.graficoEstrategiasFinanceirasCapitalSegurado[index] +=
                                    valorCS
                            }
                        }
                    }

                    estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate[
                        indexEstrategiasFinanceirasTabelaEvolucaoValorResgate].tabelaEvolucaoValorResgate[index] =
                            {
                                ano: linhaTabelaEvolucaoValorResgate.ano,
                                idade: idadeReferenciaAux,
                                idadeFamiliarCotacao: linhaTabelaEvolucaoValorResgate.idade,
                                valorCS: valorCS,
                                valorPremioAnualizado: 0,
                                valorPremioAnualizadoSemTaxaJuros: 0,
                                valorPremioAnualizadoAcumulado: linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado,
                                valorResgate: valorResgate,
                                valorSaldado: linhaTabelaEvolucaoValorResgate.valorSaldado,
                                beneficioProlongado: linhaTabelaEvolucaoValorResgate.beneficioProlongado,
                            }

                    //} else {

                    //    if (
                    //            values.exibirCapitalSeguradoCarteiraInvestimentos &&
                    //            (
                    //                !idadeSimularResgateVidaInteira ||
                    //                linhaTabelaEvolucaoValorResgate.idade < idadeSimularResgateVidaInteira
                    //            )
                    //    ) {
                        
                    //        if (values.exibirTabelaEvolucaoValorResgateCarteiraInvestimentos) {
    
                    //            estudo.current.graficoEstrategiasFinanceirasCapitalSegurado[index] -= valorResgate
                    //        }
                    //    }
    
                    //    estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate[index].valorPremioAnualizadoAcumulado +=
                    //        linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado
                    //    estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate[index].valorResgate += valorResgate
                    //}
                }
            })
        }
    })



    //const custoInventario = values.percDespesasAdvocaticias + values.percCustasJudiciais + calculaItcmdEnderecoResidencial(values)

    if (values.exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao) {

        const estrategiasFinanceirasTabelaEvolucaoValorResgate = []

        estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate?.forEach((linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate,
            indexEstrategiasFinanceirasTabelaEvolucaoValorResgate) => {

            if (linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.familiar === familiar) {

                estrategiasFinanceirasTabelaEvolucaoValorResgate.push(
                    {
                        ...JSON.parse(JSON.stringify(linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate)),
                        apenasExibirTabelaEvolucaoValorResgateSemTaxaInflacao: true
                    }
                )
            }

            estrategiasFinanceirasTabelaEvolucaoValorResgate.push(
                {
                    ...JSON.parse(JSON.stringify(linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate))
                }
            )
        })

        estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate = estrategiasFinanceirasTabelaEvolucaoValorResgate
    }

    estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgate?.forEach((linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate,
        indexEstrategiasFinanceirasTabelaEvolucaoValorResgate) => {

        if (linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.familiar === familiar) {

            let index = linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.tabelaEvolucaoValorResgate.length - 1

            while (index >= 0 ) {

                if (!linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.tabelaEvolucaoValorResgate[index]) {

                    linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.tabelaEvolucaoValorResgate.splice(index, 1)
                }
                
                index--
            }



            let valorPremioAnualizadoPrimeiroAno = 0

            const parcelasPremioAnualizado = []
            //let qtdeParcelas = 0

            let valorPremioAnualizadoAcumuladoAnt = 0

            let valorPremioAnualizadoAcumulado = 0

            let valorResgateVidaInteiraSemTaxaJuros
            let valorResgateVidaInteiraComTaxaJuros



            linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.tabelaEvolucaoValorResgate?.forEach(
                (linhaTabelaEvolucaoValorResgate, indexTabelaEvolucaoValorResgate) => {

                if (valorPremioAnualizadoPrimeiroAno === 0) {

                    valorPremioAnualizadoPrimeiroAno = linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado
                }

                if (
                        valorPremioAnualizadoAcumuladoAnt !==
                            linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado &&
                        linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado !== 0 &&
                        linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado >
                            valorPremioAnualizadoAcumuladoAnt
                ) {
                
                    linhaTabelaEvolucaoValorResgate.valorPremioAnualizado = //+= valorPremioAnualizado
                        linhaTabelaEvolucaoValorResgate.ano === 0
                            ?
                                0
                            :
                                linhaTabelaEvolucaoValorResgate.ano === 1
                                    ?
                                        linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado
                                    :
                                        round(linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado -
                                            valorPremioAnualizadoAcumuladoAnt, 2)

                    linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoSemTaxaJuros =
                        linhaTabelaEvolucaoValorResgate.valorPremioAnualizado

                    if (linhaTabelaEvolucaoValorResgate.valorPremioAnualizado !== 0) {

                        if (
                                parcelasPremioAnualizado.length === 0 ||
                                linhaTabelaEvolucaoValorResgate.valorPremioAnualizado !== 
                                    linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.tabelaEvolucaoValorResgate[
                                        indexTabelaEvolucaoValorResgate - 1
                                    ].valorPremioAnualizadoSemTaxaJuros
                        ) {

                            parcelasPremioAnualizado.push(
                                {
                                    valorPremioAnualizado: linhaTabelaEvolucaoValorResgate.valorPremioAnualizado,
                                    qtdeParcelas: 0,
                                }
                            )
                        }

                        parcelasPremioAnualizado[parcelasPremioAnualizado.length - 1].qtdeParcelas += 1
                    }
                    //qtdeParcelas++

                    valorPremioAnualizadoAcumuladoAnt = linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado

                } else {

                    linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado = 0
                }

                //linhaTabelaEvolucaoValorResgate.valorResgateMaisEconomiaInventarioSeguroVida =
                //    round(linhaTabelaEvolucaoValorResgate.valorResgate / ((100 - custoInventario) / 100), 2)

                //linhaTabelaEvolucaoValorResgate.valorEconomiaInventarioSeguroVida =
                //    round((linhaTabelaEvolucaoValorResgate.valorCS / ((100 - custoInventario) / 100)) -
                //        linhaTabelaEvolucaoValorResgate.valorCS, 2)
                //    round(linhaTabelaEvolucaoValorResgate.valorResgateMaisEconomiaInventarioSeguroVida - 
                //        linhaTabelaEvolucaoValorResgate.valorResgate, 2)
                        

                ////if (!linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.utilizarApenasComparacaoPreco) {

                    ///const indexAux = calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
                    ////    idadeReferencia(values, familiar, linhaTabelaEvolucaoValorResgate.idadeFamiliarCotacao))

                    ////if (indexAux || indexAux === 0) {

                        ////if (
                        ////        !idadeSimularResgateVidaInteira ||
                        ////        linhaTabelaEvolucaoValorResgate.idadeFamiliarCotacao < idadeSimularResgateVidaInteira
                        ////) {

                            ////if (
                            ////        values.exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos &&/*exibirTabelaEvolucaoValorResgateCarteiraInvestimentos*/
                            ////        (
                            ////            familiar === HARDCODE.idFamiliarCliente ||
                            ////            familiar === HARDCODE.idFamiliarConjuge
                            ////        )
                            ////) {
                                
                            ////    estudo.current.graficoEstrategiasFinanceirasEconomiaInventarioSeguroVida[indexAux] +=
                            ////        linhaTabelaEvolucaoValorResgate.valorEconomiaInventarioSeguroVida
                            ////}

                            //if (values.exibirCapitalSeguradoCarteiraInvestimentos) {

                            //    estudo.current.graficoEstrategiasFinanceirasEconomiaInventarioSeguroVida[indexAux] +=
                            //        round((estudo.current.graficoEstrategiasFinanceirasCapitalSegurado[indexTabelaEvolucaoValorResgate] / ((100 - custoInventario) / 100)) -
                            //            estudo.current.graficoEstrategiasFinanceirasCapitalSegurado[indexTabelaEvolucaoValorResgate], 2)
                            //}
                        ////}
                    ////}
                ////}

                const indexIdade = calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
                    idadeReferencia(values, familiar, linhaTabelaEvolucaoValorResgate.idadeFamiliarCotacao)) - 1

                let taxaInflacaoAnual

                if (linhaTabelaEvolucaoValorResgate.idade <= values.idadeVaiAposentarCliente) {

                    taxaInflacaoAnual = values.taxaInflacaoAnual

                } else {

                    taxaInflacaoAnual = values.taxaInflacaoAnualAposAposentadoria
                }

                if (linhaTabelaEvolucaoValorResgate.idadeFamiliarCotacao === idadeSimularResgateVidaInteira) {

                    valorResgateVidaInteiraSemTaxaJuros = linhaTabelaEvolucaoValorResgate.valorResgate
                    valorResgateVidaInteiraComTaxaJuros =
                        corrigeInflacaoAnual(linhaTabelaEvolucaoValorResgate.valorResgate,
                            taxaInflacaoAnual, indexIdade)
                }

                if (
                        values.aplicarTaxaInflacaoTabelaEvolucaoValorResgate &&
                        !linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.apenasExibirTabelaEvolucaoValorResgateSemTaxaInflacao &&
                        indexIdade >= 0 /* tabela MAG começa ano 0*/
                ) {

                    linhaTabelaEvolucaoValorResgate.valorCS =
                        corrigeInflacaoAnual(linhaTabelaEvolucaoValorResgate.valorCS,
                            taxaInflacaoAnual, indexIdade)

                    linhaTabelaEvolucaoValorResgate.valorPremioAnualizado =
                        corrigeInflacaoAnual(linhaTabelaEvolucaoValorResgate.valorPremioAnualizado,
                            taxaInflacaoAnual, indexIdade)

                    if (values.calcularPremioAnualizadoAcumuladoSomaSimples) {

                        if (linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado > 0) {

                            valorPremioAnualizadoAcumulado +=
                                linhaTabelaEvolucaoValorResgate.valorPremioAnualizado
                            
                            linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado =
                                valorPremioAnualizadoAcumulado
                        }

                    } else {

                        linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado =
                            corrigeInflacaoAnual(linhaTabelaEvolucaoValorResgate.valorPremioAnualizadoAcumulado,
                                taxaInflacaoAnual, indexIdade)
                    }

                    linhaTabelaEvolucaoValorResgate.valorResgate =
                        corrigeInflacaoAnual(linhaTabelaEvolucaoValorResgate.valorResgate,
                            taxaInflacaoAnual, indexIdade)

                    linhaTabelaEvolucaoValorResgate.valorSaldado =
                        corrigeInflacaoAnual(linhaTabelaEvolucaoValorResgate.valorSaldado,
                            taxaInflacaoAnual, indexIdade)
                
                    //linhaTabelaEvolucaoValorResgate.valorResgateMaisEconomiaInventarioSeguroVida =
                    //    corrigeInflacaoAnual(linhaTabelaEvolucaoValorResgate
                    //        .valorResgateMaisEconomiaInventarioSeguroVida, taxaInflacaoAnual, indexIdade)

                    //linhaTabelaEvolucaoValorResgate.valorEconomiaInventarioSeguroVida =
                    //    corrigeInflacaoAnual(linhaTabelaEvolucaoValorResgate.valorEconomiaInventarioSeguroVida,
                    //        taxaInflacaoAnual, indexIdade)
                }

                linhaTabelaEvolucaoValorResgate.bold = false

                if (
                        indexTabelaEvolucaoValorResgate > 0 &&
                        (
                            !linhaTabelaEvolucaoValorResgate.valorPremioAnualizado || 
                            linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate
                                .tabelaEvolucaoValorResgate[indexTabelaEvolucaoValorResgate - 1].valorPremioAnualizado >
                            linhaTabelaEvolucaoValorResgate.valorPremioAnualizado
                        )
                ) {

                    if (
                            linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate
                                .tabelaEvolucaoValorResgate[indexTabelaEvolucaoValorResgate - 1].valorPremioAnualizado
                    ) {

                        linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate
                            .tabelaEvolucaoValorResgate[indexTabelaEvolucaoValorResgate - 1].bold = true

                        if (linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.indexTabelaEvolucaoValorResgate) {

                            linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate
                                .tabelaEvolucaoValorResgate[
                                    linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.indexTabelaEvolucaoValorResgate - 1].bold = false
                        }

                        linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.indexTabelaEvolucaoValorResgate =
                            indexTabelaEvolucaoValorResgate

                        linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.anoQuitacaoVidaInteira =
                            linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate
                                .tabelaEvolucaoValorResgate[indexTabelaEvolucaoValorResgate - 1].ano
                    }
                }
            })
            

            
            if (
                    !linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.utilizarApenasComparacaoPreco &&
                    !linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.apenasExibirTabelaEvolucaoValorResgateSemTaxaInflacao
            ) {

                if (values.idRecursoFinanceiroOrigemOrigemPremioVidaInteira) {

                    let qtdeParcelasTotal = 0

                    parcelasPremioAnualizado?.forEach( (linha, ind) => {

                        calculaRealocacaoCarteiraInvestimentos(values, estudo, 
                            values.idRecursoFinanceiroOrigemOrigemPremioVidaInteira,
                                estudo.current.mesAtual, estudo.current.anoAtual + qtdeParcelasTotal,
                                    estudo.current.mesAtual, estudo.current.anoAtual + qtdeParcelasTotal +
                                        linha.qtdeParcelas /*+ qtdeParcelas*/ - 1,
                                            values.anoFinalVitalicioClienteConjuge, linha.valorPremioAnualizado/*valorPremioAnualizado*/,
                                                HARDCODE.idTipoPeriodicidadeAnual)

                        qtdeParcelasTotal += linha.qtdeParcelas
                    })
                }

                if (values.premioVidaInteiraOriundoCarteiraInvestimentos) {

                    let qtdeParcelasTotal = 0

                    parcelasPremioAnualizado?.forEach( (linha, ind) => {

                        values.simulacaoFinanceiraPremioVidaInteiraOriundoCarteiraInvestimentos.push(
                            {
                                idade: values.idadeDataSimulacaoCliente + qtdeParcelasTotal,
                                idadeFinal: values.idadeDataSimulacaoCliente + qtdeParcelasTotal +
                                    linha.qtdeParcelas/*+ qtdeParcelas*/ - 1,
                                mes: estudo.current.mesAtual,
                                retiradaAnual: linha.valorPremioAnualizado/*valorPremioAnualizado*/,
                            }
                        )

                        qtdeParcelasTotal += linha.qtdeParcelas
                    })
                }
                
                if (idadeSimularResgateVidaInteira) {

                    const idadeReferenciaAux = idadeReferencia(values, familiar, idadeSimularResgateVidaInteira)

                    values.simulacaoFinanceiraPremioVidaInteiraOriundoCarteiraInvestimentos.push(
                        {
                            idade: idadeReferenciaAux,
                            mes: estudo.current.mesAtual,
                            aporteAnual:
                                values.tipoRetornoFinanceiro === HARDCODE.tipoRetornoFinanceiroNominal
                                        ?
                                            valorResgateVidaInteiraComTaxaJuros
                                        :
                                            valorResgateVidaInteiraSemTaxaJuros,
                            aporteAnualResgateVidaInteira:
                                values.tipoRetornoFinanceiro === HARDCODE.tipoRetornoFinanceiroNominal ||
                                    values.aplicarTaxaInflacaoSimulacaoResgateVidaInteira
                                        ?
                                            valorResgateVidaInteiraComTaxaJuros
                                        :
                                            valorResgateVidaInteiraSemTaxaJuros,
                            naoCorrigirInflacao: true,
                        }
                    )

                    const mesAnoAux = formataMesAnoSemBarra(estudo.current.mesAtual,
                        estudo.current.anoAtual + idadeReferenciaAux - values.idadeDataSimulacaoCliente) 

                    const linhaRecursosFinanceiros = {
                        id: 10000 + familiar,
                        familiar: familiar,
                        tipoRecursoFinanceiro: HARDCODE.idTipoRecursoFinanceiroResgateSeguroVida,
                        valor: 0,
                        mesAnoInicialAporteDividendos: mesAnoAux,
                        mesAnoFinalAporteDividendos: mesAnoAux,
                        tipoPeriodicidadeAporteDividendos: HARDCODE.idTipoPeriodicidadeAnual,
                        valorAporteDividendos: valorResgateVidaInteiraSemTaxaJuros,
                    }

                    processaRecursoFinanceiro(values, estudo, linhaRecursosFinanceiros, null) 
                }

                let qtdeParcelasTotal = 0

                let tipoMomentoTributacaoIR = null
                let percIR = null

                if (
                        values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR ===
                            HARDCODE.tipoMomentoTributacaoIRComeCotas
                ) {

                    tipoMomentoTributacaoIR = values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR
                    percIR = values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR
                }

                let taxaRetornoAnualReal
                let taxaRetornoAnualRealAposAposentadoria

                if (values.aplicarTaxaInflacaoTabelaEvolucaoValorResgate) {

                    taxaRetornoAnualReal = calculaTaxaRetornoLiquida(values.taxaJurosAnual, 0/*values.taxaInflacaoAnual*/,
                        tipoMomentoTributacaoIR, percIR, null/*values.tempoMinimoEntreAportesResgatesCalculoIRAnos*/
                    )

                    taxaRetornoAnualRealAposAposentadoria = calculaTaxaRetornoLiquida(values.taxaJurosAnualAposAposentadoria,
                        0/*values.taxaInflacaoAnualAposAposentadoria*/, tipoMomentoTributacaoIR, percIR,
                            null/*values.tempoMinimoEntreAportesResgatesCalculoIRAnos*/
                    )

                } else {

                    taxaRetornoAnualReal = calculaTaxaRetornoLiquida(values.taxaJurosAnual, values.taxaInflacaoAnual,
                        tipoMomentoTributacaoIR, percIR, null/*values.tempoMinimoEntreAportesResgatesCalculoIRAnos*/
                    )

                    taxaRetornoAnualRealAposAposentadoria = calculaTaxaRetornoLiquida(values.taxaJurosAnualAposAposentadoria,
                        values.taxaInflacaoAnualAposAposentadoria, tipoMomentoTributacaoIR, percIR,
                            null/*values.tempoMinimoEntreAportesResgatesCalculoIRAnos*/
                    )
                }

                let graficoInvestimentoPremioAnualizadoSemJuros = gerarArray(0, estudo.current.graficoIdades.length)

                parcelasPremioAnualizado?.forEach( (linha, ind) => {

                    let qtdeParcelas = 1

                    while (qtdeParcelas <= linha.qtdeParcelas) {

                        const valorPremioAnualizado = 
                            values.aplicarTaxaInflacaoTabelaEvolucaoValorResgate
                                ?
                                    corrigeInflacaoAnual(linha.valorPremioAnualizado, values.taxaInflacaoAnual,
                                        qtdeParcelasTotal + qtdeParcelas - 1)
                                :
                                    linha.valorPremioAnualizado

                        let saldo = calculaGraficoAcumulacaoMensal(estudo.current.mesAtual,
                            estudo.current.anoAtual + qtdeParcelasTotal + qtdeParcelas - 1,
                                estudo.current.mesAtual, estudo.current.anoAtual + qtdeParcelasTotal + qtdeParcelas - 1, 0,
                                    valorPremioAnualizado, HARDCODE.idTipoPeriodicidadeAnual, estudo.current.graficoAnos,
                                        linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.graficoInvestimentoPremioAnualizado, null,
                                            values, estudo, taxaRetornoAnualReal, taxaRetornoAnualRealAposAposentadoria,
                                                true, null, null, null, null, null)

                        let saldoSemJuros = calculaGraficoAcumulacaoMensal(estudo.current.mesAtual,
                            estudo.current.anoAtual + qtdeParcelasTotal + qtdeParcelas - 1,
                                estudo.current.mesAtual, estudo.current.anoAtual + qtdeParcelasTotal + qtdeParcelas - 1, 0,
                                    valorPremioAnualizado, HARDCODE.idTipoPeriodicidadeAnual, estudo.current.graficoAnos,
                                        graficoInvestimentoPremioAnualizadoSemJuros, null,
                                            values, estudo, 0, 0, true, null, null, null, null, null)

                        if (estudo.current.mesAtual < 12) {

                            const indexGraficoAnos =
                                calculaIndexGraficoAnosIdades(estudo.current.graficoAnos,
                                    estudo.current.anoAtual + qtdeParcelasTotal + qtdeParcelas - 1)
            
                            linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.graficoInvestimentoPremioAnualizado[indexGraficoAnos] -=
                                saldo
                            graficoInvestimentoPremioAnualizadoSemJuros[indexGraficoAnos] -= saldoSemJuros
                        }
                    
                        calculaGraficoAcumulacaoMensal(estudo.current.mesAtual === 12 ? 1 : estudo.current.mesAtual + 1,
                            estudo.current.mesAtual === 12 ? estudo.current.anoAtual + qtdeParcelasTotal +
                                qtdeParcelas : estudo.current.anoAtual + qtdeParcelasTotal + qtdeParcelas - 1,
                                    null, values.anoFinalVitalicioClienteConjuge, saldo, 0, HARDCODE.idTipoPeriodicidadeAnual,
                                        estudo.current.graficoAnos,
                                            linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.graficoInvestimentoPremioAnualizado,
                                                null, values, estudo, taxaRetornoAnualReal,
                                                    taxaRetornoAnualRealAposAposentadoria, true, null, null, null, null, null)

                        calculaGraficoAcumulacaoMensal(estudo.current.mesAtual === 12 ? 1 : estudo.current.mesAtual + 1,
                            estudo.current.mesAtual === 12 ? estudo.current.anoAtual + qtdeParcelasTotal +
                                qtdeParcelas : estudo.current.anoAtual + qtdeParcelasTotal + qtdeParcelas - 1,
                                    null, values.anoFinalVitalicioClienteConjuge, saldoSemJuros, 0, HARDCODE.idTipoPeriodicidadeAnual,
                                        estudo.current.graficoAnos, graficoInvestimentoPremioAnualizadoSemJuros,
                                            null, values, estudo, 0, 0, true, null, null, null, null, null)

                        qtdeParcelas++
                    }

                    qtdeParcelasTotal += linha.qtdeParcelas
                })

                linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.graficoInvestimentoPremioAnualizado?.forEach((linha, ind) => {

                    let ir = 0
                    let percInventario = 0
                    let inventario = 0
                    let desagio = 0

                    if (
                            (
                                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR ===
                                    HARDCODE.tipoMomentoTributacaoIRVGBL ||
                                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR ===
                                    HARDCODE.tipoMomentoTributacaoIRResgate
                            ) &&
                            values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR
                    ) {

                        ir = round((linha - graficoInvestimentoPremioAnualizadoSemJuros[ind]) *
                            values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR / 100, 2)

                    } else {

                        if (
                                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR ===
                                    HARDCODE.tipoMomentoTributacaoIRPGBL &&
                                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR
                        ) {

                            ir = round(linha *
                                values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR / 100, 2)
                        }
                    }

                    if (values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais) {

                        percInventario = values.percDespesasAdvocaticias + values.percCustasJudiciais
                    }

                    if (values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd) {

                        percInventario += calculaItcmdEnderecoResidencial(values)
                    }

                    inventario = round(linha * percInventario / 100, 2)

                    if (values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio) {

                        desagio = round(linha *
                            values.graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio / 100, 2)
                    }

                    linhaEstrategiasFinanceirasTabelaEvolucaoValorResgate.graficoInvestimentoPremioAnualizado[ind] -=
                        (ir + inventario + desagio)
                })

                if (familiar === HARDCODE.idFamiliarCliente) {

                    values.valorPremioAnualizadoVidaInteiraCliente += valorPremioAnualizadoPrimeiroAno
                }

                if (familiar === HARDCODE.idFamiliarConjuge) {

                    values.valorPremioAnualizadoVidaInteiraConjuge += valorPremioAnualizadoPrimeiroAno
                }
            }
        }
    })
}



const calculaGraficoEstrategiasFinanceiras = (values, estudo) => {

    values.valorPrevidenciaPrivadaCliente = 
        calculaRecursosFinanceiroFamiliarTipoRecursoFinaceiro(values, HARDCODE.idFamiliarCliente,
            HARDCODE.idTipoRecursoFinanceiroPrevidenciaPGBL) +
                calculaRecursosFinanceiroFamiliarTipoRecursoFinaceiro(values, HARDCODE.idFamiliarCliente,
                    HARDCODE.idTipoRecursoFinanceiroPrevidenciaVGBL)

    values.valorPrevidenciaPrivadaConjuge =
        calculaRecursosFinanceiroFamiliarTipoRecursoFinaceiro(values, HARDCODE.idFamiliarConjuge,
            HARDCODE.idTipoRecursoFinanceiroPrevidenciaPGBL) +
                calculaRecursosFinanceiroFamiliarTipoRecursoFinaceiro(values, HARDCODE.idFamiliarConjuge,
                    HARDCODE.idTipoRecursoFinanceiroPrevidenciaVGBL)

    const valorPrevidenciaPrivadaCasal =
        calculaRecursosFinanceiroFamiliarTipoRecursoFinaceiro(values, HARDCODE.idFamiliarCasal,
            HARDCODE.idTipoRecursoFinanceiroPrevidenciaPGBL) +
                calculaRecursosFinanceiroFamiliarTipoRecursoFinaceiro(values, HARDCODE.idFamiliarCasal,
                    HARDCODE.idTipoRecursoFinanceiroPrevidenciaVGBL)

    if (valorPrevidenciaPrivadaCasal > 0) {

        const valorPrevidenciaPrivadaCasalCliente = round(valorPrevidenciaPrivadaCasal * 0.5, 2)

        values.valorPrevidenciaPrivadaCliente += valorPrevidenciaPrivadaCasalCliente

        values.valorPrevidenciaPrivadaConjuge += round(valorPrevidenciaPrivadaCasal - valorPrevidenciaPrivadaCasalCliente, 2)
    }

    values.valorCSVidaInteiraApoliceCliente =
        calculaValorCS(values, HARDCODE.idFamiliarCliente, null, HARDCODE.idTipoCoberturaSeguroVidaVidaInteira)
    
    values.valorCSVidaInteiraApoliceConjuge =
        calculaValorCS(values, HARDCODE.idFamiliarConjuge, null, HARDCODE.idTipoCoberturaSeguroVidaVidaInteira)

    if (existeTabelaEvolucaoValorResgate(values)) {

        values.simulacaoFinanceiraPremioVidaInteiraOriundoCarteiraInvestimentos = []

        values.valorPremioAnualizadoVidaInteiraCliente = 0
        values.valorPremioAnualizadoVidaInteiraConjuge = 0

        values.valorCSVidaInteiraCotacaoCliente = 0
        values.valorCSVidaInteiraCotacaoConjuge = 0

        calculaGraficoEstrategiasFinanceirasFamiliar(values, estudo, HARDCODE.idFamiliarCliente,
            values.idadeSimularResgateVidaInteiraCliente)

        calculaGraficoEstrategiasFinanceirasFamiliar(values, estudo, HARDCODE.idFamiliarConjuge,
            values.idadeSimularResgateVidaInteiraConjuge)

        familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
            if (
                    linhaFamiliaresList.id !== HARDCODE.idFamiliarCliente &&
                    linhaFamiliaresList.id !== HARDCODE.idFamiliarConjuge
            ) {
            
                calculaGraficoEstrategiasFinanceirasFamiliar(values, estudo, linhaFamiliaresList.id, null)
            }
        })

        estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgateSeguradoras =
            sortAsc(estudo.current.estrategiasFinanceirasTabelaEvolucaoValorResgateSeguradoras)

    } else {

        values.valorCSVidaInteiraCotacaoCliente =
            calculaValorCS(values, HARDCODE.idFamiliarCliente, null, HARDCODE.idTipoCoberturaSeguroVidaVidaInteira)

        values.valorCSVidaInteiraCotacaoConjuge =
            calculaValorCS(values, HARDCODE.idFamiliarConjuge, null, HARDCODE.idTipoCoberturaSeguroVidaVidaInteira)
    }

    //estudo.current.graficoEstrategiasFinanceirasRecursosFinanceirosAcumulados = [...estudo.current.graficoRecursosFinanceirosAcumulados]
}