import LABEL from '../../../business-const/Label/Label'

import AuthService from '../../../business-api/AuthService'

import ClienteService from '../../../business-api/ClienteService'

import UsuarioService from '../../../business-api/UsuarioService'

import {
    mensagemErroErro,
} from '../../../business-components/Toastr/Toastr'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'

import formList from '../../../business-rules/List/formList'

import reportList from '../../../business-rules/List/reportList'



export const incluiMapaFinanceiro = ( idFormList, linhaReportList ) => {
    
    let inclui = true

    if (linhaReportList.validaInformaMapaFinanceiro === "S") {

        if (pesquisaList(formList, idFormList).exibirMapaFinanceiro === "N") {
                
                inclui = false
        }
    }

    return inclui
}



export const incluiAlteraExcluiConfiguracaoRelatorioReportList = ( idFormList, linhaTipoLicencaNULL, jsonPaginaNULL, paginaAdicionalNULL, 
    reportImprimir, reportNaoImprimir ) => {

    const reportListAux = reportList(idFormList, linhaTipoLicencaNULL, false, jsonPaginaNULL, paginaAdicionalNULL)

    reportListAux?.forEach( (linhaReportList, indexReportList) => {

        if (
                !pesquisaList(reportImprimir, linhaReportList.id).id &&
                !pesquisaList(reportNaoImprimir, linhaReportList.id).id &&
                incluiMapaFinanceiro(idFormList, linhaReportList)
        ) {

            if (linhaReportList.imprimir === "S") {

                reportImprimir.push({
                    id: linhaReportList.id,
                    content: linhaReportList.content,
                    especial: linhaReportList.especial,
                    ordem: linhaReportList.ordem,
                })

            } else {

                reportNaoImprimir.push({
                    id: linhaReportList.id,
                    content: linhaReportList.content,
                    especial: linhaReportList.especial,
                    ordem: linhaReportList.ordem,
                })
            } 
        }
    })



    let index = reportImprimir.length - 1

    let linhaReportList

    while (index >= 0) {

        linhaReportList = pesquisaList(reportListAux, reportImprimir[index].id)

        if (!linhaReportList.id || !incluiMapaFinanceiro(idFormList, linhaReportList)) {

            reportImprimir.splice(index, 1)

        } else {

            reportImprimir[index].content = linhaReportList.content
            reportImprimir[index].especial = linhaReportList.especial
            reportImprimir[index].ordem = linhaReportList.ordem
        }

        index -= 1
    }



    index = reportNaoImprimir.length - 1

    while (index >= 0) {

        linhaReportList = pesquisaList(reportListAux, reportNaoImprimir[index].id)

        if (!linhaReportList.id || !incluiMapaFinanceiro(idFormList, linhaReportList)) {

            reportNaoImprimir.splice(index, 1)

        } else {

            reportNaoImprimir[index].content = linhaReportList.content
            reportNaoImprimir[index].especial = linhaReportList.especial
            reportNaoImprimir[index].ordem = linhaReportList.ordem
        }

        index -= 1
    }
}



export const reiniciaConfiguracaoRelatorioReportList = ( values, templateList, linhaTipoLicenca, jsonPagina) => {

    const linhaTemplateList = pesquisaList(templateList, values.template)
    
    values.reportImprimir = linhaTemplateList.relatorio.reportImprimir
    values.reportNaoImprimir = linhaTemplateList.relatorio.reportNaoImprimir

    incluiAlteraExcluiConfiguracaoRelatorioReportList(values.idFormList, linhaTipoLicenca, jsonPagina, values.paginaAdicional,
        values.reportImprimir, values.reportNaoImprimir)
}



const formataOrdem = ( ordem ) => {

    return ('000' + ordem).slice(-3)
}

const incluiRelatorioTemplateResumidoDetalhado = ( idFormList, linhaTipoLicenca, jsonPagina, paginaAdicional, detalhado ) => {

    const relatorio = {
        reportImprimir: [],
        reportNaoImprimir: [],
    }

    reportList(idFormList, linhaTipoLicenca, false, jsonPagina, paginaAdicional)?.forEach( (linhaReportList, indexReportList) => {

        if (linhaReportList.imprimir === "S" || (detalhado && linhaReportList.imprimir !== "-")) {

            relatorio.reportImprimir.push(
                {
                    id: linhaReportList.id,
                    content: linhaReportList.content,
                    especial: linhaReportList.especial,
                    ordem: linhaReportList.ordem,
                }
            )

        } else {

            relatorio.reportNaoImprimir.push(
                {
                    id: linhaReportList.id,
                    content: linhaReportList.content,
                    especial: linhaReportList.especial,
                    ordem: linhaReportList.ordem,
                }
            )
        }
    })

    return relatorio
}

const incluiTemplate = ( cont, id, linhaConfiguracaoRelatorio, templateList ) => {

    templateList.push(
        {
            id: id + '-' + linhaConfiguracaoRelatorio.id,
            descricao: linhaConfiguracaoRelatorio.descricaoTemplate,
            ordem: formataOrdem(cont),
            relatorio: {
                reportImprimir: linhaConfiguracaoRelatorio.reportImprimir,
                reportNaoImprimir: linhaConfiguracaoRelatorio.reportNaoImprimir,
            }
        }
    )

}

const calculaConfiguracaoRelatorio = ( variaveisGlobais, values, idFormList, linhaTipoLicenca, jsonPagina, jsonUsuario, jsonUsuarioAdmin,
    setTemplateListNULL) => {



    const templateList = []

    let templateListDefault = ''



    let cont = 1

    templateList.push(
        {
            id: cont,
            descricao: LABEL.resumido,
            ordem: formataOrdem(cont),
            relatorio: incluiRelatorioTemplateResumidoDetalhado(idFormList, linhaTipoLicenca, jsonPagina, values.paginaAdicional, false)
        }
    )



    cont++

    templateList.push(
        {
            id: cont,
            descricao: LABEL.detalhado,
            ordem: formataOrdem(cont),
            relatorio: incluiRelatorioTemplateResumidoDetalhado(idFormList, linhaTipoLicenca, jsonPagina, values.paginaAdicional, true)
        }
    )



    jsonUsuario.configuracaoRelatorio?.forEach( (linhaConfiguracaoRelatorio, indexConfiguracaoRelatorio) => {

        if (linhaConfiguracaoRelatorio.descricaoTemplate && linhaConfiguracaoRelatorio.ativo !== "N") {

            cont++

            incluiTemplate(cont, ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
                linhaConfiguracaoRelatorio, templateList)

            if (!templateListDefault && linhaConfiguracaoRelatorio.default) {

                templateListDefault = templateList[templateList.length - 1].id
            }
        }
    })



    jsonUsuarioAdmin.jsonUsuarioAdmin?.forEach( (linhaJsonUsuarioAdmin, indexJsonUsuarioAdmin) => {

        if (linhaJsonUsuarioAdmin.id_usuario !== AuthService.obterUsuarioLogado(variaveisGlobais)) {

            linhaJsonUsuarioAdmin.jsonUsuario.configuracaoRelatorio?.forEach( (linhaConfiguracaoRelatorio,
                indexConfiguracaoRelatorio) => {

                if (
                        linhaConfiguracaoRelatorio.descricaoTemplate &&
                        linhaConfiguracaoRelatorio.compartilhar &&
                        linhaConfiguracaoRelatorio.ativo !== "N"
                ) {

                    cont++

                    incluiTemplate(cont, linhaJsonUsuarioAdmin.id, linhaConfiguracaoRelatorio,
                        templateList)

                    if (!templateListDefault && linhaConfiguracaoRelatorio.default) {
                
                        templateListDefault = templateList[templateList.length - 1].id
                    }
                }
            })
        }
    })

    if (!values.template && templateListDefault) {

        values.template = templateListDefault
    }

    if (!values.template || !pesquisaList(templateList, values.template).id) {

        values.template = templateList[0].id
    }

    if (setTemplateListNULL) {
        
        setTemplateListNULL(templateList)
    }

    return templateList
}



export const inicializaConfiguracaoRelatorio = (variaveisGlobais, values, idFormList, linhaTipoLicenca, jsonPagina,
    jsonUsuario, jsonUsuarioAdmin, setTemplateListNULL) => {

    const templateAnt = values.template

    const templateListAux = calculaConfiguracaoRelatorio(variaveisGlobais, values, idFormList, linhaTipoLicenca, jsonPagina,
        jsonUsuario, jsonUsuarioAdmin, setTemplateListNULL)

    if (!templateAnt) {

        reiniciaConfiguracaoRelatorioReportList(values, templateListAux, linhaTipoLicenca, jsonPagina)

    } else {

        incluiAlteraExcluiConfiguracaoRelatorioReportList(idFormList, linhaTipoLicenca, jsonPagina, values.paginaAdicional,
            values.reportImprimir, values.reportNaoImprimir)
    }

    values.reportNaoImprimir = sortAsc(values.reportNaoImprimir)
}



const excluiPaginaAdicional = (reportImprimiReportNaoImprimir) => {

    const reportAux = []

    reportImprimiReportNaoImprimir?.forEach( (linha, ind) => {

        if (linha.content.indexOf(LABEL.paginaAdicional) === -1) {

            reportAux.push(
                linha
            )
        }
    })

    return reportAux
}

export const putAtualizarJsonUsuario = async (variaveisGlobais, jsonUsuario) => {

    const usuarioService = new UsuarioService()

    let respostaOk = false

    try {

        await usuarioService.atualizarJsonUsuario(variaveisGlobais, jsonUsuario)

        respostaOk = true

    } catch (erro) {

        mensagemErroErro(erro)
    }

    return respostaOk
}

export const incluirTemplate = async (variaveisGlobais, values, jsonUsuario) => {

    if (!jsonUsuario.contConfiguracaoRelatorio) {

        jsonUsuario.contConfiguracaoRelatorio = 0

        jsonUsuario.configuracaoRelatorio = []
    }

    jsonUsuario.contConfiguracaoRelatorio += 1

    jsonUsuario.configuracaoRelatorio.push({
        id: jsonUsuario.contConfiguracaoRelatorio,
        descricaoTemplate: values.descricaoTemplate,
        reportImprimir: excluiPaginaAdicional(values.reportImprimir),
        reportNaoImprimir: excluiPaginaAdicional(values.reportNaoImprimir),
    })

    await putAtualizarJsonUsuario(variaveisGlobais, jsonUsuario)
}