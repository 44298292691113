import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    obtemTextoArray,
} from '../../../business-components/Array/Array'

import {
    round
} from '../../../business-components/round/round'

import {
    trataValorTexto,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    virgula,
    identificaTipoCoberturaSeguroVida,
    calculaDataTerminoCobertura,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    obtemDadoPDFAnterior,
    obtemDadoPDFAvulso,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
    incluiTabelaEvolucaoValorResgateCoberturaVidaInteira,
} from './IntegracaoClienteArquivoService'



export const importaApoliceBradescoVidaInteiraVivaMais = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    //PDFTxt = PDFTxt.replaceAll('\u0000', 'r')

    //PDFTxt = PDFTxt.replaceAll('Prorssão', 'Profissão')





    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cód. Cia: 686-6' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    let linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    let tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, obtemTextoArray(linhaDados, 0, tamLinhaDados - 2),
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[0],
        HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.replace('-,', ',').split(',')
    tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)
        
    obtemDadoPDFAvulso (jsonRegistros, linhaDados[0],
        HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDFAvulso (jsonRegistros, linhaDados[1],
        HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
        
    if (tamLinhaDados === 4 && linhaDados[2]) {
        
        obtemDadoPDFAvulso (jsonRegistros, linhaDados[2],
            HARDCODE.importacaoComplementoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
    }



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoCidadeSeguradoCliente, false, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço' + fimLinha, espaco,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cidade Estado CEP' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.substring(0, 9),
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.substring(9),
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)


        
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, espaco,
        HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)



    jsonRegistros.jsonRegistros[indexJsonRegistros].statusApolice = HARDCODE.idStatusApoliceAtiva
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, fimLinha,
    //    HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)



    let linhasCoberturaAux

    if (PDFTxt.indexOf('SEGURO VIVA MAIS BRADESCO RESGATAVEL') !== -1) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Frequência Pagamento' + fimLinha, fimLinha,
            HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)
    
        obtemDadoPDFAvulso (jsonRegistros, jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)[0],
            HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Prazo' + fimLinha,
            fimLinha + 'Na falta de indicação expressa de beneficiário ou se por qualquer motivo não prevalecer a que' + fimLinha,
                HARDCODE.importacaoCoberturas, true, null, erros, nomeArquivo)

        linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)

    } else {

        obtemDadoPDFAnterior(PDFTxt, jsonRegistros, fimLinha + 'Angariador Susep' + fimLinha, fimLinha,
            HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)
    
        obtemDadoPDFAvulso (jsonRegistros, jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)[0],
            HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Período de Pagamento' + fimLinha,
            fimLinha + 'Na falta de indicação expressa de beneficiário ou se por qualquer motivo não prevalecer a que' + fimLinha,
                HARDCODE.importacaoCoberturas, true, null, erros, nomeArquivo)

        linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha).splice(1)
    }

   
    
    /*
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone' + fimLinha, fimLinha,
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email' + fimLinha, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão' + fimLinha, fimLinha, 
        HARDCODE.importacaoProfissaoSegurado, false, null, erros, nomeArquivo)
    */

    

    //if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {
        //jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        //    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
        //        'COBERTURA DE' + fimLinha + 'MORTE' + fimLinha,
        //            'COBERTURA DE MORTE' + espaco)

    const linhasCobertura = linhasCoberturaAux
    let indexLinhasCobertura = 0
    const tamLinhasCobertura = linhasCobertura.length / 2

    const coberturas = []

    let dadosCobertura
    //let indexDadosCobertura
    let tamDadosCobertura

    let dadosCobertura2

    let numeroCobertura
    let codigoCobertura
    let descricaoCobertura
    let tipoCoberturaSeguroVida
    let valorCS
    let valorPremioLiquido
    let valorIof
    let valorPremio
    let classeAjusteRisco
    let valorPremioExtra
    let duracaoPremioExtra
    let dataTerminoCobertura
    let valorRendaMensal

    while (indexLinhasCobertura < tamLinhasCobertura) {

        dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)
        tamDadosCobertura = dadosCobertura.length

        descricaoCobertura = obtemTextoArray(dadosCobertura, 1, tamDadosCobertura - 3)

        valorCS = trataValorTexto(dadosCobertura[tamDadosCobertura - 2])
        valorPremioLiquido = trataValorTexto(dadosCobertura[tamDadosCobertura - 1])
        valorIof = round(trataValorTexto(dadosCobertura[0]), 2)
        valorPremio = valorPremioLiquido + valorIof

        dadosCobertura2 = linhasCobertura[indexLinhasCobertura + tamLinhasCobertura].replace(espaco + espaco, espaco).split(espaco)

        tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura +
            (dadosCobertura2[2] === 'VITALICIA' ? espaco + dadosCobertura2[2] : ''), linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

        //obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'do dia' + espaco, fimLinha, 
        //    HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)
        jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao = trataData(dadosCobertura2[0])

        dataTerminoCobertura = calculaDataTerminoCobertura(jsonRegistros, indexJsonRegistros,
            HARDCODE.importacaoDataEmissao, dadosCobertura2[2])

        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
            HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio,
                classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
        
        indexLinhasCobertura++
    }

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
    //}

    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaApoliceBradescoMultiPlanoVivaMais = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    //PDFTxt = PDFTxt.replaceAll('\u0000', 'r')

    //PDFTxt = PDFTxt.replaceAll('Prorssão', 'Profissão')





    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cód. Cia: 686-6' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    let linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    let tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, obtemTextoArray(linhaDados, 0, tamLinhaDados - 2),
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[0],
        HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)



    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
    //     HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    //linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split('-')
    //tamLinhaDados = linhaDados.length
        
    //obtemDadoPDFAvulso (jsonRegistros, linhaDados[0],
    //    HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
    
    //obtemDadoPDFAvulso (jsonRegistros, linhaDados[1],
    //    HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
        


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco + '-' + espaco)
    tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[1],
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[2],
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)


    linhaDados = linhaDados[0].split(virgula)
    tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[0],
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[1],
        HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDFAvulso (jsonRegistros, linhaDados[2],
    //    HARDCODE.importacaoComplementoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço' + fimLinha, espaco,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)


        
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CNPJ % Particip' + fimLinha,
        fimLinha + 'Em atendimento à Lei número 12.741/12, informamos os tributos incidentes sobre' + fimLinha,
            HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(fimLinha)
    tamLinhaDados = linhaDados.length

    linhaDados = linhaDados[tamLinhaDados - 1].split(espaco)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[0],
        HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)



    jsonRegistros.jsonRegistros[indexJsonRegistros].statusApolice = HARDCODE.idStatusApoliceAtiva
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, fimLinha,
    //    HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)



    
    obtemDadoPDFAnterior(PDFTxt, jsonRegistros, fimLinha + 'Angariador Susep' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.replace('N/A', ''),
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Excedente Técnico:' + fimLinha,
        fimLinha + 'Beneficiários % Part. Parentesco Inclusão' + fimLinha,
            HARDCODE.importacaoCoberturas, true, null, erros, nomeArquivo)
    
    /*
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone' + fimLinha, fimLinha,
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email' + fimLinha, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão' + fimLinha, fimLinha, 
        HARDCODE.importacaoProfissaoSegurado, false, null, erros, nomeArquivo)
    */

    

    //if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {
        //jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        //    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
        //        'COBERTURA DE' + fimLinha + 'MORTE' + fimLinha,
        //            'COBERTURA DE MORTE' + espaco)

    const linhasCobertura = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha).splice(2)
    let indexLinhasCobertura = 0
    const tamLinhasCobertura = linhasCobertura.length / 2

    const coberturas = []

    let dadosCobertura
    //let indexDadosCobertura
    let tamDadosCobertura

    let dadosCobertura2

    let numeroCobertura
    let codigoCobertura
    let descricaoCobertura
    let tipoCoberturaSeguroVida
    let valorCS
    let valorPremioLiquido
    let valorIof
    let valorPremio
    let classeAjusteRisco
    let valorPremioExtra
    let duracaoPremioExtra
    let dataTerminoCobertura
    let valorRendaMensal

    while (indexLinhasCobertura < tamLinhasCobertura) {

        dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)
        tamDadosCobertura = dadosCobertura.length

        descricaoCobertura = obtemTextoArray(dadosCobertura, 1, tamDadosCobertura - 3)

        valorCS = trataValorTexto(dadosCobertura[tamDadosCobertura - 2])
        valorPremioLiquido = trataValorTexto(dadosCobertura[tamDadosCobertura - 1])
        valorIof = round(trataValorTexto(dadosCobertura[0]), 2)
        valorPremio = valorPremioLiquido + valorIof

        dadosCobertura2 = linhasCobertura[indexLinhasCobertura + tamLinhasCobertura].replace(espaco + espaco, espaco).split(espaco)

        tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura +
            (dadosCobertura2[2] === 'VITALICIA' ? espaco + dadosCobertura2[2] : ''), linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

        //obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'do dia' + espaco, fimLinha, 
        //    HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)
        jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao = trataData(dadosCobertura2[0])

        if (dadosCobertura2[2] === 'VITALICIA') {

            dataTerminoCobertura = calculaDataTerminoCobertura(jsonRegistros, indexJsonRegistros,
                HARDCODE.importacaoDataEmissao, dadosCobertura2[2])

        } else {

            dataTerminoCobertura = trataData(dadosCobertura2[2])
        }

        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
            HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio,
                classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
        
        indexLinhasCobertura++
    }

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
    //}

    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaCotacaoBradescoTabelaEvolucaoValorResgate = (values, item, PDFTxt, nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)


    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nº da cotação:' + espaco, fimLinha,
        HARDCODE.importacaoNumeroCotacao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da cotação:' + espaco, fimLinha,
        HARDCODE.importacaoDataCotacao, true, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, ',' + espaco, espaco, HARDCODE.importacaoIdadeCotacao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Coberturas Valor Capital (R$) Prêmio Líquido (R$) IOF (R$) Prêmio Total (R$)' +
        fimLinha + 'Morte' + espaco,
        espaco, HARDCODE.importacaoValorCS, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Franquia Carência Valor Capital (R$) Prêmio Líquido (R$) IOF (R$) Prêmio Total (R$)' +
            fimLinha + 'Morte' + espaco,
            espaco, HARDCODE.importacaoValorCS, true, null, erros, nomeArquivo)
    }


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Simulação de Resgates' + fimLinha + 'Mês/Ano Prêmio Acumulado Resgate' + fimLinha,
        fimLinha + 'Informações Importantes' + fimLinha,
            HARDCODE.importacaoTabelaEvolucaoValorResgate, true, null, erros, nomeArquivo)


    if (jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {

        const linhasTabelaEvolucaoValorResgateAux =
            jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate.split(fimLinha)
        let indexLinhasTabelaEvolucaoValorResgateAux = 0

        const linhasTabelaEvolucaoValorResgate = []
        let indexLinhasTabelaEvolucaoValorResgate = 0

        let numeroCotacao = jsonRegistros.jsonRegistros[indexJsonRegistros].numeroCotacao
        let dataCotacao = jsonRegistros.jsonRegistros[indexJsonRegistros].dataCotacao
        let ano = 0
        let idade = jsonRegistros.jsonRegistros[indexJsonRegistros].idadeCotacao
        let valorCS = jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS
        let valorPremioAnualizadoAcumulado
        let valorResgate
        let valorSaldado
        let beneficioProlongado

        item.tabelaEvolucaoValorResgate = []

        let dadosTabelaEvolucaoValorResgate

        while (indexLinhasTabelaEvolucaoValorResgateAux < linhasTabelaEvolucaoValorResgateAux.length) {

            if (linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux].indexOf('.') !== -1) {

                linhasTabelaEvolucaoValorResgate.push(
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux]
                )
            }

            indexLinhasTabelaEvolucaoValorResgateAux++
        }

        while (indexLinhasTabelaEvolucaoValorResgate < linhasTabelaEvolucaoValorResgate.length) {

            dadosTabelaEvolucaoValorResgate = linhasTabelaEvolucaoValorResgate[indexLinhasTabelaEvolucaoValorResgate].split(espaco)

            ano++
            idade++
            valorPremioAnualizadoAcumulado = trataValorTexto(dadosTabelaEvolucaoValorResgate[1])
            valorResgate = trataValorTexto(dadosTabelaEvolucaoValorResgate[2])
            valorSaldado = ''
            beneficioProlongado = ''

            incluiTabelaEvolucaoValorResgateCoberturaVidaInteira(values, item, numeroCotacao, dataCotacao, ano, idade, valorCS,
                valorPremioAnualizadoAcumulado, valorResgate, valorSaldado, beneficioProlongado)
            
            indexLinhasTabelaEvolucaoValorResgate++
        }
    }



    
    //jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    //return jsonRegistros.jsonRegistros
}