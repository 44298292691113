import React from 'react'

import {
    Button,
} from '@material-ui/core'

import LABEL from '../../business-const/Label/Label'

import {
    COLOR
} from '../../business-const/Color/Color'

import { 
    CustomizedDialogsConfirmacao,
} from '../../business-components/Dialog/CustomizeDialogs'



export const ButtonPadraoBase = ( props ) => {

    let { label, cor, notDisplay, ...rest } = props

    return (
        <Button
            style={{
                display: 
                    notDisplay
                        ? "none"
                        : "", // normalmente usa-se block, mas foi necessário colocar "" em função do 
                            // MUIDataTablePadrao que não estava apresentando corretamente os botões
                            // customizados através da propriedade customToolbar
                color: rest.disabled ? "" : cor === 1 ? COLOR().buttonPadrao1 :  cor === 3 ? COLOR().buttonPadrao3 : COLOR().buttonPadrao2,
                backgroundColor: rest.disabled ? "" : cor === 1 ? COLOR().bgButtonPadrao1 : cor === 3 ? COLOR().bgButtonPadrao3 : COLOR().bgButtonPadrao2,
                borderRadius: "5px",
            }}
            variant="contained"
            {...rest}
        >
            {label}
        </Button>
    )
}

export const ButtonPadrao = ( props ) => {

    let { align, className, ...rest } = props

    return (
        <div
            align={align ? align : "center"}
            className={className}
        >
            <ButtonPadraoBase
                {...rest}
            />
        </div>
    )
}

const ButtonPadraoCustomToolbar = ( props ) => {

    return (
        <ButtonPadraoBase
            {...props}
        />
    )
}

export const ButtonPadraoSubmit = ( props ) => {

    return (
       
        <ButtonPadrao
            type="submit"
            {...props}
        />
    )
}



export const ButtonNovo = ( props ) => {

    return (
        
        <ButtonPadraoCustomToolbar
            cor={1}
            {...props}
        />
    )
}



export const ButtonDuplicar = ( props ) => {

    return (

        <ButtonPadrao
            className={'mt-6'}
            label={LABEL.buttonDuplicar}
            {...props}
        />
    )
}



export const ButtonImportarXLSX = ( props ) => {

    return (
        
        <ButtonPadraoCustomToolbar
            className='ml-2'
            label={LABEL.buttonImportarXLSX}
            cor={1}
            {...props}
        />
    )
}



export const ButtonExportarXLSX = ( props ) => {

    const { label, ...rest } = props

    return (
        
        <ButtonPadraoCustomToolbar
            label={label ? label : LABEL.buttonExportarXLSX}
            cor={1}
            {...rest}
        />
    )
}



export const ButtonIntegracao = ( props ) => {

    return (
        
        <ButtonPadraoCustomToolbar
            className='ml-2'
            label={LABEL.buttonIntegracao}
            cor={1}
            {...props}
        />
    )
}

const ButtonExcluirMarcadosDialog = ( props ) => {

    return (

        <ButtonPadraoCustomToolbar
            label={LABEL.buttonExcluirMarcados}
            className='mt-3 mb-3'
            cor={1}
            {...props}
        />
    )
}

export const ButtonExcluirMarcados = ( props ) => {
    
    return (
        <div className='ml-1' align="left">
            <CustomizedDialogsConfirmacao
                ObjetoAcao={ButtonExcluirMarcadosDialog}
                confirmacaoTitle={LABEL.buttonExcluirMarcados}
                {...props}
            />
        </div>
    )
}




export const ButtonProcessarArquivosPendentes = ( props ) => {

    return (
        
        <ButtonPadraoCustomToolbar
            className='ml-2'
            label={LABEL.buttonProcessarArquivosPendentes}
            cor={1}
            {...props}
        />
    )
}

export const ButtonProcessarArquivosMarcados = ( props ) => {

    return (
        
        <ButtonPadraoCustomToolbar
            label={LABEL.buttonProcessarArquivosMarcados}
            cor={1}
            {...props}
        />
    )
}



export const ButtonAtualizarStatusArquivos = ( props ) => {

    return (
        
        <ButtonPadraoCustomToolbar
            className='ml-2'
            label={LABEL.buttonAtualizarStatusArquivos}
            cor={1}
            {...props}
        />
    )
}



export const ButtonVisualizarErrosProcessamento = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarErrosProcessamento}
            {...props}
        />
    )
}



export const ButtonTransferir = ( props ) => {

    return (

        <ButtonPadrao
            className="mt-2"
            label={LABEL.buttonTransferir}
            cor={1}
            {...props}
        />
    )
}



export const ButtonDownloadModeloImportacaoClientesExcel = ( props ) => {

    return (
        
        <ButtonPadrao
            className='ml-2'
            cor={1}
            {...props}
        />
    )
}



export const ButtonSubmit = ( props ) => {

    return (

        <ButtonPadraoSubmit
            className="mt-2"
            label={LABEL.buttonSubmitSalvar}
            cor={3}
            {...props}
        />
    )
}



export const ButtonSalvar = ( props ) => {

    return (

        <ButtonPadrao
            className="mt-2"
            label={LABEL.buttonSubmitSalvar}
            cor={3}
            {...props}
        />
    )
}


   
export const ButtonFechar = ( props ) => {

    return (

        <ButtonPadrao
            className="mt-0 mb--1"
            label={LABEL.buttonFechar}
            cor={2}
            {...props}
        />
    )
}


   
export const ButtonFecharSalvar = ( props ) => {

    return (

        <ButtonFechar
            label={LABEL.buttonFecharSalvar}
            {...props}
        />
    )
}



export const ButtonCompartilhar = ( props ) => {

    return (

        <ButtonPadrao //Submit
            label={LABEL.buttonCompartilhar}
            cor={2}
            {...props}
        />
    )
}



export const ButtonIncluirLinha = ( props ) => {

    const { className, label, ...rest } = props

    return (

        <ButtonPadrao
            align='left'
            className={"ml-1 mt-3 mb-3" + (className ? " " + className : "")}
            label={label}
            cor={1}
            {...rest}
        />
    )
}



export const ButtonCopiar = ( props ) => {

    return (

        <ButtonPadraoSubmit
            label={LABEL.buttonCopiar}
            cor={2}
            {...props}
        />
    )
}



export const ButtonEntrar = ( props ) => {

    return (

        <ButtonPadraoSubmit
            className="mt-2"
            label={LABEL.buttonEntrar}
            cor={2}
            {...props}
        />
    )
}


   
export const ButtonSair = ( props ) => {

    return (

        <ButtonPadrao
            className="mt-2"
            label={LABEL.buttonSair}
            cor={2}
            {...props}
        />
    )
}                          



export const ButtonCadastreSe = ( props ) => {

    return (
        <Button
            className="mt-3 text-primary text-12"
            {...props}
        >
            {LABEL.buttonCadastreSe}
        </Button>
    )
}



export const ButtonEsqueceuSenha = ( props ) => {
    return (
        <div className="mt-2">
            <Button
                className="text-primary text-12"
                {...props}
            >
                {LABEL.buttonEsqueceuSenha}
            </Button>
        </div>
    )
}



export const ButtonCadastrar = ( props ) => {

    return (
        <ButtonPadraoSubmit
            className="mt-2"
            label={LABEL.buttonCadastrar}
            cor={2}
            {...props}
        />
    )
}



export const ButtonAssinar = ( props ) => {

    return (
        <ButtonPadraoSubmit
            className="mt-2"
            label={LABEL.buttonAssinar}
            cor={2}
            {...props}
        />
    )
}



export const ButtonCancelar = ( props ) => {

    const { label, ...rest } = props

    return (

        <ButtonPadraoSubmit
            className="mt-2"
            label={label ? label : LABEL.buttonCancelar}
            cor={2}
            {...rest}
        />
    )
}



export const ButtonSalvarSenha = ( props ) => {

    return (

        <ButtonPadraoSubmit
            className="mt-2"
            label={LABEL.buttonSalvarSenha}
            cor={2}
            {...props}
        />
    )
}


export const ButtonAlterarCor = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonAlterarCor}
            {...props}
        />
    )
}



export const ButtonWhiteLabel = ( props ) => {

    return (

        <ButtonPadrao
            className="mt-3 mb-2"
            align='left'
            label={LABEL.whiteLabel}
            cor={2}
            {...props}
        />
    )
}



export const ButtonGerarTokenIntegracao = ( props ) => {

    return (

        <ButtonPadrao
            className="mt-2 mb-2"
            align='left'
            label={LABEL.buttonGerarTokenIntegracao}
            cor={1}
            {...props}
        />
    )
}



export const ButtonBuscarEndereco = ( props ) => {

    return (

        <ButtonPadraoSubmit
            className="mt-2 mb-2"
            align='left'
            label={LABEL.buttonBuscarEndereco}
            cor={1}
            {...props}
        />
    )
}



const ButtonCancelarAssinaturaDialog = ( props ) => {

    return (

        <ButtonCancelar
            label={LABEL.cancelarAssinatura}
            className='mt-3 mb-3'
            cor={1}
            {...props}
        />
    )
}

export const ButtonCancelarAssinatura = ( props ) => {
    
    return (
        <div align="left">
            <CustomizedDialogsConfirmacao
                ObjetoAcao={ButtonCancelarAssinaturaDialog}
                confirmacaoTitle={LABEL.confirmaCancelamentoAssinatura}
                {...props}
            />
        </div>
    )
}



export const ButtonGerarLinkCliente = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonGerarLinkCliente}
            {...props}
        />
    )
}



export const ButtonGerarLinkCaptacaoLeadsMidiasSociais = ( props ) => {

    return (

        <ButtonPadrao
            align='left'
            label={LABEL.buttonGerarLinkCaptacaoLeadsMidiasSociais}
            {...props}
        />
    )
}



export const ButtonAlterarConfiguracaoRelatorio = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonAlterarConfiguracaoRelatorio}
            {...props}
        />
    )
}



export const ButtonGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico = ( props ) => {

    return (
        
        <ButtonPadrao
            label={LABEL.buttonGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico}
            cor={1}
            {...props}
        />
    )
} 



export const ButtonAlterarParametros = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonAlterarParametros}
            {...props}
        />
    )
}

export const ButtonAlterarCenario = ( props ) => {

    return (

        <ButtonIncluirLinha
            label={LABEL.buttonAlterarCenario}
            align='center'
            cor={2}
            {...props}
        />
    )
}

export const ButtonExcluirCenario = ( props ) => {

    return (

        <ButtonIncluirLinha
            label={LABEL.buttonExcluirCenario}
            cor={3}
            {...props}
        />
    )
}

export const ButtonAlterarHipotesesFinanceiras = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonAlterarHipotesesFinanceiras}
            {...props}
        />
    )
}

export const ButtonVisualizarSucessaoVitalicia = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarSucessaoVitalicia}
            {...props}
        />
    )
}

export const ButtonVisualizarEstrategiasFinanceiras = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarEstrategiasFinanceiras}
            {...props}
        />
    )
}

export const ButtonVisualizarEvolucaoReservaFinanceira = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarEvolucaoReservaFinanceira}
            {...props}
        />
    )
}

export const ButtonExcluirTabelaEvolucaoValorResgate = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonExcluirTabelaEvolucaoValorResgate}
            cor={3}
            {...props}
        />
    )
}

export const ButtonVisualizarTabelaEvolucaoValorResgate = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarTabelaEvolucaoValorResgate}
            {...props}
        />
    )
}

export const ButtonVisualizarDiagnosticoAposentadoriaIndependenciaFinanceira = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarDiagnosticoAposentadoriaIndependenciaFinanceira}
            {...props}
        />
    )
}

export const ButtonVisualizarComparacaoCenarios = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarComparacaoCenarios}
            {...props}
        />
    )
}

export const ButtonVisualizarComparacaoEstudos = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarComparacaoEstudos}
            //fullWidth={true}
            {...props}
        />
    )
}

export const ButtonDetalhesCotacaoSeguroVidaSucessaoVitalicia = ( props ) => {
    
    return (

        <ButtonPadrao
            align="center"
            className='mt-2'
            label={LABEL.buttonDetalhesCotacaoSeguroVidaSucessaoVitalicia}
            {...props}
        />
    )
}

export const ButtonVisualizarDadosBasicos = ( props ) => {

    return (

        <ButtonPadrao
            align="left"
            className='mt-3'
            label={LABEL.buttonDadosBasicos}
            {...props}
        />
    )
}

export const ButtonVisualizarConsideracoesFinais = ( props ) => {

    return (

        <ButtonPadrao
            align="left"
            className='mt-3'
            label={LABEL.tabClienteFormListConsideracoesFinais}
            {...props}
        />
    )
}

export const ButtonFundosPrevidenciaPrivada = ( props ) => {

    return (

        <ButtonPadrao
            align="left"
            className='mt-4'
            label={LABEL.buttonFundosPrevidenciaPrivada}
            {...props}
        />
    )
}

export const ButtonVisualizarPlanoAcao = ( props ) => {

    return (

        <ButtonPadrao
            align="left"
            className='mt-3'
            label={LABEL.tabClienteFormListPlanoAcao}
            {...props}
        />
    )
}



export const ButtonVisualizarGrafico = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarGrafico}
            //fullWidth={true}
            {...props}
        />
    )
}

export const ButtonVisualizarTabela = ( props ) => {

    return (

        <ButtonPadrao
            className={'mt-1 mb--1'}
            label={LABEL.buttonVisualizarTabela}
            //fullWidth={true}
            {...props}
        />
    )
}

export const ButtonVisualizarResumo = ( props ) => {

    return (

        <ButtonPadrao
            className={'mt-6'}
            label={LABEL.buttonVisualizarResumo}
            //fullWidth={true}
            {...props}
        />
    )
}

export const ButtonVisualizarIncluirComparacaoFinanciamento = ( props ) => {

    return (

        <ButtonPadrao
            className={'mt-6'}
            label={LABEL.buttonVisualizarIncluirComparacaoFinanciamento}
            //fullWidth={true}
            {...props}
        />
    )
}

export const ButtonVisualizarIncluirComparacaoFinanciamentoDisabled = ( props ) => {

    return (

        <ButtonVisualizarIncluirComparacaoFinanciamento
            {...props}
            disabled={true}
        />
    )
}

/*
export const ButtonVisualizarTabelaRight = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarTabela}
            align='right'
            //fullWidth={true}
            {...props}
        />
    )
}
*/



export const ButtonVisualizarGraficoMapaFinanceiro = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarGraficoMapaFinanceiro}
            //fullWidth={true}
            {...props}
        />
    )
}



export const ButtonVisualizarPDF = ( props ) => {

    return (

        <ButtonPadrao
            label={LABEL.buttonVisualizarPDF}
            {...props}
        />
    )
}

export const ButtonVisualizarPDFLeft = ( props ) => {

    return (

        <ButtonVisualizarPDF
            align='left'
            {...props}
        />
    )
}



export const ButtonVisualizarPDFInstitucional = ( props ) => {
    
    return (

        <ButtonPadrao
            align='right'
            className='mt-3 mr-3'
            label={LABEL.buttonVisualizarPDFInstitucional}
            {...props}
        />
    )
}



export const ButtonVisualizarPDFTipoCarteiraInvestimento = ( props ) => {
    
    return (

        <ButtonPadrao
            align="center"
            className='mt-2'
            label={LABEL.buttonVisualizarPDFTipoCarteiraInvestimento}
            {...props}
        />
    )
}



export const ButtonVisualizarPDFTipoPoliticaInvestimento = ( props ) => {
    
    return (

        <ButtonPadrao
            align="center"
            className='mt-2'
            label={LABEL.buttonVisualizarPDFTipoPoliticaInvestimento}
            {...props}
        />
    )
}



export const ButtonIncluirTemplate = ( props ) => {
    
    return (

        <ButtonPadrao
            align="center"
            className='mt-2'
            label={LABEL.buttonIncluirTemplate}
            {...props}
        />
    )
}



export const ButtonDetalhesCalculo = ( props ) => {
    
    return (

        <ButtonPadrao
            align="center"
            className='mt-2'
            label={LABEL.buttonDetalhesCalculo}
            {...props}
        />
    )
}



export const ButtonVerParcelasMesAMes = ( props ) => {
    
    return (

        <ButtonPadrao
            align="center"
            className='mt-2'
            label={LABEL.buttonVerParcelasMesAMes}
            {...props}
        />
    )
}