import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import AuthService from './AuthService'

import {
    usuarioInfo,
} from './UsuarioService'



class ClienteService extends ApiService {

    constructor() {
        super(HARDCODE.apiClientes)
    }

    async salvarClienteInfoclient(variaveisGlobais, clienteInfoclient, arquivoArquivoAcessoDTOAssociarClienteNULL) {

        const body = {
            idUsuarioLogado: AuthService.obterUsuarioLogado(variaveisGlobais),
            clienteInfoclientDTO: clienteInfoclient,
            arquivoArquivoAcessoDTOAssociarCliente: arquivoArquivoAcessoDTOAssociarClienteNULL,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.post(variaveisGlobais, HARDCODE.apiClientesSalvarClienteInfoclient, body, false)
    }

    salvarClienteInfoclientList(variaveisGlobais, clienteInfoclientList) {

        return this.post(variaveisGlobais, HARDCODE.apiClientesSalvarClienteInfoclientList, clienteInfoclientList, false)
    }

    async atualizarClienteInfoclient(variaveisGlobais, clienteInfoclient, arquivoArquivoAcessoDTOAssociarClienteNULL) {

        const body = {
            idUsuarioLogado: AuthService.obterUsuarioLogado(variaveisGlobais),
            clienteInfoclientDTO: clienteInfoclient,
            arquivoArquivoAcessoDTOAssociarCliente: arquivoArquivoAcessoDTOAssociarClienteNULL,
            logAcessoApiDTO: await usuarioInfo(),
            token: AuthService.obterToken(variaveisGlobais),
            acessoCliente: AuthService.obterAcessoCliente(variaveisGlobais)
        }

        return this.put(variaveisGlobais, HARDCODE.apiClientesAtualizarClienteInfoclient, body, false)
    }
    
    deletarClienteInfoclient(variaveisGlobais, idUsuario, idCliente) {

        let params = `${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        params = `${params}/${idUsuario}`

        params = `${params}/${idCliente}`

        params = `${params}/${HARDCODE.conexao.bucket}`

        return this.delete(variaveisGlobais, `${HARDCODE.apiClientesDeletarClienteInfoclient}/${params}`, false)
    }
    
    async transferirCliente(variaveisGlobais, idCliente, idUsuarioOrigem, idUsuarioDestino) {

        const body = {
            transferenciaClienteDTO:
                {
                    idCliente: idCliente,
                    idUsuarioLogado: AuthService.obterUsuarioLogado(variaveisGlobais),
                    idUsuarioOrigem: idUsuarioOrigem,
                    idUsuarioDestino: idUsuarioDestino,
                },
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.put(variaveisGlobais, HARDCODE.apiClientesTransferirCliente, body, false)
    }

    atualizarCamposCalculadosClienteNovaVersao(variaveisGlobais, idUsuario) {

        let params = `idUsuario=${idUsuario}`

        return this.get(variaveisGlobais, `${HARDCODE.apiClientesAtualizarCamposCalculadosClienteNovaVersao}/?${params}`, false)
    }

    buscarClienteRecomendouList(variaveisGlobais, idUsuario, idCliente) {

        let params = `idUsuarioLogado=${AuthService.obterUsuarioLogado(variaveisGlobais)}`
                                    
        params = `${params}&idUsuario=${idUsuario}`

        params = `${params}&idCliente=${idCliente}`

        return this.get(variaveisGlobais, `${HARDCODE.apiClientesBuscarClienteRecomendouList}/?${params}`, false)
    }
    


    static setarClienteSelecionado(variaveisGlobais, idCliente) {

        variaveisGlobais.clienteSelecionado = idCliente
    }

    static obterClienteSelecionado(variaveisGlobais) {

        return variaveisGlobais.clienteSelecionado
    }



    static setarUltimaVersaoClienteSelecionado(variaveisGlobais, ultimaVersao) {

        variaveisGlobais.ultimaVersaoClienteSelecionado = ultimaVersao
    }

    static obterUltimaVersaoClienteSelecionado(variaveisGlobais) {

        return variaveisGlobais.ultimaVersaoClienteSelecionado
    }



    static setarJsonComparacaoEstudosClienteSelecionado(variaveisGlobais, jsonComparacaoEstudos) {

        variaveisGlobais.jsonComparacaoEstudosClienteSelecionado = jsonComparacaoEstudos
    }

    static obterJsonComparacaoEstudosClienteSelecionado(variaveisGlobais) {

        return variaveisGlobais.jsonComparacaoEstudosClienteSelecionado
    }



    static setarVersaoSelecionada(variaveisGlobais, versao) {

        variaveisGlobais.versaoSelecionada = versao
    }

    static obterVersaoSelecionada(variaveisGlobais) {

        return variaveisGlobais.versaoSelecionada
    }
        
    
    
    static setarUsuarioClienteSelecionado(variaveisGlobais, usuario) {

        variaveisGlobais.usuarioClienteSelecionado = usuario
    }

    static obterUsuarioClienteSelecionado(variaveisGlobais) {

        return variaveisGlobais.usuarioClienteSelecionado
    }
    


    //static setarUsuarioClienteSelecionadoIdentificacao(variaveisGlobais, identificacao) {

    //    variaveisGlobais.usuarioClienteSelecionadoIdentificacao = identificacao
    //}

    //static obterUsuarioClienteSelecionadoIdentificacao(variaveisGlobais) {

    //    return variaveisGlobais.usuarioClienteSelecionadoIdentificacao
    //}
}

export default ClienteService