import LABEL from '../../../business-const/Label/Label'

import {
    arrayAte,
    gerarArray,
    adicionaNaoInformar,
} from '../../../business-components/Array/Array'

import {
    mesMesAno,
    anoMesAno,
    formataMesAnoComBarra,
    formataAnoMesSemBarra,
    calculaMaiorMesAno,
} from '../../../business-components/Date/Date'

import {
    nvl,
    formataValor,
} from '../../../business-components/Formata/Formata'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import {
    dadosCompletosLinhaFinanciamento,
} from '../despesas-form/DespesasServiceFinanciamento'

import {
    graficoTransformaZeroNull,
    calculaIndexGraficoAnosIdades,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'

import {
    calculaTaxaRetornoLiquida,
} from './EstudoServiceMatematicaFinanceira'

import {
    obtemLinhaTipoFinanciamentoDescricaoTipoFinanciamento,
    calculaValoresMesesFinanciamento,
} from './EstudoServiceDespesasFinanciamento'



export const processaValoresDefaultIniciaisComparacaoFinanciamentos = ( values ) => {
    
    // O valor default do campo objComparacaoFinanciamentos foi implementado em
    // ClienteService - inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient
}

export const financiamentoList = (values, idFinanciamento, descricaoSemValor) => {

    const financiamentoListAux = []

    values.financiamento?.forEach( (linhaFinanciamento, index) => {

        if (linhaFinanciamento.id !== idFinanciamento && dadosCompletosLinhaFinanciamento(linhaFinanciamento)) {

            const { descricaoTipoFinanciamento } =
                obtemLinhaTipoFinanciamentoDescricaoTipoFinanciamento(linhaFinanciamento)

            financiamentoListAux.push(
                {
                    id: linhaFinanciamento.id,
                    descricao: descricaoTipoFinanciamento +
                        (descricaoSemValor ? '' : LABEL.traco + formataValor(round(linhaFinanciamento.valorParcela, 2), 2))
                }
            )
        }
    })

    return adicionaNaoInformar(financiamentoListAux)
}



const calculaGraficoFinanciamento = (values, estudo, linhaFinanciamento, graficoFinanciamento, fluxoCaixaMensalFinanciamento) => {

    const { valorMensal, valorAmortizacao, mesInicial, anoInicial, mesFinal, anoFinal } =
        calculaValoresMesesFinanciamento(values, linhaFinanciamento, true)

    let maiorMes = mesFinal
    let maiorAno = anoFinal

    if (linhaFinanciamento.tabelaSAC) {

        // se essa chamada for alterada, revisar a chamada correspondente em calculaDespesasFinanciamento
        calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
            valorAmortizacao, null, estudo.current.graficoAnos, graficoFinanciamento, null, values, estudo, 0, 0, false,
                linhaFinanciamento.qtdeParcelas, linhaFinanciamento.taxaAnual, null, null, fluxoCaixaMensalFinanciamento)

    } else {

        // se essa chamada for alterada, revisar a chamada correspondente em calculaDespesasFinanciamento
        calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
            valorMensal, null, estudo.current.graficoAnos, graficoFinanciamento, null, values, estudo, 0, 0, false,
                null, linhaFinanciamento.taxaAnual, linhaFinanciamento.mesAnoPrimeiroReajuste, null, fluxoCaixaMensalFinanciamento)
    }

    if (linhaFinanciamento.possuiParcelasAdicionais && linhaFinanciamento.parcelasAdicionais) {
        
        linhaFinanciamento.parcelasAdicionais?.forEach( (item, ind) => {

            // se essa chamada for alterada, revisar a chamada correspondente em calculaDespesasFinanciamento
            calculaGraficoAcumulacaoMensal(mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal), 0,
                    round(item.valorParcela, 2), item.tipoPeriodicidadePagto, estudo.current.graficoAnos,
                        graficoFinanciamento, null, values, estudo, 0, 0, false, null, linhaFinanciamento.taxaAnual,
                            linhaFinanciamento.mesAnoPrimeiroReajuste, null, fluxoCaixaMensalFinanciamento)

            const { mes, ano } = calculaMaiorMesAno(maiorMes, maiorAno, mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal))

            maiorMes = mes
            maiorAno = ano
        })
    }

    return { maiorMes: maiorMes, maiorAno: maiorAno }
}

const calculaGraficoEconomia = (values, estudo, graficoEcomoniaPerdaFinanciamentoSugerido, mesInicial, anoInicial, mesFinal, anoFinal,
    valor, fluxoCaixaMensalEconomiaPerdaDetalhe, mesFinalTotal, anoFinalTotal) => {

    const taxaJurosAnual = calculaTaxaRetornoLiquida(values.taxaJurosAnual, 0/*values.taxaInflacaoAnual*/,
            values.tipoMomentoTributacaoIR, values.percIR, values.tempoMinimoEntreAportesResgatesCalculoIRAnos
        )

    const taxaJurosAnualAposAposentadoria =
        calculaTaxaRetornoLiquida(values.taxaJurosAnualAposAposentadoria,
            0/*values.taxaInflacaoAnualAposAposentadoria*/, values.tipoMomentoTributacaoIR, values.percIR,
                values.tempoMinimoEntreAportesResgatesCalculoIRAnos
        )

    // se essa chamada for alterada, revisar a chamada correspondente em calculaDespesasFinanciamento

    let saldo = calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
        valor, null, estudo.current.graficoAnos,
            graficoEcomoniaPerdaFinanciamentoSugerido, null, values, estudo, taxaJurosAnual/*values.taxaRetornoAnualReal*/,
                taxaJurosAnualAposAposentadoria/*values.taxaRetornoAnualRealAposAposentadoria*/, true, null, null,
                    null, null/*estudo.current.evolucaoReservaFinanceiraEstudo*/, fluxoCaixaMensalEconomiaPerdaDetalhe)

    if (
            anoFinal < values.anoFinalVitalicioClienteConjuge ||
            (
                anoFinal === values.anoFinalVitalicioClienteConjuge &&
                mesFinal < 12
            )
    ) {

        if (mesFinal < 12) {

            const indexGraficoAnos =
                calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, anoFinal)

            graficoEcomoniaPerdaFinanciamentoSugerido[indexGraficoAnos] -= saldo
        }

        calculaGraficoAcumulacaoMensal(mesFinal === 12 ? 1 : mesFinal + 1,
            mesFinal === 12 ? anoFinal + 1 : anoFinal, mesFinalTotal,
                anoFinalTotal, saldo, 0, null, estudo.current.graficoAnos,
                    graficoEcomoniaPerdaFinanciamentoSugerido, null, values, estudo, taxaJurosAnual/*values.taxaRetornoAnualReal*/,
                        taxaJurosAnualAposAposentadoria/*values.taxaRetornoAnualRealAposAposentadoria*/,
                            true, null, null, null, null, fluxoCaixaMensalEconomiaPerdaDetalhe)
    }
}

export const calculaGraficoComparacaoFinanciamento = (values, estudo) => {

    if (
            values.objComparacaoFinanciamentos.idFinanciamentoSugerido &&
            (
                values.objComparacaoFinanciamentos.idFinanciamentoAlternativo ||
                values.objComparacaoFinanciamentos.valorPagtoFinanciamentoAVista
            ) &&
            (
                !values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo ||
                values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo.length === 6
            )
    ) {

        let indexGraficoAnos = calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, values.anoFinalVitalicioClienteConjuge)

        let graficoFinanciamentoSugerido = gerarArray(0, indexGraficoAnos + 1)
        let graficoFinanciamentoAlternativo = [...graficoFinanciamentoSugerido]
        let graficoEcomoniaPerdaFinanciamentoSugerido = [...graficoFinanciamentoSugerido]
        const graficoEcomoniaFinanciamentoSugerido = []
        const graficoPerdaFinanciamentoSugerido = []
        let graficoSaldoPagtoFinanciamentoSugeridoReserva = [...graficoFinanciamentoSugerido]
        let graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal = [...graficoFinanciamentoSugerido]
        const graficoSaldoPagtoFinanciamentoSugeridoAVistaPositivo = []
        const graficoSaldoPagtoFinanciamentoSugeridoAVistaNegativo = []
        const graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo = []
        const graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo = []
        let fluxoCaixaMensalFinanciamentoSugerido = []
        let fluxoCaixaMensalFinanciamentoAlternativo = []
        let fluxoCaixaMensalEconomiaPerdaDetalhe = []
        let fluxoCaixaMensalSaldoPagtoFinanciamentoSugeridoReserva = []
        let fluxoCaixaMensalSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal = []
        //let idadesFinanciamento
        let anosFinanciamento

        const linhaFinanciamentoSugerido =
            pesquisaList(values.financiamento, values.objComparacaoFinanciamentos.idFinanciamentoSugerido)

        const linhaFinanciamentoAlternativo =
            pesquisaList(values.financiamento, values.objComparacaoFinanciamentos.idFinanciamentoAlternativo)

        if (
                linhaFinanciamentoSugerido.id &&
                (
                    !values.objComparacaoFinanciamentos.idFinanciamentoAlternativo ||
                    (
                        values.objComparacaoFinanciamentos.idFinanciamentoAlternativo &&
                        linhaFinanciamentoAlternativo.id
                    )
                )
        ) {

            const maiorMesAnoFinanciamentoSugerido = calculaGraficoFinanciamento(values, estudo,
                linhaFinanciamentoSugerido, graficoFinanciamentoSugerido, fluxoCaixaMensalFinanciamentoSugerido)

            const maiorMesAnoFinanciamentoAlternativo = calculaGraficoFinanciamento(values, estudo,
                linhaFinanciamentoAlternativo, graficoFinanciamentoAlternativo, fluxoCaixaMensalFinanciamentoAlternativo)

            const { ano, mes } = calculaMaiorMesAno(maiorMesAnoFinanciamentoSugerido.maiorMes,
                maiorMesAnoFinanciamentoSugerido.maiorAno, maiorMesAnoFinanciamentoAlternativo.maiorMes,
                maiorMesAnoFinanciamentoAlternativo.maiorAno)

            const mesFinal =
                values.objComparacaoFinanciamentos.idFinanciamentoAlternativo ? mes : maiorMesAnoFinanciamentoSugerido.maiorMes
            const anoFinal =
                values.objComparacaoFinanciamentos.idFinanciamentoAlternativo ? ano : maiorMesAnoFinanciamentoSugerido.maiorAno

            if (values.objComparacaoFinanciamentos.valorPagtoFinanciamentoAVista) {

                calculaGraficoEconomia(values, estudo, graficoSaldoPagtoFinanciamentoSugeridoReserva, estudo.current.mesAtual,
                    estudo.current.anoAtual, estudo.current.mesAtual, estudo.current.anoAtual,
                        round(values.objComparacaoFinanciamentos.valorPagtoFinanciamentoAVista, 2),
                            fluxoCaixaMensalSaldoPagtoFinanciamentoSugeridoReserva, mesFinal, anoFinal)

                if (
                        !values.objComparacaoFinanciamentos.idFinanciamentoAlternativo &&
                        values.objComparacaoFinanciamentos.valorPagtoParcelasFinanciamentoReceitaMensal
                ) {

                    calculaGraficoEconomia(values, estudo, graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal,
                        estudo.current.mesAtual, estudo.current.anoAtual, estudo.current.mesAtual, estudo.current.anoAtual,
                            round(values.objComparacaoFinanciamentos.valorPagtoParcelasFinanciamentoReceitaMensal, 2),
                                fluxoCaixaMensalSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal, mesFinal, anoFinal)
                }
            }

            estudo.current.graficoAnos?.forEach( (linha, ind) => {

                if (linha <= anoFinal) {

                    let mes = 1

                    if (linha === estudo.current.anoAtual) {

                        mes = estudo.current.mesAtual
                    }

                    while (mes <= 12) {

                        const mesAno = formataMesAnoComBarra(mes, linha)
                        const valorFinanciamentoSugerido = nvl(pesquisaList(fluxoCaixaMensalFinanciamentoSugerido, mesAno).valor, 0)
                        const valorFinanciamentoAlternativo = nvl(pesquisaList(fluxoCaixaMensalFinanciamentoAlternativo, mesAno).valor, 0)

                        if (valorFinanciamentoSugerido !== 0 || valorFinanciamentoAlternativo !== 0) {

                            let saldo
                            
                            if (
                                    values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo &&
                                    formataAnoMesSemBarra(mes, linha) <=
                                        formataAnoMesSemBarra(mesMesAno(values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo),
                                            anoMesAno(values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo))
                            ) {

                                saldo = valorFinanciamentoSugerido * -1

                            } else {

                                if (valorFinanciamentoSugerido === 0) {

                                    saldo = valorFinanciamentoAlternativo

                                } else {

                                    if (valorFinanciamentoAlternativo === 0) {

                                        saldo = valorFinanciamentoSugerido * -1

                                    } else {

                                        saldo = valorFinanciamentoAlternativo - valorFinanciamentoSugerido
                                    }
                                }
                            }

                            calculaGraficoEconomia(values, estudo, graficoEcomoniaPerdaFinanciamentoSugerido, mes,
                                linha, mes, linha, saldo, fluxoCaixaMensalEconomiaPerdaDetalhe, mesFinal, anoFinal)

                            if (values.objComparacaoFinanciamentos.valorPagtoFinanciamentoAVista) {

                                calculaGraficoEconomia(values, estudo, graficoSaldoPagtoFinanciamentoSugeridoReserva, mes,
                                    linha, mes, linha, valorFinanciamentoSugerido * -1, fluxoCaixaMensalSaldoPagtoFinanciamentoSugeridoReserva,
                                        mesFinal, anoFinal)
                            }
                        }

                        mes++
                    }
                }
            })

            indexGraficoAnos = calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, anoFinal)
            
            graficoFinanciamentoSugerido = arrayAte(graficoFinanciamentoSugerido, indexGraficoAnos)
            graficoFinanciamentoAlternativo = arrayAte(graficoFinanciamentoAlternativo, indexGraficoAnos)
            graficoEcomoniaPerdaFinanciamentoSugerido = arrayAte(graficoEcomoniaPerdaFinanciamentoSugerido, indexGraficoAnos)
            graficoSaldoPagtoFinanciamentoSugeridoReserva = arrayAte(graficoSaldoPagtoFinanciamentoSugeridoReserva, indexGraficoAnos)
            //idadesFinanciamento = arrayAte(estudo.current.graficoIdades, indexGraficoAnos)
            anosFinanciamento = arrayAte(estudo.current.graficoAnos, indexGraficoAnos)

            graficoEcomoniaPerdaFinanciamentoSugerido?.forEach( (linha, ind) => {

                if (linha >= 0) {

                    graficoEcomoniaFinanciamentoSugerido.push(linha)
                    graficoPerdaFinanciamentoSugerido.push(null)

                    //if (ind === 1 && graficoEcomoniaPerdaFinanciamentoSugerido[ind - 1] < 0) {

                    //    graficoPerdaFinanciamentoSugerido[ind] = graficoEcomoniaFinanciamentoSugerido[ind]
                        
                    //} else {

                    //    if (ind > 0 && graficoEcomoniaPerdaFinanciamentoSugerido[ind - 1] < 0) {

                    //        graficoEcomoniaFinanciamentoSugerido[ind - 1] = graficoPerdaFinanciamentoSugerido[ind - 1]
                    //    }
                    //}

                } else {

                    graficoEcomoniaFinanciamentoSugerido.push(null)
                    graficoPerdaFinanciamentoSugerido.push(linha)

                    //if (ind > 0 && graficoEcomoniaPerdaFinanciamentoSugerido[ind - 1] >= 0) {

                    //    graficoPerdaFinanciamentoSugerido[ind - 1] = graficoEcomoniaFinanciamentoSugerido[ind - 1]
                    //}
                }



                if (values.objComparacaoFinanciamentos.valorPagtoFinanciamentoAVista) {

                    if (graficoSaldoPagtoFinanciamentoSugeridoReserva[ind] >= 0) {

                        graficoSaldoPagtoFinanciamentoSugeridoAVistaPositivo.push(graficoSaldoPagtoFinanciamentoSugeridoReserva[ind])
                        graficoSaldoPagtoFinanciamentoSugeridoAVistaNegativo.push(null)

                        if (ind > 0 && graficoSaldoPagtoFinanciamentoSugeridoReserva[ind - 1] < 0) {

                            graficoSaldoPagtoFinanciamentoSugeridoAVistaPositivo[ind - 1] = graficoSaldoPagtoFinanciamentoSugeridoAVistaNegativo[ind - 1]
                        }

                    } else {

                        graficoSaldoPagtoFinanciamentoSugeridoAVistaPositivo.push(null)
                        graficoSaldoPagtoFinanciamentoSugeridoAVistaNegativo.push(graficoSaldoPagtoFinanciamentoSugeridoReserva[ind])

                        if (ind > 0 && graficoSaldoPagtoFinanciamentoSugeridoReserva[ind - 1] >= 0) {

                            graficoSaldoPagtoFinanciamentoSugeridoAVistaNegativo[ind - 1] = graficoSaldoPagtoFinanciamentoSugeridoAVistaPositivo[ind - 1]
                        }
                    }

                    if (
                            !values.objComparacaoFinanciamentos.idFinanciamentoAlternativo &&
                            values.objComparacaoFinanciamentos.valorPagtoParcelasFinanciamentoReceitaMensal
                    ) {

                        if (graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal[ind] >= 0) {

                            graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo.push(
                                graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal[ind])
                            graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo.push(null)
    
                            if (ind > 0 && graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal[ind - 1] < 0) {
    
                                graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo[ind - 1] =
                                    graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo[ind - 1]
                            }
    
                        } else {
    
                            graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo.push(null)
                            graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo.push(
                                graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal[ind])
    
                            if (ind > 0 && graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal[ind - 1] >= 0) {
    
                                graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo[ind - 1] =
                                    graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo[ind - 1]
                            }
                        }
                    }
                }
            })

            let indFluxoMensal = 0

            fluxoCaixaMensalEconomiaPerdaDetalhe?.forEach( (linha, ind) => {

                const linhaFluxoCaixaMensalFinanciamentoSugerido =
                    pesquisaList(fluxoCaixaMensalFinanciamentoSugerido, linha.id)

                if (linhaFluxoCaixaMensalFinanciamentoSugerido.id) {

                    fluxoCaixaMensalEconomiaPerdaDetalhe[ind].financiamentoSugerido =
                        linhaFluxoCaixaMensalFinanciamentoSugerido.valor

                    indFluxoMensal = ind
                }



                const linhaFluxoCaixaMensalFinanciamentoAlternativo =
                    pesquisaList(fluxoCaixaMensalFinanciamentoAlternativo, linha.id)

                if (linhaFluxoCaixaMensalFinanciamentoAlternativo.id) {

                    fluxoCaixaMensalEconomiaPerdaDetalhe[ind].financiamentoAlternativo =
                        linhaFluxoCaixaMensalFinanciamentoAlternativo.valor

                    indFluxoMensal = ind
                }

                if (values.objComparacaoFinanciamentos.valorPagtoFinanciamentoAVista) {

                    fluxoCaixaMensalEconomiaPerdaDetalhe[ind].saldoPagtoFinanciamentoSugeridoAVista =
                        fluxoCaixaMensalSaldoPagtoFinanciamentoSugeridoReserva[ind].saldo

                    if (
                            !values.objComparacaoFinanciamentos.idFinanciamentoAlternativo &&
                            values.objComparacaoFinanciamentos.valorPagtoParcelasFinanciamentoReceitaMensal
                    ) {

                        fluxoCaixaMensalEconomiaPerdaDetalhe[ind].saldoPagtoParcelasFinanciamentoSugeridoReceitaMensal =
                            fluxoCaixaMensalSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensal[ind].saldo
                    }
                }
            })
            
            fluxoCaixaMensalEconomiaPerdaDetalhe = arrayAte(fluxoCaixaMensalEconomiaPerdaDetalhe, indFluxoMensal)

            const fluxoCaixaMensalEconomiaPerdaResumo = []

            let parcelas = 0
            let id = ''
            let financiamentoSugeridoTotal = 0
            let financiamentoAlternativoTotal = 0
            let valorTotal = 0

            let financiamentoSugeridoTotalGeral = 0
            let financiamentoAlternativoTotalGeral = 0
            let valorTotalGeral = 0

            fluxoCaixaMensalEconomiaPerdaDetalhe?.forEach( (linha, ind) => {

                parcelas++

                if (!id) {
                    
                    id = linha.id
                }

                financiamentoSugeridoTotal += nvl(linha.financiamentoSugerido, 0)
                financiamentoAlternativoTotal += nvl(linha.financiamentoAlternativo, 0)
                valorTotal += linha.valor

                if ((ind + 1) % 12 === 0 || ind === fluxoCaixaMensalEconomiaPerdaDetalhe.length - 1) {
                    
                    fluxoCaixaMensalEconomiaPerdaResumo.push({
                        parcelas: parcelas,
                        id: id + ' - ' + linha.id,
                        financiamentoSugerido: financiamentoSugeridoTotal,
                        financiamentoAlternativo: financiamentoAlternativoTotal,
                        valor: valorTotal,
                        saldo: linha.saldo,
                        saldoPagtoFinanciamentoSugeridoAVista: linha.saldoPagtoFinanciamentoSugeridoAVista,
                        saldoPagtoParcelasFinanciamentoSugeridoReceitaMensal:
                            linha.saldoPagtoParcelasFinanciamentoSugeridoReceitaMensal,
                    })

                    financiamentoSugeridoTotalGeral += financiamentoSugeridoTotal
                    financiamentoAlternativoTotalGeral += financiamentoAlternativoTotal
                    valorTotalGeral += valorTotal

                    id = ''
                    financiamentoSugeridoTotal = 0
                    financiamentoAlternativoTotal = 0
                    valorTotal = 0
                }
            })

            fluxoCaixaMensalEconomiaPerdaResumo.push({
                parcelas: '',
                id: '',
                financiamentoSugerido: '',
                financiamentoAlternativo: '',
                valor: '',
                saldo: '',
                saldoPagtoFinanciamentoSugeridoAVista: '',
                saldoPagtoParcelasFinanciamentoSugeridoReceitaMensal: '',
            })

            fluxoCaixaMensalEconomiaPerdaResumo.push({
                parcelas: '',
                id: LABEL.total,
                financiamentoSugerido: financiamentoSugeridoTotalGeral,
                financiamentoAlternativo: financiamentoAlternativoTotalGeral,
                valor: valorTotalGeral,
                saldo: fluxoCaixaMensalEconomiaPerdaDetalhe[fluxoCaixaMensalEconomiaPerdaDetalhe.length - 1].saldo,
                saldoPagtoFinanciamentoSugeridoAVista:
                    fluxoCaixaMensalEconomiaPerdaDetalhe[fluxoCaixaMensalEconomiaPerdaDetalhe.length - 1]
                        .saldoPagtoFinanciamentoSugeridoAVista,
                saldoPagtoParcelasFinanciamentoSugeridoReceitaMensal:
                    fluxoCaixaMensalEconomiaPerdaDetalhe[fluxoCaixaMensalEconomiaPerdaDetalhe.length - 1]
                        .saldoPagtoParcelasFinanciamentoSugeridoReceitaMensal,
                bold: true,
            })
            
            //let irrFinanciamentoSugerido

            //if (linhaFinanciamentoSugerido.tabelaSAC || linhaFinanciamentoSugerido.consorcio) {

            //    if (linhaFinanciamentoSugerido.tabelaSAC) {

            //        irrFinanciamentoSugerido = linhaFinanciamentoSugerido.taxaAnual

            //    } else {

            //        calculaIrr(fluxoCaixaMensalFinanciamentoSugerido, round(linhaFinanciamentoSugerido.valorSaldoDevedor, 2))
            //    }
            //}

            graficoTransformaZeroNull(graficoFinanciamentoSugerido)
            graficoTransformaZeroNull(graficoFinanciamentoAlternativo)

            estudo.current.graficoFinanciamentoSugerido = graficoFinanciamentoSugerido
            estudo.current.graficoFinanciamentoAlternativo = graficoFinanciamentoAlternativo
            estudo.current.graficoEcomoniaFinanciamentoSugerido = graficoEcomoniaFinanciamentoSugerido
            estudo.current.graficoPerdaFinanciamentoSugerido = graficoPerdaFinanciamentoSugerido
            estudo.current.graficoSaldoPagtoFinanciamentoSugeridoAVistaPositivo = graficoSaldoPagtoFinanciamentoSugeridoAVistaPositivo
            estudo.current.graficoSaldoPagtoFinanciamentoSugeridoAVistaNegativo = graficoSaldoPagtoFinanciamentoSugeridoAVistaNegativo
            estudo.current.graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo =
                graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalPositivo
            estudo.current.graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo =
                graficoSaldoPagtoParcelasFinanciamentoSugeridoReceitaMensalNegativo
            estudo.current.fluxoCaixaMensalEconomiaPerdaDetalhe = fluxoCaixaMensalEconomiaPerdaDetalhe
            estudo.current.fluxoCaixaMensalEconomiaPerdaResumo = fluxoCaixaMensalEconomiaPerdaResumo
            //estudo.current.idadesFinanciamento = idadesFinanciamento
            estudo.current.anosFinanciamento = anosFinanciamento
            estudo.current.descricaoFinanciamentoSugerido = pesquisaDescricaoList(financiamentoList(values, null, false),
                values.objComparacaoFinanciamentos.idFinanciamentoSugerido)
            estudo.current.descricaoFinanciamentoAlternativo = pesquisaDescricaoList(financiamentoList(values, null, false),
                values.objComparacaoFinanciamentos.idFinanciamentoAlternativo)
            estudo.current.descricaoAbreviadaFinanciamentoSugerido = pesquisaDescricaoList(financiamentoList(values, null, true),
                values.objComparacaoFinanciamentos.idFinanciamentoSugerido)
            estudo.current.descricaoAbreviadaFinanciamentoAlternativo = pesquisaDescricaoList(financiamentoList(values, null, true),
                values.objComparacaoFinanciamentos.idFinanciamentoAlternativo)
        }
    }
}

/*
const calculaIrr = (fluxo, invest) => {

    console.log(fluxo)
    let irrMin = 0.01
    let irrMax = 5
    let irrAdd = 0.01

    let continuar = true

    let taxa = null

    let irr = irrMin

    while (continuar) {

        let total = 0

        // eslint-disable-next-line 
        fluxo?.forEach( (linha, ind) => {
            
            total = total + (linha.valor / Math.pow(1 + (irr / 100), ind + 1))
        })
        console.log('---------')
        console.log(total)
        console.log(invest)
        console.log(irr)

        total = total - invest

        if (irr > irrMax) {

            continuar = false

        } else {

            if (total < -100) {

                irr = irr - irrAdd
                
                irrAdd = 0.001

            } else {

                if (total >= -100 && total <= 100) {

                    taxa = irr
                    continuar = false

                } else {

                    irr = irr + irrAdd
                }
            }
        }
    }

    console.log(taxa)

    return taxa
}
*/